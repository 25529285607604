import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import { Eye } from '@styled-icons/heroicons-solid/Eye';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { StatusOffline } from '@styled-icons/heroicons-outline/StatusOffline';
import { StatusOnline } from '@styled-icons/heroicons-outline/StatusOnline';
import { EditOff } from '@styled-icons/material/EditOff';
import { Data } from 'styled-icons/boxicons-regular';
import { TimeSlot } from '@styled-icons/entypo/TimeSlot';
import { Phone } from '@styled-icons/entypo/Phone';
import { AlarmAdd } from '@styled-icons/boxicons-regular/AlarmAdd';

const RUTable = ({ reglas, setReglas, setChange }) => {
  const [confR, setConfR] = useState(false);
  const [idR, setIdR] = useState(null);
  const [regla, setRegla] = useState({});
  const [bloque, setBloque] = useState({});
  const [edit, setEdit] = useState(false);
  const [editBloque, setEditBloque] = useState(false);
  const [dataBloque, setDataBloque] = useState({});
  const [data, setData] = useState({});
  const [newBloq, setNewBloq] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeB = (e) => {
    setDataBloque({ ...dataBloque, [e.target.name]: e.target.value });
  };
  const handleChangeNewB = (e) => {
    setNewBloq({ ...newBloq, [e.target.name]: e.target.value });
  };
  const editR = (regla) => {
    setEdit(true);
    setData(regla);
    setRegla(regla);
  };

  const editB = (bloque) => {
    setEditBloque(true);
    setDataBloque(bloque);
    setBloque(bloque);
  };
  const endEditBloque = () => {
    if (dataBloque !== bloque) {
      axiosInstance.patch(`bloques_regla/${bloque.id}/`, dataBloque).then((res) => {
        toast.success('bloque actualizado');
        setChange(Date().toLocaleString());
      });
    }

    setEditBloque(false);
    setDataBloque({});
    setBloque({});
  };
  const endEdit = () => {
    axiosInstance
      .patch(`reglas/${regla.id}/`, {
        day_tries: data['day_tries'],
        nombre: data['nombre'],
        week_tries: data['week_tries'],
        call_tries: data['call_tries'],
        month_tries: data['month_tries'],
        month_confirms: data['month_confirms'],
        week_confirms: data['week_confirms'],
        week_pendientes: data['week_pendientes'],
        month_pendientes: data['month_pendientes'],
        confirms_delta: data['confirms_delta'],
        pendientes: data['pendientes'],
        is_active: data['is_active'],
      })
      .then((res) => {
        setChange(Date().toLocaleString());
      });

    setEdit(false);
    setData({});
    setRegla({});
  };

  const close = () => {
    setIdR(null);
    setConfR(false);
  };
  const rem = (obj) => {
    setRegla(obj);
    setIdR(obj.id);
    setConfR(true);
  };

  const createBlock = () => {
    axiosInstance.post(`bloques_regla/`, { ...newBloq, regla: regla.id, index: 1 }).then((res) => {
      setChange(new Date());
      toast.success('bloque creado');
    });
  };

  const deleteBlock = (id) => {
    axiosInstance.delete(`bloques_regla/${id}/`).then((res) => {
      setChange(new Date());
      toast.success('bloque eliminado');
    });
  };

  const del = () => {
    axiosInstance.delete(`reglas/${idR}/`).then((res) => {
      setReglas(reglas.filter((x) => x.id !== idR));
      setChange(idR);
    });

    setConfR(false);
    setIdR(null);
    setRegla({});
  };

  useEffect(() => {});

  return (
    <table className="w-full table-auto rounded-lg shadow-2xl overflow-scroll scroll">
      {confR && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-gray-300 shadow-2xl rounded-xl pt-3 px-2 w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl h-1/3">Eliminar esta Regla:</p>
            <div className="flex justify-center w-full h-2/3 text-4xl font-bold">
              <p className="my-auto">{regla.nombre} </p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => close()}
              className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => del()}
              className=" focus:outline-none mx-4 py-2 px-1 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <thead>
        <tr>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Nombre
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Mandante
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Llamada
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Dia
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Semana
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Mes
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            C. Delta
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            C. Semana
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            C. Mes
          </th>
          {/* <th */}
          {/*   scope="col" */}
          {/*   className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal" */}
          {/* > */}
          {/*   P. Semana */}
          {/* </th> */}
          {/* <th */}
          {/*   scope="col" */}
          {/*   className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal" */}
          {/* > */}
          {/*   P. Mes */}
          {/* </th> */}
          {/* <th */}
          {/*   scope="col" */}
          {/*   className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal" */}
          {/* > */}
          {/*   P. Max */}
          {/* </th> */}
          {/* <th
            scope="col"
            className="w-10 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Bloques
          </th>*/}
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Status
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Editar
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Eliminar
          </th>
        </tr>
      </thead>
      <tbody>
        {reglas &&
          reglas.map((obj) => (
            <tr key={obj.id} className="">
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                <div className="flex items-center">
                  <div className="ml-3">
                    {edit && obj === regla ? (
                      <input
                        name="nombre"
                        value={data['nombre']}
                        onChange={handleChange}
                        className="w-32 border-gray-800 rounded-lg border-3 p-2 ml-1 border px-1 my-1"
                      ></input>
                    ) : (
                      <p className="text-lg text-gray-900 whitespace-no-wrap">
                        {obj.nombre && obj.nombre}
                      </p>
                    )}
                  </div>
                </div>
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                <p className="text-gray-900 whitespace-no-wrap">
                  {obj.mandante && obj.mandante.nombre}
                </p>
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['call_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="call_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.call_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['day_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="day_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.day_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['week_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="week_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.week_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['month_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="month_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.month_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['confirms_delta']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="confirms_delta"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.confirms_delta}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['week_confirms']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="week_confirms"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.week_confirms}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['month_confirms']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="month_confirms"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.month_confirms}</p>
                )}
              </td>
              {/* <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg"> */}
              {/*   {edit && obj === regla ? ( */}
              {/*     <input */}
              {/*       type="number" */}
              {/*       value={data['week_pendientes']} */}
              {/*       className="w-16 border-gray-800 border rounded-lg border-3 p-2" */}
              {/*       name="week_pendientes" */}
              {/*       onChange={handleChange} */}
              {/*     ></input> */}
              {/*   ) : ( */}
              {/*     <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.week_pendientes}</p> */}
              {/*   )} */}
              {/* </td> */}
              {/* <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg"> */}
              {/*   {edit && obj === regla ? ( */}
              {/*     <input */}
              {/*       type="number" */}
              {/*       value={data['month_pendientes']} */}
              {/*       className="w-16 border-gray-800 border rounded-lg border-3 p-2" */}
              {/*       name="month_pendientes" */}
              {/*       onChange={handleChange} */}
              {/*     ></input> */}
              {/*   ) : ( */}
              {/*     <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.month_pendientes}</p> */}
              {/*   )} */}
              {/* </td> */}
              {/**/}
              {/* <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg"> */}
              {/*   {edit && obj === regla ? ( */}
              {/*     <input */}
              {/*       type="number" */}
              {/*       value={data['pendientes']} */}
              {/*       className="w-16 border-gray-800 border rounded-lg border-3 p-2" */}
              {/*       name="pendientes" */}
              {/*       onChange={handleChange} */}
              {/*     ></input> */}
              {/*   ) : ( */}
              {/*     <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.pendientes}</p> */}
              {/*   )} */}
              {/* </td> */}
              {/* <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg relative">
                <TimeSlot
                  size="30"
                  className="transform hover:scale-125 cursor-pointer text-blue-ligth"
                />

                {edit && obj === regla && (
                  <div className="flex  flex-wrap content-start absolute z-40 w-96 h-64 transform -translate-x-96 translate-y-4 bg-gray-50 border border-blue-semidark rounded-lg overflow-y-scroll scroll px-1 gap-y-3">
                    <div className="w-full h-auto rounded-md border-blue-ligth">
                      <p className="text-center font-bold text-blue-ligth text-lg">Bloques</p>
                    </div>
                    {regla.bloques &&
                      regla.bloques.map((bloq) => {
                        return (
                          <div className="w-full h-1/3 flex border border-blue-ligth rounded-lg">
                            <div className="w-1/2 h-full flex justify-around px-2 border-r-2 border-blue-ligth">
                              <p className="text-2xl text-blue-semi my-auto">
                                {editBloque && bloq === bloque ? (
                                  <input
                                    type="number"
                                    onChange={handleChangeB}
                                    name="inicio_hora"
                                    className="w-8 border border-blue-ligth"
                                  ></input>
                                ) : (
                                  ('0' + bloq.inicio_hora).slice(-2)
                                )}
                                :
                                {editBloque && bloq === bloque ? (
                                  <input
                                    type="number"
                                    onChange={handleChangeB}
                                    name="inicio_minutos"
                                    className="w-8 border border-blue-ligth"
                                  ></input>
                                ) : (
                                  ('0' + bloq.inicio_minutos).slice(-2)
                                )}
                              </p>

                              <p className="text-xl font-bold text-blue-semi my-auto">-</p>

                              <p className="text-2xl text-blue-semi my-auto">
                                {editBloque && bloq === bloque ? (
                                  <input
                                    type="number"
                                    onChange={handleChangeB}
                                    name="fin_hora"
                                    className="w-8 border border-blue-ligth"
                                  ></input>
                                ) : (
                                  ('0' + bloq.fin_hora).slice(-2)
                                )}
                                :
                                {editBloque && bloq === bloque ? (
                                  <input
                                    type="number"
                                    onChange={handleChangeB}
                                    name="fin_minutos"
                                    className="w-8 border border-blue-ligth"
                                  ></input>
                                ) : (
                                  ('0' + bloq.fin_minutos).slice(-2)
                                )}
                              </p>
                            </div>
                            <div className="w-1/2 flex flex-wrap h-full px-1">
                              <div className="w-1/3 h-full justify-center flex border-r-2 border-blue-ligth">
                                {editBloque && bloq === bloque ? (
                                  <input
                                    type="number"
                                    onChange={handleChangeB}
                                    name="intentos"
                                    className="w-10 h-10 my-auto border border-blue-ligth"
                                  ></input>
                                ) : (
                                  <>
                                    <p className="text-3xl text-blue-semi my-auto">
                                      {bloq.intentos}
                                    </p>
                                    <Phone size="30" className="text-blue-semi my-auto" />
                                  </>
                                )}
                              </div>
                              <div className="w-1/3 h-full flex justify-center">
                                {editBloque && bloq === bloque ? (
                                  <EditOff
                                    onClick={() => {
                                      endEditBloque();
                                    }}
                                    size="32"
                                    className="my-auto text-blue-800 transform hover:scale-125 cursor-pointer"
                                  />
                                ) : (
                                  <Edit
                                    onClick={() => editB(bloq)}
                                    size="32"
                                    className="my-auto text-blue-600 transform hover:scale-125 cursor-pointer"
                                  />
                                )}
                              </div>
                              <div className="w-1/3 h-full flex justify-center">
                                <button
                                  onClick={() => deleteBlock(bloq.id)}
                                  className="text-red-500 hover:text-red-600 transform hover:scale-125"
                                >
                                  <TrashBin size="35" className="" />
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="w-full h-1/3 flex border border-blue-ligth rounded-lg">
                      <div className="w-1/2 h-full flex justify-around px-2 border-r-2 border-blue-ligth">
                        <p className="text-2xl text-blue-semi my-auto">
                          <input
                            type="number"
                            onChange={handleChangeNewB}
                            name="inicio_hora"
                            className="w-8 border border-blue-ligth"
                          ></input>
                          :
                          <input
                            type="number"
                            onChange={handleChangeNewB}
                            name="inicio_minutos"
                            className="w-8 border border-blue-ligth"
                          ></input>
                        </p>

                        <p className="text-xl font-bold text-blue-semi my-auto">-</p>

                        <p className="text-2xl text-blue-semi my-auto">
                          <input
                            type="number"
                            onChange={handleChangeNewB}
                            name="fin_hora"
                            className="w-8 border border-blue-ligth"
                          ></input>
                          :
                          <input
                            type="number"
                            onChange={handleChangeNewB}
                            name="fin_minutos"
                            className="w-8 border border-blue-ligth"
                          ></input>
                        </p>
                      </div>
                      <div className="w-1/2 flex flex-wrap h-full px-1">
                        <div className="w-1/2 h-full justify-center flex border-r-2 border-blue-ligth">
                          <input
                            type="number"
                            onChange={handleChangeNewB}
                            name="intentos"
                            className="w-10 h-10 my-auto border border-blue-ligth"
                          ></input>
                        </div>

                        <div className="w-1/2 h-full flex justify-center">
                          <button
                            onClick={() => createBlock()}
                            className="text-green-500 hover:text-green-600 transform hover:scale-125"
                          >
                            <AlarmAdd size="35" className="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </td>*/}
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  data['is_active'] === true ? (
                    <label
                      for="unchecked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                          <input
                            id="unchecked1"
                            onClick={() => setData({ ...data, ['is_active']: false })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Activa</span>
                    </label>
                  ) : (
                    <label
                      for="checked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                          <input
                            id="checked1"
                            onClick={() => setData({ ...data, ['is_active']: true })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Inactiva</span>
                    </label>
                  )
                ) : obj && obj.is_active ? (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">Activa</span>
                  </span>
                ) : (
                  <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">Inactiva</span>
                  </span>
                )}
              </td>

              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <EditOff
                    onClick={() => endEdit()}
                    size="32"
                    className="my-auto text-blue-800 transform hover:scale-125 cursor-pointer"
                  />
                ) : (
                  <Edit
                    onClick={() => editR(obj)}
                    size="32"
                    className="my-auto text-blue-600 transform hover:scale-125 cursor-pointer"
                  />
                )}
              </td>

              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                <button
                  onClick={() => rem(obj)}
                  className="text-red-500 hover:text-red-600 transform hover:scale-125"
                >
                  <TrashBin size="35" className="" />
                </button>
              </td>
            </tr>
          ))}

        <tr className="h-20"></tr>
      </tbody>
    </table>
  );
};

export default RUTable;
