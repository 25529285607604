import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axiosInstance from '../../axios';

import toast from 'react-hot-toast';
import CuentasT from '../../components/carga/cuentas/CuentasT';
import Filters from '../../components/carga/cuentas/Filters';
import Cargar from '../../components/carga/cuentas2/Cargar';
import Tcargas from '../../components/carga/cuentas2/TCargas';
import TCargas from '../../components/carga/cuentas2/TCargas';
import Plantillas from '../../components/carga/cuentas2/Plantillas';
import TPlantillas from '../../components/carga/cuentas2/TPlantillas';

import { getQueryValue } from '../../utils/forms';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CargaCuentas = () => {
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(null);
  const [changePlantillas, setChangePlantillas] = useState(null);
  const [resps, setResps] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const getResps = async () => {
    await axiosInstance.get(`contactos/?${query.toString()}`).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const cargar = (q) => {
    //console.log({nro_cuenta:getQueryValue(q,'nro_cuenta__in').split(",")})
    //console.log(getQueryValue(q,'nro_cuenta__in'))
    //if(getQueryValue(q,'nro_cuenta__in') !== ""){
    //axiosInstance.post(`carga/cuentas/`,{nro_cuenta:getQueryValue(q,'nro_cuenta__in').split(',')})
    //}
    var toastId = toast.loading('carga enviada');
    axiosInstance.post(`carga/cuentas/`, { nro_cuenta: q.split(',') }).then((res) => {
      setChange(new Date());
      toast.dismiss(toastId);
      toast.success('carga finalizada');
    });
  };

  useEffect(() => {
    //getResps()
    //console.log(query)
    //console.log(query.toString())

    return () => {};
  }, [change]);

  return (
    <div className="w-full h-full flex flex-wrap bg-white p-3 ">
      <div className="h-20 w-full flex justify-around border-b">
        <div
          onClick={() => setPage(1)}
          className={`flex my-auto justify-center text-xl w-64 h-10 transition ease-in-out duration-200  rounded-lg text-white font-bold hover:bg-blue-semi transform hover:scale-105 cursor-pointer ${
            page === 1 ? 'bg-blue-normal' : 'bg-blue-semi'
          }`}
        >
          <p className="my-auto">Cargar</p>
        </div>
        <div
          onClick={() => setPage(2)}
          className={`flex my-auto justify-center text-xl w-64 h-10 transition ease-in-out duration-200  rounded-lg text-white font-bold hover:bg-blue-semi transform hover:scale-105 cursor-pointer ${
            page === 2 ? 'bg-blue-normal' : 'bg-blue-semi'
          }`}
        >
          <p className="my-auto">Plantillas</p>
        </div>
      </div>
      {page === 1 && (
        <div className="h-3/5 w-2/6 rounded-md p-2 ">
          <Cargar setChange={setChange} cargar={cargar}></Cargar>
        </div>
      )}
      {page === 1 && (
        <div className="h-3/5 w-4/6 rounded-md p-2 overflow-y-scroll scroll ">
          <TCargas setChange={setChange} change={change}></TCargas>
        </div>
      )}
      {page === 2 && (
        <div className="h-4/5 w-3/5 rounded-md p-2 overflow-x-scroll scroll">
          <Plantillas setChange={setChange}></Plantillas>
        </div>
      )}
      {page === 2 && (
        <div className="h-4/5 w-2/5 rounded-md p-2 overflow-x-scroll scroll">
          <TPlantillas change={change} setChange={setChange}></TPlantillas>
        </div>
      )}
    </div>
  );
};

export default CargaCuentas;

/*
<div className="flex flex flex-wrap w-full h-full bg-white">
             <div className="flex flex-wrap h-1/5 w-full  border-black pb-2 px-2">
                 <div className="w-full flex h-1/5  border-black "><p className="text-3xl font-bold my-auto">Carga Cuentas</p></div>
                    <Filters cargar={cargar} count={pagination.count}></Filters>
             </div>

                <div  style={{height:'72vh'}} className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar ">
                    <CuentasT move={move} pagination={pagination} resps={resps}></CuentasT>
                </div>
            </div>


*/
