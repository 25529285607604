import {useEffect,useState} from 'react'

import axiosInstance from '../../axios'
import toast from 'react-hot-toast'

const TestMails = () =>{
  const [templates,setTemplates] = useState([])
  const [data,setData] = useState({})
  const [template,setTemplate] = useState(null)
  const [templates2,setTemplates2] = useState([])
  const [templateType,setTemplateType] = useState(1)
  const [domains,setDomains] = useState([])

  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      })
      if(e.target.name === 'template_id'){
        setTemplate(templates2.filter(x=>x.id==e.target.value)[0])
        
      }
      console.log(templates2.filter(x=>x.id === data.template_id))
  }
  const send = async () => {
    await axiosInstance.post(`mails/test/`,{...data,template_type:templateType}).then((res) => {
      toast.success('En unos segundos le llegara  un mail')  

    })}

  const getTemplates = async () => {
    await axiosInstance.get(`mail_templates?status=true`).then((res) => {
      setTemplates(res.data);
      if(res.data.length > 0){
        setData({...data,['template_id']:res.data[0].id_template})
      }

    })}

  const getTemplates2 = async () => {
    await axiosInstance.get(`mail_templates_designs?status=true`).then((res) => {
      setTemplates2(res.data);
      if(res.data.length > 0){
        setData({...data,['template_id']:res.data[0].id})
      }

    });
  };

  const getDomains = async () =>{
    await axiosInstance.get(`mail_domains`).then(res=>{
      setDomains(res.data) 
    })
  }

  useEffect(()=>{
    getTemplates()
    getTemplates2()
    getDomains()
  },[])

  return (

	  <div className="w-full h-full flex p-4 gap-x-10 bg-gray-100  gap-y-10 content-start">
      

      <div className="w-96 flex flex-shrink-0 flex-wrap border bg-white rounded-md content-start">
        <div className="w-full h-12 border-b rounded-t-md justify-start p-2 content-center">
          <p className="text-lg text-gray-900 p-1">Datos</p>
        </div>
        
        <div className="w-full h-auto flex flex-wrap p-2 gap-y-3 content-start items-start">
          
          <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Tipo Template</p>
            <div className="w-1/2">
            <select
              onChange={(e)=>setTemplateType(e.target.value)}
              type="number"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >
              <option key={1} value={1}>FullCall</option>
              <option key={2} value={2}>Sendgrid</option>
            </select>            

          </div>
      </div>

      {templateType == 1 && <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Template</p>
            <div className="w-1/2">
            <select
              onChange={handleChange}
              type="text"
              name="template_id"
              className="w-full h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >
              <option key={0} value="">---------</option>
            {templates2.map((template)=>{
              return <option key={template.id} value={template.id}>{template.nombre}</option>
            })}

            </select>
            </div>

          </div>}
    {templateType == 2 && <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Template</p>
            <div className="w-1/2">
            <select
              onChange={handleChange}
              type="text"
              name="template_id"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >
              <option key={0} value="">---------</option>
            {templates.map((template)=>{
              return <option key={template.id} value={template.id_template}>{template.nombre}</option>
            })}

            </select>
            </div>

          </div>}
          <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Dominio</p>
            <div className="w-1/2">
            <select
              onChange={handleChange}
              type="text"
              name="domain"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >
            <option key={0} value="">---------</option>
            {domains.map((dom)=>{
              return <option key={dom.id} value={dom.id}>{dom.dominio}</option>
            })}
            </select>
            </div>
          </div>

          
        <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Email</p>
            <div className="w-1/2">
            <input
              onChange={handleChange}
              type="email"
              name="email"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >

            </input>
            </div>
          </div>

        <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Asunto</p>
            <div className="w-1/2">
            <input
              onChange={handleChange}
              type="text"
              name="subject"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >

            </input>
            </div>
          </div>
        <div className="w-full h-14 flex  p-1 content-center border-b items-center">
            <p className="w-1/2 ">Numero Cuenta</p>
            <div className="w-1/2">
            <input
              onChange={handleChange}
              type="text"
              name="nro_cuenta"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >

            </input>
            </div>
          </div>
        
          <div onClick={()=>send()} className="rounded-md cursor-pointer w-auto px-3 py-1 bg-blue-ligth text-gray-50">Enviar</div>

        </div>

    
      </div>

      <div className=" flex flex-grow flex-wrap border bg-white rounded-md items-start content-start gap-y-2">

        <div className="w-full h-12 border-b rounded-t-md justify-start py-2 px-4 content-center">
          <p className="text-lg text-gray-700">Preview</p>
        </div>
        <div className='w-full h-8 bg-gray-50 border rounded-lg mx-2 flex items-center px-4 gap-x-4'>
          <p className='text-gray-800'>From :</p>
          <p className='w-auto border text-gray-500 bg-white rounded-full px-4'>test@fullcall.cl</p>
        </div>
        <div className='w-full h-8 bg-gray-50 border rounded-lg mx-2 flex items-center px-4 gap-x-4'>
          <p className='text-gray-800'>To :</p>
          <p className='w-auto border text-gray-500 bg-white rounded-full px-4'>{data['email']}</p>
        </div>

        <div className='w-full h-8 bg-gray-50 border rounded-lg mx-2 flex items-center px-4 gap-x-4'>
          <p className='text-gray-800'>Subject :</p>
          <p className='w-auto  text-gray-500 rounded-full px-4'>{data['subject']}</p>
        </div>

        <div className="w-full h-96 flex  justify-center flex-wrap p-2  ">
          {/* <p className="text-gray-600 font-mono">{template && template.text}</p> */}
          {templateType == 1  && template && <img
            className="inline-block h-full w-full object-cover object-center"
            src={template.picture}
            alt=""
          />}

        </div>
      </div>

	  </div>
  )

}
export default TestMails;
