import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';

import Back from '../../components/utils/Back';
import Resumen from '../../components/campaigns/Resumen';
import EditCamp from '../../components/campaigns/EditCamp';
import Llamadas2 from '../../components/callCampaigns/Llamadas.jsx';
import Llamadas from '../../components/campaigns/Llamadas';
import Agentes2 from '../../components/callCampaigns/Agentes2.jsx';
import {MultiPageSelectorChange3} from '../../structs/layout/MultiPage'

import TitleContent from '../../structs/layout/Title-Content'
import TitleBack from '../../structs/layout/TitleBack'

const Campaña = () => {
  const { id } = useParams();
  const [camp, setCamp] = useState({});
  const [page, setPage] = useState(3);
  const [change, setChange] = useState(null);
  //const [llamadas,setLlamadas] = useState([])
  const [data, setData] = useState(null);

  const getData = async () => {
    await axiosInstance.get(`campaigns/${id}/llamadas/`).then((res) => {
      setData(res.data);
    });
  };

  const [addAgenteModal, setAddAgenteModal] = useState(false);

  const getCamp = async () => {
    await axiosInstance.get(`campaigns/` + id + '/resumen/').then((res) => {
      setCamp(res.data);
    });
  };

  useEffect(() => {
    getCamp();
    // getData();
  }, [change]);

  return (
	

	  <div className="w-full flex flex-col h-full">
	      <TitleBack title={camp && camp.nombre}></TitleBack>

      <MultiPageSelectorChange3 setPage={setPage} tabs={[{name:'Llamadas',page:3,current:page == 3 ? true : false},{name:'Estadisticas',page:0,current:page == 0 ? true : false},{name:'Agentes',page:7,current:page == 7 ? true : false},{name:'Configuracion',page:5,current:page == 5 ? true : false},{name:'Llamadas',page:9,current:page == 9 ? true : false}]}></MultiPageSelectorChange3>
          
        <div
          className="flex flex-wrap w-full flex-grow px-3 pb-3 bg-gray-100 "
        >
          {page === 0 && (
            <Resumen
              data={data && data}
              camp={camp && camp}
              id={id}
              
            ></Resumen>
          )}
          {page === 3 && <Llamadas2 camp={camp} setChange={setChange}></Llamadas2>}

          {page === 7 && <Agentes2 setChange={setChange} camp={camp} agentes={camp.agentes} />}
          {page === 5 && camp.id && <EditCamp camp={camp}></EditCamp>}
          {page === 9 && <Llamadas camp={camp} setChange={setChange}></Llamadas>}
        </div>
    </div>
  );
};

export default Campaña;

          // {page === 2 && <Llamadas camp={camp} setChange={setChange}></Llamadas>}
          // {page === 4 && <Agentes setChange={setChange} camp={camp} agentes={camp.agentes} />}
/*
 return (
	
    <div className=" w-full h-full overflow-y-hidden">
      <div className="flex flex-wrap w-full h-full bg-white">
        {addAgenteModal === true && (
          <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
            <AddAgente camp={camp} setModal={setAddAgenteModal} setChange={setChange} />
          </div>
        )}
        <div style={{ height: '15%' }} className="flex flex-wrap w-full ">
          <div className="flex w-full h-1/3 border-b px-3 pt-1 ">
            <div className="w-1/3 flex h-full">
              <Back />
              <h1 className="text-2xl font-bold">Detalle Campaña</h1>
            </div>
            <div className="w-1/3 h-full text-center font-bold text-2xl text-blue-dark">
              {camp && camp.nombre}
            </div>
            <div className="w-1/3 h-full"></div>
          </div>
          <div className="flex w-full h-2/3  py-5 px-3 ">
            {page === 0 ? (
              <div className="w-1/3 h-10 border bg-blue-ligth  border-blue-ligth text-white pt-1 cursor-pointer">
                <p className="text-center font-bold text-xl">Resumen</p>
              </div>
            ) : (
              <div
                onClick={() => setPage(0)}
                className="w-1/3 h-10  cursor-pointer border  text-blue-ligth bg-gray-100 hover:bg-blue-ligth hover:text-white pt-1 border-blue-ligth"
              >
                <p className="text-center font-bold text-xl">Resumen</p>
              </div>
            )}
            {page === 1 ? (
              <div className="w-1/3 h-10 border border-blue-ligth bg-blue-ligth text-white pt-1 cursor-pointer">
                <p className="text-center font-bold text-xl">Estadisticas</p>
              </div>
            ) : (
              <div
                onClick={() => setPage(1)}
                className="w-1/3 h-10  cursor-pointer border  text-blue-ligth bg-gray-100 hover:bg-blue-ligth hover:text-white pt-1 border-blue-ligth"
              >
                <p className="text-center font-bold text-xl">Estadisticas</p>
              </div>
            )}
            {page === 2 ? (
              <div className="w-1/3 h-10 border border-blue-ligth  bg-blue-ligth text-white pt-1 cursor-pointer">
                <p className="text-center font-bold text-xl">Llamadas</p>
              </div>
            ) : (
              <div
                onClick={() => setPage(2)}
                className="w-1/3 h-10  cursor-pointer border  text-blue-ligth bg-gray-100 hover:bg-blue-ligth hover:text-white pt-1 border-blue-ligth"
              >
                <p className="text-center font-bold text-xl">Llamadas</p>
              </div>
            )}
            {page === 3 ? (
              <div className="w-1/3 h-10 border border-blue-ligth  bg-blue-ligth text-white pt-1 cursor-pointer">
                <p className="text-center font-bold text-xl">Lista</p>
              </div>
            ) : (
              <div
                onClick={() => setPage(3)}
                className="w-1/3 h-10  cursor-pointer border  text-blue-ligth bg-gray-100 hover:bg-blue-ligth hover:text-white pt-1 border-blue-ligth"
              >
                <p className="text-center font-bold text-xl">Lista</p>
              </div>
            )}
            {page === 4 ? (
              <div className="w-1/3 h-10 border border-blue-ligth  bg-blue-ligth text-white pt-1 cursor-pointer">
                <p className="text-center font-bold text-xl">Agentes</p>
              </div>
            ) : (
              <div
                onClick={() => setPage(4)}
                className="w-1/3 h-10  cursor-pointer border  text-blue-ligth bg-gray-100 hover:bg-blue-ligth hover:text-white pt-1 border-blue-ligth"
              >
                <p className="text-center font-bold text-xl">Agentes</p>
              </div>
            )}
            {page === 5 ? (
              <div className="w-1/3 h-10 border border-blue-ligth  bg-blue-ligth text-white pt-1 cursor-pointer">
                <p className="text-center font-bold text-xl">Configuracion</p>
              </div>
            ) : (
              <div
                onClick={() => setPage(5)}
                className="w-1/3 h-10  cursor-pointer border  text-blue-ligth bg-gray-100 hover:bg-blue-ligth hover:text-white pt-1 border-blue-ligth"
              >
                <p className="text-center font-bold text-xl">Configuracion</p>
              </div>
            )}
          </div>
        </div>

        <div
          style={{ height: '85%' }}
          className="flex flex-wrap w-full px-3 pb-3 overflow-y-hidden"
        >
          {page === 0 && (
            <Resumen
              data={data && data}
              camp={camp && camp}
              id={id}
              setAddAgenteModal={setAddAgenteModal}
            ></Resumen>
          )}
          {page === 1 && <Statistics data={data} id={id} />}
          {page === 2 && <Llamadas camp={camp} setChange={setChange}></Llamadas>}

          {page === 4 && <Agentes setChange={setChange} camp={camp} agentes={camp.agentes} />}
          {page === 5 && camp.id && <EditCamp camp={camp}></EditCamp>}
        </div>
      </div>
    </div>
  );
 */
