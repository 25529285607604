import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'


import toast from 'react-hot-toast'
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import {useParams} from 'react-router-dom'

import Select from 'react-select'

 

const Config= ({camp}) => {
  const [data,setData] = useState({

  })
  const [resps,setResps] = useState([])
  const [agentes,setAgentes] = useState([])
  const { id } = useParams();

  const [ct_recieved,setCt_received] = useState(false)

  const handleChangeCheck = (e) => {
      setData({
        ...data,
        [e.target.name]: !ct_recieved,
      });

      setCt_received(!ct_recieved);
    }
  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }

  const optionsTipos =
    resps.map((tipo) => {
      return { value: tipo.id, label: `${tipo.nombre}` };
    });
  const optionsAgentes=
    agentes.map((tipo) => {
      return { value: tipo.id, label: `${tipo.first_name} ${tipo.last_name}` };
    });

  const getAgentes = async () => {
    await axiosInstance.get(`users?is_active=True`).then((res) => {
      setAgentes(res.data);
    });
  };
  const getResps = async () => {
    await axiosInstance.get(`trespuestas`).then((res) => {
      setResps(res.data);
    });
  };

  const save = () =>{
    axiosInstance.patch(`voice_msg_campaigns/${id}/`,data).then(res=>{
      toast.success('configuracion guardada')
    })
  }

  const handleSelect = (e, name) => {
    console.log(name.name)
    console.log(e.value)
    setData({...data,[name.name]:e.value})

  };

  useEffect(()=>{
    setData({answer:camp.answer,busy:camp.busy,failed:camp.failed,no_answer:camp.no_answer,agente:camp.agente})
    getResps()
    getAgentes()
  },[]) 
  

  return (
      <div className="w-full flex flex-wrap mt-4 bg-white rounded-md shadow p-4 mx-auto gap-y-8">
        <div className="w-full flex flex-wrap p-4 gap-y-3">
            <div className="w-full flex border h-16 rounded-md">
              <div className="w-1/2 border-r flex items-center justify-center"><p className="text-xl text-center">Contesta</p></div>
              <div className="w-1/2 flex items-center justify-center px-10">
              {resps.length > 0 && <Select 
                className="w-full"
                 name="answer"
                 onChange={handleSelect}
                 options={optionsTipos} 
                 value={optionsTipos.filter(x=>x.value==data['answer'])[0]}
                  >

                </Select>}

                </div>
            </div>

            <div className="w-full flex border h-16 rounded-md">
              <div className="w-1/2 border-r flex items-center justify-center"><p className="text-xl text-center">No Contesta</p></div>
              <div className="w-1/2 flex items-center justify-center px-10">
              {resps.length > 0 && <Select 
                className="w-full"
                 name="no_answer"
                 onChange={handleSelect}
                 options={optionsTipos} 
                 value={optionsTipos.filter(x=>x.value==data['no_answer'])[0]}
                  >

                </Select>}

                </div>
            </div>

            <div className="w-full flex border h-16 rounded-md">
              <div className="w-1/2 border-r flex items-center justify-center"><p className="text-xl text-center">Ocupado</p></div>
              <div className="w-1/2 flex items-center justify-center px-10">
              {resps.length > 0 && <Select 
                className="w-full"
                 name="busy"
                 onChange={handleSelect}
                 options={optionsTipos} 
                 value={optionsTipos.filter(x=>x.value==data['busy'])[0]}
                  >

                </Select>}

                </div>
            </div>       

            <div className="w-full flex border h-16 rounded-md">
              <div className="w-1/2 border-r flex items-center justify-center"><p className="text-xl text-center">Fallida</p></div>
              <div className="w-1/2 flex items-center justify-center px-10">
              {resps.length > 0 && <Select 
                className="w-full"
                 name="failed"
                 onChange={handleSelect}
                 options={optionsTipos} 
                 value={optionsTipos.filter(x=>x.value==data['failed'])[0]}
                  >

                </Select>}

                </div>
            </div>
            <div className="h-0.5 w-full bg-gray-300 my-2"></div>

            <div className="w-full flex border h-16 rounded-md">
              <div className="w-1/2 border-r flex items-center justify-center"><p className="text-xl text-center">Agente</p></div>
              <div className="w-1/2 flex items-center justify-center px-10">
              {resps.length > 0 && <Select 
                className="w-full"
                 name="agente"
                 onChange={handleSelect}
                 options={optionsAgentes} 
                 value={optionsAgentes.filter(x=>x.value==data['agente'])[0]}
                  >

                </Select>}

                </div>
            </div>
          <button className="bg-blue-ligth px-3 py-1 rounded-sm text-gray-100 mt-4" onClick={()=>save()}>Guardar</button>
        </div>    

   </div>
  )
}

export default Config;


