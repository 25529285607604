import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';

import StatsUser from '../components/dashboardAgente/StatsUser';

const DashboardStatsAgente = () => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [change, setChange] = useState(1);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });

  const handleChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };

  const getData = async () => {
    if (dates['desde'] !== null && dates['hasta'] !== null) {
      await axiosInstance
        .get(
          `llamadas/dashboard_agente/?user=${user.id}&desde=${dates['desde']}&hasta=${dates['hasta']}`,
        )
        .then((res) => {
          setData(res.data);
        });
    }
  };

  useEffect(() => {
    if (dates['desde'] === null || dates['hasta'] === null) {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
    }

    getData();
  }, [change, dates]);

  return (
    <div className="w-full h-full pt-1 px-1 bg-gray-50 overflow-y-scroll no-scrollbar flex flex-wrap">
      <div className="h-8 w-full flex rounded-lg overflow-hidden mt-1 mb-1 justify-end">
        <div className="rounded-md flex border border-blue-semi w-auto px-2">
          <p className="text-lg mr-2">Desde</p>
          <input
            name="desde"
            type="date"
            value={dates['desde']}
            onChange={handleChange}
            className="border bg-gray-200 rounded-md"
          ></input>
        </div>
        <div className="rounded-md flex bg-gray-200  border border-blue-semi w-auto px-2 ml-3">
          <p className="text-lg mr-2">Hasta</p>
          <input
            name="hasta"
            type="date"
            value={dates['hasta']}
            onChange={handleChange}
            className="border bg-gray-200 rounded-md"
          ></input>
        </div>
        <div
          onClick={() => {
            setChange(change + 1);
          }}
          className="px-2 hover:bg-opacity-75 transform hover:scale-110 cursor-pointer rounded-lg bg-blue-semi flex mx-4 justify-center"
        >
          <p className="my-auto font-bold text-white ">Buscar</p>
        </div>
      </div>
      <StatsUser dates={dates} data={data} />
    </div>
  );
};

export default DashboardStatsAgente;
