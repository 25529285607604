import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

const stats = [
  { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UserStats = ({data}) => {
  

  return (
    <div className="w-full mx-4">
      <dl className=" grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
          <div key={1} className="px-4 py-2 ">
            <dt className="text-base font-normal text-gray-900">Total Llamadas</dt>
            <dd className="mt-3 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                {data.stats.total}
              </div>
            </dd>
          </div>
          <div key={2} className="px-4 py-2">
            <dt className="text-base font-normal text-gray-900">Prom. Diario</dt>
            <dd className="mt-3 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                {data.stats.promedio_llamadas_diario.count}
              </div>
            </dd>
          </div>
        <div key={3} className="px-4 py-2 ">
            <dt className="text-base font-normal text-gray-900">Contactabilidad</dt>
            <dd className="mt-3 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                {data.stats.contactabilidad.perc}%
              </div>
            </dd>
          </div>
      </dl>
    </div>
  )
}
export default UserStats;
{/* <div */}
{/*                 className={classNames( */}
{/*                   item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', */}
{/*                   'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0' */}
{/*                 )} */}
{/*               > */}
{/*                 {item.changeType === 'increase' ? ( */}
{/*                   <ArrowUpIcon */}
{/*                     className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" */}
{/*                     aria-hidden="true" */}
{/*                   /> */}
{/*                 ) : ( */}
{/*                   <ArrowDownIcon */}
{/*                     className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500" */}
{/*                     aria-hidden="true" */}
{/*                   /> */}
{/*                 )} */}
{/**/}
{/*                 <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span> */}
{/*                 {item.change} */}
{/*               </div> */}
{/**/}
