
import Grafico from '../../Grafico';
import Bars from './Bars.js'

const Days = ({data}) =>{
  return (
    <>
          {data && <Grafico data={data.days} groups={data.days}>
            <Bars></Bars>
            </Grafico>}
            </>

  )
}

export default Days;
