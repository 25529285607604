export const ActionTypes = {
  SET_DEVICE: 'SET_DEVICE',
  SET_STATE: 'SET_STATE',
  SET_TOKEN: 'SET_TOKEN',
  SET_LLAMADA: 'SET_LLAMADA',
  SET_NUMERO: 'SET_NUMERO',
  SET_CONTACTO: 'SET_CONTACTO',
  SET_AGENTE: 'SET_AGENTE',
  SET_DIR: 'SET_DIR',
  SET_MODAL: 'SET_MODAL',
  SET_TYPE: 'SET_TYPE',
  SET_REALIZADA: 'SET_REALIZADA',
};
