import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'

import {Email} from '@styled-icons/material-outlined/Email'
import {CursorClick} from '@styled-icons/heroicons-outline/CursorClick'
import {MarkEmailRead} from '@styled-icons/material-sharp/MarkEmailRead'
import {ArrowBounce} from '@styled-icons/fluentui-system-filled/ArrowBounce'
import {Minutemailer} from '@styled-icons/simple-icons/Minutemailer'



const Stats = ({data}) =>{

  return (
    <div className="w-full flex flex-wrap justify-between gap-4">
      <div 
            className="relative h-28 overflow-hidden rounded-lg bg-white px-2 pt-5  shadow  w-64 "
          >
            <dt>
              <div className="absolute rounded-md  p-3">
                <Email size="32" className="text-blue-ligth font-bold"></Email> 
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Total</p>
            
            </dt>
            <dd className="ml-16 flex items-baseline  ">
              <p className="text-2xl font-semibold text-gray-900">{data.stats.total}</p>
            </dd>
          </div>

        <div 
            className="relative overflow-hidden h-28 rounded-lg bg-white px-2 pt-5  shadow w-64"
          >
            <dt>
              <div className="absolute rounded-md  p-3">
                <Minutemailer size="32" className="text-blue-ligth font-bold"></Minutemailer> 
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Enviados</p>
            
            </dt>
            <dd className="ml-16 flex items-baseline justify-between">
              <p className="text-2xl font-semibold text-gray-900">{data.stats.delivered.count}</p>
              <div className="flex items-baseline text-xl font-semibold text-blue-600">
                {data.stats.delivered.pct}
              </div>
            </dd>
          </div>

          <div 
            className="relative overflow-hidden h-28 rounded-lg bg-white px-2 pt-5  shadow w-64"
          >
            <dt>
              <div className="absolute rounded-md  p-3">
                <MarkEmailRead size="32" className="text-blue-ligth font-bold"/> 
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Abiertos</p>
            
            </dt>
            <dd className="ml-16 flex items-baseline justify-between">
              <p className="text-2xl font-semibold text-gray-900">{data.stats.opened.count}</p>
              <div className="flex items-baseline text-xl font-semibold text-blue-600">
                {data.stats.opened.pct}
              </div>
            </dd>
          </div>

        <div 
            className="relative overflow-hidden h-28 rounded-lg bg-white px-2 pt-5  shadow w-64"
          >
            <dt>
              <div className="absolute rounded-md  p-3">
                <CursorClick size="32" className="text-blue-ligth font-bold"/> 
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Clickeados</p>
            
            </dt>
            <dd className="ml-16 flex items-baseline justify-between">
              <p className="text-2xl font-semibold text-gray-900">{data.stats.clicked.count}</p>
              <div className="flex items-baseline text-xl font-semibold text-blue-600">
                {data.stats.clicked.pct}
              </div>
            </dd>
          </div>

        <div 
            className="relative overflow-hidden h-28 rounded-lg bg-white px-2 pt-5  shadow w-64"
          >
            <dt>
              <div className="absolute rounded-md  p-3">
                <ArrowBounce size="32" className="text-blue-ligth font-bold"/> 
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">Rebotados</p>
            
            </dt>
            <dd className="ml-16 flex items-baseline justify-between">
              <p className="text-2xl font-semibold text-gray-900">{data.stats.bounced.count}</p>
              <div className="flex items-baseline text-xl font-semibold text-blue-600">
                {data.stats.bounced.pct}
              </div>
            </dd>
          </div>
           </div>

    )
}
export default Stats;
