const FastFiltro = ({children}) =>{

  return (
    <div className="border bg-white rounded-md w-full h-16 p-2 flex items-center justify-between">
      {children}

    </div>
  )
}


export default FastFiltro;
