import {useState,useEffect} from 'react'

import axiosInstance from '../../axios';

import TableContactabilidad from '../../components/mandantes/TableContactabilidad'

const months = [{value:1,name:'Enero'},{value:2,name:'Febrero'},{value:3,name:'marzo'},{value:4,name:'Abril'},{value:5,name:'Mayo'},{value:6,name:'Junio'},{value:7,name:'Julio'},{value:8,name:'Agosto'},{value:9,name:'Septiembre'},{value:10,name:'Octubre'},{value:11,name:'Noviembre'},{value:12,name:'Diciembre'}]
const MandantesDashboard = () =>{
  const [data,setData] = useState([])
  const [dates, setDates] = useState({
    month: null,
    year: null,
  });
  const [inform,setInform] = useState(1)
  
  


  const getData = async  () =>{
    //console.log(`llamadas/contactabilidad_mandantes?year=${dates['year']}&month=${dates['month']}`)
    console.log('getDataaaa')
    console.log(inform)
    console.log(inform ==1)

    if(inform == 1){
        console.log('1111111')
        await axiosInstance.get(`llamadas/contactabilidad_mandantes?year=${dates['year']}&month=${dates['month']}`).then(res=>{
            setData(res.data)
        })
    }else if(inform == 2){
        console.log('2222222')
        await axiosInstance.get(`llamadas/contactabilidad_mandantes_camps`).then(res=>{
            setData(res.data)
        })
    }
    
    

  }

  const handleChange = (e) =>{
    setDates({...dates,[e.target.name]:e.target.value})
  }

  useEffect(()=>{
     if (dates['year'] === null || dates['month'] === null) {
        var date = new Date();
        var year = parseInt(date.getFullYear())
        var month = parseInt(date.getMonth()) + 1
        setDates({ year: year, month: month});
    }else{
      getData()
    }   
    
    
  },[dates,inform])





  return (
    <div className="w-full h-full flex-wrap bg-gray-100">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Dashboard Mandantes</p>

        <div className='flex gap-x-2'>
            <select onChange={(e)=>setInform(e.target.value)} type="integer" value={inform} className='px-2 py-1 bg-white border border-blue-semi rounded-md'>
                <option value={1}>Contactabilidad Mensual</option>
                <option value={2}>Contactabilidad Camps. Activas</option>
            </select> 
        </div>
        <div className='flex gap-x-2'>
            <select onChange={(e)=>setDates({...dates,month:e.target.value})} value={dates['month']} className='px-2 py-1 bg-white border border-blue-semi rounded-md'>
                {months.map((m)=>{
                    return <option value={m.value}>{m.name}</option>
                })}
            </select> 
        </div>
      </div>

      <div className="w-full flex flex-wrap p-6 gap-6 overflow-y-scroll bg-gray-100">
        {inform == 1 && data && <TableContactabilidad data={data}></TableContactabilidad>}
        {inform == 2 && data && <TableContactabilidad data={data}></TableContactabilidad>}

     </div>
                    



    </div>


  )
}

export default MandantesDashboard;

