
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer ,Tooltip, Legend,LabelList} from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" className="px-2">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );

};
const GeneroG = ({data}) =>{
    return (

      <ResponsiveContainer className="flex justify-center items-center" width="100%" height="100%">
      <PieChart >
        <Pie
          data={data}
          innerRadius={90}
          outerRadius={130}
          fill="#8884d8"
          labelLine={false}
          paddingAngle={5}
          dataKey="value"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}

        {/* <LabelList dataKey="perc" position="inside"/> */}


        </Pie>
          <Tooltip />
          <Legend iconType='circle'/>
      </PieChart>

      </ResponsiveContainer>
    );
}
export default GeneroG;
