import React,{useEffect,useState} from 'react'
import axiosInstance from '../../../axios'
import format from '../../../utils/formatDate'

    /* rut = models.CharField(max_length=15,null=True,blank=True)
    numero = models.CharField(max_length=50,null=True,blank=True)
    tipo = models.CharField(max_length=15,null=True,blank=True)
    fecha = models.DateField(null=True)
    monto = models.FloatField(null=True,blank=True)
    saldo = models.FloatField(null=True,blank=True)
    status = models.CharField(max_length=20,null=True,blank=True)
    numero_operacion = models.CharField(max_length=50,null=True,blank=True)
    mandante = models.CharField(max_length=50,null=True,blank=True) */

const Docs = () => {
    const [docs,setDocs] = useState([])

    const getDocs= async () =>{
        await axiosInstance.get(`documents?rut=21076972-2`).then(res=>{
            setDocs(res.data)
        })
    }

    useEffect(()=>{
        getDocs()
    },[])

  return (
    <div className='w-full flex flex-wrap justify-around'>
        {docs.map((doc)=>{
            return <div className='flex flex-wrap w-64   justify-between rounded-md border p-2'>
            <div className='w-full flex justify-between items-center mb-4 '>
                <p className='text-center text-lg '>{doc.document_name}</p>
                <p className='w-20 p-1 border text-center rounded-full text-sm'>{doc.status}</p>
            </div>


            <div className='w-full flex justify-between items-center mb-4 '>
                <p className='text-gray-600 font-mono text-sm'>{doc.rut}</p>
                <p className='text-gray-600 font-mono text-sm'>{doc.numero}</p>
            </div>

            

            <p>{doc.fecha && format(doc.fecha)}</p>
            <p className='text-gray-800'>{doc.mandante}</p>
            <p className='text-gray-700 w-full text-lg font-semibold text-center my-4'>{doc.monto} <span className='text-sm'>{doc.monto_tipo}</span></p>
            
        </div>
        })}
        


    </div>
  )
}

export default Docs