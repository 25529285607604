import { useState } from 'react';
import CargaSQL from '../../components/carga/cuentas3/CargaSQL.jsx';
import CargaFile from '../../components/carga/docs/CargaExcel.jsx';
import Terminar from '../../components/carga/docs/Terminar.jsx';
import CargaTxt from '../../components/carga/docs/CargaTxt.jsx';
import TCargas from '../../components/carga/docs/TCargas';

const CargaDocs = () => {
  const [change, setChange] = useState(null);

  return (
    <div className="w-full min-h-full h-auto flex flex-wrap bg-gray-100 content-start">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Carga Documentos</p>
      </div>

      <div className="w-full flex flex-wrap px-6 content-start mt-8 gap-x-4 justify-center gap-y-4 mb-10">
        <div className="w-full flex gap-x-2">
          <CargaFile></CargaFile>
          <CargaTxt />
    <Terminar/>
        </div>

        <div className="w-full h-auto rounded-md border shadow-md bg-white ">
          <TCargas change={change} setChange={setChange}></TCargas>
        </div>
      </div>
    </div>
  );
};

export default CargaDocs;
