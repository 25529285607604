import React, { useState, useEffect } from 'react';

import { Link,useParams } from 'react-router-dom';

import axiosInstance from '../../axios';
import format from '../../utils/formatDate';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { Verified } from '@styled-icons/material/Verified';
import { Unverified } from '@styled-icons/octicons/Unverified';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';

const Emails= ({ contacto, setChange }) => {
  const [type, setType] = useState('2');
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [addNumModal, setAddNumModal] = useState(false);
  const [delNumModal, setDelNumModal] = useState(false);
  const [toggleEditNum, setToggleEditNum] = useState(1);
  const [editNumero, setEditNumero] = useState(false);
  const [numeros,setNumeros] = useState([])
  const [numero, setNumero] = useState({
    contacto: null,
    numero: '+',
    verified: null,
    status: 1,
  });
  const [numeroData, setNumeroData] = useState({
    id: null,
    numero: '+',
    verified: null,
    status: 1,
    contacto: null,
  });
  const [llamadas, setLlamadas] = useState([]);

  const [data, setData] = useState({
    contacto: contacto.id,
    address:'',
    verification: 2,
  });

  const [pag, setPag] = useState({
    next: null,
    count: 0,
    previous: null,
  });

  const verificate = (num, status) => {
    axiosInstance.post(`numeros/${num.id}/verificar/`, { verified: status }).then((res) => {
      setChange(res.data);
    });
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getNumeros = async () =>{
    await axiosInstance.get(`emails?contacto=${id}`).then(res=>{
      res.data && setNumeros(res.data)
    })
  }

  const handleChangeNum = (e) => {
    // for edit num form
    setNumeroData({ ...data, [e.target.name]: e.target.value });
  };

  const addNum = () => {
    var errs = [];
      axiosInstance
        .post(`emails/`, { ...data, contacto: contacto.id })
        .then((res) => {
          setErrors([]);
          setData({ contacto: contacto.id, numero: '+', verified: 2, status: 1 });
          setChange(res.data);
          setAddNumModal(false);
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status == 500) {
            setErrors(['Error de sistema']);
          } else if (err.response !== undefined && err.response.status == 400) {
            if (err.response !== undefined && 'data' in err.response) {
              for (let property in err.response.data) {
                //console.log(`${property} : ${err.response.data[property]}`)
                errs.push(`${property} : ${err.response.data[property]}`);
              }
            }
          } else {
          }
          setErrors(errs);
        });
  };

  const handleChangeN = (e) => {
    // for create num form
    e.preventDefault();
   setData({ ...data, ['address']: e.target.value });
  };

  const delNumConf = (numero) => {
    setNumero(numero);
    setDelNumModal(true);
  };

  const delNum = () => {
    axiosInstance.delete(`numeros/${numero.id}/`).then((res) => {
      setDelNumModal(false);

      setChange(Date().toLocaleString());
    });
  };

  const editNum = (num) => {
    setToggleEditNum(num.status);
    setNumero(num);
    setNumeroData({
      ['id']: num.id,
      ['numero']: num.numero,
      ['verified']: num.verified,
      ['status']: num.status,
      ['contacto']: num.contacto,
    });
    setEditNumero(true);
  };

  const editNumT = (status) => {
    setToggleEditNum(status);
    setNumeroData({ ...numeroData, ['status']: status });
  };
  const endEditNum = () => {
    //console.log(JSON.stringify(numeroData))
    //console.log(JSON.stringify(numero))
    //console.log(JSON.stringify(numeroData) == JSON.stringify(numero))
    if (JSON.stringify(numeroData) !== JSON.stringify(numero)) {
      axiosInstance
        .patch(`numeros/${numero.id}/`, {
          ...numeroData,
          id: numero.id,
          contacto: numero.contacto,
          verified: numero.verified,
        })
        .then((res) => {
          console.log(res);
          setChange(Date().toLocaleString());
        });
    }

    setEditNumero(false);
    setNumero({ contacto: null, numero: '+', verified: null, status: 1 });
    setNumeroData({ contacto: null, numero: '+', verified: null, status: 1 });
  };

  useEffect(() => {
    getNumeros()

  }, [type, contacto]);

  return (
    <>
      {delNumModal && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-gray-50 shadow-2xl rounded-xl pt-3 px-2 w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex border">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl h-1/3">Eliminar este Email:</p>
            <div className="flex justify-center w-full h-2/3 text-4xl font-bold">
              <p className="my-auto">{numero && numero.address}</p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => setDelNumModal(false)}
              className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => delNum()}
              className=" focus:outline-none mx-4 py-2 px-5 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      {addNumModal && (
        <div className="fixed pin z-50 overflow-auto flex w-full h-full justify-center">
          <div className="flex flex-wrap mt-6 flex-col h-96 bg-gray-50 border shadow-2xl md:mr-64 sm:mr-0  rounded-lg pt-3 px-2 w-96 fixed pin z-50 overflow-auto bg-smoke-light flex">
            <form className="flex flex-wrap content-start h-3/5 w-full p-4">
              <input
                type="email"
                name="address"
                className="w-full h-2/6 border p-3"
                onChange={handleChangeN}
                placeholder=""
              ></input>
            </form>
            <div className="flex flex-wrap w-full justify-center h-2/5">
              <div className="w-full h-1/2 flex justify-center">
                <button
                  onClick={() => setAddNumModal(false)}
                  className=" focus:outline-none mx-4  px-4 h-1/2 bg-red-500 rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer  hover:text-white text-white ease-linear transition-all duration-150"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={() => addNum()}
                  className="bg-blue-ligth h-1/2 w-2/6 py-1 px-4 rounded-lg text-white font-bold hover:bg-black"
                >
                  Crear
                </button>
              </div>
              {errors.length > 0 && (
                <div className="flex flex-wrap h-1/2  pt-2 overflow-y-scroll no-scrollbar w-full">
                  {errors.length > 0 &&
                    errors.map((error) => {
                      return (
                        <div
                          className="bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                          role="alert"
                        >
                          <p className="font-bold w-full">Error</p>
                          <p clasName="w-full">{error}</p>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-wrap w-full  h-auto">
        <div className="flex flex-wrap w-full  p-4 border-gray-200">
          <div className="flex w-full bg-gray-200 border-gray-500 justify-center rounded-t-lg">
            <p className="text-xl w-full text-center font-bold my-auto">Emails</p>
            <div className="flex ">
              <PlusCircle
                onClick={() => setAddNumModal(true)}
                size="40"
                className="text-green-600 mt-1 justify-self-end self-end transform hover:scale-125"
              />
            </div>
          </div>
          <div className="flex flex-wrap content-start w-full shadow-md rounded-lg p-2 overflow-y-scroll gap-y-3">
            {numeros &&
              numeros.map((number) => {
                return (
                  <div className="flex w-full  bg-gray-50 rounded-lg mb-2 border p-2">
                    <div className=" flex justify-center px-1 ">
                      {editNumero && number === numero ? (
                        <input
                          name="address"
                          value={numeroData['address']}
                          onChange={handleChangeNum}
                          className="w-4/5 ml-1 border px-1 my-1"
                        ></input>
                      ) : (
                        <p className="my-auto font-medium ">{number.address}</p>
                      )}
                    </div>

                     </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Emails;
