import { useState } from 'react'
import {Link} from 'react-router-dom'

import axiosInstance from '../../../axios'
import {ThreeDotsVertical} from '@styled-icons/bootstrap/ThreeDotsVertical'
import Actions from './Actions'
import { Template } from 'react-hero-icon/solid'


const TemplateActions = ({template,setShowActions,setChange}) =>{

  const duplicar = async () =>{
    await axiosInstance.post(`mail_templates_designs/${template.id}/duplicar/`).then(res=>{
      setChange(new Date())
      setShowActions(false)
    })
  }

  const changeStatus = async () =>{
    await axiosInstance.patch(`mail_templates_designs/${template.id}/`,{status:!template.status}).then(res=>{
      setChange(new Date())
      setShowActions(false)
    })
  }
  return (
    <div className='absolute w-60 h-auto flex flex-wrap content-start bg-white text-blue-semi border z-0  transform translate-y-8 -translate-x-4 border-blue-semi rounded-md '>
      <div onClick={()=>duplicar()} className='w-full   h-10 flex items-center justify-center  transform  cursor-pointer hover:bg-blue-100'>
        <p>Duplicar</p>
      </div>
      <div onClick={()=>changeStatus()} className='w-full h-10 flex items-center justify-center  transform  cursor-pointer border-t border-blue-semi hover:bg-blue-100'>
        {template.status ? <p>Desactivar</p> : <p>Activar</p>}
        
      </div>

    </div>
  )
}




const EmailTemplateCard = ({template,setChange}) =>{
  const [showActions,setShowActions] = useState(false)
  

  console.log(template)

  return (
      <div className="flex flex-wrap w-64 border bg-white rounded-md  ">
              <div className="aspect-w-4 aspect-h-3 overflow-hidden  bg-gray-100 w-full h-40 border-b">

                <Link to={`mail_templates_designs/${template.id}`} className="hover:bg-gray-300 hover:bg-opacity-75">
                <img src={template.picture} alt="" className="object-cover object-center "/>
                </Link>
              </div>

              <div className="text-base font-medium text-gray-900 w-full p-2 py-2 flex items-center static">
                <h3 className='flex-grow'>
                  <Link to={`mail_templates_designs/${template.id}`}>
                    <span aria-hidden="true text-lg" className=" inset-0" />
                      {template.nombre}
                  </Link>
                </h3>

                {/*<ThreeDotsVertical onClick={()=>setShowActions(!showActions)} size="20" className="relative cursor-pointer transform hover:scale-125 text-blue-ligth">
                </ThreeDotsVertical> */}
                <Actions setChange={setChange} setShowActions={setShowActions} template={template}></Actions>
                {showActions && <TemplateActions setChange={setChange} setShowActions={setShowActions} template={template}></TemplateActions>}
                
              </div>
            <div className="w-full py-1 px-2">
                {/* <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800"> */}
                {/*   Badge */}
                {/* </span> */}
            </div>

            </div>
  )
}

export default EmailTemplateCard;

