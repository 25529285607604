import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import Select from 'react-select';

import toast from 'react-hot-toast';

const LlamadaAction = ({ llamadas, setChange }) => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const [tipoS, setTipoS] = useState(null);
  const [operators, setOperators] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agentesO, setAgentesO] = useState([]);
  const [agente, setAgente] = useState(null);
  const [status, setStatus] = useState(null);
  const [operator, setOperator] = useState(null);
  const [tiposRespuestas, setTiposRespuestas] = useState([]);
  const [dataR, setDataR] = useState({ tipos_in: [], exclude_tipos_in: [] });
  const [data, setData] = useState({
    user: user.id,
  });

  const optionsRespuestas =
    tiposRespuestas &&
    tiposRespuestas.map((r) => {
      return { value: r.id, label: `${r.nombre}` };
    });
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectTiposExclude = (e) => {
    if (e.length > 0) {
      setDataR({
        ...dataR,
        ['exclude_tipos_in']: e.map((mandante) => {
          return mandante.value;
        }),
      });
    } else {
      setDataR({ ...dataR, ['exclude_tipos_in']: [] });
    }
  };
  const handleSelectTiposIn = (e) => {
    if (e.length > 0) {
      setDataR({
        ...dataR,
        ['tipos_in']: e.map((mandante) => {
          return mandante.value;
        }),
      });
    } else {
      setDataR({ ...dataR, ['tipos_in']: [] });
    }
  };
  const getOperators = () => {
    axiosInstance.get(`users/?rol__in=2,3`).then((res) => {
      setOperators(res.data);
    });
  };
  const getAgentes = () => {
    axiosInstance.get(`users/?rol__in=1,2,3&status=1&status=true&is_active=true`).then((res) => {
      setAgentes(res.data);
    });
  };

  const createAction = () => {
    if (tipoS === 1) {
      axiosInstance
        .post(`lactions/delete_llamada/`, {
          ['llamadas']: llamadas.map((ll) => {
            return ll.id;
          }),
        })
        .then((res) => {
          setShow(false);
          setChange(Date.now());
          res.status == 200 &&
            toast.success(`se eliminaron ${llamadas.length} llamadas`, { duration: 5000 });
        });
    } else if (tipoS === 2 && agente !== null) {
      axiosInstance
        .post(`lactions/change_agente/`, {
          ['agente']: agente && agente.id,
          ['llamadas']: llamadas.map((ll) => {
            return ll.id;
          }),
        })
        .then((res) => {
          setShow(false);
          setChange(Date.now());
          res.status == 200 &&
            toast.success(`se reasignaron ${llamadas.length} llamadas a ${agente.first_name}`, {
              duration: 5000,
            });
        });
    } else if (tipoS === 3 && status !== null) {
      axiosInstance
        .post(`lactions/change_status/`, {
          ['llamadas']: llamadas.map((ll) => {
            return ll.id;
          }),
          ['status']: status,
        })
        .then((res) => {
          setShow(false);
          setChange(Date.now());
          res.status == 200 &&
            toast.success(`se cambio el status a ${llamadas.length} llamadas`, { duration: 5000 });
        });
    } else if (tipoS === 4 && agentesO.length > 0) {
      console.log({
        ...dataR,
        ['llamadas']: llamadas.map((ll) => {
          return ll.id;
        }),
        agentes: agentesO.map((a) => {
          return a.id;
        }),
      });
      // axiosInstance.post(`lactions/revolver/`,{...dataR,['llamadas']:llamadas.map((ll)=>{return ll.id}),'agentes':agentesO.map((a)=>{return a.id})})
      // .then(res =>{
      // setShow(false)
      // setChange(Date.now())
      // res.status == 200 && toast.success(`se cambio el status a ${llamadas.length} llamadas`,{duration:5000})
      // })
    }
  };
  const getTipos = async () => {
    await axiosInstance.get('trespuestas/').then((res) => {
      if (res.data.length > 0) {
        setTiposRespuestas(res.data);
      }
    });
  };

  useEffect(() => {
    show && tipoS === 2 && getAgentes();
    show && tipoS === 4 && getAgentes();
    show && tipoS === 4 && getTipos();
  }, [show]);

  return (
    <div className="absolute w-48 flex flex-wrap  z-30 bg-blue-normal transform -translate-x-40 h-auto">
      <div
        onClick={() => {
          setTipoS(1);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Eliminar</p>
      </div>
      <div
        onClick={() => {
          setTipoS(2);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Reasignar</p>
      </div>
      <div
        onClick={() => {
          setTipoS(3);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Cambiar estado</p>
      </div>
      <div
        onClick={() => {
          setTipoS(4);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Revolver</p>
      </div>
      {show && (
        <div className="border border-white flex p-2 flex-wrap content-start w-80 h-96 overflow-y-scroll min-h-80 bg-blue-normal absolute transform -translate-x-80  no-scrollbar">
          {tipoS === 1 && (
            <div className="flex flex-wrap h-3/5 w-full border-b overflow-y-scroll no-scrollbar border mt-3 mb-3 content-start">
              <div className="h-6 w-full bg-white flex justify-center font-bold">Llamadas</div>
              {llamadas.length > 0 &&
                llamadas.map((ll) => {
                  return (
                    <div className="h-10 cursor-pointer w-full border-b">
                      <p className="text-lg text-white">
                        {ll.contacto.nombre} {ll.contacto.apellido} || {ll.fecha_asignada}
                      </p>
                    </div>
                  );
                })}
            </div>
          )}
          {tipoS === 2 && (
            <div className="flex flex-wrap h-3/5 w-full border-b overflow-y-scroll no-scrollbar border mt-3 content-start">
              <div className="h-6 w-full bg-white flex justify-center font-bold">Agente</div>
              {agentes.length > 0 &&
                agentes.map((age) => {
                  if (agente !== null && age.id === agente.id) {
                    return (
                      <div
                        onClick={() => {
                          setAgente(null);
                        }}
                        className="h-10 cursor-pointer w-full border-b bg-gray-700"
                      >
                        <p className="text-lg text-white">
                          {age.first_name} {age.last_name}
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        onClick={() => {
                          setAgente(age);
                        }}
                        className="h-10  cursor-pointer w-full border-b"
                      >
                        <p className="text-lg text-white">
                          {age.first_name} {age.last_name}
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          )}
          {tipoS === 3 && (
            <div className="flex flex-wrap h-3/5 w-full border-b overflow-y-scroll no-scrollbar border mt-3 content-start">
              <div className="h-6 w-full bg-white flex justify-center font-bold ">Status</div>
              <div
                onClick={() => {
                  setStatus(1);
                }}
                className={`h-10 flex justify-center cursor-pointer w-full border-b ${
                  status === 1 && 'bg-gray-700'
                }`}
              >
                <p className={`text-lg text-green-400  my-auto`}>Realizada</p>
              </div>
              <div
                onClick={() => {
                  setStatus(2);
                }}
                className={`h-10 flex justify-center cursor-pointer w-full border-b ${
                  status === 2 && 'bg-gray-700'
                }`}
              >
                <p className={`text-lg text-yellow-400 my-auto`}>Pendiente</p>
              </div>
              <div
                onClick={() => {
                  setStatus(3);
                }}
                className={`h-10 flex justify-center cursor-pointer w-full border-b ${
                  status === 3 && 'bg-gray-700'
                }`}
              >
                <p className={`text-lg text-blue-400   my-auto`}>Rellamada</p>
              </div>
              <div
                onClick={() => {
                  setStatus(4);
                }}
                className={`h-10 flex justify-center cursor-pointer w-full border-b ${
                  status === 4 && 'bg-gray-700'
                }`}
              >
                <p className={`text-lg text-gray-400   my-auto`}>Inactiva</p>
              </div>
              <div
                onClick={() => {
                  setStatus(5);
                }}
                className={`h-10 flex justify-center cursor-pointer w-full border-b ${
                  status === 5 && 'bg-gray-700'
                }`}
              >
                <p className={`text-lg text-red-400    my-auto`}>Recibida</p>
              </div>
              <div
                onClick={() => {
                  setStatus(6);
                }}
                className={`h-10 flex justify-center cursor-pointer w-full border-b ${
                  status === 6 && 'bg-gray-700'
                }`}
              >
                <p className={`text-lg text-red-900    my-auto`}>Terminada</p>
              </div>
            </div>
          )}
          {tipoS === 4 && (
            <>
              <div className="flex flex-wrap h-2/6 w-full border-b overflow-y-scroll no-scrollbar border mt-3 content-start">
                <div className="h-6 w-full bg-white flex justify-center font-bold">Agente</div>
                {agentes.length > 0 &&
                  agentes.map((age) => {
                    if (agentes.length > 0 && agentesO.filter((x) => x == age).length > 0) {
                      return (
                        <div
                          onClick={() => setAgentesO(agentesO.filter((x) => x !== age))}
                          className="h-10 cursor-pointer w-full border-b bg-gray-700"
                        >
                          <p className="text-lg text-white">
                            {age.first_name} {age.last_name}
                          </p>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() => setAgentesO([...agentesO, age])}
                          className="h-10  cursor-pointer w-full border-b"
                        >
                          <p className="text-lg text-white">
                            {age.first_name} {age.last_name}
                          </p>
                        </div>
                      );
                    }
                  })}
              </div>
              <div className="w-full h-2/6 pt-2 px-1">
                <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                  Tiene
                </label>
                <Select
                  name="tipos_in"
                  onChange={handleSelectTiposIn}
                  className="w-11/12 h-4/6"
                  isMulti
                  options={optionsRespuestas}
                />
              </div>
              <div className="w-full h-2/6 pt-2 px-1 mb-4">
                <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                  Excluir si tiene
                </label>
                <Select
                  name="exclude_tipos_in"
                  onChange={handleSelectTiposExclude}
                  className="w-11/12 h-4/6"
                  isMulti
                  options={optionsRespuestas}
                />
              </div>
            </>
          )}

          <div className="w-full h-1/6  flex justify-around py-1">
            <button
              onClick={() => setShow(false)}
              className="rounded-lg bg-red-600 px-3 py-1 text-gray-100"
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="rounded-lg bg-green-600 px-3 py-1 text-gray-100"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LlamadaAction;
