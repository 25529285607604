import React, { useEffect, useState, Component } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Back from '../../components/utils/Back';
import ContactTable from '../../components/listas/ContactTable';
import ContactosT from '../../components/contactos/ContactosT';
import InserExcel from '../../components/listas/InsertExcel';
import CreateCModal from '../../components/contactos/CreateCModal';

import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { PersonAdd } from '@styled-icons/evaicons-solid/PersonAdd';
import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ListaView = () => {
  const { id } = useParams();
  const [lista, setLista] = useState({
    id: '',
  });
  const [contactos, setContactos] = useState([]);
  const [excelModal, setExcelModal] = useState(false);
  const [contactoModal, setContactoModal] = useState(false);
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState(false);
  const [size, setSize] = useState(100);

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setContactos(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const getContactos = async (q = null) => {
    await axiosInstance
      .get(`contactos/?lista=${id}&${q !== null ? q : query.toString()}`)
      .then((res) => {
        setContactos(res.data.results);
        setPagination(res.data);
        console.log(res.data);
      });
  };

  const getLista = async (p) => {
    //await axiosInstance.get('/listas/'+id+'?page='+p.toString()).then((res)=>{
    await axiosInstance.get('/listas/' + id).then((res) => {
      console.log(res.data);
      setLista(res.data);
      //setContactos(res.data.contactos)
    });
  };

  useEffect(() => {
    getLista(page);
    getContactos();
  }, [change, page]);

  return (
    <div className=" w-full h-full">
      {contactoModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateCModal
            listaId={id}
            setModal={setContactoModal}
            contactos={contactos}
            setContacto={setContactos}
            setChange={setChange}
          />
        </div>
      )}
      <div className="flex flex-wrap w-full h-full bg-white">
        <div className="flex flex-wrap justify-between h-12 w-full  border-b">
          <div className="flex  px-3 sm:pt-0 md:pt-1">
            <Back></Back>
            <h1 className="sm:text-xl md:text-2xl lg:text-3xl font-bold">Detalle Lista</h1>
          </div>
          <div className="flex  justify-center py-1">
            <h1 className="sm:text-2xl md:text-3xl lg:text-4xl font-bold text-blue-ligth">
              {lista && lista.nombre}
            </h1>
          </div>

          <div className="flex justify-end">
            <h1 className="my-auto text-2xl font-bold">
              Contactos: {lista && lista.total && lista.total}
            </h1>
            <button
              onClick={() => setContactoModal(true)}
              className="text-blue-800 focus:outline-none hover:text-blue-700 transform hover:scale-125 mx-3"
            >
              <PersonAdd size="35" className="" />
            </button>
          </div>
        </div>
        <div
          className={` h-10 w-full bg-gray-dark mx-2 rounded-t-lg flex justify-between px-2 content-start`}
        >
          <p className="my-auto text-gray-300 font-semibold">Total Contactos: {pagination.count}</p>
          <div className="flex">
            <input
              type="text"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              className="w-10 bg-blue-ligth text-gray-100 font-semibold p-1 my-1"
            ></input>
            <button
              onClick={() => setFilters(!filters)}
              className="my-auto   w-auto p-1 px-2 text-gray-50 font-semibold h-8 rounded-lg  transition ease-in-out delay-150 bg-blue-ligth hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
            >
              {filters ? (
                <ArrowBoldDown size="23" className="text-white transform rotate-180" />
              ) : (
                <ArrowBoldDown size="23" className="text-white transform rotate-0 " />
              )}
            </button>
          </div>
        </div>
        <div
          className={`transition-height ease-in-out delay-300 ${
            filters ? 'h-20' : 'h-0'
          } bg-gray-300 w-full border mx-2`}
        ></div>
        <div className="flex flex-wrap h-auto w-full px-3 overflow-hidden">
          <div
            style={{ height: '78vh' }}
            className="flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
          >
            <div className="flex w-full py-3 text-3xl font-bold border-b-2 border-black">
              <div className="w-1/3">
                {page > 1 && (
                  <button
                    onClick={() => {
                      setPage(page - 1);
                      setChange(Date.now());
                    }}
                    className="text-white mx-2 font-bold bg-blue-500 px-1"
                  >
                    {'<<'}
                  </button>
                )}
                {contactos && contactos.length > 50 && (
                  <button
                    onClick={() => setPage(page + 1)}
                    className="text-white font-bold bg-blue-500 px-1"
                  >
                    {'>>'}
                  </button>
                )}
              </div>
            </div>
            <ContactosT
              setChange={setChange}
              move={move}
              pagination={pagination}
              resps={contactos}
            />
            {/*<ContactTable contactos={contactos} setPage={setPage} page={page} setContactos={setContactos} lista={lista} setChange={setChange}></ContactTable>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListaView;
