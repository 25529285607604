import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import Table from '../../components/respuestas/admin/Table.jsx'
import FastFiltro from '../../components/FastFiltro'
import CreateModal from '../../components/respuestas/CreateModal2.jsx'


import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import {Refresh } from '@styled-icons/foundation/Refresh'

const RespuestasAdmin = () =>{
  const [objs,setObjs] = useState([])
  const [data,setData] = useState({nombre:'',dominio:''})
  const [create,setCreate] = useState(false)
  const [display,setDisplay] = useState([])
  const [status,setStatus] = useState(true)
  const [change,setChange] = useState(false)

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }
  const getObjs = async () =>{
    await axiosInstance.get(`trespuestas/?is_active=${status}`).then(res=>{
      setObjs(res.data) 
      setDisplay(res.data)
    })
  }

  const addObj = async () =>{
    
    data.nombre !== '' && await axiosInstance.post(`trespuestas/`,data).then(res=>{
      // setData({nombre:'',color:''})
      getObjs()
      setCreate(false)
    })
  }

  const filterDisplay = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    var filtered
    if(e.target.name === 'nombre'){
      filtered = objs.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase())
      )
    }else if(e.target.name === 'grupo'){
      if(e.target.value == 0){
       filtered = objs.filter((x) =>
        [1,2,3].includes(e.target.value)
      ) 
      }else{
      filtered = objs.filter((x) =>
        x[`${e.target.name}`] == e.target.value
      )
      }
      
    }
     
    setDisplay(filtered);
  };

  useEffect(()=>{
    getObjs()

  },[status,change])
  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll ">
      <CreateModal open={create} setOpen={setCreate} setChange={setChange}/>

      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Respuestas</p> <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
      </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12 gap-y-6">
            <FastFiltro>
              <input onChange={filterDisplay} name="nombre" className="w-64 border-2  bg-gray-100 px-2 py-1 rounded-md text-gray-700" placeholder="Buscar"></input>
              <select className="border p-2 rounded-md" onChange={(e)=>setStatus(e.target.value)}>
                <option value={true} key={0} >Activo</option>
                <option value={false} key={1} >Inactivo</option>
              </select>

              <select className="border p-2 rounded-md" name="grupo" onChange={filterDisplay}>
                <option value={1} key={1} >Positiva</option>
                <option value={2} key={2} >Negativa</option>
                <option value={3} key={3} >Rellamada</option>
              </select>

              <Refresh onClick={()=>setDisplay(objs)} size="32" className="text-blue-400 transform hover:scale-105 cursor-pointer"/> 
            </FastFiltro>

            <Table objs={display} getObjs={getObjs}></Table>
        </div> 

                
      </div>
  )
}

export default RespuestasAdmin;

  
