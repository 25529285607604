import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';

import { Microphone } from '@styled-icons/boxicons-solid/Microphone';
//import {VolumeFull} from '@styled-icons/boxicons-solid/VolumeFull'
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { PhoneSlash } from '@styled-icons/fa-solid/PhoneSlash';
import { Phone } from '@styled-icons/fa-solid/Phone';
import { ExpandOutline } from '@styled-icons/evaicons-outline/ExpandOutline';
import { ExpandMore } from '@styled-icons/material-rounded/ExpandMore';
import { ExpandLess } from '@styled-icons/material-rounded/ExpandLess';

import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';

//import states from "../twilio/states";
import { useSelector, useDispatch } from 'react-redux';
import { setLlamada, setDir, setType } from '../../redux/actions/llamadaActions';
import { setState } from '../../redux/actions/stateActions';

import Draggable from 'react-draggable';

import Timer from '../Timer';

const PhoneModal = ({ conn }) => {
  const history = useHistory();
  const [mute, setMute] = useState(false);
  const [expand, setExpand] = useState(false);

  const dispatch = useDispatch();

  const state = useSelector((state) => state.state);
  const device = useSelector((state) => state.device);
  const contacto = useSelector((state) => state.llamada.contacto);
  const llama = useSelector((state) => state.llamada.llamada);

  const hang_up = () => {
    //console.log(conn.parameters)
    //console.log(contacto)
    conn.disconnect();
    //device.device.disconnectAll();
  };

  const reject = () => {
    if (conn) {
      conn.reject();
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getCall = async (id) => {
    console.log('##########################');
    //await sleep(1000)
    await axiosInstance.get(`llamadas?id=${id}`).then((res) => {
      console.log(res.data.results[0]);
      dispatch(setLlamada(res.data.results[0]));
    });
  };

  const accept = () => {
    if (conn) {
      conn.accept();
      console.log(conn.customParameters.get('call_id'));
      //console.log(conn)
      let call_id = conn.customParameters.get('call_id');
      console.log(call_id);
      getCall(call_id);

      //dispatch(setDir('incoming'))
      dispatch(setType('phone'));
    }
  };

  const muteM = (bool) => {
    setMute(bool);
    if (conn) {
      conn.mute(bool);
    }
  };

  useEffect(() => {}, [expand]);

  if (!expand) {
    return (
      <Draggable defaultPosition={{ x: 900, y: 50 }}>
        <div className="flex flex-col m-1 bg-gray-900 w-72 h-36 rounded-2xl absolute">
          {state.state === 'Incoming' && (
            <div className="p-1 h-3/6 w-full">
              <h1 className="ml-2 mt-2 text-white text-lg font-bold">
                {conn ? conn.parameters.From : ''}
              </h1>
            </div>
          )}

          {state.state === 'On call' && (
            <div className="flex flex-wrap justify-between items-start content-start p-1 h-3/6 w-full">
              <h1 className="mt-2 w-10/12 text-white text-lg font-bold pl-4">
                {contacto ? `${contacto.nombre} ${contacto.apellido}` : ''}
                {contacto.id && (
                  <UserDetail
                    size="24"
                    onClick={() => history.push(`/contactos/${contacto.id}`)}
                    className="ml-2 text-gray-300 transform hover:scale-110 hover:text-white"
                  />
                )}
              </h1>
              <div className="h-8 w-2/12 rounded-lg px-2 py-2">
                <ExpandMore
                  onClick={() => setExpand(!expand)}
                  size="32"
                  className="text-gray-100 hover:text-gray-300 transform hover:scale-125"
                />
              </div>
              <h1 className="pl-4 pt-1  text-gray-400 text-md font-bold">
                <Timer></Timer>
              </h1>
            </div>
          )}

          {state.state === 'On call' && (
            <div className="flex h-3/6 w-full justify-around px-2 py-3">
              <div className="h-12 bg-gray-700 w-12 rounded-lg px-2 py-2 transform hover:scale-105">
                {mute ? (
                  <Microphone
                    onClick={() => muteM(false)}
                    size="32"
                    className="text-red-500 hover:text-gray-100"
                  />
                ) : (
                  <Microphone
                    onClick={() => muteM(true)}
                    size="32"
                    className="text-gray-100 hover:text-red-500"
                  />
                )}
              </div>
              {/*<div className="h-12 bg-gray-700 w-12 rounded-lg px-2 py-2"><VolumeFull size="32" className="text-gray-100"/></div>*/}
              <div
                onClick={() => hang_up()}
                className="h-12 bg-red-700 w-12 rounded-lg px-2  py-2 text-gray-100 hover:text-gray-300 transform hover:scale-105"
              >
                <PhoneSlash size="32" className="" />
              </div>
              <div className="h-12 bg-gray-700 w-12 rounded-lg px-2 py-2">
                <MoreVerticalOutline size="32" className="text-gray-100" />
              </div>
            </div>
          )}

          {state.state === 'Incoming' && (
            <div className="flex h-3/6 w-full justify-around px-2 py-3">
              <div
                onClick={() => accept()}
                className="h-12 bg-green-600 w-12 rounded-lg px-2  py-2 hover:bg-green-700"
              >
                <Phone size="32" className="text-gray-100" />
              </div>
              <div
                onClick={() => reject()}
                className="h-12 bg-red-700   w-12   rounded-lg px-2    py-2 hover:bg-red-800  "
              >
                <PhoneSlash size="32" className="text-gray-100" />
              </div>
            </div>
          )}
        </div>
      </Draggable>
    );
  } else {
    return (
      <Draggable defaultPosition={{ x: 900, y: 50 }}>
        <div className="flex flex-col m-1 bg-gray-900 w-2/5 h-4/6 min-h-400 rounded-2xl absolute">
          {state.state === 'Incoming' && (
            <div className="p-1 h-1/6 w-full">
              <h1 className="ml-2 mt-2 text-white text-lg font-bold">
                {conn ? conn.parameters.From : ''}
              </h1>
            </div>
          )}

          {state.state === 'On call' && (
            <div className="flex flex-wrap justify-between items-start content-start p-1 h-1/6 w-full">
              <h1 className="mt-2 w-10/12 text-white text-lg font-bold pl-4">
                {contacto ? `${contacto.nombre} ${contacto.apellido}` : ''}
                {contacto.id && (
                  <UserDetail
                    size="24"
                    onClick={() => history.push(`/contactos/${contacto.id}`)}
                    className="ml-2 text-gray-300 transform hover:scale-110 hover:text-white"
                  />
                )}
              </h1>
              <div className="h-8 w-2/12 rounded-lg px-2 py-2">
                <ExpandLess
                  onClick={() => setExpand(!expand)}
                  size="32"
                  className="text-gray-100 hover:text-gray-300 transform hover:scale-125"
                />
              </div>
              <h1 className="pl-4 pt-1  text-gray-400 text-md font-bold">
                <Timer></Timer>
              </h1>
            </div>
          )}
          <div className="w-full h-4/6 p-3">
            <div className="w-full h-full border-white border rounded-lg p-3 overflow-y-scroll no-scrollbar">
              <p className="text-white font-bold text-xl">{llama && llama.script}</p>
            </div>
          </div>
          {state.state === 'On call' && (
            <div className="flex h-1/6 w-full justify-around px-2 py-3">
              <div className="h-12 bg-gray-700 w-12 rounded-lg px-2 py-2 transform hover:scale-105">
                {mute ? (
                  <Microphone
                    onClick={() => muteM(false)}
                    size="32"
                    className="text-red-500 hover:text-gray-100"
                  />
                ) : (
                  <Microphone
                    onClick={() => muteM(true)}
                    size="32"
                    className="text-gray-100 hover:text-red-500"
                  />
                )}
              </div>
              {/*<div className="h-12 bg-gray-700 w-12 rounded-lg px-2 py-2"><VolumeFull size="32" className="text-gray-100"/></div>*/}
              <div
                onClick={() => hang_up()}
                className="h-12 bg-red-700 w-12 rounded-lg px-2  py-2 text-gray-100 hover:text-gray-300 transform hover:scale-105"
              >
                <PhoneSlash size="32" className="" />
              </div>
              <div className="h-12 bg-gray-700 w-12 rounded-lg px-2 py-2">
                <MoreVerticalOutline size="32" className="text-gray-100" />
              </div>
            </div>
          )}

          {state.state === 'Incoming' && (
            <div className="flex h-1/6 w-full justify-around px-2 py-3">
              <div
                onClick={() => accept()}
                className="h-12 bg-green-600 w-12 rounded-lg px-2  py-2 hover:bg-green-700"
              >
                <Phone size="32" className="text-gray-100" />
              </div>
              <div
                onClick={() => reject()}
                className="h-12 bg-red-700   w-12   rounded-lg px-2    py-2 hover:bg-red-800  "
              >
                <PhoneSlash size="32" className="text-gray-100" />
              </div>
            </div>
          )}
        </div>
      </Draggable>
    );
  }
};

export default PhoneModal;
