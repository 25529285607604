import React from 'react';

import Stat from '../../campaigns/graficos2/Stat';
const AStats = ({ data }) => {
  console.log(data);
  return (
    <>
      {data.length > 0 &&
        data.map((agente) => {
          return (
            <div className="flex flex-wrap justify-center h-1/2 w-full md:w-1/2 lg:w-1/3 rounded-md  p-4">
              <div className="flex flex-wrap w-full h-full justify-center border px-4 rounded-lg">
                <p className="w-full text-center font-bold text-lg text-blue-dark  ">
                  {agente.name}
                </p>
                <Stat
                  nombre="Promedio Diario"
                  desc={agente.promedio_llamadas_diario['desc']}
                  count={agente.promedio_llamadas_diario['count']}
                  circles={[]}
                ></Stat>

                <Stat
                  nombre="Contactabilidad"
                  desc={agente.contactabilidad['desc']}
                  count={agente.contactabilidad['count']}
                  circles={[
                    {
                      nombre: '',
                      perc: agente.contactabilidad['perc'],
                    },
                  ]}
                ></Stat>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default AStats;
