import { ActionTypes } from '../constants/action-types';
import axiosInstance from '../../axios';

export const setLlamada = (llamada) => {
  return {
    type: ActionTypes.SET_LLAMADA,
    payload: llamada,
  };
};
export const setContacto = (contacto) => {
  return {
    type: ActionTypes.SET_CONTACTO,
    payload: contacto,
  };
};
export const setNumero = (numero) => {
  return {
    type: ActionTypes.SET_NUMERO,
    payload: numero,
  };
};
export const setAgente = (agente) => {
  return {
    type: ActionTypes.SET_AGENTE,
    payload: agente,
  };
};
export const setDir = (dir) => {
  return {
    type: ActionTypes.SET_DIR,
    payload: dir,
  };
};
export const setModall = (modal) => {
  return {
    type: ActionTypes.SET_MODAL,
    payload: modal,
  };
};
export const setType = (type) => {
  return {
    type: ActionTypes.SET_TYPE,
    payload: type,
  };
};
export const setRealizada = (f) => {
  return {
    type: ActionTypes.SET_REALIZADA,
    payload: f,
  };
};
