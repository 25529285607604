import React,{useEffect,useState} from 'react'

import {useParams} from 'react-router-dom'
import axiosInstance from '../../axios.js'

const Movimientos = () => {
  const [envios,setEnvios] = useState([])

  const { id } = useParams();
  const getEnvios = async () =>{
    await axiosInstance.get(`mail_envios?campaign=${id}`).then(res=>{
      setEnvios(res.data)
    })
  }
  
  useEffect(() => {
    getEnvios()
  }, [])


  return (
    <div className="w-full flex flex-wrap mt-4 justify-center p-4 mx-auto gap-y-8">

      {envios.length > 0 && envios.map((envio)=>{
      return (
        <div className="w-3/5 border rounded-md h-72 bg-white shadow flex flex-wrap p-2">
             
          </div>
      )})}

    </div>
  )
}

export default Movimientos
