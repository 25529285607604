import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import format from '../../utils/formatDate';
import { getTipoOp } from './utils/fields';

import { Link } from 'react-router-dom';
import { CheckSquareFill } from '@styled-icons/bootstrap/CheckSquareFill';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { PeopleCall } from '@styled-icons/fluentui-system-filled/PeopleCall';

const OperacionRow = ({ o, addSelected, removeSelected, selected, removeOp,agentes }) => {
  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState('');
  const [newAgente,setNewAgente] = useState(null)

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(o);
    } else {
      removeSelected(o);
    }

    setChecked(!checked);
  };

  
 const handleChange = (e) => {
    setComment(e.target.value);
  };

  const sendRespuesta = (resp) => {
    var data = { comment: comment, tipo_respuesta: resp ,new_agente:newAgente }
    console.log(data)

    axiosInstance
      .post(`lactions/${o.id}/respuesta/`,data)
      .then((res) => {
        removeOp(o.id);
      });
  };


  useEffect(()=>{
    console.log(o.new_agente.id)
    setNewAgente(o.new_agente.id)
  },[])

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((op) => op.id === o.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
    <tr className="text-gray-700 h-12">
      <td className="border p-1 flex justify-center">
        <input className="my-auto h-14" type="checkbox" checked={checked} onChange={handleCheck} />
      </td>
      <td className="border p-1">{o.llamada.contacto.nombre +' '+o.llamada.contacto.apellido} 
        
    </td>
    
        
      <td className="border p-1">{format(o.fecha)}</td>
      <td className="border p-1">{o.user && `${o.user.first_name} ${o.user.last_name}`}</td>
      <td className="border p-1">
        {o.operador && `${o.operador.first_name} ${o.operador.last_name}`}
      </td>
      <td className="border p-1">{o.tipo && getTipoOp(o.tipo)}</td>
      <td className="border p-1">
        <select onChange={(e)=>{setNewAgente(e.target.value)}} value={newAgente}>
        {agentes.map((option) => (
              <option value={option.id}>{option.first_name +` `+option.last_name}</option>
            ))}
        </select>
        {/* {o.new_agente && `${o.new_agente.first_name} ${o.new_agente.last_name}`} */}
      </td>
      <td className="border p-1 overflow-y-scroll scroll">
        {o.comentario && <p className="">{o.comentario}</p>}
      </td>
      
      <td className="border p-1 w-10">
        <CheckSquareFill
          onClick={() => sendRespuesta(1)}
          size="30"
          className="text-green-500 transform hover:scale-110"
        />
      </td>
      <td className="border p-1 w-10">
        <SquaredCross
          onClick={() => sendRespuesta(2)}
          size="34"
          className="text-red-500 transform hover:scale-110"
        />
      </td>
      <td className="border  w-10">
        
        <Link to={o.llamada.contacto && `/contactos/${o.llamada.contacto.id}`}>
          <UserDetail size="35" className="text-blue-ligth" />
        </Link>
    </td>
    <td className="border w-10"><Link to={o && `/llamadas?id=${o.id}`}>
          <PeopleCall size="25" className="text-blue-ligth" />
        </Link></td>
    </tr>
  );
};

export default OperacionRow;
