import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axiosInstance from '../../axios';

import format from '../../utils/formatDate';
import formatter from '../../utils/formatMoney';

import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Phone } from '@styled-icons/fa-solid/Phone';
import { History } from '@styled-icons/boxicons-regular/History';

import { useSelector, useDispatch } from 'react-redux';
import {
  setLlamada,
  setNumero,
  setContacto,
  setDir,
  setType,
} from '../../redux/actions/llamadaActions';
import { Data } from 'styled-icons/boxicons-solid';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { XCircle } from '@styled-icons/bootstrap/XCircle';
import { Circle } from '@styled-icons/boxicons-regular/Circle';

import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';

import { ExpandLess } from '@styled-icons/material-rounded/ExpandLess';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';

const LlamadaRow = (props, { type }) => {
  const device = useSelector((state) => state.device);

  const [seeNumber, setSeeNumber] = useState(true);
  const [historial, setHistorial] = useState(false);
  const [h, setH] = useState([]);

  const [checked, setChecked] = useState(false);
  var user = JSON.parse(localStorage.getItem('user'));
  const [numero, setNum] = useState('');
  const [llamada, setLlama] = useState({
    contacto: {
      numeros: [],
    },
  });
  const [numeros, setNumeros] = useState([]);

  const data = useSelector((state) => state.llamada);
  const state = useSelector((state) => state.state);
  const dispatch = useDispatch();

  const getHistorial = () => {
    if (historial === false) {
      axiosInstance
        .get(`respuestas?contacto=${props.llamada.contacto.id}&ordering=-add_datetime&page_size=10`)
        .then((res) => {
          setH(res.data.results);
          console.log(res.data.results);
        });
      setHistorial(true);
    } else {
      setHistorial(false);
    }
  };

  const handleCheck = (e) => {
    if (checked === false) {
      props.addSelected(llamada);
    } else {
      props.removeSelected(llamada);
    }

    setChecked(!checked);
    //console.log(props.sel)
  };

  const newResp = () => {
    //patch llamada
    //if(numero !== ''){
    dispatch(setType('ext'));
    dispatch(setLlamada(llamada));
    dispatch(setNumero(numero));
    dispatch(setContacto(llamada.contacto));
    data.modal(true);
    //}
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setNum(numeros.filter((x) => x.id == e.target.value)[0]);
    setSeeNumber(true);
  };

  const Call = (num) => {
    //console.log(device.device.device)
    console.log(props.pen);
    if (numero && device) {
      device.device.connect({
        To: num.numero,
        Status: 'pendiente',
        call_id: llamada.id,
        agente: user.id,
      });

      dispatch(setLlamada(llamada));
      dispatch(setNumero(num));
      dispatch(setContacto(llamada.contacto));
      dispatch(setDir('out'));
      dispatch(setType('phone'));
      props.sP({
        ...props.pen,
        results: props.pen.results.filter((x) => x.id !== props.llamada.id),
      });
      //dispatch() set agente
      //console.log(llamada.contacto)
      //console.log(llamada.id)
      //console.log(data)
    }
  };

  useEffect(() => {
    if (llamada.id === undefined) {
      setLlama(props.llamada);
      if (props.llamada.contacto) {
        setNumeros(props.llamada.contacto.numeros);

        if (props.llamada.contacto.numeros[0]) {
          setNum(props.llamada.contacto.numeros[0]);
        }
      }
    }

    //console.log(props.sel)
    //console.log(props.sel && props.sel.some(ll => ll.id === llamada.id))
    if (props.sel.length > 0 && props.sel.some((ll) => ll.id === llamada.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [llamada, numeros, props.selAll]);
  if (props.type === '2' || props.type === '3') {
    return (
      <tr className="text-gray-700">
        <td className="border w-10 px-3">
          <input className="my-auto " type="checkbox" checked={checked} onChange={handleCheck} />
        </td>
        <td className="border  border-l-0 lg:w-60 p-1">
          {llamada.contacto ? llamada.contacto.nombre : ''}
        </td>
        <td className="border  lg:w-60 p-1">{llamada.contacto ? llamada.contacto.apellido : ''}</td>
        <td className="border  p-1">{llamada.contacto ? llamada.contacto.rut : ''}</td>
        <td className="border  p-1">
          {llamada.contacto && llamada.contacto.mandante && llamada.contacto.mandante}
        </td>
        <td className="border  p-1">
          {llamada.contacto && formatter.format(llamada.contacto.saldo_deuda)}
        </td>
        <td className="border  p-1">{llamada.contacto && llamada.contacto.estado}</td>
        <td className="border w-52 p-1">
          <div className="flex ">
            {numeros.length > 0 && (
              <ExpandLess
                size="40"
                onClick={() => {
                  setSeeNumber(!seeNumber);
                }}
                className="text-gray-700 transform rotate-180 hover:rotate-0 w-1/6"
              ></ExpandLess>
            )}
            {seeNumber ? (
              <p className="my-auto font-bold w-2/3">
                {numero && numero.numero.length == 12 && numero.numero.substring(3, 12)}
                {numero && numero.numero.length == 11 && numero.numero.substring(2, 12)}
              </p>
            ) : (
              <select
                name="numero"
                onChange={handleChange}
                className="border-none inline-block py-2 px-2 rounded leading-tight w-full"
              >
                {numeros.map((num) => {
                  if (num.verified === 1 && num.status === 1) {
                    return (
                      <option key={num.id} className="bg-green-200" value={num.id}>
                        {num.numero}
                      </option>
                    );
                  }
                  if (num.verified === 2 && num.status === 1) {
                    return (
                      <option key={num.id} className="" value={num.id}>
                        {num.numero}
                      </option>
                    );
                  }
                  if (num.verified === 3 && num.status === 1) {
                    return (
                      <option key={num.id} className="bg-red-300" value={num.id}>
                        {num.numero}
                      </option>
                    );
                  }
                })}
              </select>
            )}
            {numero && numero.verified === 1 && (
              <Check size="32" className="text-green-700 w-1/6 text-xl" />
            )}
            {numero && numero.verified === 2 && (
              <Circle size="32" className="text-gray-500 w-1/6 text-xl" />
            )}
            {numero && numero.verified === 3 && (
              <XCircle size="32" className="text-red-600 w-1/6 text-xl" />
            )}
            <p className="font-bold text-blue-ligth">{numeros.length}</p>
          </div>
        </td>

        <td className="border p-1">{llamada.fecha_asignada && format(llamada.fecha_asignada)}</td>
        {/*<td className="border p-1"><Phone onClick={()=>{state.state == 'Ready' && Call(numero)}} size="32" className="transform hover:-translate-y-1 hover:scale-125 ml-10 text-green-500 ml-4"/></td>*/}

        <td className="border  p-1 w-10">
          <History
            size="38"
            onClick={() => getHistorial()}
            className="transform hover:scale-110 text-blue-ligth"
          />
          {historial && (
            <div
              style={{ width: '700px', transform: 'translate(-700px,-10px)' }}
              className="absolute rounded-md h-32 flex flex-wrap  overflow-y-scroll scroll z-20 bg-gray-50 border border-blue-semi transform -translate-x-100 "
            >
              {h.map((r) => {
                return (
                  <div className="w-full h-28 border border-gray-500 flex flex-wrap ">
                    <div className="w-full flex justify-between  h-8 border-b">
                      <p style={{ color: r.tipo.color }} className="my-auto font-bold  break-all">
                        {r.tipo.nombre}
                      </p>
                      <p className="text-sm my-auto font-semibold ">
                        {format(r.add_date)} {r.add_datetime}
                      </p>
                    </div>
                    <div className="flex w-full h-20">
                      <div className="h-20 w-9/12 border-r p-1">
                        <p className="text-gray-700 break-all overflow-y-scroll no-scrollbar">
                          {r.observacion}
                        </p>
                      </div>
                      <div className="h-20 w-3/12 flex flex-wrap  p-1">
                        <p className="w-full h-1/3 text-end text-sm  ">
                          {r.numero && r.numero.numero}
                        </p>
                        <p className="w-full h-1/3 text-end text-sm ">
                          {r.agente && r.agente.first_name + ' ' + r.agente.last_name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </td>
        {/*<td className="border  p-1 w-10"><Link to={`/llamadas/${llamada.id}`}><button  className="text-gray-dark hover:text-gray-dark transform hover:scale-125"><IndentMore  size="35" className=""/></button></Link></td>*/}
        <td className="border  p-1 w-10">
          {/*<Link to={llamada.contacto && `/contactos/${llamada.contacto.id}`}><button  className="text-blue-ligth hover:text-blue-semi transform hover:scale-125"><UserDetail size="35" className=""/></button></Link>*/}
        </td>
        <td className="border p-1 w-10">
          <TelephoneAccessible
            onClick={() => {
              llamada.id && newResp();
            }}
            size="38"
            className="text-blue-normal transform hover:scale-125"
          />
        </td>
        {/*<td className="border p-1 w-10"><MoreVerticalOutline onClick={()=>{llamada.id && newResp()}} size="38" className="text-blue-800 transform hover:scale-125"/></td>*/}
      </tr>
    );
  } else if (props.type === '1') {
    return (
      <tr className="text-gray-700">
        <td className="border p-1">{llamada.contacto ? llamada.contacto.nombre : ''}</td>
        <td className="border p-1">{llamada.contacto ? llamada.contacto.apellido : ''}</td>
        <td className="border p-1">
          {llamada.contacto && llamada.contacto.mandante && llamada.contacto.mandante}
        </td>
        <td className="border p-1">{llamada.numero_to && llamada.numero_to}</td>
        <td className="border p-1">{llamada.date && format(llamada.date)}</td>
        <td className="border w-92 p-1 overflow-y-scroll">
          {llamada.respuesta &&
            llamada.respuesta.length > 0 &&
            llamada.respuesta.map((r) => {
              return <p className="w-full">{r.add_date + '-' + r.tipo.nombre + '||'}</p>;
            })}
        </td>
        <td className="border p-1">
          <History
            size="38"
            className="transform hover:scale-110 hover:text-blue text-blue-normal ml-5"
          />
        </td>
        <td className="border p-1">
          <Link to={`/llamadas/${llamada.id}`}>
            <button className="text-black hover:text-gray-900 transform hover:scale-125">
              <IndentMore size="35" className="" />
            </button>
          </Link>
        </td>
        <td className="border p-1 ">
          <Link to={llamada && llamada.contacto && `/contactos/${llamada.contacto.id}`}>
            <button className="text-black hover:text-gray-900 transform hover:scale-125">
              <UserDetail size="35" className="" />
            </button>
          </Link>
        </td>
      </tr>
    );
  } else if (props.type === '5') {
    return <tr className="text-gray-700"></tr>;
  } else {
    return <></>;
  }
};

export default LlamadaRow;
