import React, { useState, useEffect } from 'react';

import { Link,useParams} from 'react-router-dom';

import axiosInstance from '../../axios';
import format from '../../utils/formatDate';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { Verified } from '@styled-icons/material/Verified';
import { Unverified } from '@styled-icons/octicons/Unverified';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';


import {Phone } from '@styled-icons/boxicons-solid/Phone'
const Llamadas1 = ({ contacto, setChange,objs}) => {

  // const [objs,setObjs] = useState([])
  
  const { id } = useParams();

  const getStatusColor= (group) =>{
    if(group === 1){
      return 'bg-green-400'
    }else if(group === 5){
      return 'bg-red-400'
    }else if(group === 2){
      return 'bg-blue-400'
    }else if(group === 3){
      return 'bg-yellow-400'
    }
  }
  const getObjs= () => {
    axiosInstance.get(`llamadas?contacto=${id}&page_size=100`).then((res) => {
      // setObjs(res.data.results);
      // setPag({
      //   next: res.data.next,
      //   'previous ': res.data.previous,
      //   count: res.data.count,
      // });
      // console.log(res.data);
    });
  };

  useEffect(() => {
    // if (contacto.id !== undefined) {
      // getRespuestas();
    // }
    //
    // getObjs()
  }, [contacto]);

  return (
    <div className="w-full flex justify-center gap-x-3">
      
      <div className="flex flex-wrap content-start w-full md:w-3/4 p-1 ">
      {objs.map((obj)=>{
        return <><div className="flex w-full content-start items-start flex-wrap gap-x-4 ">
              
              <div className="w-full">
              <div className={`w-8 h-8 rounded-full flex justify-center p-1 bg-blue-ligth`}>
                <Phone size="25" className="text-white"/>
              </div>
              </div>

          <div className="w-full flex gap-x-6">
              <span className="ml-4 h-auto w-0.5 bg-gray-400"> </span>
              <div className="flex-grow flex flex-wrap bg-white rounded-md p-3">
                <div className="w-full flex  justify-between">
                  <div className="flex items-center">
                    {/*foto */}
                    <div className="w-8 h-8 rounded-full bg-gray-300 mr-2 flex justify-center content-center items-center text-gray-500 uppercase text-sm">
                        {obj.agente && obj.agente.first_name.charAt(0)}{obj.agente && obj.agente.last_name.charAt(0)}
                    </div>
                    {/**/}
                    <p className="text-gray-400 text-md"><span className="text-gray-700 font-semibold"> {obj.agente && obj.agente.first_name} {obj.agente && obj.agente.last_name}</span></p>
                    </div>
                  <p className="text-gray-500 text-sm">{format(obj.fecha_asignada)}</p>

          
                </div>
                  
                <div className="w-full flex  justify-between mt-4">
                    <p className="text-gray-600 text-lg font-semibold">{obj.campaign && obj.campaign.nombre}</p>

                    <span className={` ${obj.campaign && obj.campaign.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800' } inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium `}>
                      {obj.campaign && obj.campaign.status === 1 && 'Activa'}
                      {obj.campaign && obj.campaign.status === 2 && 'Inactiva'}
                    </span>
                </div>


              </div>
          </div>
                
          </div>

          </>
      })}
      </div>
    </div>
      
  );
};

export default Llamadas1;
