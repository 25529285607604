import {useState,useEffect} from 'react'

import axiosInstance from '../../../axios.js'

import toast from 'react-hot-toast'

import Select, { createFilter } from 'react-select';

import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import {DeleteForever} from '@styled-icons/material/DeleteForever'
import {AddSquare} from '@styled-icons/fluentui-system-filled/AddSquare'
import {FileDoc} from '@styled-icons/boxicons-solid/FileDoc'


const PlantillaModal = ({setPlantilla,plantilla,setChange,change}) =>{
  const [data,setData] = useState({})
  const [fields,setFields] = useState([])
  const [addField,setAddField] = useState(false)
  const [newField,setNewField] = useState({
    plantilla:plantilla.id,
    nombre:'',
    field:'',
    position: plantilla.length == 0 ? 1 : plantilla.campos.length + 1,
  })
  
  const getFields = async () =>{
    
      await axiosInstance.get(`contactos/fields/`).then(res=>{
        var fs = res.data
        fs.push('emails','numeros')
        setFields(fs.map((x) => {
      return {
        value: x,
        label: x,
      };
    })) })
    }
  
  const updateFields = () =>{
    data.campos.map((field)=>{
      console.log(field)
      axiosInstance.put(`plantillas_carga_fields/${field.id}/`,field).then(res=>{
      })

    })

    toast.success(`campos actualizados`)
      
    }
  const removeField = (field) =>{
      axiosInstance.delete(`plantillas_carga_fields/${field.id}/`).then(res=>{
        var campos = data.campos.filter(x=>x.id != field.id)
        setData({...data,campos:campos})
        // setChange(new Date())
      })
    }
  const createField = () =>{
      axiosInstance.post(`plantillas_carga_fields/`,{...newField,plantilla:plantilla.id}).then(res=>{
        console.log(res.data)
        var campos = data.campos
        campos.push(res.data)
        // console.log(campos)
        setData({...data,campos:campos})
        setAddField(false)
      })
    }
      
  const handleChangeField = (f) => (e) =>{
      var campos = data.campos
      var objIndex = data.campos.findIndex((obj => obj.id == f.id))
      campos[objIndex][e.target.name] = e.target.value
      //
      // 
      setData({...data,campos:campos})
    

    

  }
  const handleChangeField2 = (f) => (e) =>{
    console.log(e)
    if(e !== null){
      var campos = data.campos
      var objIndex = data.campos.findIndex((obj => obj.id == f.id))
      campos[objIndex].field = e.value
      //
      //
      setData({...data,campos:campos})
    }

  }
  const handleChangeNewField = (e) =>{
    setNewField({...newField,nombre:e.target.value})
  }

  const handleChangeNewField2 = (e) =>{
      setNewField({...newField,field:e.value})
    }

  const handleChangeFile = (e) =>{
    
    const data = new FormData();
    data.append('instructions', e.target.files[0], e.target.files[0].name);
    axiosInstance.patch(`plantillas_carga/${plantilla.id}/`,data).then(res=>{
      toast.success('intrucciones guardadas')
    })}


  useEffect(()=>{
    setData(plantilla) 
    getFields()
  },[plantilla])



  return (
    <div className="absolute w-full h-full flex justify-center items-center">

      <div className="w-4/5 h-4/5 bg-white border rounded-md z-40 shadow-lg flex flex-wrap content-start gap-y-2">
        <div className="w-full h-8 border-b flex justify-end items-center px-2"><XCircle onClick={()=>{setPlantilla(null);setChange(new Date())}} size="24" className="text-red-500 transform hover:scale-105 cursor-pointer"/></div>
        <div className="flex w-full justify-between px-4">
        <p className="w-full text-2xl">{plantilla && plantilla.nombre}</p>   


    <label>
     <input type="file" onChange={handleChangeFile} class="hidden" name="file1"></input>
      <FileDoc size="32" className="text-blue-ligth transform hover:scale-105 cursor-pointer"/>
    </label>
        

        </div>
        <div className="w-full h-4/5 flex flex-wrap content-start p-4">
          <div className="w-full h-full border rounded-md flex flex-wrap overflow-y-scroll content-start">
            <div className="w-1/2 h-12 flex justify-center content-center items-center text-gray-600 font-semibold text-lg border-r border-b "><p>Nombre Columna</p></div>
            <div className="w-1/2 h-12 flex justify-center content-center items-center text-gray-600 font-semibold text-lg  border-b "><p>Campo</p></div>
            {plantilla && data.campos?.sort((a,b)=>{return(a-b)}).map((f)=>{
              return (
              <>
                <div className="w-1/2 h-12 flex justify-center content-center items-center text-gray-500 font-semibold text-lg border-r border-b  p-1">
                <input type="text" name="nombre" onChange={handleChangeField(f)} value={f.nombre}  className="w-auto px-2 py-1"></input>

                </div>
                <div className="w-1/2 h-12 flex justify-center content-center items-center text-gray-500 font-semibold text-lg  border-b p-1 ">
                {/* <p>{f.field}</p> */}
                <Select
                        // defaultValue={colourOptions[0]}
                        isClearable
                        isSearchable
                        name="field"
                        options={fields}
                        className="w-5/6"
                        // value={{label:data.campos.filter(x=>x.field=f.field)[0].field,value:data.campos.filter(x=>x.field=f.field)[0].field}}
                        defaultValue={{label:f.field,value:f.field}}
                        onChange={handleChangeField2(f)}
                        // filterOption={createFilter(filterConfig)}
                      />
                  <input type="number" name="position" onChange={handleChangeField(f)} value={f.position}  className="w-10 px-2 py-1 mx-1 border"></input>
                  <DeleteForever onClick={()=>removeField(f)} size="32" className="text-red-500 transform hover:scale-105 cursor-pointer"/>
                </div>
              </>
              )
            })}
              {addField && <>
                <div className="w-1/2 h-12 flex justify-center content-center items-center text-gray-500 font-semibold text-lg border border-green-500 border-dashed p-1 ">
                <input type="text" onChange={handleChangeNewField} value={newField.nombre}  className="w-auto px-2 py-1 border"></input>

                </div>
                <div className="w-1/2 h-12 flex justify-center content-center items-center text-gray-500 font-semibold text-lg  border border-green-500 border-dashed p-1">
                {/* <p>{f.field}</p> */}
                <Select
                        // defaultValue={colourOptions[0]}
                        isClearable
                        isSearchable
                        name="field"
                        options={fields}
                        className="w-5/6"
                        // value={{label:data.campos.filter(x=>x.field=f.field)[0].field,value:data.campos.filter(x=>x.field=f.field)[0].field}}
                        // defaultValue={{label:f.field,value:f.field}}
                        onChange={handleChangeNewField2}
                        // filterOption={createFilter(filterConfig)}
                      />
                  <AddSquare onClick={()=>createField()} size="32" className="text-green-500 transform hover:scale-105 cursor-pointer"/>
                </div>
              </>}
<button onClick={()=>setAddField(true)} className="px-2 mx-auto my-4 w-52 py-1 text-gray-50 font-bold bg-green-500 rounded-md transform hover:scale-105  "><span>Agregar Campo</span></button>
          </div>

        </div>
      
      <button onClick={()=>updateFields()} className="px-2   py-1 text-gray-50 font-bold bg-green-500 rounded-md transform hover:scale-105 mx-auto"><span>Guardar</span></button>
      </div>

    </div>
  )
}

export default PlantillaModal;
