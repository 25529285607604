import React, { useState, useEffect } from 'react';
import { Insert } from 'styled-icons/fluentui-system-regular';
import axiosInstance from '../../../axios';

import toast from 'react-hot-toast';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import InsertExcel from './InsertExcel';

const Cargar = ({ cargar, setChange }) => {
  const [nro_cuentas, setNro] = useState('');
  const [digitos, setDigitos] = useState(0);

  const [file, setFile] = useState(null);

  const handleChangeDigitos = (e) => {
    setDigitos(e.target.value);
  };
  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const close = () => {
    setFile(null);
  };

  const cargar_excel = () => {
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('digitos', digitos);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`carga/cuentas_excel/`, formData)
      .then((res) => {
        console.log(res.data);

        setChange(new Date());
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  const handleChange = (e) => {
    //var value = e.target.value
    //console.log(value)
    //console.log(value)
    setNro(`${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`);
    console.log(nro_cuentas);
  };

  return (
    <div className="h-full w-full flex flex-wrap  border-4 border-blue-ligth rounded-lg">
      <div className="w-full h-2/5 border-b-4 border-blue-ligth p-4 flex flex-wrap justify-center">
        <div className="w-1/2 h-full flex justify-center flex-wrap">
          <label className="my-auto h-1/2 w-40 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
            <CloudUpload size="60"></CloudUpload>
            <span className="mt-2 text-base leading-normal">Select a file</span>
            <input
              type="file"
              onChange={handleChangeFile}
              className="hidden"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx  xls, .xlsb , .xltx , xls "
            />
          </label>
          <div className="w-full mt-2 flex justify-center">
            <div className="rounded-md border border-purple-800 shadow-lg tracking-wide h-10 w-40 px-2 py-1 flex justify-start">
              <h1 className="font-bold text-purple-500">{file && file.name}</h1>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full flex justify-center flex-wrap border-l-2 border-blue-ligth">
          <label className="my-auto h-1/2 w-40 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
            <span className="mt-1 text-base leading-normal text-center">Digitos</span>
          </label>
          <div className="w-full mt-2 flex justify-center">
            <input
              type="number"
              onChange={handleChangeDigitos}
              value={digitos}
              className="w-40 h-10 rounded-md shadow-lg font-bold border border-purple-800 text-purple-500"
            ></input>
          </div>
        </div>
      </div>
      <div className="w-full h-2/5 border-b-4 border-blue-ligth p-2">
        <textarea
          value={nro_cuentas}
          onChange={handleChange}
          type="text"
          placeholder="numero de cuenta"
          className=" w-full h-4/5 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-full 1/5 flex justify-center">
        <button
          onClick={() => (file !== null ? cargar_excel() : cargar(nro_cuentas))}
          className="p-2 my-auto bg-green-500 font-bold text-gray-50 text-xl rounded-lg"
        >
          Cargar
        </button>
      </div>
    </div>
  );
};

export default Cargar;
