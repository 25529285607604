import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';
import { useLocation } from 'react-router-dom';

import { getQueryValue } from '../../../utils/forms';

import Select from 'react-select';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Filters = ({ count, cargar }) => {
  const [query, setQuery] = useState('?');
  //const query = queryString.parse(location.search);
  let Q = useQuery();

  const handleChange = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
    //console.log(query)
  };

  useEffect(() => {
    setQuery(Q.toString().replace(/%2C/gi, ','));
  }, []);

  return (
    <div className="w-full flex gap-x-3 justify-between flex-wrap content-start h-4/5 px-3 py-2 bg-gray-400 rounded-md">
      <div className="w-1/12 h-full border-b">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">
          Codigo
        </label>
        <input
          name="codigo__in"
          value={getQueryValue(query, 'codigo__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></input>
      </div>
      {/*<div className="w-1/12 h-full border-b ">
                    <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2" >Rut</label>
                    <input name="contacto__rut__in" value={getQueryValue(query,'contacto__rut__in')} onChange={handleChange} type="text" className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"></input>
            </div>*/}
      <div className="flex flex-wrap w-1/12 h-full border-b content-start justify-end gap-y-1">
        <button
          onClick={() => (window.location.href = `${window.location.pathname}?${query}`)}
          className="bg-blue-600 h-1/4 w-full text-white rounded-lg p-1"
        >
          Filtrar
        </button>
        <select
          name="page_size"
          onChange={handleChange}
          value={getQueryValue(query, 'page_size')}
          type="number"
          className="w-full h-1/4 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
        >
          <option key={1} value={100} label={'100'}></option>
          <option key={2} value={500} label={'500'}></option>
          <option key={3} value={1000} label={'1000'}></option>
          <option key={4} value={5000} label={'5000'}></option>
          <option key={5} value={10000} label={'10000'}></option>
          <option key={5} value={10000} label={'20000'}></option>
        </select>
        <div className="w-full h-1/4 flex justify-center bg-gray-50 rounded-md">
          <p className="my-auto font-semibold">Total: {count}</p>
        </div>
        <button
          onClick={() => cargar(query)}
          className="w-full h-1/5 flex justify-center  bg-green-500 rounded-md text-white font-bold"
        >
          Cargar
        </button>
      </div>
    </div>
  );
};

export default Filters;
