import {ArrowBackIos} from '@styled-icons/material/ArrowBackIos'

import Back from '../../components/utils/Back';
const TitleBack = ({title}) =>{

  return (
    <div className="w-full p-3 flex items-center gap-x-4 bg-white">
      <Back></Back> 
      <p className="text-2xl text-gray-500">{title}</p>
    </div>
  )
}

export default TitleBack;
