import {useState} from 'react'

import ContactoAction from '../contactos/ContactoAction';
import ContactoRow from './ContactoRow.js'
import { CSVLink, CSVDownload } from 'react-csv';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';


const ContactosT = ({objs,setChange}) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [actionModal, setActionModal] = useState(false);

  const addSelected = (obj) => {
    setSelected([...selected, obj]);
  };
  const removeSelected = (obj) => {
    setSelected(selected.filter((x) => x.id !== obj.id));
    
  };

  const handleCheckAll = (e) => {
    if (checkedAll === false) {
      setSelected(objs);
      setCheckedAll(true);
    } else {
      setSelected([]);
      setCheckedAll(false);
    }
  };

  const getData = () => {
    return selected.map((row) => {
      return {
        id: `${row.id}`,
        nombre: `${row.nombre}`,
        apellido: `${row.apellido}`,
        nro_cuenta: `${row.nro_cuenta}`,
        rut: `${row.rut}`,
      };
    });
  };
  return (
        <table className="min-w-full divide-y divide-gray-300 border rounded-md">
                <thead className="bg-gray-50">
                  <tr>
                      <th className=" w-10">
                      <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} />
                    </th>

                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Nombre
                    </th>
                    
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Apellido
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Rut
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Mandante
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Estado
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Cuenta
                    </th>
                    <th className=" p-1 w-10">
          <MoreVerticalOutline
            onClick={() => {
              setActionModal(!actionModal);
            }}
            size="38"
            className="text-blue-semi transform hover:scale-125"
          />
          {actionModal && (
            <ContactoAction setChange={setChange} contactos={selected}></ContactoAction>
          )}
        </th>

                  </tr>
                </thead>
                <tbody className="bg-white">
                  {objs && objs.length > 0 && objs.map((obj,objIdx) => {
                    
                    return (
                      <ContactoRow
                        selected={selected}
                        idx={objIdx}
                        obj={obj}
                        addSelected={addSelected}
                        removeSelected={removeSelected}
                      />
                    );
                  })}

                </tbody>
              </table>
            
  )
}
export default ContactosT;
