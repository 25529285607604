import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'
import fileDownload from 'js-file-download'


import PlantillaModal from '../../components/carga/cuentas3/PlantillaModal.jsx'
import CreatePlantillaModal from '../../components/carga/cuentas3/CreatePlantillaModal.jsx'
import {ArrowDownload} from '@styled-icons/fluentui-system-regular/ArrowDownload'
import {ChevronRightCircle} from '@styled-icons/boxicons-regular/ChevronRightCircle'
import {DeleteForever} from '@styled-icons/material/DeleteForever'


const Plantillas = () =>{
  const [change,setChange] = useState(null)
  const [plantillas,setPlantillas] = useState([])
  const [filter,setFilter] = useState("")
  const [addPlantilla,setAddPlantilla] = useState(false)
  const [plantilla,setPlantilla] = useState(null)

  const downloadPlantilla = async (p) =>{
    await axiosInstance.post(`plantillas_carga/${p.id}/download/`,{responseType:'blob'}).then(res=>{
        // console.log(res.headers)
        fileDownload(res.data,`${p.nombre}.csv`);
        // fileDownload(res.data,res.headers['filename']);
    })
  }
  const getPlantillas = async () =>{
    await axiosInstance.get(`plantillas_carga?page_size=100`).then(res=>{
      console.log(res.data)
      setPlantillas(res.data.results)
    })
  }
  const removePlantilla = (p) =>{
      axiosInstance.delete(`plantillas_carga/${p.id}/`).then(res=>{
        getPlantillas()
      })
    }
  
  // useEffect(()=>{
  //   getPlantillas()
  // },[plantilla])

  useEffect(()=>{
    getPlantillas()
  },[change])
  

  return (
    <div className="relative w-full min-h-full h-auto flex flex-wrap bg-gray-100 content-start">
      {plantilla !== null && <PlantillaModal change={change} setChange={setChange} plantilla={plantilla} setPlantilla={setPlantilla}></PlantillaModal>}
      {addPlantilla && <CreatePlantillaModal change={change} setChange={setChange}  setAddPlantilla={setAddPlantilla}></CreatePlantillaModal>}
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Plantillas</p> 
      </div>
      
      <div className="w-full h-auto p-4 mt-4 ">
        <div className="w-full bg-white flex flex-wrap p-4 content-start overflow-hidden rounded-md">
              <div className="w-full flex items-start justify-between">
                <input onChange={(e)=>setFilter(e.target.value)} className="bg-gray-200 rounded-md w-42 h-10 p-4">
                </input>
                <button onClick={()=>setAddPlantilla(true)} className="px-2  py-1 text-gray-50 font-bold bg-green-500 rounded-md transform hover:scale-105  "><span>Crear</span></button>
              </div>

              <div className="w-full flex flex-wrap p-2 mt-2 h-4/6  overflow-y-scroll gap-y-2 content-start">
                {plantillas && plantillas.filter(x=>x.nombre.toLowerCase().includes(filter)).map((t)=>{
                  return (
                  <div className={`${plantilla && plantilla.id === t.id && 'border border-blue-500'} h-12 w-full bg-gray-100 rounded-md flex px-2 py-1 justify-between items-center`}>
                    <p className="w-1/3 overflow-hidden">{t.nombre}</p>
                    <div className="flex text-sm items-center text-gray-700">
                      <ArrowDownload  size="26" className="text-gray-900 "></ArrowDownload>
                      <p  onClick={()=>downloadPlantilla(t)} className="text-blue-ligth cursor-pointer transform hover:scale-105">Plantilla</p>
                      <p className="text-gray-700 mx-1">/</p>
                      <a href={t.instructions && t.instructions} target="_blank" className="text-blue-ligth cursor-pointer transform hover:scale-105">Instrucciones</a>
                    </div>
                    <div className="flex items-center gap-x-2">
                    <DeleteForever onClick={()=>removePlantilla(t)} size="32" className="text-red-500 transform hover:scale-105 cursor-pointer"/>
                    <ChevronRightCircle onClick={()=>setPlantilla(t)} size="26" className="text-blue-normal transform hover:scale-105 cursor-pointer"/>
                    </div>
                </div>
                  )
                })}
                
        
              </div>
          </div>
      </div>
      
    </div>
  )

}

export default Plantillas;
