import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../axios';

import { useLocation } from 'react-router-dom';
import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';
import toast from 'react-hot-toast';

import formatter from '../../utils/formatMoney';
import PhoneModal from '../twilio/PhoneModal';
import LlamadaRow2 from './LlamadaRow2';
import LlamadaAction from './LlamadaAction';
import Filters from './Filters';

import { CSVLink, CSVDownload } from 'react-csv';

import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { setRealizada } from '../../redux/actions/llamadaActions';

import { Phone } from '@styled-icons/feather/Phone';

import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';
import { MagnifyingGlass } from '@styled-icons/entypo/MagnifyingGlass';
import { Filter } from '@styled-icons/fa-solid/Filter';
import { Refresh } from '@styled-icons/foundation/Refresh';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PanelAlgo = ({ setF }) => {
  const dispatch = useDispatch();
  let Q = useQuery();
  const [query, setQuery] = useState('');

  const [filter, setFilter] = useState(false);

  const apiUrl = 'http://localhost:8000/api/';
  const [actionModal, setActionModal] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [prevLlamadas, setPrevLlamadas] = useState([]);
  const [nextLlamadas, setNextLlamadas] = useState([]);
  const [selected, setSelected] = useState(null);
  const [pendientes, setPendientes] = useState({
    next: null,
    previous: null,
    count: null,
    page_number: 0,
    results: [],
  });

  const [pag, setPag] = useState({
    next: null,
    count: 0,
    previous: null,
    page_number: 0,
  });
  const [type, setType] = useState('2');
  const [mandantes, setMandantes] = useState([]);

  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.codigo, label: man.nombre };
    });

  var user = JSON.parse(localStorage.getItem('user'));
  var today = new Date().toISOString().slice(0, 10) + ' 00:00:00';

  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      setMandantes(res.data);
    });
  };

  const get_page_number = () => {
    return pendientes.page_number;
  };

  const handleChangeQ = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
    //console.log(query)
  };
  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };

  const getLlamadas = async (t) => {
    var url = `llamadas/llamadas_agent/?user=${user.id}&${Q.toString()}`;
    //var url = `llamadas/llamadas_agent/?user=${user.id}&${Q.toString()}`
    setQuery(Q.toString());
    await axiosInstance.get(url).then((res) => {
      console.log(res.data);
      setPendientes(res.data);
    });
  };
  const handleCheckAll = (e) => {
    if (checkedAll === false) {
      setSelected(pendientes.results);
    } else {
      setSelected([]);
    }

    setCheckedAll(!checkedAll);
  };
  const addSelected = (call) => {
    setSelected(call);
  };
  const removeSelected = (call) => {
    setSelected(call);
  };

  const prev = () => {
    var url = '';
    url = pendientes.previous;
    axiosInstance.get(url).then((res) => {
      setPendientes(res.data);
    });
  };

  const next = () => {
    var url = '';
    url = pendientes.next;
    axiosInstance.get(url).then((res) => {
      setPendientes(res.data);
    });
  };

  const getData = () => {
    var numeros = '';
    return pendientes.results.map((row) => {
      return {
        id: `${row.id}`,
        contacto: `${row.contacto.nombre} ${row.contacto.apellido}`,
        rut: `${row.contacto.rut}`,
        mandante: `${row.contacto.mandante}`,
        'Saldo Deuda': `${formatter.format(row.contacto.saldo_deuda)}`,
        fecha_asignada: `${row.fecha_asignada}`,
        agente: `${row.agente.first_name} ${row.agente.last_name}`,
        numeros: row.contacto.numeros
          .map((n) => {
            return n.numero;
          })
          .join(', '),
      };
    });
  };

  const handleType = (type) => {
    setType(type);
  };
  const moveLlamada = (ids) => {
    setPendientes({
      ...pendientes,
      results: pendientes.results.filter((x) => !ids.includes(x.id)),
    });
  };

  const refreshLlamadas = () => {
    const promise = getLlamadas();
    toast.promise(promise, {
      loading: 'Cargando',
      error: 'Error',
    });
  };

  useEffect(() => {
    getMandantes();
    dispatch(setRealizada(moveLlamada));
    if (user === null) {
      window.location.href = '/login/';
    }
    let set = false;
    if (
      type === '2' &&
      pendientes.results.length === 0 &&
      pendientes.page_number === 0 &&
      set === false
    ) {
      const promise = getLlamadas();
      toast.promise(promise, {
        loading: 'Cargando',
        error: 'Error',
      });

      set = true;
    }
  }, [type, pendientes]);

  return (
    <div style={{ backgroundColor: '#FFFFFF' }} className="w-full rounded-tl-xl">
      <div className="flex flex-wrap content-start w-full  px-3 pt-5">
        <div  className=" w-full overflow-y-scroll no-scrollbar">
          {filter && <Filters setModal={setFilter}></Filters>}
          <table
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                window.location.href = `${window.location.pathname}?${query}`;
              }
            }}
            className="table-auto p-4 bg-white w-full  mx-auto overflow-hidden shadow rounded-lg"
          >
            {type === '2' && (
              <thead className="bg-gray-200 font-semibold ">
                <th className="border-l-8 border-t border-b w-10">
                  {/* <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} /> */}
                </th>
                <th className="border border-l-0 px-1 py-1 whitespace-nowrap font-semibold ">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__nombre__icontains')}
                      name="contacto__nombre__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Nombre"
                    ></input>
                  ) : (
                    'Nombre'
                  )}
                </th>
                <th className="border   px-1 py-1 whitespace-nowrap font-semibold text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__apellido__icontains')}
                      name="contacto__apellido__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Apellido"
                    ></input>
                  ) : (
                    'Apellido'
                  )}
                </th>
                <th className="border lg:w-36 px-1 py-1 whitespace-nowrap font-semibold text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__rut__in')}
                      name="contacto__rut__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Rut"
                    ></input>
                  ) : (
                    'Rut'
                  )}
                </th>
                <th className="border  py-1 whitespace-nowrap font-semibold text-gray-900">
                  {' '}
                  Mandante
                </th>
                <th className="border px-1 py-1 whitespace-nowrap font-semibold text-gray-900">
                  Monto
                </th>
                <th className="border px-1 py-1 whitespace-nowrap font-semibold text-gray-900 w-20 overflow-hidden">
                  Camp
                </th>
                <th className="border px-4 py-1 whitespace-nowrap font-semibold text-gray-900">
                  Estado
                </th>
                <th className="border px-1 py-1 whitespace-nowrap font-semibold text-gray-900">
                  Numeros
                </th>
                {/* <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Fecha</th> */}
                {/*<th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Llamar</th>*/}
                <th className="border w-10">
                  <Filter
                    onClick={() => {
                      setFilter(!filter);
                    }}
                    size="32"
                    className="transform hover:scale-110 text-gray-800"
                  />
                  {false && (
                    <MagnifyingGlass
                      onClick={() =>
                        (window.location.href = `${window.location.pathname}?${query}`)
                      }
                      size="32"
                      className="transform hover:scale-110 text-blue-500"
                    />
                  )}
                </th>
                <th className="border w-10">
                  <Refresh
                    onClick={() => refreshLlamadas()}
                    size="32"
                    className="transform hover:scale-110 text-blue-500"
                  />
                </th>
                {/*<th className="w-10 "><MagnifyingGlass onClick={()=>window.location.href = `${window.location.pathname}?${query}`} size="32" className="transform hover:scale-110 text-blue-500"/></th>*/}
                <th className="border p-1 w-10">
                  <CSVLink filename="llamadas.csv" data={getData(selected)}>
                    <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
                  </CSVLink>
                </th>
                <th className="border p-1 w-10">
                  <MoreVerticalOutline
                    onClick={() => {
                      setActionModal(!actionModal);
                    }}
                    size="38"
                    className="text-blue-semi transform hover:scale-125"
                  />
                  {actionModal && <LlamadaAction llamada={selected}></LlamadaAction>}
                </th>
              </thead>
            )}

            <tbody className="">
              {type === '2' &&
                pendientes.results.length > 0 &&
                pendientes.results.map((llamada,objIdx) => (
                  <LlamadaRow2
                    idx={objIdx}
                    moveLlamada={moveLlamada}
                    llamada={llamada}
                    selAll={checkedAll}
                    sel={selected}
                    removeSelected={removeSelected}
                    addSelected={addSelected}
                    key={llamada.id}
                    type={type}
                    sP={setPendientes}
                    pen={pendientes}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div style={{ height: '4vh' }} className="flex justify-end gap-x-4 w-full h-10 pt-1 mt-1">
          {type === '2' && (
            <>
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-32 mr-auto">
                Total: {pendientes.count}
              </div>
              {pendientes.previous && (
                <button
                  onClick={() => prev(pag.previous)}
                  className=" w-14 border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm"
                >
                  Prev
                </button>
              )}
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-10">
                {get_page_number()}
              </div>
              {pendientes.next && (
                <button
                  onClick={() => next(pag.next)}
                  className=" w-16 border shadow-xl bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm text-xl font-bold"
                >
                  next
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PanelAlgo;
