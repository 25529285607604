import {
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
  Cell,
  XAxis,
  YAxis,
} from 'recharts';

const status_opts = [{'name':"realizadas",'color':"#6EE7B7"},{'name':'pendientes','color':"#FDE68A"},{'name':"rellamadas",'color':"#93C5FD"},{'name':"terminadas",'color':"#F77171"}]

const Calls = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        layout="vertical"
      >

                <XAxis type="number" hide={true} />
                <YAxis dataKey="name" type="category" hide={true} />
              {status_opts &&
                status_opts.map((r) => (
                    <Bar dataKey={r.name} stackId="a" fill={r.color}>
                    {data &&
                        data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={r.color} />
                        ))}
                    </Bar>
                ))}
            <Tooltip
                allowEscapeViewBox={{ x: false, y: true }}
                position={{ x: 0, y: -100 }}
                wrapperStyle={{ zIndex: 1000 }}
                />

      </BarChart>
    </ResponsiveContainer>
  );
};

export default Calls;
