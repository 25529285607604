import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import axiosInstance from '../../../axios';

const AddAgente = ({ camp, setModal, setChange, ags }) => {
  const [agentes, setAgentes] = useState([]);
  const [data, setData] = useState({
    agentes: [],
  });
  const [options, setOptions] = useState([]);

  const close = () => {
    setModal(false);
  };

  const handleSelect = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    if (e.length > 0) {
      setData({
        ...data,
        ['agentes']: e.map((agente) => {
          return agente.value;
        }),
      });
    }
  };

  const getAgentes = () => {
    axiosInstance.get(`users?is_active=true`).then((res) => {
      //console.log(agentes)
      //console.log()

      setAgentes(res.data);
      setOptions(
        res.data
          .filter((x) => !(x.id in ags.map((a) => a.id)))
          .map((agente) => {
            return { value: agente.id, label: `${agente.first_name} ${agente.last_name}` };
          }),
      );
    });
  };

  const addAgentes = () => {
    console.log(data.agentes);
    if (data.agentes.length == 0) {
      alert('selecione un agente');
    } else {
      console.log(data);
      axiosInstance.post(`campaigns/${camp.id}/addAgentes/`, data).then((res) => {
        setChange(res.data);
        setModal(false);
      });
    }
  };

  useEffect(() => {
    getAgentes();
  }, []);

  return (
    <div className="flex flex-wrap ml-96 mt-6 flex-col h-4/6 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
      <div className="bg-white h-5/6 w-full shadow overflow-hidden sm:rounded-lg overflow-y-scroll border border-gray-300">
        <Select
          closeMenuOnSelect={false}
          onChange={handleSelect}
          className="w-/2"
          isMulti
          options={options}
        />
      </div>
      <div className="w-full my-auto flex justify-center ">
        <button
          onClick={() => close()}
          className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
        >
          Cancelar
        </button>

        <button
          type="button"
          onClick={() => addAgentes()}
          className=" focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
        >
          add
        </button>
      </div>
    </div>
  );
};

export default AddAgente;
