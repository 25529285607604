import {useState,useEffect} from 'react'

import axiosInstance from '../../axios';

import EventsGrafico from '../../components/dashboards/mails/EventosMails.jsx'
import DeliveredGrafico from '../../components/dashboards/mails/DeliveredG.jsx'
import Camps from '../../components/dashboards/mails/Camps.jsx'
import Events from '../../components/dashboards/mails/Events.jsx'

import {ArrowUpRight2} from '@styled-icons/icomoon/ArrowUpRight2'
import {ArrowDownRight2} from '@styled-icons/icomoon/ArrowDownRight2'

const LlamadasDashboard  = () =>{
  const [data,setData] = useState(null)

  
  


  const getData = async  () =>{
    await axiosInstance.get(`mails/dashboard/?date_from=2022-09-01&date_to=2022-09-30`).then(res=>{
      setData(res.data)
      console.log(res.data)
    })

  }

  useEffect(()=>{
    getData()
  
  },[])




  return (
    <div className="w-full h-full flex-wrap bg-gray-100">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Dashboard Mails</p>
      </div>

      <div className="w-full flex flex-wrap p-6 gap-6 overflow-y-scroll bg-gray-100">
      
         {/* stats  */}
          <div className="w-full flex h-40 border-b bg-white rounded-md border p-4">
            <div className="w-2/5 border-r flex flex-wrap content-start gap-y-4">
              
              <div className="w-1/2  flex flex-wrap content-start gap-y-4 px-6">
                <p className="font-thin text-lg w-full">Total</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.delivered.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.stats.delivered.pct}</span></div>
              </div>
            
              <div className="w-1/2 h-full  flex flex-wrap content-start gap-y-4 px-1">
                <DeliveredGrafico data={data && data.days}></DeliveredGrafico>
              </div>

            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Pendientes</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.opened.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.stats.opened.pct}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Rellamadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.clicked.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 "><ArrowDownRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-blue-500 ">{data && data.stats.clicked.pct}</span></div>
            </div>
            <div className="w-1/5 flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Realizadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.bounced.count}</p>
    
                <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 ">{data && data.stats.bounced.pct}</span></div>
            
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}

            </div>
        </div>
          
         {/* -------stats--------  */}

          {/* events graph*/}
            <Events data={data}></Events>

          {/* <div className="w-3/5  bg-white rounded-md border  flex flex-wrap"> */}
          {/**/}
          {/*   <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Eventos</p></div> */}
          {/*   <div className="w-full h-96 p-4"> */}
          {/*     <EventsGrafico></EventsGrafico> */}
          {/*   </div> */}
          {/**/}
          {/* </div> */}

          {/* camps */}

          <div className="flex-grow  bg-white rounded-md border  flex flex-wrap">
            {data && <Camps data={data && data.camps}></Camps>}
        </div>



      <div className="w-full   h-96 bg-white rounded-md border  flex flex-wrap"></div>
    </div>


    </div>
  )
}

export default LlamadasDashboard;
