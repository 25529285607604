import { CalendarIcon, ChevronRightIcon, UsersIcon } from '@heroicons/react/20/solid';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { es, ru } from 'date-fns/locale';

import { getImportanciaP } from '../../utils/models';
import toast from 'react-hot-toast';

import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import { ArrowDown } from '@styled-icons/bootstrap/ArrowDown';
import { ArrowUp } from '@styled-icons/bootstrap/ArrowUp';
import { PersonFill } from '@styled-icons/bootstrap/PersonFill';

const ViewOrder = ({ objs, setChange }) => {
  const cambiarStatus = (camp) => {
    if (camp.status === 1) {
      var status = 2;
    } else if (camp.status === 2) {
      var status = 1;
    }
    axiosInstance.patch(`listas/${camp.id}/`, { status: status }).then((res) => {
      setChange(new Date());
      toast.success(`Cambio de status en ${camp.nombre}`, { duration: 3000 });
    });
  };

  return (
    <div className="w-full bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {objs.map((obj) => (
          <li key={obj.id}>
            <a href="#" className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-2 sm:px-6 justify-between">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between ">
                  <div className="truncate ">
                    <div className="flex text-lg">
                      <p className="truncate font-medium text-blue-ligth">{obj.nombre}</p>
                    </div>
                  </div>

                  <div className="mt-1 flex  w-1/2 flex-shrink-0 sm:mt-0 sm:ml-5 items-center justify-between">
                    <div className="flex items-center"></div>

                    <span
                      onClick={() => cambiarStatus(obj)}
                      className={`relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${
                        obj.status == 1
                          ? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600'
                          : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'
                      }`}
                    >
                      {obj.status == 1 ? 'activa' : 'inactiva'}
                    </span>
                  </div>
                </div>

                <Link className="ml-5  flex-shrink-0" to={`/listas/${obj.id}`}>
                  <ChevronRightIcon className="h-10 w-5 text-gray-400" aria-hidden="true" />
                </Link>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ViewOrder;
