import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';

import { Delete } from '@styled-icons/typicons/Delete';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { XCircle } from '@styled-icons/bootstrap/XCircle';
import { Circle } from '@styled-icons/boxicons-regular/Circle';
import { create } from 'lodash';

const CreateCModal = ({ setModal, setChange, contactos, setContactos, listaId }) => {
  const [status, setStatus] = useState(1);

  const [errors, setErrors] = useState([]);
  const [numeros, setNumeros] = useState([]);
  const [numero, setNumero] = useState('');
  const [verified, setVerification] = useState(2);
  const [data, setData] = useState({
    lista: listaId,
    nombre: null,
    apellido: null,
    email: null,
    rut: null,
    status: status ? 1 : 2,
    numeros: [],
  });

  const close = () => {
    setModal(false);
  };
  //setNumeros(numeros.filter(x=> x.numero !== numero)
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const addContact = () => {
    //console.log(data)
    //console.log(numeros)
    axiosInstance
      .post(`contactos/`, { ...data, numeros: numeros })
      .then((res) => {
        //console.log(res.data)
        setChange(res.data.id);
        setModal(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        var errs = [];
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              errs.push(`${property} : ${err.response.data[property]}`);
            }
          }
        } else {
        }
        setErrors(errs);
      });
  };

  const handleChangeN = (e) => {
    console.log(numeros);
    console.log(e.target.value[0]);
    e.preventDefault();
    if ('+' == e.target.value[0]) {
      setNumero(e.target.value);
    } else {
      setNumero(`+${e.target.value}`);
    }
  };

  const addNum = () => {
    //console.log(typeof(numeros))
    if (numero !== '') {
      console.log('setsdfsdf');
      setNumeros([...numeros, { numero: numero, verified: verified }]);
    }
    setNumero('');
  };

  useEffect(() => {}, []);

  return (
    <div className="flex flex-wrap ml-96 mt-6 flex-col h-4/5 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
      <div className="bg-white h-3/6 w-full shadow overflow-hidden sm:rounded-lg overflow-y-scroll">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Nombre</dt>
              <input
                type="text"
                placeholder="Juan"
                name="nombre"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Apellido</dt>
              <input
                type="text"
                placeholder="Gonzales"
                name="apellido"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Rut</dt>
              <input
                type="text"
                placeholder="21.987.123-2"
                name="rut"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <input
                type="email"
                placeholder="correo@gmail.com"
                name="email"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">status</dt>
              {status ? (
                <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                      <input
                        id="unchecked"
                        onClick={() => setStatus(!status)}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Activo</span>
                </label>
              ) : (
                <label for="checked" class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                      <input
                        id="checked"
                        onClick={() => setStatus(!status)}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Inactivo</span>
                </label>
              )}
            </div>
            {/*<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                               Deuda
                            </dt>
                            <input type="text" placeholder="1500000" name="deuda" onChange={handleChange} className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2">
                            </input>
                        </div>*/}
          </dl>
        </div>
      </div>
      <div className="flex h-2/6 w-full border">
        <div className="flex flex-wrap content-start h-full w-1/2 p-1 border bg-gray-50 oveflow-y-scroll">
          {numeros &&
            numeros.length > 0 &&
            numeros.map((num) => {
              return (
                <div className="flex rounded-lg w-1/2 h-8 bg-gray-200 border border-gray-500">
                  <p className="text-gray-800 text-center w-7/12 my-auto">{num.numero}</p>
                  {num && num.verified === 1 && (
                    <Check size="32" className="text-green-700 w-2/12 my-auto " />
                  )}
                  {num && num.verified === 2 && (
                    <Circle size="32" className="text-gray-500 w-2/12 my-auto" />
                  )}
                  {num && num.verified === 3 && (
                    <XCircle size="26" className="text-red-600 w-2/12 my-auto" />
                  )}

                  <Delete
                    size="28"
                    onClick={() => setNumeros(numeros.filter((x) => x !== num))}
                    className="text-gray-600 w-3/12 my-auto hover:text-gray-800 transform hover:scale-105"
                  />
                </div>
              );
            })}
        </div>
        <form className="flex flex-wrap content-start h-full w-1/2 p-4">
          <input
            type="text"
            className="w-full h-1/6 border"
            onChange={handleChangeN}
            placeholder="+56912345678"
            value={numero}
          ></input>
          <div class="flex items-center gap-8 w-full mt-8">
            <label class="inline-flex items-center">
              <input
                type="radio"
                name="vehicle"
                onClick={() => setVerification(1)}
                class="h-5 w-5 text-red-600"
              />
              <span class="ml-2 text-gray-700">verificado</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="radio"
                name="vehicle"
                onClick={() => setVerification(2)}
                class="h-5 w-5 text-red-600"
              />
              <span class="ml-2 text-gray-700">Sin verificar</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="radio"
                name="vehicle"
                onClick={() => setVerification(3)}
                class="h-5 w-5 text-red-600"
              />
              <span class="ml-2 text-gray-700">invalido</span>
            </label>
          </div>
          <button
            type="button"
            onClick={() => addNum()}
            className=" mt-10 mx-auto bg-gray-900 h-1/6 w-3/6 p-1 rounded-lg text-white font-bold hover:bg-black"
          >
            añadir numero
          </button>
        </form>
      </div>
      <div className="h-1/6 w-full flex flex-wrap justify-center items-center">
        <div className="flex justify-center h-1/3 w-full">
          <button
            onClick={() => close()}
            className="h-full  focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
          >
            Cancelar
          </button>

          <button
            onClick={() => addContact()}
            className="h-full focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Crear
          </button>
        </div>
        {errors.length > 0 && (
          <div className="flex flex-wrap h-2/3 px-20 pt-2 overflow-y-scroll w-full">
            {errors.length > 0 &&
              errors.map((error) => {
                return (
                  <div
                    className="bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                    role="alert"
                  >
                    <p className="font-bold w-full">Error</p>
                    <p clasName="w-full">{error}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateCModal;
