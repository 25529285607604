import { ActionTypes } from '../constants/action-types';

const initialState = {
  state: '',
};

export const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_STATE:
      return { ...state, state: payload };
    default:
      return state;
  }
};
