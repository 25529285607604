
import {useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';

import {TitleActions} from '../../structs/layout/Title'
import toast from 'react-hot-toast'

import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';

const SMSTemplateDetail2 = () =>{
  const { id } = useParams();
  const [obj, setObj] = useState({});
  const [change, setChange] = useState(null);
  const [edit,setEdit] = useState(false)
  const [data, setData] = useState({text:""});

  const [fields,setFields] = useState([])


  const getObj = async () => {
    await axiosInstance.get(`sms_templates/` + id).then((res) => {
      setObj(res.data);
      setData(res.data);
    });
  };

  const save = async () => {
    await axiosInstance.patch(`sms_templates/${id}/`,{text:data['text']}).then((res) => {
      // setObj(res.data);

      setData(res.data);
      toast.success()
    });
  };


  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})

  }
  const getFields = async () =>{
    await axiosInstance.get(`contactos/fields/`).then(res=>{
      setFields(res.data)
    })
  }

  

  

  useEffect(() => {
    getObj();
    getFields();
  }, [change]);



  return (
	  <div className="w-full  flex flex-col h-full bg-gray-100 overflow-hidden">
      <div className="w-full h-16 border-b bg-white py-2 flex items-center p-4 justify-between">
        <p className="text-xl">{obj.nombre && obj.nombre}</p>
        <button onClick={()=>save()} className="p-2 rounded-md bg-blue-ligth text-white font-semibold transform hover:scale-105">Guardar</button>

      </div>

      <div className="w-full flex-grow flex p-4 gap-4">
        <textarea onChange={handleChange} value={data['text']} name="text" className="h-96 flex-grow border rounded-md bg-white p-4 text-gray-500 text-lg"></textarea>

        <div className="w-40 h-96  p-2 bg-white border rounded-md flex justify-center gap-y-2 flex-wrap overflow-y-scroll">
            {fields.map((f)=>{
              return <p onClick={() => {navigator.clipboard.writeText(`{${f}}`);toast.success(`${f} copiado`)}} className="h-auto w-5/6 text-gray-800 p-1 rounded-md bg-gray-50 border text-center text-lg cursor-pointer break-all">{f}</p> 
            })}
        </div>

      </div>

    </div>
  )
}

export default SMSTemplateDetail2;
