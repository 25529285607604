import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import FastFiltro from '../../components/FastFiltro'
import Table from '../../components/mails/TemplatesTable2.js';
import EmailTemplateCard from '../../components/mails/templates/TemplatesCard.jsx';
// import CreateModal from '../../components/mails/CreateTemplateModal.jsx';
import CreateTemplateModal from '../../components/mails/createTemplateModal2.jsx';
import {MultiPageSelector} from '../../structs/layout/MultiPage'

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';


const statusTemplates = [{name:'Activas',value:true},{name:'Inactivas',value:false}]

const MailTemplates22 = () => {
  const [objs, setObjs] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [create,setCreate] = useState(false)

  const [status, setStatus] = useState(true);

  const [change, setChange] = useState(false);

  const getObjs = () => {
    axiosInstance.get(`mail_templates_designs?status=${status}`).then((res) => {
      setObjs(res.data);
      
      setDisplay(res.data);
    });
  };

  const filterDisplay = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    setDisplay(
      objs.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    getObjs();
  }, [change, status]);

  return (
    <div className="flex flex-wrap w-full h-auto bg-gray-100 p-1 gap-2">

      <CreateTemplateModal open={create} setOpen={setCreate} setChange={setChange}/>
      
      
	  {/* <MultiPageSelector  tabs={[{name:'Mails',href:'mails',current:false},{name:'Templates',href:'mail_templates',current:true}]}/>  */}
	    <MultiPageSelector  tabs={[{name:'Campañas',href:'camps_mails',current:false},{name:'Mails',href:'mails',current:false},{name:'Templates',href:'mail_templates_designs',current:true}]}/> 
      
      <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12 gap-y-2">
            <FastFiltro>
              <input onChange={filterDisplay} name="nombre" className="w-64 border-2  bg-gray-100 px-2 py-1 rounded-md text-gray-700" placeholder="Buscar"></input>

              <select className={`border-l-8 p-2 bg-gray-200 cursor-pointer border-${status  ? 'green-500' : 'red-500'}`} name="is_active" value={status} onChange={(e)=>setStatus(e.target.value)} >
                  {statusTemplates.map((op)=>{
                    return <option value={op.value} >{op.name}</option>
                  })}
              </select>

              <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
            </FastFiltro>
            <div className="w-full px-4 py-4 flex flex-wrap gap-4">
            {display && display.map((template)=>{
              return <EmailTemplateCard template={template} setChange={setChange}/>
            })} 
          </div>
              
            {/* <ViewOrder objs={display} setChange={setChange}></ViewOrder> */}
        </div> 
        
    </div>
  );
};

export default MailTemplates22;
