import React, { Component } from 'react';

import Panel from '../components/dashboardAgente/Panel';

export class DashboardAgente extends Component {
  render() {
    return <Panel></Panel>;
  }
}

export default DashboardAgente;
