import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';
const CreateModal = ({ setModal, camps, setCamps, setChange }) => {
  const [toggle, setToggle] = useState(true);
  const [listas, setListas] = useState([]);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    nombre: '',
    status: true,
    fecha_inicio: null
  });

  const close = () => {
    setModal(false);
  };
  const handleChange = (e) => {
    //console.log(e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getListas = () => {
    axiosInstance.get(`listas/?status=1`).then((res) => {
      setListas(res.data);
      res.data.length > 0 && setData({ ...data, lista: res.data[0].id });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //setData({
    //    ...data,
    //    'status': toggle ? 1 : 2
    //})
    axiosInstance
      .post(`voice_msg_campaigns/`, { ...data, ['status']: toggle})
      .then((res) => {
        console.log(res.data);
        setCamps([...camps, res.data]);

        setModal(false);
        setChange(res.data);
      })
      .catch((err) => {
        console.log(err);
        var errs = [];
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (data['nombre'] == '') {
            console.log('error nombre');
            errs.push('nombre invalido');
          }
          if (data['fecha_inicio'] == null) {
            console.log('error fecha');
            errs.push('fecha inicio invalida');
          }
        } else {
          if (
            err.response !== undefined &&
            'data' in err.response &&
            'non_field_errors' in err.response.data
          ) {
            errs.push(err.response.data.non_field_errors);
          }
        }
        setErrors(errs);
      });
  };

  useEffect(() => {
    getListas();
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-wrap ml-96 mt-6 flex-col h-3/5 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex"
    >
      <div className="flex justify-center h-1/5 w-full pt-3 px-20">
        <label className=" flex flex-wrap w-full">
          <div className="flex justify-center  w-full rounded-t-lg text-xl text-center bg-blue-ligth text-white font-bold align-middle">
            <span className="my-auto">Nombre</span>
          </div>
          <input
            type="text"
            name="nombre"
            onChange={handleChange}
            class=" rounded-b-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            placeholder="campaña 1..."
          />
        </label>
      </div>
      <div class="flex flex-col w-full h-1/6 pt-3 border-b">
        {toggle ? (
          <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer mx-auto">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                <input
                  id="unchecked"
                  onClick={() => setToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Activa</span>
          </label>
        ) : (
          <label for="checked" class="mt-3 inline-flex items-center cursor-pointer mx-auto">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                <input
                  id="checked"
                  onClick={() => setToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Inactiva</span>
          </label>
        )}
      </div>
      <div className="flex h-1/6 w-ful border-b">
        <div className="w-1/2 flex flex-wrap p-1">
          <label className="w-full font-bold" for="start">
            Fecha Inicio:
          </label>
          <input type="date" id="start" name="fecha_inicio" onChange={handleChange}></input>
        </div>
        
      </div>

      <div className="flex flex-wrap justify-center py-2 h-1/5 w-full border-b">
        <label className="w-1/2 font-bold text-2xl " for="start">
          Lista:
        </label>
        <select
          name="lista"
          onChange={handleChange}
          className="w-1/2 h-1/2 bg-white border rounded-lg font-bold"
        >
          {listas &&
            listas.map((lista) => {
              return (
                <option className="bg-white" key={lista.id} value={lista.id}>
                  {lista.nombre} || Contactos: {lista.contactos}
                </option>
              );
            })}
        </select>
      </div>
      <div className="flex flex-wrap justify-center h-1/5 pt-3 ">
        <div className="w-full h-2/3 flex justify-center ">
          <button
            onClick={() => close()}
            className="h-12 focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-600 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
          >
            Cancelar
          </button>

          <button
            type="submit"
            className="h-12 focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Crear
          </button>
        </div>
        <div className="flex flex-wrap h-2/3 px-20 pt-3 overflow-y-scroll w-full">
          {errors.length > 0 &&
            errors.map((error) => {
              return (
                <div
                  className="h-5/6 w-full bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                  role="alert"
                >
                  <p className="font-bold w-full">Error</p>
                  <p clasName="w-full">{error}</p>
                </div>
              );
            })}
        </div>
      </div>
    </form>
  );
};

export default CreateModal;
