import {useState} from 'react'

import EventsGrafico from './EventosMails.jsx'
const Events = ({data}) => {
  const [type,setType] = useState('answered')



  return (
      <div className="w-3/5  bg-white rounded-md border  flex flex-wrap">

            <div className="w-full h-14 border-b flex justify-between content-center items-center px-4">
               <p className="text-lg">Eventos</p>
                <div className="flex h-full  items-center gap-x-1 ">
                  <div onClick={()=>setType('answered')} className={`${type === 'answered' ?  '   text-blue-700' : 'text-gray-600'} px-1 h-full flex items-center`}><p className="hover:text-blue-700 cursor-pointer">Contestadas</p></div>
                  <div onClick={()=>setType('no_answer')} className={`${type === 'no_answer' ?  'text-blue-700' : 'text-gray-600'} px-1 h-full flex items-center`}><p className="hover:text-blue-700 cursor-pointer">Sin Respuesta</p></div>
                  <div onClick={()=>setType('busy')} className={`${type === 'busy' ?  ' text-blue-700' : 'text-gray-600'} px-1 h-full flex items-center`}><p className="hover:text-blue-700 cursor-pointer">Ocupado</p></div>
                  <div onClick={()=>setType('failed')} className={`${type === 'failed' ?  ' text-blue-700' : 'text-gray-600'} px-1 h-full flex items-center`}><p className="hover:text-blue-700 cursor-pointer">Fallidas</p></div>

                </div>

            </div>
            <div className="w-full h-96 py-4 px-0">
    {data && <EventsGrafico data={data.days} dataKey={type}></EventsGrafico>}
            </div>

          </div>
  )
}

export default Events;
