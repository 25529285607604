import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import format from '../../../utils/formatDate';
import { getTipoCarga } from '../../../utils/models';
import { getStatusCarga } from '../../../utils/models';

import { Refresh } from '@styled-icons/foundation/Refresh';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { Delete } from '@styled-icons/fluentui-system-filled/Delete';
import { Download } from '@styled-icons/fa-solid/Download';

import { FileExcel } from '@styled-icons/fa-solid/FileExcel';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadE = ({ cols }) => {
  return (
    <ExcelFile
      element={
        <FileExcel size="32" className="text-green-600 cursor-pointer transform hover:scale-125" />
      }
    >
      <ExcelSheet data={[]} name="">
        {cols &&
          cols.length > 0 &&
          cols.map((col) => {
            return <ExcelColumn label={col} value={col} />;
          })}
      </ExcelSheet>
    </ExcelFile>
  );
};
const TPlantillas = ({ change, setChange }) => {
  const [plantilla, setPlantilla] = useState(null);
  const [detail, showDetail] = useState(false);
  const [plantillas, setPlantillas] = useState([]);

  const getPlantillas = () => {
    axiosInstance.get('plantillas/').then((res) => {
      setPlantillas(res.data);
    });
  };

  const deletePlantilla = (p) => {
    axiosInstance.delete(`plantillas/${p.id}`).then((res) => {
      setChange(new Date());
    });
  };

  useEffect(() => {
    getPlantillas();
  }, [change]);

  return (
    <div className="flex flex-wrap w-full  overflow-y-scroll scroll p-2 rounded-lg">
      <div className="w-full h-12 flex justify-end">
        <Refresh
          onClick={() => setChange(new Date())}
          size="30"
          className="text-blue-400 cursor-pointer transition ease-in-out duration-700 transform hover:scale-125 hover:rotate-90"
        ></Refresh>
      </div>
      <table className="w-full ">
        <thead className="border-blue-ligth bg-white border">
          <th>Nombre</th>
          <th>Descripcion</th>
          <th>Detalle</th>
          <th>Descargar</th>
          <th>Eliminar</th>
        </thead>
        <tbody>
          {plantillas &&
            plantillas.length > 0 &&
            plantillas.map((p) => {
              return (
                <tr className="h-10  border border-blue-ligth">
                  <td className="p-2 text-center">{p.nombre}</td>
                  <td></td>
                  <td className="text-center"></td>
                  <td className="text-center">
                    <DownloadE
                      cols={p.fields.split(',')}
                      size="33"
                      className="transform hover:scale-125 text-green-500"
                    />
                  </td>
                  <td className="text-center">
                    <Delete
                      onClick={() => deletePlantilla(p)}
                      size="33"
                      className="transform hover:scale-125 text-red-500"
                    ></Delete>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TPlantillas;
