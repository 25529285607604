import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';

import Back from '../../components/utils/Back';
import Resumen from '../../components/smsCampaigns/Resumen';
import EditCamp from '../../components/campaigns/EditCamp';
import Llamadas from '../../components/campaigns/Llamadas';
import Agentes from '../../components/campaigns/Agentes';
import AddAgente from '../../components/campaigns/modals/AddAgente';
import Statistics from '../../components/campaigns/Statistics';


import Enviar from '../../components/smsCampaigns/Enviar.jsx'
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import {TitleActions} from '../../structs/layout/Title'
import {MultiPageSelectorChange} from '../../structs/layout/MultiPage'
import {MultiPageSelectorChange3} from '../../structs/layout/MultiPage'
import TitleBack from '../../structs/layout/TitleBack'

const SMSCampaign = () => {
  const { id } = useParams();
  const [camp, setCamp] = useState({});
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(null);
  //const [llamadas,setLlamadas] = useState([])
  const [data, setData] = useState(null);

  const iconStyles = '-ml-0.5 mr-2 text-gray-400'


  const [addAgenteModal, setAddAgenteModal] = useState(false);

  const getCamp = async () => {
    await axiosInstance.get(`sms_campaigns/` + id).then((res) => {
      setCamp(res.data);
    });
  };


  useEffect(() => {
    getCamp();
  }, [change]);

  return (
	
	  <div className="w-full flex flex-wrap h-auto ">
        {addAgenteModal === true && (
          <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
            <AddAgente camp={camp} setModal={setAddAgenteModal} setChange={setChange} />
          </div>
        )}
    {/* <TitleActions title={camp.nombre} path={[{name:'Campañas',path:'/camps_sms'},{name:'SMS',path:'/camps_sms'},{name:'Detalle',path:`/camps_sms/${camp ? camp.id : '1'}`},]}> */}
    <TitleBack title={camp && camp.nombre}></TitleBack>

    {/* </TitleActions> */}
    <MultiPageSelectorChange3 setPage={setPage} tabs={[{name:'Resumen',page:1,current:page == 1 ? true : false},{name:'Enviar SMS',page:2,current:page == 2 ? true : false},{name:'Configuracion',page:4,current:page == 4 ? true : false}]}></MultiPageSelectorChange3>
    {/* <MultiPageSelectorChange2 setPage={setPage} tabs={[{name:'Resumen',page:1,icon:<UserDetail size="20" className={iconStyles}/>,current:page == 1 ? true : false},{name:'Enviar',page:2,current:page == 2 ? true : false}]}></MultiPageSelectorChange2> */}
    <div className="w-full h-auto bg-gray-50 p-4">

      {page==1 && <Resumen id={id}></Resumen>}
      {page==2 && <Enviar></Enviar>}
    </div>
      

    </div>
  );
};

export default SMSCampaign;

