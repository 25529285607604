import React from 'react';
import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import toast from 'react-hot-toast';

import { IndentMore } from '@styled-icons/foundation/IndentMore';

import { getImportanciaP } from '../../utils/models';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { ArrowDown } from '@styled-icons/bootstrap/ArrowDown';
import { ArrowUp } from '@styled-icons/bootstrap/ArrowUp';
const Table = ({ camps, setChange }) => {

  const cambiarStatus = (camp) => {
    
    axiosInstance.patch(`mail_templates/${camp.id}/`, { status: !camp.status }).then((res) => {
      setChange(new Date());
      toast.success(`Cambio de status en ${camp.nombre}`, { duration: 3000 });
    });
  };

  
  return (
    <table className="w-full leading-normal rounded-lg shadow-2xl overflow-hidden">
      <thead>
        <tr>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            Nombre
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            Fecha Creacion
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            ID
          </th>

          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            status
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            detalle
          </th>
        </tr>
      </thead>
      <tbody>
        {camps &&
          camps.map((camp) => {
            return (
              <tr>
                <td className="p-1 border-b border-gray-200 bg-white text-sm">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-lg font-bold text-gray-900 whitespace-no-wrap">
                        {camp.nombre && camp.nombre}
                      </p>
                    </div>
                  </div>
                </td>
                
                <td className="p-1  border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {camp.add_date&&
                      camp.add_date.substring(8, 10) +
                        '/' +
                        camp.add_date.substring(5, 7) +
                        '/' +
                        camp.add_date.substring(0, 4)}
                  </p>
                </td>
                <td className="p-1 border-b border-gray-200 bg-white text-sm">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-lg  text-gray-600 whitespace-no-wrap">
                        {camp.id_template && camp.id_template}
                      </p>
                    </div>
                  </div>
                </td>
                   <td className="p-1  border-b border-gray-200 bg-white text-sm">
                  {camp && camp.status === true ? (
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span
                        aria-hidden="true"
                        className="absolute cursor-pointer hover:bg-red-200 inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span onClick={() => cambiarStatus(camp)} className="relative cursor-pointer">
                        activa
                      </span>
                    </span>
                  ) : (
                    <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 cursor-pointer hover:bg-green-200 bg-red-200 opacity-50 rounded-full"
                      ></span>
                      <span onClick={() => cambiarStatus(camp)} className="relative cursor-pointer">
                        inactiva
                      </span>
                    </span>
                  )}
                </td>

                <td className="p-1  border-b border-gray-200 bg-white text-sm ">
                  <Link to={`/mail_templates/${camp.id}`}>
                    <button className="text-black hover:text-gray-900 transform hover:scale-125">
                      <IndentMore size="35" className="" />
                    </button>
                  </Link>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Table;
