
import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'


import toast from 'react-hot-toast'
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import {useParams} from 'react-router-dom'

import ReactTooltip from 'react-tooltip';
import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';

 
 

const Enviar = () => {
  const [data,setData] = useState({
    'numxct':1,
    'number':null,
    'template_id':null

  })
  const [templates,setTemplates] = useState([])
  const [nums,setNums] = useState([])
  const { id } = useParams();

  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }

  const getTemplates = async () => {
    await axiosInstance.get(`sms_templates?status=true`).then((res) => {
      setTemplates(res.data);
      // if(res.data.length > 0){
      //   setData({...data,['template_id']:res.data[0].id})
      // }

    });
  };
  const getNums = async () => {
    await axiosInstance.get(`twilio/numeros`).then((res) => {
      setNums(res.data);
      // if(res.data.length > 0){
      //   setData({...data,['number']:res.data[0].numero})
      // }

    });
  };
  const send = () =>{
    if(data['number'] === null){
      toast.error('seleciona un numero')
      return null 
    }
    if(data['template_id'] === null){
      toast.error('seleciona un template')
      return null 
    }
    var tid = toast.loading('Cargando')
    axiosInstance.post(`sms_campaigns/${id}/send/`,data).then(res=>{
      toast.remove(tid)
      toast.success('Enviando SMS')
    }).catch(err=>toast.remove(tid))}


  useEffect(()=>{
    getTemplates()
    getNums()
  },[]) 
  

  return (
      <div className="w-full flex flex-wrap mt-4 bg-white rounded-md shadow p-4 mx-auto gap-y-8">
        <div className="flex w-full">
        <div className="w-40 min-w-32 border-r px-4 flex items-center">
            <h3 className="text-xl font-medium leading-6 text-gray-900">Configuracion</h3>
        </div>
        <div className="w-5/6 h-auto  flex flex-wrap px-4 gap-x-4 justify-around">
          {/* <div className="font-semibold leading-6 rounded-md px-3 py-2"> */}
          {/*   <span className="ml-3">Emisor</span> */}
          {/*   <input */}
          {/*     onChange={handleChange} */}
          {/*     type="text" */}
          {/*     name="sender" */}
          {/*     className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm" */}
          {/*   /> */}
          {/* </div>           */}
          {/* <div className="font-semibold leading-6 rounded-md px-3 py-2"> */}
          {/*   <span className="ml-3">Asunto</span> */}
          {/*   <input */}
          {/*     onChange={handleChange} */}
          {/*     type="text" */}
          {/*     name="subject" */}
          {/*     className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm" */}
          {/*   /> */}
          {/* </div>           */}
          <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3">Template</span>
            <select
              onChange={handleChange}
              type="text"
              name="template_id"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >

            <option key={0} value="">---------</option>
            {templates.map((template)=>{
              return <option key={template.id} value={template.id}>{template.nombre}</option>
            })}

            </select>
          </div>          

          <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3">Numero</span>
            <select
              onChange={handleChange}
              type="text"
              name="number"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >

            <option key={0} value="">---------</option>
            {nums.map((num)=>{
              return <option key={num.id} value={num.numero}>{num.numero}</option>
            })}

            </select>
          </div>

        </div>
      </div>
      <div className="flex w-full  ">
        <div className="w-40 border-r px-4 flex items-center ">
            <h3 className="text-xl font-medium leading-6 text-gray-900">Filtros</h3>
        </div>
        <div className="w-5/6 h-auto  flex flex-wrap px-4 gap-x-4 justify-around">
          <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3">Numeros x Contacto <InfoCircle size={16} data-tip data-for="numxct" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="numxct" effect="solid">
                        <p className="break-words">Cuantos numeros por contacto llamar.</p>
                    </ReactTooltip></span>
            <input
              onChange={handleChange}
              value={data['numxct']}
              type="number"
              name="numxct"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >
            </input>
          </div>

        </div>
      </div>

      <div className="flex w-full mt-4 ">
        <div className="w-40 border-r px-4 flex items-center ">
            <h3 className="text-xl font-medium leading-6 text-gray-900">Enviar</h3>
        </div>
        <div className="w-5/6 h-auto  flex flex-wrap px-4 gap-x-4 justify-center">
          <button
          onClick={()=>send()}
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Enviar
        <EnvelopeIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>

        </div>
      </div>
   </div>
  )
}

export default Enviar;


