import {useState,useEffect} from 'react'

import axiosInstance from '../../axios';

import EventsGrafico from '../../components/dashboards/mails/EventosMails.jsx'
import DeliveredGrafico from '../../components/dashboards/mails/DeliveredG.jsx'
import Camps from '../../components/dashboards/mails/Camps.jsx'
import Events from '../../components/dashboards/mails/Events.jsx'

import Bars from '../../components/dashboardAdmin/graficos/Bars';
import Pies from '../../components/dashboardAdmin/graficos/Pies';
import Camps2 from '../../components/dashboardAdmin/graficos/Camps2';

import {ArrowUpRight2} from '@styled-icons/icomoon/ArrowUpRight2'
import {ArrowDownRight2} from '@styled-icons/icomoon/ArrowDownRight2'

const LlamadasDashboard2  = () =>{
  const [data,setData] = useState(null)
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });
  const [mandante,setMandante] = useState("")
  const [mandantes,setMandantes] = useState([])
  
  const getMandantes = async () =>{
    await axiosInstance.get(`mandantes`).then(res=>{
      setMandantes(res.data)
    })
  }  



  const getData = async () => {
    if (dates['desde'] !== null && dates['hasta'] !== null) {
      await axiosInstance
        .get(`llamadas/dashboard_general/?desde=${dates['desde']}&hasta=${dates['hasta']}&mandante=${mandante}`)
        .then((res) => {
          setData(res.data);
          console.log(res.data);
        });
    }
  };

  const handleChange = (e) =>{
    setDates({...dates,[e.target.name]:e.target.value})
  }

  useEffect(()=>{
    if (dates['desde'] === null || dates['hasta'] === null) {
      var date = new Date();

      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
    }else{
      getData()
    }
  },[dates,mandante])

  useEffect(()=>{
    getMandantes()
  },[])




  return (
    <div className="w-full h-full flex-wrap bg-gray-100">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Dashboard Llamadas</p>

        <select name="mandante" onChange={(e)=>setMandante(e.target.value)} className='px-2 py-1 bg-white border border-blue-semi rounded-md'>
          <option key={0} value={""}>-------------</option>
          {mandantes.map((man)=>{
            return <option key={man.id} value={man.codigo}>{man.nombre}</option>
          })}
        </select>

        <div className="flex items-center gap-x-2  p-1">
              <div className="rounded-md flex bg-gray-50 w-auto px-2 border border-blue-semi border-t-2 ">
                <input
                  name="desde"
                  type="date"
                  value={dates['desde']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
                <span className="h-full w-0.5 bg-gray-500"></span>
              <div className="rounded-md flex bg-gray-50 border border-blue-semi border-t-2">
                <input
                  name="hasta"
                  type="date"
                  value={dates['hasta']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
        </div>
      </div>

      <div className="w-full flex flex-wrap p-6 gap-6 overflow-y-scroll bg-gray-100">
      
         {/* stats  */}
          <div className="w-full flex h-40 border-b bg-white rounded-md border p-4">
              
              <div className="w-1/5  border-r flex flex-wrap content-start gap-y-4 px-6">
                <p className="font-thin text-lg w-full">Llamadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.llamadas.total.count}</p>
                <div className="font-normal w-full flex text-md mx-1"><span className="text-indigo-500 ">{data && data.llamadas.total.pct}</span></div>
              </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Pendientes</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.llamadas.pendientes.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-yellow-500 ">{data && data.llamadas.pendientes.pct}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Rellamadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.llamadas.rellamadas.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 "><ArrowDownRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-blue-500 ">{data && data.llamadas.rellamadas.pct}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Realizadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.llamadas.realizadas.count}</p>
    
                <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.llamadas.realizadas.pct}</span></div>
            </div>
            <div className="w-1/5 flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Terminadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.llamadas.terminadas.count}</p>
    
                <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 ">{data && data.llamadas.terminadas.pct}</span></div>
            </div>
        </div>

          <div className="w-full flex h-22 border-b bg-white rounded-md border px-4">
              <div className="w-1/5  border-r flex flex-wrap content-start gap-y-2 px-2 py-2 justify-center items-center">
                <p className="font-thin text-lg text-center w-full">Contactabilidad</p>
                <span className="text-blue-500 text-center w-full text-lg font-semibold">{data && data.stats_resps.contactability.pct}</span>
              </div>

              <div className="w-1/5  border-r flex flex-wrap content-start gap-y-2 px-2 py-2 justify-center items-center">
                <p className="font-thin text-lg text-center w-full">Directos</p>
                <span className="text-blue-500 text-center w-full text-lg font-semibold">{data && data.stats_resps.direct_contact.pct}</span>
              </div>

              <div className="w-1/5  border-r flex flex-wrap content-start gap-y-2 px-2 py-2 justify-center items-center">
                <p className="font-thin text-lg text-center w-full">Terceros</p>
                <span className="text-blue-500 text-center w-full text-lg font-semibold">{data && data.stats_resps.indirect_contact.pct}</span>
              </div>

              <div className="w-1/5  border-r flex flex-wrap content-start gap-y-2 px-2 py-2 justify-center items-center">
                <p className="font-thin text-lg text-center w-full">Outbound</p>
                <span className="text-blue-500 text-center w-full text-lg font-semibold">{data && data.stats_resps.outbound.pct}</span>
              </div>

              <div className="w-1/5   flex flex-wrap content-start gap-y-2 px-2 py-2 justify-center items-center">
                <p className="font-thin text-lg text-center w-full">Inbound</p>
                <span className="text-blue-500 text-center w-full text-lg font-semibold">{data && data.stats_resps.inbound.pct}</span>
              </div>

        </div>

          
         {/* -------stats--------  */}


          <div className="w-full flex gap-x-4">
            <div className="flex-grow bg-white rounded-md border  flex flex-wrap">
            
            <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Contactabilidad</p></div>
              <div className="w-full h-96 p-4"> 
                    {data && <Bars data={data.dias}></Bars>}
            </div> 
            
            </div> 
            
            <div className="w-2/5 flex-shrink-0 bg-white rounded-md border  flex flex-wrap">
            
            <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Respuestas</p></div>
              <div className="w-full h-96 p-4"> 
                    {data && <Pies grupos={data.grupos} tipos={data.tipos}></Pies>}
            </div> 
            
            </div> 

          </div>

          {data && <Camps2 camps={data.camps} resps_opts={data.resps_opts}
          ></Camps2>}

          {/* camps */}

          



    </div>


    </div>
  )
}

export default LlamadasDashboard2;
