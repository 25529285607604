import React, { useState, useEffect } from 'react';

import axiosInstance from '../../../axios';

import AddResp from './AddResp';
import RTable from './RTable';

import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';
import { PlusMedical } from '@styled-icons/boxicons-regular/PlusMedical';

const Respuestas = ({ show, setShow, camp }) => {
  const [resps, setResps] = useState([]);
  const [change, setChange] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const getRespuestas = async () => {
    axiosInstance.get(`campaigns/${camp.id}/get_respuestas/`).then((res) => {
      setResps(res.data);
    });
  };

  useEffect(() => {
    getRespuestas();
  }, [change]);

  return (
    <div
      className={`${
        show === true ? 'h-full' : 'h-12'
      } w-full flex justify-between flex-wrap border content-start   bg-white rounded-lg  relative`}
    >
      {showAdd && (
        <AddResp
          setModal={setShowAdd}
          campResps={resps.map((r) => r.id)}
          campId={camp.id}
          setChange={setChange}
        />
      )}
      <div className="w-full h-12 bg-gray-dark justify-between flex text-2xl font-bold text-white px-4">
        <h1 className="text-center my-auto">Respuestas</h1>
        <div clasName="w-1/3 flex">
          <button
            onClick={() => {
              setShowAdd(!showAdd);
            }}
            className="my-1 mx-2 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-green-600 hover:-translate-y-1 hover:scale-105   ring-transparent"
          >
            <PlusMedical
              size="23"
              className={`text-white transform transition-all ease-in-out duration-300`}
            />
          </button>
          <button
            onClick={() => setShow(!show)}
            className="my-1 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105   ring-transparent"
          >
            <ArrowBoldDown
              size="23"
              className={`text-white transform transition-all ease-in-out duration-300  ${
                show ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </button>
        </div>
      </div>
      {show && (
        <div className="h-full w-full flex justify-between flex-wrap border bg-white rounded-lg">
          <RTable campId={camp.id} resps={resps} setResps={setResps} setChange={setChange} />
        </div>
      )}
    </div>
  );
};

export default Respuestas;
