import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const RAgents = ({ data, resps }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" />
        {resps &&
          resps.map((r) => (
            <Bar dataKey={r.name} stackId="a" fill={r.color}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={r.color} />
              ))}
            </Bar>
          ))}

        <Tooltip itemStyle={{ zIndex: 50 }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RAgents;
