import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { PeopleCall } from '@styled-icons/fluentui-system-filled/PeopleCall';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';

const ContactoRow = ({ obj, addSelected, removeSelected, selected,idx}) => {
  
  
  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(obj);
    } else {
      removeSelected(obj);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((rr) => rr.id === obj.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
          <tr key={obj.id} className={`${idx % 2 === 0 ? '' : 'bg-gray-50 '} border-b`}>
                      
                    <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        checked={checked}
                        onChange={handleCheck}
                      />
                    </th>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {obj.nombre}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{obj.apellido}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj.rut}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj.mandante}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj.estado}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{obj.Cuenta}</td>
                       <td className=" w-10 text-center">
                        <Link to={obj && `/contactos/${obj.id}`}>
                          <UserDetail size="25" className="text-blue-ligth" />
                        </Link>
                      </td>
                  
                    </tr>
    
  )
};

export default ContactoRow;
