import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast';

const MailAction = ({ contactos, setChange }) => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const [tipoS, setTipoS] = useState(null);
  const [listas, setListas] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente] = useState(null);
  const [lista, setLista] = useState(null);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getListas = () => {
    axiosInstance.get(`listas?status=1`).then((res) => {
      setListas(res.data);
    });
  };

  const createAction = () => {
    if (tipoS === 1) {
      if (lista !== null && contactos.length > 0) {
        axiosInstance
          .post(`contactos/add_to_list/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
            lista: lista.id,
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(
                `se agregaron ${contactos.length} contactos a la lista ${lista.nombre} `,
                { duration: 3000 },
              );
          });
      }
    } else if (tipoS === 2) {
      if (lista !== null && contactos.length > 0) {
        axiosInstance
          .post(`contactos/remove_from_list/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
            lista: lista.id,
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(
                `se eliminaron ${contactos.length} contactos de la lista ${lista.nombre} `,
                { duration: 3000 },
              );
          });
      }
    }
  };

  useEffect(() => {
    show && getListas();
  }, [show]);

  return (
    <div className="absolute w-48 flex flex-wrap  z-30 bg-blue-normal transform -translate-x-40 h-auto">
      <div
        onClick={() => {
          setTipoS(1);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-gray-800 cursor-pointer"
      >
        <p className="text-white my-auto text-center">Añadir a lista</p>
      </div>
      <div
        onClick={() => {
          setTipoS(2);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-gray-800 cursor-pointer"
      >
        <p className="text-white my-auto text-center">Eliminar de lista</p>
      </div>
      {show && (
        <div className="rounded-lg flex p-2 flex-wrap content-start w-80 h-80 bg-blue-normal absolute transform -translate-x-80 overflow-y-scroll no-scrollbar">
          <div className="flex flex-wrap content-start h-4/5 w-full border-b overflow-y-scroll no-scrollbar border">
            <div className="h-6 w-full bg-white flex justify-center font-bold">Lista</div>
            {listas.length > 0 &&
              listas.map((li) => {
                if (lista !== null && li.id === lista.id) {
                  return (
                    <div
                      onClick={() => {
                        setLista(null);
                      }}
                      className="h-auto  justify-center cursor-pointer w-full border-b bg-gray-700 flex"
                    >
                      <p className="my-auto text-lg text-white">{li.nombre}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setLista(li);
                      }}
                      className="h-auto flex justify-center  cursor-pointer w-full border-b"
                    >
                      <p className="text-lg text-white my-auto">{li.nombre}</p>
                    </div>
                  );
                }
              })}
          </div>
          <div className="w-full h-1/5  flex justify-around py-2">
            <button
              onClick={() => setShow(false)}
              className="rounded-lg bg-red-600 px-3 py-1 text-gray-100"
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="rounded-lg bg-green-600 px-3 py-1 text-gray-100"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MailAction;
