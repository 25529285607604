import { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';
import toast from 'react-hot-toast';

import fileDownload from 'js-file-download';

import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { ArrowDownload } from '@styled-icons/fluentui-system-regular/ArrowDownload';
import { ChevronRightCircle } from '@styled-icons/boxicons-regular/ChevronRightCircle';

const CargaFile = () => {
  const [digitos, setDigitos] = useState(0);
  const [change, setChange] = useState(null);

  const [file, setFile] = useState(null);

  const handleChangeDigitos = (e) => {
    setDigitos(e.target.value);
  };
  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const close = () => {
    setFile(null);
  };

  const downloadPlantilla = async (p) => {
    await axiosInstance.post(`documents/plantilla/`, { responseType: 'blob' }).then((res) => {
      // console.log(res.headers)
      fileDownload(res.data, `plantilla_docs.csv`);
    });
  };

  const cargar_excel = () => {
    if (file === null) {
      toast.error('Seleciona un archivo');
      return null;
    }
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('digitos', digitos);
    console.log(data.values());
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`documents/cargar/`, data)
      .then((res) => {
        setChange(new Date());
        toast.remove(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => toast.remove(toastId))
      .finally(() => toast.remove(toastId));
    //setFile(null)
  };

  useEffect(() => {}, []);

  return (
    <div className="flex-grow flex  h-96 rounded-md border shadow-md bg-white">
      <div className="border-r w-full flex flex-wrap content-between p-4 ">
        <div className="w-full flex-wrap content-start h-42">
          <p className="text-lg ">Carga Excel</p>
          <p className="text-md text-gray-600 pl-2">
            1. Descargue la plantilla{' '}
            <ArrowDownload
              size="24"
              onClick={() => downloadPlantilla()}
              className="text-blue-ligth transform hover:scale-110 cursor-pointer"
            ></ArrowDownload>
          </p>
          <p className="text-md text-gray-600 pl-2">2. Eliga su archivo</p>
          <p className="text-md text-gray-600 pl-2">3. Ingrese numero de digitos nro_cuenta</p>
          <p className="text-md text-gray-600 pl-2">4. Click el boton Cargar</p>
        </div>

        <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center">
          <div className="w-full bg-gray-100 rounded-md h-10 flex justify-center items-center"></div>
          <label className="my-auto  w-full flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
            <CloudUpload size="40"></CloudUpload>
            <span className="mt-1 text-base leading-normal">Select a file</span>
            <input
              type="file"
              onChange={handleChangeFile}
              className="hidden"
              accept=".csv,.txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx  xls, .xlsb , .xltx , xls "
            />
          </label>
          <h1 className="font-bold text-blue-500  ">{file && file.name}</h1>
          <p className="text-gray-700 font-semibold mx-2">Digitos :</p>
          <input
            type="number"
            onChange={handleChangeDigitos}
            value={digitos}
            className="w-12  px-2 h-8 rounded-md shadow-lg font-bold border border-blue-800 text-blue-500"
          ></input>
        </div>

        <button
          onClick={() => cargar_excel()}
          className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600"
        >
          Cargar
        </button>
      </div>
    </div>
  );
};

export default CargaFile;
