import React from 'react'

const TableContactabilidad = ({data}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300 border rounded-md bg-white">
    <thead >
      <tr>
        <th
          scope="col"
          className="whitespace-nowrap py-3.5 px-2 text-left text-sm font-semibold text-gray-900 "
        >
          Mandante

        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Intensidad
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          CD
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          CI
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          SC
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          SG
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          Total
        </th>
        
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200 bg-white">
      {data && data.map((man) => (
        <tr >
          <td className="whitespace-nowrap py-2 px-2 text-md">{man.nombre}</td>
          <td className="whitespace-nowrap px-2 py-2 text-md text-gray-800">{man.intensidad && man.intensidad}</td>
          <td className="whitespace-nowrap px-2 py-2 text-md text-gray-800">{man.contacto_directo}<span className='ml-4 text-sm '>{man.contacto_directo_pct}</span></td>
          <td className="whitespace-nowrap px-2 py-2 text-md text-gray-800">{man.contacto_indirecto}<span className='ml-4 text-sm '>{man.contacto_indirecto_pct}</span></td>
          <td className="whitespace-nowrap px-2 py-2 text-md text-gray-800">{man.sin_contacto}<span className='ml-4 text-sm '>{man.sin_contacto_pct}</span></td>
          <td className="whitespace-nowrap px-2 py-2 text-md text-gray-800">{man.sin_gestion}<span className='ml-4 text-sm '>{man.sin_gestion_pct}</span></td>
          <td className="whitespace-nowrap px-2 py-2 text-md text-gray-800">{man.total}<span className='ml-4 text-sm '>100.0%</span></td>
        </tr>
      ))}
    </tbody>
  </table>
  )
}

export default TableContactabilidad
