import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { PeopleCall } from '@styled-icons/fluentui-system-filled/PeopleCall';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';

const ContactoRow = ({ r, addSelected, removeSelected, selected }) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(r);
    } else {
      removeSelected(r);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((rr) => rr.id === r.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
    <tr className="text-gray-700 ">
      <td className="border p-1 flex justify-center">
        <input className="my-auto h-8" type="checkbox" checked={checked} onChange={handleCheck} />
      </td>
      <td className="border text-center text-sm">{r && r.nombre}</td>
      <td className="border text-center text-sm">{r && r.apellido}</td>
      <td className="border text-center text-sm">{r && r.rut}</td>
      <td className="border text-center text-sm">{r && r.mandante}</td>
      <td className="border text-center text-sm">{r && r.estado}</td>
      <td className="border  text-center text-sm">{r && r.nro_cuenta}</td>
      <td className="border  text-center text-sm">{r && r.nro_pagare}</td>
      <td className="border w-10 text-center">
        <Link to={r && `/contactos/${r.id}`}>
          <UserDetail size="25" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border w-10 text-center">
        <Link to={r && `/llamadas?contacto=${r.id}`}>
          <PeopleCall size="25" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border w-10 text-center">
        <Link to={r && `/respuestas?contacto=${r.id}`}>
          <TelephoneAccessible size="25" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border w-10"></td>
      <td className="border w-10"></td>
    </tr>
  );
};

export default ContactoRow;
