import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import UsersTable from '../../components/admin/UsersTable.jsx'

// import CreateModal from '../../components/users/CreateUModal2.jsx';
import CreateModal from '../../components/users/CreateUModal2.jsx';
import TestBar from '../../components/users/TestBar';
import { CSVLink, CSVDownload } from 'react-csv';

import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import FastFiltro from '../../components/FastFiltro'
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';

const Users2 = () =>{
  const [objs,setObjs] = useState([])
  const [data,setData] = useState({nombre:'',dominio:''})
  const [create,setCreate] = useState(false)
  const [change,setChange] = useState(null)
  const [display,setDisplay] = useState([])
  const [status,setStatus] = useState(true)

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }
  const getObjs = async () =>{
    await axiosInstance.get(`users/?is_active=${status}`).then(res=>{
      setObjs(res.data) 
      setDisplay(res.data) 
    })
  }

  const addObj = async () =>{
    
    data.nombre !== '' && await axiosInstance.post(`mail_domains/`,data).then(res=>{
      setData({nombre:'',dominio:''})
      getObjs()
      setCreate(false)
    })
  }

  const getData = () => {
    return display.map((row) => {
      return {
        id: `${row.id}`,
        nombre: `${row.first_name} ${row.last_name}`,
        rut: `${row.rut}`,
        email: `${row.email}`,
        numero: `${row.phone_nunber}`
        
      };
    });
  };

  const filterDisplay = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    var filtered
    if(e.target.name === 'first_name'){
      filtered = objs.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase())
      )
    }else if(e.target.name === 'rol'){
      if(e.target.value == 0){
       filtered = objs.filter((x) =>
        [1,2,3].includes(e.target.value)
      ) 
      }else{
      filtered = objs.filter((x) =>
        x[`${e.target.name}`] == e.target.value
      )
      }
      
    }
     
    setDisplay(filtered);
  };

  useEffect(()=>{
    getObjs()

  },[change,status])
  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll">
      <CreateModal open={create} setOpen={setCreate} setChange={setChange}></CreateModal> 
    
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Usuarios</p> <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
      </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12 gap-y-2">
            <FastFiltro>
              <input onChange={filterDisplay} name="first_name" className="w-64 border-2  bg-gray-100 px-2 py-1 rounded-md text-gray-700" placeholder="Buscar"></input>
              <select className="border p-2 rounded-md" onChange={(e)=>setStatus(e.target.value)}>
                <option value={true} key={0} >Activo</option>
                <option value={false} key={1} >Inactivo</option>
              </select>

              <select className="border p-2 rounded-md" name="rol" onChange={filterDisplay}>
                <option value={1} key={1} >Agente</option>
                <option value={2} key={2} >Operador</option>
                <option value={3} key={3} >Administrador</option>
              </select>
          <CSVLink filename="users.csv" data={getData()}>
            <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
          </CSVLink>
            </FastFiltro>
            <UsersTable objs={display} getObjs={getObjs}/>
        </div> 

      </div>
  )
}

export default Users2;

  
