
import React from 'react'

export const Button = ({onClick,title,size=2,type="primary"}) => {

  return (
    <div
          onClick={onClick}
          className={`flex my-auto justify-center ${size === 3 && 'text-xl w-64'} ${size === 2 && 'text-lg w-32'} ${size === 1 && 'text-lg w-20'} h-10 transition ease-in-out duration-200  rounded-lg text-white font-bold  transform hover:scale-105 cursor-pointer 
          ${type === 'primary' && 'bg-blue-ligth'} ${type === 'warning' && 'bg-red-500'} ${type === 'info' && 'bg-blue-500'} ${type === 'create' && 'bg-green-500'}
                `}
        >
          <p className="my-auto">{title}</p>
        </div>
  )
}
