import React, { useState, useEffect } from 'react';

import { Link,useParams } from 'react-router-dom';

import axiosInstance from '../../axios';
import format from '../../utils/formatDate';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { Verified } from '@styled-icons/material/Verified';
import { Unverified } from '@styled-icons/octicons/Unverified';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';


import {EmailOutline} from '@styled-icons/evaicons-outline/EmailOutline'
import {Sms as SmsIcon} from '@styled-icons/material/Sms'


const Sms = ({ contacto, setChange,sms}) => {

  // const [mails,setMails] = useState([])
  
  const { id } = useParams();

  const getRespuestas = () => {
    axiosInstance.get(`mails?contacto${id}&page_size=100`).then((res) => {
      // setMails(res.data.results);
      console.log(res.data)
      // setPag({
      //   next: res.data.next,
      //   'previous ': res.data.previous,
      //   count: res.data.count,
      // });
    });
  };

  useEffect(() => {
    // if (contacto.id !== undefined) {
    //   getRespuestas();
    // }
    // //
  }, [contacto]);

  return (
    <div className="w-full flex justify-center gap-x-3">
      
      <div className="flex flex-wrap  content-start w-full md:w-3/4 p-1 ">
      {sms.map((sms)=>{
        return <><div className="flex w-full content-start flex-wrap gap-x-4 ">
              
              <div className="w-full">
                <div className="w-8 h-8 rounded-full bg-green-500 flex justify-center p-1 ">
                  <SmsIcon size="25" className="text-white"/>
                </div>
              </div>
          <div className="w-full flex gap-x-6">
              <span className="ml-4 h-auto w-0.5 bg-green-400"></span>

              <div className="flex-grow flex flex-wrap bg-white rounded-md p-3">
                <div className="w-full flex  justify-between">
                  <p className="text-gray-400 text-sm">SMS de <span className="text-gray-800"> {sms.template && sms.template.nombre}</span></p>
                  <p className="text-gray-500 text-sm">{format(sms.add_date)}</p>
                </div>

                <div className="w-full flex  justify-between mt-2">
                  <p className="text-gray-400 font-semibold"><span className="text-gray-500"> {sms.num_from}</span></p>
                  <p className="text-gray-400 font-semibold"><span className="text-gray-500"> {sms.num_to}</span></p>
                </div>
              </div>
          </div>
                
          </div>

          </>
      })}
      </div>
    </div>
      
  );
};

export default Sms;
