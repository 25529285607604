import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,LabelList} from 'recharts';

const Bars = ({data}) => {

    return (
   
      <ResponsiveContainer width="100%" height="90%">
      <BarChart
        barSize={20}
        layout="vertical"
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis type="number" hide  padding={{ right: 28 }}/>
        <YAxis dataKey="name" tickLine={false} axisLine={false} type="category" />
        <Bar  dataKey="value" fill='#1338bf'>

       <LabelList dataKey="perc" position="right" />
      </Bar>
        <Tooltip cursor={false}/>

      </BarChart>
    </ResponsiveContainer>


    );
}
export default Bars;

   {/* <ResponsiveContainer width="100%" height="100%"> */}
   {/*      <BarChart  data={data}  */}
   {/*        layout="vertical" */}
   {/*          data={data} */}
   {/*          margin={{ */}
   {/*            top: 5, */}
   {/*            right:5, */}
   {/*            left: 5, */}
   {/*            bottom: 5, */}
   {/*          }} */}
   {/*        > */}
   {/**/}
   {/*        <Bar dataKey="uv" fill='#1338bf' /> */}
   {/*      </BarChart> */}
   {/*    </ResponsiveContainer> */}

