import React, { useEffect, useState } from 'react';

import axiosInstance from '../../axios';

import RUTable from '../../components/admin/RUTable';

import CreateRModal from '../../components/reglas/CreateRModal';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

import Select from 'react-select';

function Reglas() {
  const [reglas, setReglas] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [change, setChange] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [filtro, setFiltro] = useState({
    first_name: '',
    last_name: '',
    roles: [],
  });

  useEffect(() => {
    axiosInstance.get('reglas/').then((res) => {
      if (res.data) {
        console.log(res.data.results);
        setReglas(res.data.results);
        setDisplay(res.data.results);
      }
    });
  }, [change]);

  const handleToggle = (is_active) => {
    setToggle(is_active);
    //setDisplay(resps.filter(x=>x.is_active == is_active))
  };

  const options = [
    { value: 1, label: 'Positivas' },
    { value: 2, label: 'Negativas' },
    { value: 3, label: 'Rellamada' },
  ];

  const handleChange = (e) => {
    console.log(typeof e.target.value);
    if (e.target.value === '') {
      setDisplay();
    }

    setDisplay(
      reglas.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  return (
    <div className="flex flex flex-wrap w-full h-full bg-white px-2">
      {addModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateRModal setModal={setAddModal} setChange={setChange} />
        </div>
      )}
      <div className="flex justify-between w-full h-16 bg-white border-b px-5 pt-1">
        <h1 className="text-4xl font-bold mt-2">Reglas</h1>
        <PlusCircle
          onClick={() => setAddModal(true)}
          size="55"
          className="text-green-600 mt-1 transform hover:scale-125"
        />
      </div>
      {/* <div className="flex w-full mb-3 px-2 bg-gray-dark rounded-lg py-4 gap-4 pt-7"> */}
      {/*   <input */}
      {/*     type="text" */}
      {/*     name="nombre" */}
      {/*     onChange={handleChange} */}
      {/*     placeholder="Nombre" */}
      {/*     className="p-1 border border rounded-sm w-1/12" */}
      {/*   ></input> */}
      {/**/}
      {/*   {toggle ? ( */}
      {/*     <label for="unchecked" class="inline-flex items-center cursor-pointer"> */}
      {/*       <span class="relative"> */}
      {/*         <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span> */}
      {/*         <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out"> */}
      {/*           <input */}
      {/*             id="unchecked" */}
      {/*             onClick={() => handleToggle(!toggle)} */}
      {/*             type="checkbox" */}
      {/*             class="absolute opacity-0 w-0 h-0" */}
      {/*           /> */}
      {/*         </span> */}
      {/*       </span> */}
      {/*       <span class="ml-3 text-sm">Activo</span> */}
      {/*     </label> */}
      {/*   ) : ( */}
      {/*     <label for="checked" class="inline-flex items-center cursor-pointer"> */}
      {/*       <span class="relative"> */}
      {/*         <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span> */}
      {/*         <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full"> */}
      {/*           <input */}
      {/*             id="checked" */}
      {/*             onClick={() => handleToggle(!toggle)} */}
      {/*             type="checkbox" */}
      {/*             class="absolute opacity-0 w-0 h-0" */}
      {/*           /> */}
      {/*         </span> */}
      {/*       </span> */}
      {/*       <span class="ml-3 text-sm">Inactivo</span> */}
      {/*     </label> */}
      {/*   )} */}
      {/* </div> */}
      <div
        style={{ height: '78vh' }}
        className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
      >
        <RUTable reglas={display} setReglas={setReglas} setChange={setChange}></RUTable>
      </div>
    </div>
  );
}
export default Reglas;
