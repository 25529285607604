import React, { useEffect } from 'react';
import Bars from './graficos/Bars';
import Pies from './graficos/Pies';
import Camp from './graficos/Camp';

const camps = [1, 2, 3, 4, 5, 6, 7];
const General = ({ data, getGeneral, change, dates }) => {
  useEffect(() => {}, []);

  return (
    <div className="w-full h-full flex flex-wrap content-start px-4 gap-y-3">
      <div className="w-3/5 h-1/2  p-3">
        <div className="w-full h-full shadow-xl border  rounded-lg">
          {data && <Bars data={data.dias}></Bars>}
        </div>
      </div>
      <div className="w-2/5 h-1/2  p-3 rounded-lg">
        <div className="w-full h-full shadow-xl border p-2  rounded-lg">
          {data && <Pies grupos={data.grupos} tipos={data.tipos}></Pies>}
        </div>
      </div>
      <div className="w-1/2 h-auto flex flex-wrap p-3 border  rounded-lg shadow-xl">
        <div className="w-full h-16 flex justify-center p-3 border-b-4 border-blue-ligth">
          <p className="my-auto text-xl font-bold text-blue-ligth">Campañas</p>
        </div>
        {data?.camps &&
          data.camps.length > 0 &&
          data.camps.map((camp) => {
            return (
              <div className="w-full h-24 p-1">
                {data && (
                  <Camp
                    nombre={camp.nombre}
                    status_data={camp.status_data}
                    resps_data={camp.resps_data}
                    resps_opts={data.resps_opts}
                  ></Camp>
                )}
              </div>
            );
          })}
      </div>
      <div className="w-1/2 h-1/2 p-3">
        <div className="h-full w-full border rounded-lg"></div>
      </div>
      <div className="w-full h-1/3 "></div>
    </div>
  );
};

export default General;
