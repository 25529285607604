import React, { useState, useEffect } from 'react';

import axiosInstance from '../../../axios';
import { Link } from 'react-router-dom';

import { Eye } from '@styled-icons/heroicons-solid/Eye';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { StatusOffline } from '@styled-icons/heroicons-outline/StatusOffline';
import { StatusOnline } from '@styled-icons/heroicons-outline/StatusOnline';
import { EditOff } from '@styled-icons/material/EditOff';
import { Data } from 'styled-icons/boxicons-regular';

const RUTable = ({ reglas, setReglas, setChange }) => {
  const [confR, setConfR] = useState(false);
  const [idR, setIdR] = useState(null);
  const [regla, setRegla] = useState({});
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const editR = (regla) => {
    setEdit(true);
    setData(regla);
    setRegla(regla);
  };
  const endEdit = () => {
    axiosInstance
      .patch(`reglas/${regla.id}/`, {
        day_tries: data['day_tries'],
        nombre: data['nombre'],
        week_tries: data['week_tries'],
        call_tries: data['call_tries'],
        month_tries: data['month_tries'],
        month_confirms: data['month_confirms'],
        week_confirms: data['week_confirms'],
        week_pendientes: data['week_pendientes'],
        month_pendientes: data['month_pendientes'],
        confirms_delta: data['confirms_delta'],
        pendientes: data['pendientes'],
        is_active: data['is_active'],
      })
      .then((res) => {
        setChange(Date().toLocaleString());
      });

    setEdit(false);
    setData({});
    setRegla({});
  };

  const close = () => {
    setIdR(null);
    setConfR(false);
  };
  const rem = (obj) => {
    setRegla(obj);
    setIdR(obj.id);
    setConfR(true);
  };

  const del = () => {
    axiosInstance.delete(`reglas/${idR}/`).then((res) => {
      setReglas(reglas.filter((x) => x.id !== idR));
      setChange(idR);
    });

    setConfR(false);
    setIdR(null);
    setRegla({});
  };

  useEffect(() => {
    console.log(reglas);
  });

  return (
    <table className="w-full table-auto rounded-lg shadow-2xl overflow-y-hidden overflow-x-scroll">
      {confR && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-gray-300 shadow-2xl rounded-xl pt-3 px-2 w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl h-1/3">Eliminar esta Regla:</p>
            <div className="flex justify-center w-full h-2/3 text-4xl font-bold">
              <p className="my-auto">{regla.nombre}</p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => close()}
              className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => del()}
              className=" focus:outline-none mx-4 py-2 px-1 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <thead>
        <tr>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Llamada
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Dia
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Semana
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            I. Mes
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            C. Delta
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            C. Semana
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            C. Mes
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            P. Semana
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            P. Mes
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            P. Max
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Status
          </th>

          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Editar
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-sm uppercase font-normal"
          >
            Eliminar
          </th>
        </tr>
      </thead>
      <tbody>
        {reglas &&
          reglas.map((obj) => (
            <tr key={obj.id} className="">
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['call_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="call_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.call_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['day_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="day_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.day_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['week_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="week_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.week_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['month_tries']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="month_tries"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.month_tries}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['confirms_delta']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="confirms_delta"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.confirms_delta}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['week_confirms']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="week_confirms"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.week_confirms}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['month_confirms']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="month_confirms"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.month_confirms}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['week_pendientes']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="week_pendientes"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.week_pendientes}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['month_pendientes']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="month_pendientes"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.month_pendientes}</p>
                )}
              </td>

              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <input
                    type="number"
                    value={data['pendientes']}
                    className="w-16 border-gray-800 border rounded-lg border-3 p-2"
                    name="pendientes"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.pendientes}</p>
                )}
              </td>
              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  data['is_active'] === true ? (
                    <label
                      for="unchecked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                          <input
                            id="unchecked1"
                            onClick={() => setData({ ...data, ['is_active']: false })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Activa</span>
                    </label>
                  ) : (
                    <label
                      for="checked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                          <input
                            id="checked1"
                            onClick={() => setData({ ...data, ['is_active']: true })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Inactiva</span>
                    </label>
                  )
                ) : obj && obj.is_active ? (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">Activa</span>
                  </span>
                ) : (
                  <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">Inactiva</span>
                  </span>
                )}
              </td>

              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                {edit && obj === regla ? (
                  <EditOff
                    onClick={() => endEdit()}
                    size="32"
                    className="my-auto text-blue-800 transform hover:scale-125"
                  />
                ) : (
                  <Edit
                    onClick={() => editR(obj)}
                    size="32"
                    className="my-auto text-blue-600 transform hover:scale-125"
                  />
                )}
              </td>

              <td className="p-1 border-b  text-center border-gray-200 bg-white text-lg">
                <button
                  onClick={() => rem(obj)}
                  className="text-red-500 hover:text-red-600 transform hover:scale-125"
                >
                  <TrashBin size="35" className="" />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default RUTable;
