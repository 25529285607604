import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import SMSRulesTable from '../../components/admin/SMSRulesTable.jsx'
import CreateModal from '../../components/sms/CreateRuleModal.jsx'

import { XCircle } from '@styled-icons/boxicons-solid/XCircle';

const SMSRules = () =>{
  const [objs,setObjs] = useState([])
  const [data,setData] = useState({nombre:'',dominio:''})
  const [create,setCreate] = useState(false)
  const [change,setChange] = useState(null)

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }
  const getObjs = async () =>{
    await axiosInstance.get(`sms_rules/`).then(res=>{
      setObjs(res.data) 
    })
  }

  const addObj = async () =>{
    
    data.nombre !== '' && await axiosInstance.post(`mail_domains/`,data).then(res=>{
      setData({nombre:'',dominio:''})
      getObjs()
      setCreate(false)
    })
  }

  useEffect(()=>{
    getObjs()

  },[change])
  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll ">

      <CreateModal open={create} setOpen={setCreate} setChange={setChange}/>
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Reglas SMS</p> <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
      </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12">

            <SMSRulesTable objs={objs} getObjs={getObjs}></SMSRulesTable>
        </div> 

                
      </div>
  )
}

export default SMSRules;

  
