import React, { useState } from 'react';

import axiosInstance from '../../axios';
const CreateModal = ({ setModal, listas, setListas,setChange }) => {
  const [toggle, setToggle] = useState(true);

  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    nombre: '',
    status: 1,
  });

  const close = () => {
    setModal(false);
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData({
      ...data,
      status: toggle ? 1 : 2,
    });

    axiosInstance
      .post(`listas/`, { ...data, ['status']: toggle ? 1 : 2 })
      .then((res) => {
        //setListas(listas.push(res.data));
        setModal(false);
        setChange(new Date())
      })
      .catch((err) => {
        console.log(err);
        var errs = [];
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (data['nombre'] == '') {
            console.log('error nombre');
            errs.push('nombre invalido');
          }
        } else {
          if (
            err.response !== undefined &&
            'data' in err.response &&
            'non_field_errors' in err.response.data
          ) {
            errs.push(err.response.data.non_field_errors);
          }
        }
        setErrors(errs);
      });
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-wrap ml-96 mt-6 flex-col h-2/5 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex"
    >
      <div className="flex justify-center h-2/6 w-full pt-3 px-20">
        <label className=" flex flex-wrap w-full">
          <div className="flex justify-center  w-full rounded-t-lg text-xl text-center bg-blue-ligth text-white font-bold align-middle">
            <span className="my-auto">Nombre</span>
          </div>
          <input
            type="text"
            name="nombre"
            onChange={handleChange}
            class=" rounded-b-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            placeholder="lista 1..."
          />
        </label>
      </div>
      <div class="flex flex-col w-full h-2/6 pt-3">
        {toggle ? (
          <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer mx-auto">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                <input
                  id="unchecked"
                  onClick={() => setToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Activa</span>
          </label>
        ) : (
          <label for="checked" class="mt-3 inline-flex items-center cursor-pointer mx-auto">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                <input
                  id="checked"
                  onClick={() => setToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Inactiva</span>
          </label>
        )}
      </div>

      <div className="flex flex-wrap justify-center h-2/6 pt-3 ">
        <div className="w-full h-1/3 flex justify-center ">
          <button
            onClick={() => close()}
            className="h-12 focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-600 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
          >
            Cancelar
          </button>

          <button
            type="submit"
            className="h-12 focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Crear
          </button>
        </div>
        {errors.legth > 0 && (
          <div className="flex flex-wrap h-2/3 px-20 pt-3 overflow-y-scroll w-full">
            {errors.length > 0 &&
              errors.map((error) => {
                return (
                  <div
                    className="h-5/6 w-full bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                    role="alert"
                  >
                    <p className="font-bold w-full">Error</p>
                    <p clasName="w-full">{error}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </form>
  );
};

export default CreateModal;
