import React, { useEffect } from 'react';

import Bars from '../dashboardAdmin/graficos/Bars';
import Pies from '../dashboardAdmin/graficos/Pies';

const StatsUser = ({ data }) => {
  console.log(data);
  return (
    <div className="w-full h-full flex flex-wrap content-around px-4 gap-y-3">
      <div className="w-full h-1/4 p-3">
        <div className="w-full h-full justify-around flex flex-wrap rounded-lg">
          <div className="flex flex-wrap w-80 rounded-lg h-full border border-blue-ligth shadow-xl">
            <div className="h-1/4 w-full">
              <p className="w-full text-center text-2xl font-bold text-blue-semi">Llamadas</p>
            </div>
            <div className="w-full h-3/4 flex justify-center item-center content-center">
              <p className="my-auto text-5xl text-blue-semi font-bold">
                {data && data.stats['total']}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap w-80 rounded-lg h-full border border-blue-ligth shadow-xl">
            <div className="h-1/4 w-full">
              <p className="w-full text-center text-2xl font-bold text-blue-semi">
                Promedio Diario
              </p>
            </div>
            <div className="w-full h-3/4 flex justify-center item-center content-center">
              <p className="my-auto text-5xl text-blue-semi font-bold">
                {data && data.stats.promedio_llamadas_diario.count}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap w-80 rounded-lg h-full border border-blue-ligth shadow-xl">
            <div className="h-1/4 w-full">
              <p className="w-full text-center text-2xl font-bold text-blue-semi">
                Contactabilidad
              </p>
            </div>
            <div className="w-full h-3/4 flex justify-center item-center content-center">
              <p className="my-auto text-5xl text-blue-semi font-bold">
                {data && data.stats.contactabilidad.perc}%
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-3/5 h-2/4  p-3">
        <div className="w-full h-full shadow-xl  border border-blue-ligth rounded-lg">
          {data && <Bars data={data.dias}></Bars>}
        </div>
      </div>
      <div className="w-2/5 h-2/4  p-3 rounded-lg">
        <div className="w-full h-full shadow-xl  border border-blue-ligth rounded-lg">
          {data && <Pies grupos={data.grupos} tipos={data.tipos}></Pies>}
        </div>
      </div>
    </div>
  );
};

export default StatsUser;
