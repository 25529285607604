import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Filtro from '../Filtro.js';

import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';

import Select from 'react-select';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Filters = ({ pagination }) => {
  const [expand, setExpand] = useState(false);
  const [agentes, setAgentes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [mandantes, setMandantes] = useState([]);
  const [camps, setCamps] = useState([]);
  const [query, setQuery] = useState('?');
  //const query = queryString.parse(location.search);
  let Q = useQuery();
  const filtrar = () => {
    window.location.href = `${window.location.pathname}?${query}`;
  };
  const limpiar = () => {
    window.location.href = `${window.location.pathname}`;
  };
  //make util
  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.codigo, label: man.nombre };
    });
  const optionsAgentes =
    agentes &&
    agentes.map((agente) => {
      return {
        value: agente.id,
        label: `${agente.first_name} ${agente.last_name}`,
      };
    });
  const optionsTipos =
    tipos &&
    tipos.map((tipo) => {
      return { value: tipo.id, label: `${tipo.nombre}` };
    });
  const optionsGrupo = [
    { value: 1, label: 'Positiva' },
    { value: 2, label: 'Negativa' },
    { value: 3, label: 'rellamada' },
  ];

  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };

  const getAgentes = async () => {
    await axiosInstance.get(`users?is_active=true`).then((res) => {
      setAgentes(res.data);
    });
  };
  const getTipos = async () => {
    await axiosInstance.get(`trespuestas/`).then((res) => {
      setTipos(res.data);
    });
  };
  const getMandantes = async () => {
    await axiosInstance.get('mandantes?is_active=true').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };
  const getCamps = async () => {
    await axiosInstance.get('campaigns?status=1').then((res) => {
      setCamps(
        res.data.map((camp) => {
          return { value: camp.id, label: camp.nombre };
        }),
      );
    });
  };

  // const handleChange = (e) => {
  //   if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
  //     setQuery(
  //       query
  //         .split('&')
  //         .map((x) => {
  //           if (x.includes(e.target.name)) {
  //             // return `${e.target.name}=${e.target.value}`;
  //             return `${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`
  //           } else {
  //             return x;
  //           }
  //         })
  //         .join('&'),
  //     );
  //   } else {
  //     setQuery(`${query}&${e.target.name}=${e.target.value}`);
  //   }
  // };
const handleChange = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              // return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`;
              return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`);

      // setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
    //console.log(query)
  };

  useEffect(() => {
    getCamps();
    getAgentes();
    getMandantes();
    getTipos();
    setQuery(Q.toString().replace(/%2C/gi, ','));
  }, []);

  return (
    <Filtro
      pagination={pagination}
      filtrar={filtrar}
      handleChange={handleChange}
      limpiar={limpiar}
      page_size={
        getQueryValue(query, 'page_size') !== null
          ? getQueryValue(query, 'page_size')
          : pagination.page_size
      }
      nombre="Respuestas"
    >
      <div className={`select-filter`}>
        <label>Agentes</label>
        <Select
          onChange={handleSelect}
          name="agente__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          placeholder="agentes"
          getOptionLabel={(option) =>
            optionsAgentes.map((item) => {
              //console.log(option);
              //console.log(item)
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'agente__in', optionsAgentes)}
          options={optionsAgentes}
        />
      </div>
      <div className={`select-filter`}>
        <label>Tipo</label>
        <Select
          onChange={handleSelect}
          name="tipo__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsTipos.map((item) => {
              //console.log(option);
              //console.log(item)
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'tipo__in', optionsTipos)}
          options={optionsTipos}
        />
      </div>
      <div className={`select-filter`}>
        <label>Grupo</label>
        <Select
          onChange={handleSelect}
          className="w-full"
          name="tipo__grupo__in"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsGrupo.map((item) => {
              //console.log(option);
              //console.log(item)
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'tipo__grupo__in', optionsGrupo)}
          options={optionsGrupo}
        />
      </div>
      <div className={`select-filter`}>
        <label>Mandante</label>
        <Select
          onChange={handleSelect}
          className="w-full"
          name="contacto__mandante__in"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsMandantes.map((item) => {
              //console.log(option);
              //console.log(item)
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  console.log(option);
                  console.log(item);
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'contacto__mandante__in', optionsMandantes)}
          options={optionsMandantes}
        />
      </div>
      <div className="w-32 h-24  flex flex-wrap">
        <div className="w-full h-1/2  ">
          <label className="text-gray-50 font-semibold text-sm flex justify-between w-full h-1/2">
            Fecha Desde
          </label>
          <input
            name="add_date__gte"
            value={getQueryValue(query, 'add_date__gte')}
            onChange={handleChange}
            type="date"
            className=" w-full text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-full h-1/2  ">
          <label className="text-gray-50 font-semibold flex text-sm justify-between w-full h-1/2">
            Fecha Hasta
          </label>
          <input
            name="add_date__lte"
            value={getQueryValue(query, 'add_date__lte')}
            onChange={handleChange}
            type="date"
            className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
      </div>
      <div className="w-1/12 h-32 border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full text-sm  mb-1">
          Rut
        </label>
        <textarea
          name="contacto__rut__in"
          value={getQueryValue(query, 'contacto__rut__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-1/12 h-32 border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full text-sm  mb-1">
          Numero Cuenta
        </label>
        <textarea
          name="contacto__nro_cuenta__in"
          value={getQueryValue(query, 'contacto__nro_cuenta__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-1/12 h-32 border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full text-sm  mb-1">
          Numero
        </label>
        <textarea
          name="numero__numero__icontains"
          value={getQueryValue(query, 'numero__numero__icontains')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>

      {/*<div className="w-1/12 h-full border-b ">
                    <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2" >Rut</label>
                    <input name="contacto__rut__in" value={getQueryValue(query,'contacto__rut__in')} onChange={handleChange} type="text" className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"></input>
            </div>*/}

      <div className={`select-filter`}>
        <label>Campaña</label>
        <Select
          onChange={handleSelect}
          name="llamada__campaign__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            camps.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'llamada__campaign__in', camps)}
          options={camps}
        />
      </div>
      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Embargado
        </label>
        <select name="contacto__embargado" onChange={handleChange} value={getQueryValue(query,'contacto__embargado')}>
            <option value={''} key={0}>------</option>
            <option value={true} key={1}>Embargado</option>
            <option value={false} key={2}>No Embargado</option>
        </select>
              </div>
    <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
         Notificado 
        </label>
        <select name="contacto__notificado" onChange={handleChange} value={getQueryValue(query,'contacto__notificado')}>
            <option value={''} key={0}>------</option>
            <option value={true} key={1}>Notificado</option>
            <option value={false} key={2}>No Notificado</option>
        </select>
              </div>
    </Filtro>
  );
};

export default Filters;

{
  /*
getOptionLabel={(option) =>
                      optionsGrupo.map((item) => {
                        //console.log(option);
                        //console.log(item)
                        if(typeof option === 'number' || typeof option === 'string'){
                            if (item.value == option) {
                              return item.label;
                            }
                        }else if(typeof option === 'object'){
                            if (item.value == option.value) {
                              return item.label;
                            }

                        }
                        
                      })
                    }

    value={getQueryValueSelect(query,'tipo__grupo__in',optionsGrupo)}
*/
}
