import {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axios';


import GeneroG from './charts/Genero.jsx'
import Estados2 from './charts/Estados2'
import Bars from './charts/Bars.jsx'


const Stats = () =>{
  const { id } = useParams();
  const [data,setData] = useState(null)

  const getData = async () =>{
    axiosInstance.get(`listas/${id}/stats`).then(res=>{
      setData(res.data)
    })
  }

  useEffect(()=>{
    getData()
    
  },[id])

  return (
    <div className="w-full flex flex-wrap items-start mt-4 gap-y-3 ">
      <div className="w-full flex gap-x-4">
        <div className="w-1/4 h-32 border rounded-md bg-white px-4 py-2">
          <dt className="truncate   text-xl text-gray-500">Total Contactos</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-800">{data && data.total.value}</dd>
        </div> 
        <div className="w-1/4 h-32 border rounded-md bg-white px-4 py-2">
          <dt className="truncate   text-xl text-gray-500">Total Numeros</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-800">{data && data.numeros.value}</dd>
        </div> 
        <div className="w-1/4 h-32 border rounded-md bg-white px-4 py-2">
          <dt className="truncate   text-xl text-gray-500">Total Emails</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-800">{data && data.emails.value}</dd>
        </div> 
            
        <div className="w-1/4 h-32 border rounded-md bg-white"></div> 
    </div>

    <div className="w-full flex gap-x-3">
      <div className="w-1/4 h-96 border rounded-md bg-white"></div> 

      <div className="w-1/4 flex flex-wrap bg-white rounded-md border">
        <div className="w-full h-10 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Edad</p></div>
        <div className="w-full h-96 rounded-md ">{data &&<Bars data={data.age}/>}</div> 
      </div>

      <div className="w-1/4 flex flex-wrap bg-white rounded-md border">
        <div className="w-full h-10 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Estados</p></div>
        <div className="w-full h-96 rounded-md flex justify-center content-start">{data && <Estados2 data={data.estados}/>}</div> 
      </div>
      <div className="w-1/4 flex flex-wrap bg-white rounded-md border">
        <div className="w-full h-10 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Genero</p></div>
        <div className="w-full h-96 rounded-md flex justify-center content-start">{data && <GeneroG data={data.genero}/>}</div> 
      </div>

    </div>
    </div>
  )
}

export default Stats;
