export const getQueryValue = (query, name) => {
  if (query.includes(name)) {
    return query
      .split('&')
      .filter((f) => f.includes(name))[0]
      .split('=')[1];
  } else {
    return null;
  }
};

const getLabel = (value, dict) => {
  dict.map((item) => {
    if (item.value == value) {
      return item.label;
    }
  });
  //var filt = dict.filter(x=>x.value === parseInt(value)).length
};

const format_query = (st) => {
  // replace + for spaces in value of a query string
  st = st.split('+').join(' ');
  return st;
};

export const getQueryValueSelect = (query, name, dict) => {
  if (query.includes(name)) {
    var val = query
      .split('&')
      .filter((f) => f.includes(name))[0]
      .split('=')[1];
    if (val !== '') {
      if (val.length >= 2) {
        console.log(
          val.split(',').map((n) => {
            return { value: n, label: getLabel(n, dict) };
          }),
        );
        return val.split(',').map((n) => {
          return { value: format_query(n), label: getLabel(n, dict) };
        });
      } else if (val.length === 1) {
        console.log(
          val.split(',').map((n) => {
            return { value: parseInt(n), label: getLabel(n, dict) };
          }),
        );
        return val.split(',').map((n) => {
          return { value: parseInt(n), label: getLabel(n, dict) };
        });
      }
    } else {
      return [];
    }
  } else {
    return [];
  }
};
