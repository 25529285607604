import format from '../../utils/formatDate.js'

const BloqueosTable = ({data,changePage}) =>{

  return (
        <div className="overflow-hidden  md:rounded-lg w-full py-1">
        <table className="min-w-full divide-y divide-gray-300 shadow-sm">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 "
              >
                Archivo
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Fecha
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Usuario
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Bloqueados
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Total Filas 
              </th>

              
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data.results.map((obj) => (
              <tr key={obj.id}>
                <td className="whitespace-nowrap py-2 px-2 text-sm font-medium text-blue-500 font-semibold ">
                    <a href={obj.file && obj.file} target="_blank">{obj.file && obj.file.split('/').pop()}</a>
                </td>
                <td className="whitespace-nowrap py-2 px-2 text-sm font-medium  ">
                    {format(obj.fecha_inicio)}
                </td>
                <td className="whitespace-nowrap py-2 px-2 text-sm font-medium  font-bold ">
                    {obj.status}
                </td>
                <td className="whitespace-nowrap py-2 px-2 text-sm text-gray-400 ">
                    {obj.user && `${obj.user.first_name} ${obj.user.last_name}`}
                </td>
                <td className="whitespace-nowrap py-2 px-2 text-sm text-gray-500 ">
                    {obj.bloqueos}
                </td>
                <td className="whitespace-nowrap py-2 px-2 text-sm text-gray-500 ">
                    {obj.rows}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       <div className="flex justify-end w-full p-1">
        {data.previous && 
        <button
          onClick={()=>changePage(data.previous)}
          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
                {'<'}
        </button>}

        {data.next && 
        <button
          onClick={()=>changePage(data.next)}
          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
                {'>'}
        </button>}
      </div>
      </div>
          
  )
}
export default BloqueosTable;
