
import {useState} from 'react'
import axiosInstance from '../../../axios';
import toast from 'react-hot-toast'

const CargaSQL = () =>{
  const [nro_cuentas, setNro] = useState('');

  const cargar = () => {
    //console.log({nro_cuenta:getQueryValue(q,'nro_cuenta__in').split(",")})
    //console.log(getQueryValue(q,'nro_cuenta__in'))
    //if(getQueryValue(q,'nro_cuenta__in') !== ""){
    //axiosInstance.post(`carga/cuentas/`,{nro_cuenta:getQueryValue(q,'nro_cuenta__in').split(',')})
    //}
    var toastId = toast.loading('carga enviada');
    var nro = nro_cuentas
    if(nro[nro.length] === ','){
      nro = nro.slice(0, -1)
      console.log(nro)
    }
    nro = nro.split(',')
    nro = nro.filter(v=>v!='');
    axiosInstance.post(`carga/cuentas/`, { nro_cuenta: nro }).then((res) => {
      // setChange(new Date());
      toast.remove(toastId);
      toast.success('carga finalizada');
    })
      .catch(()=>{toast.error('error');toast.remove(toastId)})
      .finally(()=>toast.remove(toastId))
  };
 

  const handleChange = (e) => {
    setNro(`${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`);
  };



  return (
      <div className="w-4/12  rounded-md border shadow-md bg-white flex flex-wrap p-4 content-between flex-shrink-0">
          <div className="w-full flex-wrap content-start h-42">
            <p className="text-lg ">Carga SQL</p>
            <p className="text-md text-gray-600 pl-2">1. Ingrese los numero de cuenta separados por ","</p>
            <p className="text-md text-gray-600 pl-2">2. Click el boton Cargar</p>
          </div>

          <textarea onChange={handleChange} value={nro_cuentas} className="bg-gray-100 rounded-md h-1/2 w-full p-2 text-gray-700 font-mono">

          </textarea>
          
          <button  onClick={()=>cargar()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
            Cargar
          </button>
          
    </div>
  )

}

export default CargaSQL;
