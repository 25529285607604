
const TagCt = ({tag}) =>{

return (
      <span style={{borderColor: tag.color}} className="inline-flex items-center gap-x-1.5 rounded-full px-4 py-1 text-sm font-medium text-gray-900 border-2">
          <div
            style={{ backgroundColor: tag.color }}
              className="h-2 w-2 rounded-full"
            ></div>
          {tag.nombre}
      </span> 
      )
}

export default TagCt;

//{/* <span style={{borderColor: tag.color}} className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"> */}
      {/*   <svg fill={tag.color} className="h-1.5 w-1.5 " viewBox="0 0 6 6" aria-hidden="true"> */}
      {/*     <circle cx={3} cy={3} r={3} /> */}
      {/*   </svg> */}
      {/*   {tag.nombre} */}
      {/* </span> */}

