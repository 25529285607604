import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import axiosInstance from '../axios';

import InfoContacto from '../components/contactos/InfoContacto';
import Llamadas from '../components/contactos/Llamadas';

import Back from '../components/utils/Back';

const TestC2 = () => {
  const [contacto, setContacto] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [load, setLoad] = useState(true);
  let history = useHistory();
  const { id } = useParams();

  const getContacto = async () => {
    await axiosInstance
      .get(`contactos/` + id + '/')
      .then((res) => {
        if (res.data) {
          setContacto(res.data);
          console.log(res.data);
          setLoad(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status == 404) {
            setNotFound(true);
            setLoad(false);
          }
        }
      });
  };
  useEffect(() => {
    getContacto();
  }, [id]);

  if (notFound == false) {
    return (
      <div className="h-full w-full">
        <div className="flex flex-wrap w-full h-full bg-white">
          <div className="flex h-16 w-full border-b px-3 pt-2">
            <Back></Back>
            <h1 className="text-4xl font-bold">Detalles de Contacto</h1>
          </div>
          <div className="flex flex-wrap content-start h-5/6 w-full mx-4 border rounded-lg border-gray-900 overflow-hidden">
            <div className="h-1/3"></div>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg w-1/3 h-full bg-blue-500">
              <div className="w-full h-1/3 bg-white">foto</div>
              <InfoContacto contacto={contacto}></InfoContacto>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full flex justify-center bg-gradient-to-b from-gray-400 to-gray-300">
        <div className="flex flex-wrap  content-start justify-center w-auto h-72 border mt-52 bg-white rounded-lg">
          <p className="text-8xl font-bold w-full text-center">404</p>
          <p className="text-6xl w-full text-center mt-10">No Encontrado</p>
          <button
            onClick={() => history.goBack()}
            className="mt-4 p-4 rounded-md text-white font-bold text-2xl bg-gradient-to-r from-black to-gray-900 transform hover:scale-110 hover:bg-gradient-to-r  hover:from-gray-900 hover:to-black"
          >
            Volver
          </button>
        </div>
      </div>
    );
  }
};

export default TestC2;
