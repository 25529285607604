import React from 'react';

import {ArrowBackIos} from '@styled-icons/material/ArrowBackIos'

import { useHistory } from 'react-router-dom';

const Back = () => {
  let history = useHistory();
  return (
      <div onClick={() => history.goBack()} className="w-8 h-8 rounded-full bg-gray-50 flex pl-2 content-center items-center cursor-pointer">
        <ArrowBackIos size="24" className="text-gray-500"></ArrowBackIos>
      </div>
  );
}
export default Back;
