
import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import Stats from './stats/Stat.jsx'
import Days from './stats/Days.jsx'
import Events from '../dashboards/voicemsg/Events.jsx'

const Resumen = ({id}) =>{
  const [data,setData] = useState(null)

  const getData = async () =>{
    axiosInstance.get(`voice_msg_campaigns/${id}/stats/`).then(res=>{
      // console.log(res)
      setData(res.data)
    })
  }

  useEffect(()=>{
    getData()
  },[])
  return ( 
    <div className="w-full flex flex-wrap   mx-auto gap-y-8 content-start">
    {data !== null && <div className="w-full flex h-40 border-b bg-white rounded-md border p-4">
            {/* <div className="w-2/5 border-r flex flex-wrap content-start gap-y-4"> */}
            {/*    */}
            {/*   <div className="w-1/2  flex flex-wrap content-start gap-y-4 px-6"> */}
            {/*     <p className="font-thin text-lg w-full">Enviados</p> */}
            {/*     <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.delivered.count}</p> */}
            {/*     <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.stats.delivered.pct}</span></div> */}
            {/*   </div> */}
            {/*  */}
            {/*   <div className="w-1/2 h-full  flex flex-wrap content-start gap-y-4 px-1"> */}
            {/*     <DeliveredGrafico data={data && data.days}></DeliveredGrafico> */}
            {/*   </div> */}
            {/**/}
            {/* </div> */}
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Total</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.total}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-blue-500 ">{data && '100%'}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Contestadas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.answered.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.stats.answered.pct}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Sin Respuesta</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.no_answer.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 "><ArrowDownRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-yellow-500 ">{data && data.stats.no_answer.pct}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Ocupado</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.busy.count}</p>
    
                <div className="font-normal w-full flex text-md mx-1"><span className="text-yellow-500 ">{data && data.stats.busy.pct}</span></div>
            </div>
            <div className="w-1/5 flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Fallidas</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.failed.count}</p>
    
                <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 ">{data && data.stats.failed.pct}</span></div>
            </div>
        </div>}
    {data !== null && <Events data={data}/>}
    
    </div>
)

}

export default Resumen;
