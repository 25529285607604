import { useState } from 'react';
import axiosInstance from '../../axios.js';

const TagsTable = ({ objs, getObjs }) => {
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(false);

  const editar = (obj) => {
    if (data !== null && obj.id !== data.id) {
      setData(obj);
      console.log('change');
    } else {
      if (edit === false) {
        console.log('start edit');
        setData(obj);
        setEdit(true);
      } else {
        if (data !== obj) {
          console.log('update');
          update(obj);
          setEdit(false);
        } else {
          setEdit(false);
          console.log('close');
        }
      }
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const update = async (obj) => {
    await axiosInstance.put(`tags_contacto/${obj.id}/`, data).then((res) => {
      getObjs();
      setData(null);
    });
  };

  const cambiarStatus = (obj) => {
    axiosInstance.patch(`tags_contacto/${obj.id}/`, { is_active: !obj.is_active }).then((res) => {
      // setChange(new Date());
      getObjs();
    });
  };

  const cambiarPermission = (obj) => {
    axiosInstance
      .patch(`tags_contacto/${obj.id}/`, { agent_permission: !obj.agent_permission })
      .then((res) => {
        // setChange(new Date());
        getObjs();
      });
  };
  const remove = async (obj) => {
    await axiosInstance.delete(`tags_contacto/${obj.id}/`).then((res) => {
      getObjs();
    });
  };

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg w-full">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
            >
              Nombre
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Color
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Codigo
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Permiso Agente
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Desc.
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Status
            </th>

            <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Remove</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {objs.map((obj) => (
            <tr key={obj.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {edit && data.id === obj.id ? (
                  <input
                    className="ring-0 px-2 py-1"
                    name="nombre"
                    value={data['nombre']}
                    onChange={handleChange}
                  ></input>
                ) : (
                  obj.nombre
                )}
              </td>
              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                {edit && data.id === obj.id ? (
                  <input
                    name="color"
                    value={data['color']}
                    onChange={handleChange}
                    type="color"
                  ></input>
                ) : (
                  <div
                    style={{ backgroundColor: obj.color }}
                    className="h-8 w-8 rounded-full"
                  ></div>
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {edit && data.id === obj.id ? (
                  <input
                    className="ring-0 px-2 py-1"
                    name="codigo"
                    value={data['codigo']}
                    onChange={handleChange}
                  ></input>
                ) : (
                  obj.codigo
                )}
              </td>
              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                <span
                  onClick={() => cambiarPermission(obj)}
                  className={`cursor-pointer relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${
                    obj.agent_permission
                      ? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600'
                      : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'
                  }`}
                >
                  {obj.agent_permission ? 'activo' : 'inactivo'}
                </span>
              </td>

              <td className="whitespace-nowrap py-4 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6">
                {edit && data.id === obj.id ? (
                  <textarea
                    className="ring-0 px-2 py-1"
                    name="description"
                    value={data['description']}
                    onChange={handleChange}
                  ></textarea>
                ) : (
                  <p className="font-medium text-gray-500 text-sm">{obj.description}</p>
                )}
              </td>

              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                <span
                  onClick={() => cambiarStatus(obj)}
                  className={`cursor-pointer relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${
                    obj.is_active
                      ? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600'
                      : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'
                  }`}
                >
                  {obj.is_active ? 'activo' : 'inactivo'}
                </span>
              </td>

              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <a
                  onClick={() => remove(obj)}
                  className="text-red-600 hover:text-red-900 cursor-pointer"
                >
                  Eliminar<span className="sr-only">, {obj.id}</span>
                </a>
              </td>
              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <a
                  onClick={() => editar(obj)}
                  className={`${
                    edit === true && data !== null && data.id === obj.id
                      ? 'text-blue-super-ligth hover:text-blue-600'
                      : 'text-blue-600 hover:text-blue-super-ligth'
                  }  cursor-pointer`}
                >
                  Editar<span className="sr-only">, {obj.id}</span>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default TagsTable;
