import Stat from './graficos2/Stat';

const Statistics = ({data }) => {

  return (
    <div className="h-auto w-full flex flex-wrap  px-2">
      {data && (
        <div className="h-auto w-full flex flex-wrap justify-around content-start gap-y-5 mt-2 ">
          <div className="flex flex-wrap h-auto justify-around content-start gap-y-5 w-full p-2">
            <h1 className="w-full h-auto text-3xl text-center font-semibold text-blue-dark">Campaign</h1>
            <Stat
              nombre="Total Cuentas"
              desc={data['stats'].contactos_con_llamada['desc']}
              count={data['stats'].contactos_con_llamada['count']}
              circles={[
                {
                  nombre: 'con gestion',
                  perc: data['stats'].contactos_gestionados['perc_llamadas'],
                },
                {
                  nombre: 'sin gestion',
                  perc: data['stats'].contactos_sin_gestion_con_llamada['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Cuentas con gestion"
              desc={data['stats'].contactos_gestionados['desc']}
              count={data['stats'].contactos_gestionados['count']}
              circles={[
                {
                  nombre: 'Llamadas',
                  perc: data['stats'].contactos_gestionados['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Cuentas sin gestion"
              desc={data['stats'].contactos_sin_gestion_con_llamada['desc']}
              count={data['stats'].contactos_sin_gestion_con_llamada['count']}
              circles={[
                {
                  nombre: 'Llamadas',
                  perc: data['stats'].contactos_sin_gestion_con_llamada['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Total Telefonos "
              desc={data['stats'].telefonos_en_campana['desc']}
              count={data['stats'].telefonos_en_campana['count']}
              circles={[
                {
                  nombre: 'Con Gestion',
                  perc: data['stats'].telefonos_con_gestion_en_campana['perc'],
                },
                {
                  nombre: 'Sin Gestion',
                  perc: data['stats'].telefonos_sin_gestion_en_campana['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Telefonos gestionados"
              desc={data['stats'].telefonos_con_gestion_en_campana['desc']}
              count={data['stats'].telefonos_con_gestion_en_campana['count']}
              circles={[
                {
                  nombre: '',
                  perc: data['stats'].telefonos_con_gestion_en_campana['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Telefonos sin gestion"
              desc={data['stats'].telefonos_sin_gestion_en_campana['desc']}
              count={data['stats'].telefonos_sin_gestion_en_campana['count']}
              circles={[
                {
                  nombre: '',
                  perc: data['stats'].telefonos_sin_gestion_en_campana['perc'],
                },
              ]}
            ></Stat>

            <Stat
              nombre="Cuentas sin llamada"
              desc={data['stats'].contactos_sin_llamada['desc']}
              count={data['stats'].contactos_sin_llamada['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactos_sin_llamada['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Contactabilidad Cuentas"
              desc={data['stats'].contactabilidad_contactos['desc']}
              count={data['stats'].contactabilidad_contactos['count']}
              circles={[
                {
                  nombre: '',
                  perc: data['stats'].contactabilidad_contactos['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Contactabilidad Respuestas"
              desc={data['stats'].contactabilidad_respuestas['desc']}
              count={data['stats'].contactabilidad_respuestas['count']}
              circles={[
                {
                  nombre: '',
                  perc: data['stats'].contactabilidad_respuestas['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Promedio Intentos por Cuenta"
              desc={data['stats'].promedio_intentos_contacto['desc']}
              count={data['stats'].promedio_intentos_contacto['count']}
              circles={[]}
            ></Stat>
            <Stat
              nombre="Promedio Respuestas Diario"
              desc={data['stats'].promedio_respuestas_diario['desc']}
              count={data['stats'].promedio_respuestas_diario['count']}
              circles={[]}
            ></Stat>
            <Stat
              nombre="Promedio Respuestas de Agente por dia"
              desc={data['stats'].promedio_intentos_dia_agente['desc']}
              count={data['stats'].promedio_intentos_dia_agente['count']}
              circles={[]}
            ></Stat>
          </div>
          <div className="flex flex-wrap h-auto justify-around content-start gap-y-5 w-full p-2">
            <h1 className="w-full h-auto text-3xl text-center font-semibold text-blue-dark">Lista</h1>
            <Stat
              nombre="Total de cuentas"
              desc={data['stats'].contactos_en_lista['desc']}
              count={data['stats'].contactos_en_lista['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactos_en_lista['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Cuentas con gestion"
              desc={data['stats'].contactos_gestionados['desc']}
              count={data['stats'].contactos_gestionados['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactos_gestionados['perc_lista'],
                },
                {
                  nombre: 'Llamadas',
                  perc: data['stats'].contactos_gestionados['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Cuentas sin gestion"
              desc={data['stats'].contactos_sin_gestion_con_llamada['desc']}
              count={data['stats'].contactos_sin_gestion_con_llamada['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactos_sin_gestion_con_llamada['perc_lista'],
                },
                {
                  nombre: 'Llamadas',
                  perc: data['stats'].contactos_sin_gestion_con_llamada['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Total Telefonos"
              desc={data['stats'].telefonos_en_lista['desc']}
              count={data['stats'].telefonos_en_lista['count']}
            ></Stat>
            <Stat
              nombre="Telefonos gestionados"
              desc={data['stats'].telefonos_con_gestion['desc']}
              count={data['stats'].telefonos_con_gestion['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].telefonos_con_gestion['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Telefonos sin gestion"
              desc={data['stats'].telefonos_sin_gestion['desc']}
              count={data['stats'].telefonos_sin_gestion['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].telefonos_sin_gestion['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Cuenta con llamada"
              desc={data['stats'].contactos_con_llamada['desc']}
              count={data['stats'].contactos_con_llamada['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactos_con_llamada['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Cuentas sin llamada"
              desc={data['stats'].contactos_sin_llamada['desc']}
              count={data['stats'].contactos_sin_llamada['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactos_sin_llamada['perc'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Contactabilidad contactos"
              desc={data['stats'].contactabilidad_contactos['desc']}
              count={data['stats'].contactabilidad_contactos['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactabilidad_contactos['perc_lista'],
                },
                {
                  nombre: 'Llamadas',
                  perc: data['stats'].contactabilidad_contactos['perc_llamadas'],
                },
              ]}
            ></Stat>
            <Stat
              nombre="Contactabilidad respuestas"
              desc={data['stats'].contactabilidad_respuestas['desc']}
              count={data['stats'].contactabilidad_respuestas['count']}
              circles={[
                {
                  nombre: 'Lista',
                  perc: data['stats'].contactabilidad_respuestas['perc'],
                },
              ]}
            ></Stat>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistics;
