import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import Table from '../../components/mails/TemplatesTable.js';
import CreateTemplateModal from '../../components/mails/createTemplateModal2.jsx';
import {MultiPageSelector} from '../../structs/layout/MultiPage'

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

const MailTemplates = () => {
  const [objs, setObjs] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [status, setStatus] = useState(true);

  const [change, setChange] = useState(false);

  const getObjs = () => {
    axiosInstance.get(`mail_templates?status=${status}`).then((res) => {
      setObjs(res.data);

      setDisplay(res.data);
    });
  };

  useEffect(() => {
    getObjs();
  }, [change, status]);

  return (
    <div className="flex flex-wrap w-full h-auto bg-white p-1 gap-2">
      <CreateTemplateModal open={addModal} setOpen={setAddModal} setChange={setChange}/>
      {/* <div className="flex justify-between w-full h-12 bg-white border-b px-5 pt-1 "> */}
      {/*   <h1 className="text-2xl font-bold  w-11/12">Campañas</h1> */}
      {/*    <PlusCircle  */}
      {/*      onClick={() => setAddModal(true)}  */}
      {/*      size="55"  */}
      {/*     className="w-1/12 text-green-600 mt-1 transform hover:scale-125" */}
      {/*    />  */}
      {/*   <div className="px-2 p-1 bg-blue-ligth h-10 font-bold text-white transform hover:scale-110"> */}
      {/*     <p>Crear</p> */}
      {/*   </div> */}
      {/* </div> */}
    
	  {/* <MultiPageSelector  tabs={[{name:'Mails',href:'mails',current:false},{name:'Templates',href:'mail_templates',current:true}]}/>  */}
	    <MultiPageSelector  tabs={[{name:'Campañas',href:'camps_mails',current:false},{name:'Mails',href:'mails',current:false},{name:'Templates',href:'mail_templates',current:true}]}/> 
      <div
        className="px-3 flex flex-wrap content-start h-auto w-full "
      >
      
        <div className="w-full h-12 flex wrap justify-end p-2 gap-x-4 mt-4">
          <div
            onClick={() => setStatus(true)}
            className={`w-32 h-full ${
              status !== 1 ? 'bg-green-400' : 'bg-green-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Activas
          </div>
          <div
            onClick={() => setStatus(false)}
            className={`w-32 h-full ${
              status !== 2 ? 'bg-red-400' : 'bg-red-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Inactivas
          </div>
           <button
              type="button"
              className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setAddModal(true)}
            >
              Crear
            </button>
        </div>
        <Table camps={display} setChange={setChange}></Table>
      </div>
    </div>
  );
};

export default MailTemplates;
