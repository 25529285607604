import React, { useState, useEffect } from 'react';

import format from '../../utils/formatDate'

import { Link } from 'react-router-dom';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { PeopleCall } from '@styled-icons/fluentui-system-filled/PeopleCall';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';

const MailRow = ({ r, addSelected, removeSelected, selected }) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(r);
    } else {
      removeSelected(r);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((rr) => rr.id === r.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
    <tr className="text-gray-700 h-12">
      <td className="border  flex justify-center">
        <input className="my-auto h-11" type="checkbox" checked={checked} onChange={handleCheck} />
      </td>
      <td className="border text-center text-sm">{r && r.contacto && `${r.contacto.nombre} ${r.contacto.apellido}` }</td>
      <td className="border text-center text-sm">{r && format(r.add_date)}</td>
      <td className="border text-center text-sm">{r && r.tipo_template === 1 ? r.template_l && r.template_l.nombre : r.template_f && r.template_f.nombre}</td>
      <td className="border text-center text-sm">{r && r.sender && r.sender}</td>
      <td className="border text-center text-sm">{r && r.subject&& r.subject}</td>
      <td className="border text-center text-sm">{r && r.address}</td>
      <td className="border w-10 text-center">
          {r && r.delivered ? <div className="h-3 w-3 mx-auto rounded-full bg-green-400"></div> : <div className="h-3 w-3 mx-auto rounded-full bg-red-400"></div>}
      </td>
      <td className="border w-10 text-center">
          {r && r.opened ? <div className="h-3 w-3 mx-auto rounded-full bg-green-400"></div> : <div className="h-3 w-3 mx-auto rounded-full bg-red-400"></div>}
      </td>
      <td className="border w-10 text-center">
          {r && r.clicked ? <div className="h-3 w-3 mx-auto rounded-full bg-green-400"></div> : <div className="h-3 w-3 mx-auto rounded-full bg-red-400"></div>}
      </td>
      <td className="border w-10 text-center ">
        
      </td>
      <td className="border w-10 text-center">
        {/* <Link to={r && `/llamadas?contacto=${r.id}`}> */}
        {/*   <PeopleCall size="25" className="text-blue-ligth" /> */}
        {/* </Link> */}
      </td>
      <td className="border w-10 text-center">
        {/* <Link to={r && `/respuestas?contacto=${r.id}`}> */}
        {/*   <TelephoneAccessible size="25" className="text-blue-ligth" /> */}
        {/* </Link> */}
      </td>
      <td className="border w-10"></td>
      <td className="border w-10 px-2">
        <Link to={r && `/contactos/${r.contacto.id}`}>
          <UserDetail size="25" className="text-blue-ligth" />
        </Link>
    </td>
    </tr>
  );
};

export default MailRow;
