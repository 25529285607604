import React from 'react'

import {Link} from 'react-router-dom'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import {getImportanciaColorPlain} from '../../../utils/models'

import {ExternalLinkOutline} from '@styled-icons/evaicons-outline/ExternalLinkOutline'


const status_opts = [
  { name: 'realizadas', color: '#6EE7B7' },
  { name: 'rellamadas', color: '#93C5FD' },
  { name: 'terminadas', color: '#630101' },
  { name: 'pendientes', color: '#facc4b' },
];
const data = [{ name: 'camp', realizadas: 10, rellamadas: 5, pendientes: 5 }];


const Camps2 = ({camps,resps_opts}) => {

  return (
    <div className="w-full flex flex-wrap justify-between gap-x-4 gap-y-4 pb-10">
        {camps && camps.map((camp)=>{
            return <div className={`rounded-md w-96 h-32  p-2 border-l-8 flex flex-wrap bg-white border${getImportanciaColorPlain(camp.importancia)}`}>
                <p className=' flex-grow font-semibold text-center'>{camp.nombre}</p>
                <Link target="_blank" to={`camps_llamadas/${camp.id}`}> <ExternalLinkOutline size="22" className='text-blue-700 cursor-pointer transform hover:scale-105'></ExternalLinkOutline></Link>

                <ResponsiveContainer width="100%" height="40%">
                <BarChart width="100%" layout="vertical" data={camp.status_data}>
                <XAxis type="number" hide={true} />
                <YAxis dataKey="name" type="category" hide={true} />
                {status_opts &&
                status_opts.map((r) => (
                    <Bar dataKey={r.name} stackId="a" fill={r.color}>
                    {camp.status_data &&
                        camp.status_data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={r.color} />
                        ))}
                    </Bar>
                ))}
                <Tooltip wrapperStyle={{ zIndex: 1000 }} />
            </BarChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height="40%">
            <BarChart layout="vertical" data={camp.resps_data}>
                <XAxis type="number" hide={true} />
                <YAxis dataKey="name" type="category" hide={true} />
                {resps_opts &&
                resps_opts.map((r) => (
                    <Bar dataKey={r.name} stackId="a" fill={r.color}>
                    {camp.resps_data &&
                        camp.resps_data.map((entry, index) => <Cell key={`cell-${index}`} fill={r.color} />)}
                    </Bar>
                ))}
                <Tooltip
                allowEscapeViewBox={{ x: false, y: true }}
                position={{ x: 0, y: -100 }}
                wrapperStyle={{ zIndex: 1000 }}
                />
            </BarChart>
            </ResponsiveContainer>


            </div>
        })}
    </div>
  )
}

export default Camps2;