import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import ReactTooltip from 'react-tooltip';

import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';

const data02 = [
  { name: 'A1', value: 90 },
  { name: '', value: 10 },
];

const setData = (perc) => {
  return [
    { name: '%', value: perc },
    { name: '0', value: 100 - perc },
  ];
};

const COLORS = ['#57cf77', '#d2d4d2'];

const Stat = ({ nombre, desc, count, circles }) => {
  return (
    <div className="w-96 h-36 flex flex-wrap border rounded-md overflow-hidden bg-white  ">
      <div className="h-6 w-full px-1 flex justify-between">
        <p className="text-blue-semi font-bold text-lg text-start">{nombre && nombre}</p>
        <InfoCircle
          size={20}
          data-tip
          data-for={nombre}
          className="transform hover:scale-110 hover:text-blue-normal text-blue-dark mt-1"
        />
      </div>
      <ReactTooltip id={nombre} effect="solid">
        <p className="break-words">{desc}</p>
      </ReactTooltip>

      <div className="w-full h-full flex">
        <div
          className={`${circles && circles.length > 0 ? 'w-1/3' : 'w-full'}  h-32 flex flex-wrap`}
        >
          <div className="w-full h-full flex justify-center ">
            <h1 className="text-center my-auto font-bold text-blue-semi text-2xl">
              {count && count}
            </h1>
          </div>
        </div>
        <div
          className={`${
            circles && circles.length > 0 ? 'w-2/3' : 'hidden'
          } h-28 flex justify-center`}
        >
          {circles &&
            circles.length > 0 &&
            circles.map((circle) => {
              return (
                <div className="w-1/2 h-full flex flex-wrap ">
                  <div className="w-full h-5/6 text-blue-normal flex justify-center relative">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={60} height={60}>
                        <Pie
                          data={setData(circle.perc)}
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          innerRadius={36}
                          outerRadius={45}
                        >
                          {setData(circle.perc).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>

                    <p className="absolute mt-9 mx-auto">{circle.perc && circle.perc}%</p>
                  </div>
                  <div className="w-full h-1/6 text-blue-normal flex justify-center">
                    <h1 className="text-center font-bold my-auto text-md">{circle.nombre}</h1>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Stat;

/*
 */
