import {useState,useEffect} from 'react'

import axiosInstance from '../../axios';

import EventsGrafico from '../../components/dashboards/mails/EventosMails.jsx'
import DeliveredGrafico from '../../components/dashboards/mails/DeliveredG.jsx'
import Camps from '../../components/dashboards/mails/Camps.jsx'
import Events from '../../components/dashboards/mails/Events.jsx'

import {ArrowUpRight2} from '@styled-icons/icomoon/ArrowUpRight2'
import {ArrowDownRight2} from '@styled-icons/icomoon/ArrowDownRight2'

const MailDashboard  = () =>{
  const [data,setData] = useState(null)
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });
  
  


  const getData = async  () =>{
    // await axiosInstance.get(`mails/dashboard/?date_from=2022-09-01&date_to=2022-09-30`).then(res=>{
    await axiosInstance.get(`mails/dashboard/?date_from=${dates.desde}&date_to=${dates.hasta}`).then(res=>{
      setData(res.data)
      console.log(res.data)
    })

  }

  const handleChange = (e) =>{
    setDates({...dates,[e.target.name]:e.target.value})
  }

  useEffect(()=>{
    if (dates['desde'] === null || dates['hasta'] === null) {
      var date = new Date();

      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
    }else{
      getData()
    }
  
  },[dates])




  return (
    <div className="w-full h-full flex-wrap bg-gray-100">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Dashboard Mails</p>

        <div className="flex items-center gap-x-2  p-1">
              <div className="rounded-md flex bg-gray-50 w-auto px-2 border border-blue-semi border-t-2 ">
                <input
                  name="desde"
                  type="date"
                  value={dates['desde']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
                <span className="h-full w-0.5 bg-gray-500"></span>
              <div className="rounded-md flex bg-gray-50 border border-blue-semi border-t-2">
                <input
                  name="hasta"
                  type="date"
                  value={dates['hasta']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
        </div>
      </div>

      <div className="w-full flex flex-wrap p-6 gap-6 overflow-y-scroll bg-gray-100">
      
         {/* stats  */}
          <div className="w-full flex h-40 border-b bg-white rounded-md border p-4">
            <div className="w-2/5 border-r flex flex-wrap content-start gap-y-4">
              
              <div className="w-1/2  flex flex-wrap content-start gap-y-4 px-6">
                <p className="font-thin text-lg w-full">Enviados</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.delivered.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.stats.delivered.pct}</span></div>
              </div>
            
              <div className="w-1/2 h-full  flex flex-wrap content-start gap-y-4 px-1">
                <DeliveredGrafico data={data && data.days}></DeliveredGrafico>
              </div>

            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Abiertos</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.opened.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 ">{data && data.stats.opened.pct}</span></div>
            </div>
            <div className="w-1/5 border-r flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Clicks</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.clicked.count}</p>
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 "><ArrowDownRight2 size="14" className="font-bold"/> 2.5%</span></div> */}
                <div className="font-normal w-full flex text-md mx-1"><span className="text-blue-500 ">{data && data.stats.clicked.pct}</span></div>
            </div>
            <div className="w-1/5 flex flex-wrap content-start gap-y-4 px-4">
                <p className="font-thin text-lg w-full">Rebotados</p>
                <p className="font-semibold text-2xl w-full mx-1">{data && data.stats.bounced.count}</p>
    
                <div className="font-normal w-full flex text-md mx-1"><span className="text-red-500 ">{data && data.stats.bounced.pct}</span></div>
            
                {/* <div className="font-normal w-full flex text-md mx-1"><span className="text-green-500 "><ArrowUpRight2 size="14" className="font-bold"/> 2.5%</span></div> */}

            </div>
        </div>
          
         {/* -------stats--------  */}

          {/* events graph*/}
          <div className="w-full flex gap-x-4">
            <Events data={data}></Events>

          <div className="flex-grow  bg-white rounded-md border  flex flex-wrap">
            {data && <Camps data={data && data.camps}></Camps>}
        </div>
        </div>
    
          {/* <div className="w-3/5  bg-white rounded-md border  flex flex-wrap"> */}
          {/**/}
          {/*   <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Eventos</p></div> */}
          {/*   <div className="w-full h-96 p-4"> */}
          {/*     <EventsGrafico></EventsGrafico> */}
          {/*   </div> */}
          {/**/}
          {/* </div> */}

          {/* camps */}

          



    </div>


    </div>
  )
}

export default MailDashboard;
