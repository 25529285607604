import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axiosInstance from '../../axios';

import OperacionesT from '../../components/operaciones/OperacionesT';
import Filters from '../../components/respuestas/Filters';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Operaciones = () => {
  const [ops, setOps] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  var user = JSON.parse(localStorage.getItem('user'));
  //const [query,setQuery] = useState('')
  let query = useQuery();

  const getOps = async (status) => {
    await axiosInstance
      .get(`lactions?operador=${user.id}&is_active=true&${query.toString()}`)
      .then((res) => {
        setOps(res.data.results);
        setPagination(res.data);
        console.log(res.data);
      });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setOps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const removeOp = (id) => {
    setOps(ops.filter((x) => x.id !== id));
  };

  useEffect(() => {
    getOps();

    return () => {};
  }, []);

  return (
    <div className="flex flex flex-wrap w-full h-full bg-white">
      <div className="flex flex-wrap h-1/5 w-full  border-black pb-2 px-2">
        <div className="w-full flex h-1/5  border-black ">
          <p className="text-3xl font-bold my-auto">Operaciones</p>
        </div>
        {/*<Filters></Filters>*/}
      </div>

      <div className="px-3 flex flex-wrap content-start h-4/5 w-full overflow-y-scroll no-scrollbar ">
        <OperacionesT move={move} pagination={pagination} ops={ops} removeOp={removeOp} />
      </div>
    </div>
  );
};

export default Operaciones;
