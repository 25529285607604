import React, { useState } from 'react';

import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { Refresh } from '@styled-icons/foundation/Refresh';
const Filtro = ({ nombre, children, filtrar, limpiar, pagination, page_size, handleChange,setPageSize }) => {
  const [filters, setFilters] = useState(false);

  return (
    <div className="w-full flex-wrap content-start">
      <div
        className={` h-10 w-full bg-blue-normal rounded-t-lg flex justify-between px-2 content-start`}
      >
        <p className="my-auto text-gray-100 font-semibold">
          Total {nombre && nombre}: {pagination && pagination.count}
        </p>
        <div className="flex gap-x-2">
          <button
            onClick={() => handleChange({target:{name:'page_size',value:`${pagination.count}`}})}
            className="my-auto h-8 w-auto transform p-1 px-2 text-gray-50 font-semibold h-8 rounded-lg duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105  ring-transparent"
          >
            Max
          </button>
          <input
            type="number"
            name="page_size"
            onChange={handleChange}
            value={page_size}
            className="w-10 text-center font-bold rounded-md bg-blue-ligth text-gray-100 font-semibold p-1 my-1 ring-transparent"
          ></input>
          <button
            onClick={() => limpiar()}
            className="my-auto h-8 w-auto transform p-1 px-2 text-gray-50 font-semibold h-8 rounded-lg duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105  ring-transparent"
          >
            <Refresh size="23" className={`text-white transform transition-all ease-in-out`} />
          </button>
          <button
            onClick={() => filtrar()}
            className="my-auto h-8 w-auto transform p-1 px-2 text-gray-50 font-semibold h-8 rounded-lg duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105 hover:bg-blue-500 ring-transparent"
          >
            <Search size="23" className={`text-white transform transition-all ease-in-out`} />
          </button>
          <button
            onClick={() => setFilters(!filters)}
            className="my-auto  transform w-auto p-1 px-2 text-gray-50 font-semibold h-8 rounded-lg  duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105   ring-transparent"
          >
            <ArrowBoldDown
              size="23"
              className={`text-white transform transition-all ease-in-out duration-300  ${
                filters ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className={`transition-height ease-in-out  duration-500 border-t border-white ${
          filters ? 'h-auto min-h-64' : 'h-0 opacity-0'
        } bg-blue-normal w-full `}
      >
        {filters && (
          <div
            className={`w-full  h-auto transition-opacity flex ${
              filters === false && 'invisble'
            } flex-wrap justify-between gap-x-4 gap-y-4 p-4 `}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Filtro;
