import React, { useEffect } from 'react';
import LAgents from './graficos/LAgents';
import RAgents from './graficos/RAgents';
import PAgents from './graficos/PAgents';
import AStats from './graficos/AStats';
import Grafico from '../Grafico';

const Agentes = ({ data, getData, change, dates }) => {
  console.log(data)
  return (
    <div className="w-full h-full flex flex-wrap content-start px-4 gap-y-3 bg-gray-100">
      
      <div className="w-full h-full p-3">
        <div className="w-full h-full shadow-xl  rounded-lg bg-white">
          {data && <Grafico data={data.hour} groups={data.agentes}><LAgents/></Grafico>}
        </div>
      </div>
	    <div className="w-full min-h-full p-3">
        <div className="w-full h-full shadow-xl  rounded-lg bg-white">
          {data && <Grafico data={data.dias} groups={data.agentes}><LAgents/></Grafico>}
        </div>
      </div>
      {/*<div className="w-full h-1/2 p-3">
        <div className="w-full h-full shadow-xl  rounded-lg bg-white">
          {data && <LAgents data={data.hour} agentes={data.agentes} title={'Llamadas por hora'} />}
        </div>
      </div>*/}
      {/*<div className="w-full h-1/2 p-3">
        <div className="w-full h-full shadow-xl  rounded-lg bg-white">
          {data && <LAgents data={data.dias} agentes={data.agentes} title={'Llamadas por dia'} />}
        </div>
      </div>*/}
      <div className="w-full min-h-full p-3">
        <div className="w-full h-full shadow-xl  rounded-lg">
          {data && <PAgents data={data.agentes_pending} groups={data.status_opts} />}
        </div>
      </div>

      <div className="w-full h-full p-3">
        <div className="w-full h-full shadow-xl  rounded-lg">
          {data && <Grafico data={data.agentes_tipos} groups={data.resps_opts}><RAgents/></Grafico>}
        </div>
      </div>
      {/*<div className="w-1/2 h-1/2 p-3">
        <div className="w-full h-full shadow-xl  rounded-lg">
          {data && <RAgents data={data.agentes_tipos} resps={data.resps_opts} />}
        </div>
  </div>*/}
      <div className="w-full min-h-full p-3">
        <div className="w-full min-h-full  h-auto flex flex-wrap shadow-xl   rounded-lg   ">
          {data && <AStats data={data.agentes_stats} />}
        </div>
      </div>
      <div className="w-full h-1/3 "></div>
    </div>
  );
};

export default Agentes;
