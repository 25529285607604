import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import BloqueosTable from '../../components/bloqueos/BloqueosTable.jsx'
import fileDownload from 'js-file-download'
import toast from 'react-hot-toast'

import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import {FileTxt} from '@styled-icons/boxicons-solid/FileTxt'



const Bloqueos = () =>{
  const [file, setFile] = useState(null);
  const [fileC, setFileC] = useState(null); // FNC
  const [fileCe, setFileCe] = useState(null); // Celulares
  const [fileRuts , setFileRuts ] = useState(null); // Ruts 
  const [fileCorreos, setFileCorreos] = useState(null); // Ruts 
  const [fileRutFono, setFileRutFono] = useState(null); // Ruts 
  const [fileFijos, setFileFijos] = useState(null); // Ruts 

  const [bloqueos, setBloqueos] = useState({results:[],next:null,previous:null}); // Ruts 
  const [dataBloq,setDataBloq] = useState(null)

  const getData = async () =>{
    await axiosInstance.get(`numeros/data_caja/`).then(res=>{
      setDataBloq(res.data)
    })
  }
  const getBloqueos = async () =>{
    await axiosInstance.get(`bloqueos/`).then(res=>{
      setBloqueos(res.data)
    })
  }

  const changePage = async (url) =>{
    await axiosInstance.get(url).then(res=>{
      setBloqueos(res.data)
    })
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleChangeFile2 = (e) => {
    setFileC(e.target.files[0]);
  };
  const handleChangeFile3 = (e) => {
    setFileCe(e.target.files[0]);
  };
  const handleChangeFile4 = (e) => {
    setFileRuts(e.target.files[0]);
  };
  const handleChangeFile5 = (e) => {
    setFileCorreos(e.target.files[0]);
  }
  const handleChangeFile6 = (e) => {
    setFileRutFono(e.target.files[0]);
  }
  const handleChangeFile7 = (e) => {
    setFileFijos(e.target.files[0]);
  }

  const downloadTxt= async (p) =>{
    await axiosInstance.post(`numeros/get_bloqueados/`,{responseType:'blob'}).then(res=>{
        // console.log(res.headers)
        fileDownload(res.data,`bloqueados.txt`);
    })
  }
  const bloq_fijos = () => {
    const data = new FormData();
    data.append('file', fileFijos, fileFijos.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_fijos/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  const desbloquear = () => {
    var toastId = toast.loading('desbloqueando');
    axiosInstance
      .post(`numeros/desbloquear/`)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('desbloqueados ');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  const bloq_fnc = () => {
    const data = new FormData();
    data.append('file', fileC, fileC.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_fnc/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };
  const bloq_rut_fono = () => {
    const data = new FormData();
    data.append('file', fileRutFono, fileRutFono.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_fnc/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };
  const bloq_diario = () => {
    const data = new FormData();
    data.append('file', file, file.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_diario/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  const bloq_celulares = () => {
    const data = new FormData();
    data.append('file', fileCe, fileCe.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_celulares/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  const bloq_ruts = () => {
    const data = new FormData();
    data.append('file', fileRuts, fileRuts.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_rut/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  const bloq_correos= () => {
    const data = new FormData();
    data.append('file', fileCorreos, fileCorreos.name);
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`numeros/bloq_emails/`, data)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => console.log(err));
    //setFile(null)
  };

  useEffect(() => {
    getBloqueos()
    getData()
  }, [])


  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll ">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Bloqueos </p> 
      </div>

        <div className="px-4  w-full flex justify-between">
          <div className="w-64 h-32 border flex flex-wrap shadow-sm bg-white items-center content-start justify-center text-xl font-bold p-3">
            <p className="w-full text-center">Contactos Activos</p> 
            <p className="w-full text-center text-green-600 mt-5 text-4xl">{dataBloq && dataBloq.active_contacts}</p> 
          </div>
          <div className="w-64 h-32 border flex flex-wrap shadow-sm bg-white items-center content-start justify-center text-xl font-bold p-3">
            <p className="w-full text-center">Contactos Bloqueados</p> 
            <p className="w-full text-center text-red-600 mt-5  text-4xl">{dataBloq && dataBloq.bloqued_contacts}</p> 
          </div>
        <div className="w-64 h-32 border flex flex-wrap shadow-sm bg-white items-center content-start justify-center text-xl font-bold p-3">
            <p className="w-full text-center">Numeros Activos</p> 
            <p className="w-full text-center text-green-600  mt-5 text-4xl">{dataBloq && dataBloq.active_numbers}</p> 
          </div>

          <div className="w-64 h-32 border flex flex-wrap shadow-sm bg-white items-center content-start justify-center text-xl font-bold p-3">
            <p className="w-full text-center">Numeros Bloqueados</p> 
            <p className="w-full text-center text-red-600  mt-5 text-4xl">{dataBloq && dataBloq.bloqued_numbers}</p> 
          </div>
      </div>

        <div className="px-4  w-full flex">
          <BloqueosTable data={bloqueos} changePage={changePage}/>
        </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start py-2 gap-x-3 gap-y-4">

          <div className="border w-64  flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="text-lg font-semibold w-full text-center">Bloqueos Diarios</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile}
                  className="hidden"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx  xls, .xlsb , .xltx , xls "
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ">{file && file.name}</h1>
              </div>

              

            <button  onClick={()=>bloq_diario()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              Cargar
            </button>
          </div>
    
        <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">Celulares</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile3}
                  className="hidden"
                  accept=".txt"
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ml-2">{fileCe && fileCe.name}</h1>
              </div>
            <button  onClick={()=>bloq_celulares()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              cargar
            </button>
          </div>

        <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">Ruts</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile4}
                  className="hidden"
                  accept=".txt"
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ml-2">{fileRuts && fileRuts.name}</h1>
              </div>
            <button  onClick={()=>bloq_ruts()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              cargar
            </button>
          </div>

        <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-ful text-center text-lg font-semibold">Correos</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile5}
                  className="hidden"
                  accept=".txt"
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ml-2">{fileCorreos && fileCorreos.name}</h1>
              </div>
            <button  onClick={()=>bloq_correos()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              cargar
            </button>
          </div>
        <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">Rut_Fono</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile6}
                  className="hidden"
                  accept=".txt"
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ml-2">{fileRutFono && fileRutFono.name}</h1>
              </div>
            <button  onClick={()=>bloq_rut_fono()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              cargar
            </button>
          </div>

          <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">Fijos</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile7}
                  className="hidden"
                  accept=".txt"
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ml-2">{fileFijos && fileFijos.name}</h1>
              </div>
            <button  onClick={()=>bloq_fijos()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              cargar
            </button>
          </div>
        <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">FNC</p>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center mb-2 mt-2">
                            <label className="my-auto  w-auto  px-4 flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <input
                  type="file"
                  onChange={handleChangeFile2}
                  className="hidden"
                  accept=".txt"
                />
              </label>
              <h1 className="font-semibold text-blue-500  text-sm  ml-2">{fileC && fileC.name}</h1>
              </div>
            <button  onClick={()=>bloq_fnc()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              cargar
            </button>
          </div>
          <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">Desbloquear Todos</p>

            <button  onClick={()=>desbloquear()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              Desbloquear
            </button>
          </div>

          <div className="border w-64 flex flex-wrap content-between p-4 h-auto bg-white rounded-md" >
              <p className="w-full text-center text-lg font-semibold">Bloqueados</p>

              

            <FileTxt size="32" onClick={()=>downloadTxt()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600 cursor-pointer">
              
            </FileTxt>
          </div>

        </div> 
                
      </div>
  )
}

export default Bloqueos;

 
