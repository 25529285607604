import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axiosInstance from '../../axios';

import toast from 'react-hot-toast';

import MultiPage from '../../structs/layout/MultiPage'
import {MultiPageSelector} from '../../structs/layout/MultiPage.js'
import FilterTable from '../../structs/layout/Filter-Table';
import Filtro from '../../components/Filtro';
import LlamadasT from '../../components/llamadas/LlamadasT';
//import Filters from '../../components/llamadas/Filters'
import Filters from '../../components/llamadas/F2';

import { getQueryValue } from '../../utils/forms';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Llamadas = () => {
  const [change, setChange] = useState(null);
  const [llamadas, setLlamadas] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
    page_size: null,
  });

  let query = useQuery();

  const getLlamadas = async () => {
    await axiosInstance.get(`llamadas/list_admin/?page_size=50&${query.toString()}`).then((res) => {
      setLlamadas(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setLlamadas(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    const promise = getLlamadas();
    toast.promise(promise, {
      loading: 'Cargando',
      error: 'Error',
    });

    //console.log(query)
    //console.log(query.toString())

    return () => {};
  }, [change]);

  return (
      <div className="w-full flex flex-wrap p-1 gap-y-3">

	    <MultiPageSelector  tabs={[{name:'Campañas',href:'camps_llamadas',current:false},{name:'Llamadas',href:'llamadas',current:true},{name:'Respuestas',href:'respuestas',current:false}]}/> 


    <FilterTable
      filters={<Filters pagination={pagination} />}
      table={
        <LlamadasT move={move} pagination={pagination} setChange={setChange} llamadas={llamadas} />
      }
      nombre="Llamadas"
    />
    </div>
  );
};

export default Llamadas;
