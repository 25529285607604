import React, { useState, useEffect } from 'react';

import { Link,useParams } from 'react-router-dom';

import axiosInstance from '../../axios';
import format from '../../utils/formatDate';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { Verified } from '@styled-icons/material/Verified';
import { Unverified } from '@styled-icons/octicons/Unverified';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';


import {Phone } from '@styled-icons/boxicons-solid/Phone'
const Llamadas = ({ contacto, setChange ,resps}) => {

  const [respuestas,setRespuestas] = useState([])
  

  const { id } = useParams();

  const getGroupColor = (group) =>{
    if(group === 1){
      // postiva
      return 'bg-green-600'
    }else if(group === 2){
      return 'bg-red-600'
    }else if(group === 3){
      return 'bg-blue-600'
    }
  }
  const getRespuestas = () => {
    axiosInstance.get(`respuestas?contacto=${id}&page_size=100`).then((res) => {
      setRespuestas(res.data.results);
      // setPag({
      //   next: res.data.next,
      //   'previous ': res.data.previous,
      //   count: res.data.count,
      // });
      console.log(res.data);
    });
  };

  useEffect(() => {
    // if (contacto.id !== undefined) {
      // getRespuestas();
    // }
    //
  }, [contacto]);

  return (
    <div className="w-full flex justify-center gap-x-3">
      
      <div className="flex flex-wrap content-start w-full md:w-3/4 p-1 ">
      {resps.map((resp)=>{
        return <><div className="flex w-full content-start items-start flex-wrap gap-x-4 ">
              
              <div className="w-full">
              <div className={`w-8 h-8 rounded-full bg-blue-ligth flex justify-center p-1`}>
                <Phone size="25" className="text-white"/>
              </div>
              </div>

          <div className="w-full flex gap-x-6">
              <span className="ml-4 h-auto w-0.5 bg-blue-400"> </span>
              <div className="flex-grow flex flex-wrap bg-white rounded-md p-3">
                <div className="w-full flex  justify-between">
                  <p className="text-gray-400 text-sm">Llamada de <span className="text-gray-800"> {resp.agente && resp.agente.first_name} {resp.agente && resp.agente.last_name}</span></p>
                  <p className="text-gray-500 text-sm">{format(resp.add_date)} {resp.add_datetime}</p>
                </div>
                  
                <div className="w-full flex  justify-between ">
                    <p  style={{ color: resp.tipo && resp.tipo.color }} className="font-mono">{resp.tipo && resp.tipo.nombre}</p>
                    <p className="text-gray-600 text-sm font-semibold">{resp.numero && resp.numero.numero}</p>
                </div>

                <div className="w-full flex-wrap mt-1">
                  <p className="text-gray-400">Nota</p>
                  <div className="w-full rounded-md bg-gray-100 p-2">
                    <p className="text-gray-700 font-mono">{resp.observacion}</p>
                    
                  </div>

                </div>

              </div>
          </div>
                
          </div>

          </>
      })}
      </div>
    </div>
      
  );
};

export default Llamadas;
