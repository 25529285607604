import React, { useState, useEffect } from 'react';

import axiosInstance from '../../../axios';
import toast from 'react-hot-toast';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { PlusMedical } from '@styled-icons/boxicons-regular/PlusMedical';

const AddResp = ({ setModal, campResps, campId, setChange }) => {
  const [resps, setResps] = useState([]);

  const [search, setSearch] = useState('');
  const [mandantes, setMandantes] = useState([]);

  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.id, label: man.nombre };
    });

  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    //setSearch(e.target.value);
    if (e.target.value.length > 2) {
      axiosInstance
        .get(`trespuestas/?search=${e.target.value}`)
        .then((res) => {
          console.log(res.data);
          setResps(res.data.filter((x) => !campResps.includes(x.id)));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addRespuesta = async (tresp) => {
    console.log({ respuesta: tresp.id });
    axiosInstance.post(`campaigns/${campId}/add_respuesta/`, { respuesta: tresp }).then((res) => {
      toast.success('Respuesta Agregada');
      setChange(new Date());
      setResps(resps.filter((x) => x.id !== tresp));
    });
  };

  useEffect(() => {
    getMandantes();
  }, []);

  return (
    <div className="flex content-start flex-wrap p-2 absolute z-40 mx-20 my-16 rounded-lg overflow-hidden w-7/12 h-5/6 bg-blue-normal">
      <div className="w-full h-8 flex justify-end mb-4">
        <button
          onClick={() => setModal(false)}
          className="w-7 my-auto h-7 flex justify-center rounded-full bg-red-500 transform hover:scale-110 text-white p-1"
        >
          <Close className="h-full w-full font-bold" />
        </button>
      </div>
      <input
        onChange={handleChange}
        type="text"
        placeholder="Buscar Respuestas"
        className="px-3 w-full mx-4 text-xl font-bold  h-12 border-2 border-white rounded-lg"
      ></input>

      <div className="flex flex-wrap w-full h-4/6 border border-white mt-4 rounded-md mx-2 overflow-y-scroll ">
        <table className="w-full bg-white table-auto h-auto text-center font-bold">
          <thead>
            <th>Nombre</th>
            <th>Codigo</th>
            <th>Grupo</th>
            <th>Mandantes</th>
            <th>Acciones</th>
          </thead>
          <tbody>
            {resps &&
              resps.length > 0 &&
              resps.map((obj) => {
                return (
                  <tr className="border border-gray-900 h-12">
                    <td>{obj.nombre}</td>
                    <td>{obj.grupo}</td>
                    <td>
                      <p className="text-gray-900 whitespace-no-wrap">
                        {obj.grupo === 1 && (
                          <span className="relative inline-block px-1 py-1 font-semibold text-green-900 leading-tight">
                            {' '}
                            <span
                              aria-hidden="true"
                              className="absolute inset-0 bg-white  opacity-50 rounded-full"
                            >
                              {' '}
                            </span>{' '}
                            <span className="relative"> Positiva </span>
                          </span>
                        )}
                        {obj.grupo === 2 && (
                          <span className="relative inline-block px-1 py-1 font-semibold text-red-800 leading-tight">
                            {' '}
                            <span
                              aria-hidden="true"
                              className="absolute inset-0 bg-white opacity-50 rounded-full"
                            >
                              {' '}
                            </span>{' '}
                            <span className="relative"> Negativa </span>
                          </span>
                        )}
                        {obj.grupo === 3 && (
                          <span className="relative inline-block px-1 py-1 font-semibold text-blue-800 leading-tight">
                            {' '}
                            <span
                              aria-hidden="true"
                              className="absolute inset-0 bg-white opacity-50 rounded-full"
                            >
                              {' '}
                            </span>{' '}
                            <span className="relative"> Rellamada </span>
                          </span>
                        )}
                      </p>
                    </td>
                    <td>
                      <div className="w-42 h-12 overflow-y-scroll scroll">
                        {optionsMandantes
                          .filter((x) => obj.mandantes.includes(x.value))
                          .map((x) => {
                            return (
                              <p className="overflow-y-scroll">
                                {x.label}
                                <br />
                              </p>
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          addRespuesta(obj.id);
                        }}
                        className="my-1 mx-2 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-green-600 hover:-translate-y-1 hover:scale-105   ring-transparent"
                      >
                        <PlusMedical
                          size="23"
                          className={`text-white transform transition-all ease-in-out duration-300`}
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddResp;
