import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';

import Reglas from './settings/Reglas';
import Respuestas from './settings/Respuestas';
import toast from 'react-hot-toast';

const EditCamp = ({ camp }) => {
  const [data, setData] = useState({});
  const [listas, setListas] = useState([]);

  const [errors, setErrors] = useState([]);

  const [show, setShow] = useState(0);
  const [showGeneral, setShowGeneral] = useState(false);
  const [showReglas, setShowReglas] = useState(false);
  const [showRespuestas, setShowRespuestas] = useState(false);

  const getListas = () => {
    axiosInstance.get(`listas/`).then((res) => {
      setListas(res.data);
    });
  };

  const submitForm = () => {
    console.log(data);

    axiosInstance
      .patch(`campaigns/${camp.id}/`, data)
      .then((res) => {
        toast.success('actualizada');

        console.log(res);
        //window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        var errs = [];
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              errs.push(`${property} : ${err.response.data[property]}`);
              toast.error(`${property} : ${err.response.data[property]}`);
            }
          }
        } else {
        }
        setErrors(errs);
        console.log(errs);
      });
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getListas();
    setData({
      ...camp,
      ['lista']: camp.lista.id,
      ['agentes']: camp.agentes.map((a) => {
        return a.id;
      }),
    });
  }, []);

  return (
    <div className="flex flex-wrap w-full h-auto p-3 gap-y-5 content-start ">
      {/*  +++++++++++++++++++++++++++++GENERAL++++++++++++++++++++++++*/}
      <div
        className={`${
          showGeneral === true ? 'h-full' : 'h-12'
        } w-full flex justify-between flex-wrap border content-start   bg-gray-100 rounded-lg `}
      >
        <div className="w-full h-12 bg-gray-dark justify-between flex text-2xl font-bold text-white px-4">
          <h1 className="text-center my-auto">General</h1>
          <div clasName="w-1/3 flex">
            <button
              onClick={() => setShowGeneral(!showGeneral)}
              className="my-1 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105   ring-transparent"
            >
              <ArrowBoldDown
                size="23"
                className={`text-white transform transition-all ease-in-out duration-300  ${
                  showGeneral ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </button>
          </div>
        </div>
        {showGeneral && (
          <div className="h-full w-full flex justify-between flex-wrap border pb-5 py-2 px-8 bg-gray-100 rounded-lg">
            <div className="w-2/6 h-1/6 ">
              <label
                className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
                for="username"
              >
                Nombre <span className="text-black font-bold">||</span>{' '}
                <p className="">{camp && camp.nombre} </p>
              </label>
              <input
                name="nombre"
                value={data['nombre']}
                onChange={handleChange}
                type="text"
                className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              ></input>
            </div>
            <div className="w-2/6 h-1/6 ">
              <label
                className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
                for="fecha_inicio"
              >
                Fecha inicio<span className="text-black font-bold">||</span>{' '}
                <p className="">{camp && camp.fecha_inicio} </p>
              </label>
              <input
                type="date"
                name="fecha_inicio"
                onChange={handleChange}
                value={data['fecha_inicio']}
                id="s"
                className="w-1/2 p-2"
              ></input>
            </div>
            <div className="w-2/6 h-1/6 ">
              <label
                className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
                for="fecha_fin"
              >
                Fecha fin<span className="text-black font-bold">||</span>{' '}
                <p className="">{camp && camp.fecha_fin} </p>
              </label>
              <input
                type="date"
                name="fecha_fin"
                onChange={handleChange}
                value={data['fecha_fin']}
                id="e"
                className="w-1/2 p-2"
              ></input>
            </div>

            <div className="w-2/6 h-1/6 ">
              <label
                className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
                for="lista"
              >
                Lista
              </label>
              <select
                name="lista"
                onChange={handleChange}
                className="w-1/2 h-1/2 bg-white border rounded-lg font-bold"
              >
                {listas &&
                  listas.length > 0 &&
                  listas.map((lista) => {
                    return (
                      <option className="bg-white" key={lista.id} value={lista.id}>
                        {lista.nombre} || Contactos: {lista.contactos}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="w-2/6 h-1/6 ">
              <label
                className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
                for="username"
              >
                Status
              </label>
              {camp && data['status'] === 1 ? (
                <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                      <input
                        id="unchecked"
                        onClick={() => setData({ ...data, ['status']: 2 })}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Activo</span>
                </label>
              ) : (
                <label for="checked" class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                      <input
                        id="checked"
                        onClick={() => setData({ ...data, ['status']: 1 })}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Inactivo</span>
                </label>
              )}
            </div>
            <div className="w-2/6 h-1/6 ">
              <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2">
                Terminar AUTO
              </label>
              {camp && data['terminar_auto'] === true ? (
                <label class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                      <input
                        id="uncheckedterminar"
                        onClick={() => setData({ ...data, ['terminar_auto']: false })}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Activo</span>
                </label>
              ) : (
                <label class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                      <input
                        id="checkedterminar"
                        onClick={() => setData({ ...data, ['terminar_auto']: true })}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Inactivo</span>
                </label>
              )}
            </div>

            <div className="w-2/6 h-1/6 justify-self-end self-end ">
              <button
                onClick={() => submitForm()}
                className="focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </div>

      <Reglas camp={camp} show={showReglas} setShow={setShowReglas}></Reglas>

      <Respuestas camp={camp} show={showRespuestas} setShow={setShowRespuestas}></Respuestas>
    </div>
  );
};

export default EditCamp;
