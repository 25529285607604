import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';

const CreateSModal = ({ setModal, setChange }) => {
  const [pas, setPas] = useState(false);
  const [status, setStatus] = useState(1);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    nombre: null,
  });

  const close = () => {
    setModal(false);
  };
  //setNumeros(numeros.filter(x=> x.numero !== numero)
  const handleChange = (e) => {
    console.log(e.target.value);

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const addContact = () => {
    //console.log(data)
    var errs = [];

    axiosInstance
      .post(`scripts/`, data)
      .then((res) => {
        setChange(res.data);
        setModal(false);
      })
      .catch((err) => {
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              errs.push(`${property} : ${err.response.data[property]}`);
            }
          }
        } else {
        }
        setErrors(errs);
      });
  };

  useEffect(() => {}, []);

  return (
    <div className="flex flex-wrap ml-96 mt-6 flex-col h-4/6 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
      <div className="bg-white h-5/6 w-full shadow overflow-hidden sm:rounded-lg overflow-y-scroll border border-gray-300">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Nombre</dt>
              <input
                type="text"
                placeholder="..."
                name="nombre"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
          </dl>
        </div>
      </div>

      <div className="h-1/6 w-full flex flex-wrap justify-center items-center">
        <div className="flex justify-center h-1/3 w-full">
          <button
            onClick={() => close()}
            className="h-full  focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
          >
            Cancelar
          </button>

          <button
            onClick={() => addContact()}
            className="h-full focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Crear
          </button>
        </div>
        {errors.length > 0 && (
          <div className="flex flex-wrap h-2/3 px-20 pt-2 overflow-y-scroll w-full">
            {errors.length > 0 &&
              errors.map((error) => {
                return (
                  <div
                    className="bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                    role="alert"
                  >
                    <p className="font-bold w-full">Error</p>
                    <p clasName="w-full">{error}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateSModal;
