import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import format from '../../../utils/formatDate';
import { getTipoCarga } from '../../../utils/models';
import { getStatusCarga } from '../../../utils/models';

import { Refresh } from '@styled-icons/foundation/Refresh';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';
import { ArrowDownload } from '@styled-icons/fluentui-system-regular/ArrowDownload';

// import { mkConfig, generateCsv, download, asBlob } from 'export-to-csv';
import { Download } from '@styled-icons/evaicons-solid/Download';

import fileDownload from 'js-file-download';

import { CSVLink, CSVDownload } from 'react-csv';

import ReactExport from 'react-export-excel';

import CargasAction from './CargasAction';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TCargas = ({ change, setChange }) => {
  const [page, setPage] = useState(1);

  const [carga, setCarga] = useState(null);
  const [detail, showDetail] = useState(false);
  const [cargas, setCargas] = useState([]);
  const [errors, setErrors] = useState('');
  const [selected, setSelected] = useState(null);
  const [actionModal, setActionModal] = useState(false);

  const getCargas = async () => {
    await axiosInstance.get('carga2?page_size=10').then((res) => {
      console.log(res.data);
      setCargas(res.data);
    });
  };

  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setCargas(res.data);
    });
  };

  const downloadErrors = (c) => {
    console.log('123123123');
    axiosInstance.post(`carga2/${c.id}/download_errors/`).then((res) => {
      console.log(res.data);
      var errs = res.data.errors;
      const titleKeys = Object.keys(errs[0]);
      console.log(titleKeys);

      const refinedData = [];
      refinedData.push(titleKeys);

      errs.forEach((item) => {
        let vals = Object.values(item);
        let err = vals.shift();
        console.log(JSON.stringify(err));
        vals.unshift(JSON.stringify(err));
        refinedData.push(vals);
      });

      let csvContent = '';

      refinedData.forEach((row) => {
        csvContent += row.join(',') + '\n';
      });

      console.log(csvContent);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });

      // setErrors(res.data.errors);
      // done();
      // const csv = generateCsv(csvConfig)(res.data.errors);

      // // Generate the Blob from the CsvOutput
      // const blob = asBlob(csvConfig)(csv);

      // // Requires URL to be available (web workers or client scripts only)
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // //
      // // Extract the filename from the Content-Disposition header

      link.setAttribute('download', 'errors.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
    // console.log(response.data.errors);

    // return response.data.errors;
  };
  // const downloadErrors = async (c) => {
  //   const response = await axiosInstance.post(`carga2/${c.id}/download_errors/`, {
  //     responseType: 'blob',
  //   });
  //   const blob = new Blob([response.data], { type: response.data.type });
  //   const url = window.URL.createObjectURL(blob);

  //   // Create a link element
  //   const link = document.createElement('a');
  //   link.href = url;

  //   // Extract the filename from the Content-Disposition header

  //   link.setAttribute('download', 'errors.xlsx');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   window.URL.revokeObjectURL(url); // Free up the memory
  // };
  // fileDownload(res.data, res.headers['filename']);
  // const blob = new Blob([res.data]);
  // type: 'application/octet-stream',
  // , { responseType: 'blob' }
  const downloadFile = (c) => {
    axiosInstance.post(`carga2/${c.id}/download_file/`, { responseType: 'blob' }).then((res) => {
      // fileDownload(res.data, res.headers['filename']);
      // const blob = new Blob([res.data]);
      const blob = new Blob([JSON.stringify(res.data)], {
        // type: 'application/octet-stream',
        // type: 'application/vnd.ms-excel',
        // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with a filename

      link.setAttribute('download', res.headers['filename']);

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    });
  };

  const dataErrors = () => {
    return '';
  };
  useEffect(() => {
    getCargas();
  }, [change]);

  return (
    <div className="flex flex-wrap w-full h-auto p-2 rounded-lg">
      <div className="w-full h-12 flex justify-end">
        <Refresh
          onClick={() => setChange(new Date())}
          size="30"
          className="text-blue-400 cursor-pointer transition ease-in-out duration-700 transform hover:scale-125 hover:rotate-90"
        ></Refresh>
      </div>
      {page === 1 && (
        <table className="table-auto w-full">
          <thead>
            <th className="border p-2">Fecha</th>
            <th className="border p-2">Status</th>
            <th className="border p-2">Tipo</th>
            <th className="border p-2">Plantilla</th>
            <th className="border p-2">Archivo</th>
            <th className="border p-2">User</th>
            <th className="border p-2">Changed</th>
            <th className="border p-2">Created</th>
            <th className="border p-2">Failed</th>
            <th className="border p-2"></th>
          </thead>

          <tbody>
            {cargas.results &&
              cargas.results.length > 0 &&
              cargas.results.map((c) => {
                return (
                  <tr>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {format(c.fecha_inicio)}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {getStatusCarga(c.status)}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {getTipoCarga(c.tipo)}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.plantilla && c.plantilla.nombre}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      <button onClick={() => downloadFile(c)} className="text-blue-500">
                        {c.file_name}
                      </button>
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.user && `${c.user.first_name} ${c.user.last_name}`}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.actualizadas}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.creadas}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.invalidas}{' '}
                      {c.invalidas > 0 && (
                        <Download
                          size="16"
                          onClick={() => downloadErrors(c)}
                          className="hover:scale-105 text-blue-500 transform"
                        />
                      )}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      <MoreVerticalOutline
                        onClick={() => {
                          setActionModal(!actionModal);
                          setSelected(c);
                        }}
                        size="20"
                        className="text-blue-semi transform hover:scale-125 cursor-pointer"
                      />
                      {actionModal && selected == c && <CargasAction carga={c} />}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {cargas.previous && (
        <div
          onClick={() => move(cargas.previous)}
          className="px-3 py-1 bg-blue-ligth transform hover:scale-110 font-semibold text-white rounded-md mt-1 cursor-pointer"
        >
          Prev
        </div>
      )}
      {cargas.next && (
        <div
          onClick={() => move(cargas.next)}
          className="px-3 py-1 bg-blue-ligth transform hover:scale-110 font-semibold text-white rounded-md mt-1 cursor-pointer"
        >
          Next
        </div>
      )}
    </div>
  );
};

export default TCargas;
