
import {useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';

import {TitleActions} from '../../structs/layout/Title'

import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';

const MailTemplateDetail = () =>{
  const { id } = useParams();
  const [obj, setObj] = useState({});
  const [change, setChange] = useState(null);
  const [edit,setEdit] = useState(false)
  const [data, setData] = useState({text:""});



  const getObj = async () => {
    await axiosInstance.get(`mail_templates/` + id).then((res) => {
      setObj(res.data);
      setData(res.data);
    });
  };


  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})

  }

  
  const editar = () =>{
    setEdit(!edit)
    if(!edit === false && data['text'] !== "" && data["text"] !== obj['text']){
      console.log('update')
      axiosInstance.patch(`mail_templates/${id}/`,{text:data['text']}).then(res=>{
        
        setObj(res.data)
        setData(res.data)
      })
    }

  }

  useEffect(() => {
    getObj();
  }, [change]);



  return (
	  <div className="w-full flex flex-wrap h-auto pt-3 px-4">
      <TitleActions title={obj.nombre} path={[{name:'Mails',path:'/sms'},{name:'Templates',path:'/mail_templates'},{name:'Detalle',path:`/mail_templates/${obj ? obj.id : '1'}`},]}/>
    <div className="w-full h-12 flex justify-end">
            {edit ? <EditOff
              onClick={() => editar()}
              className="transform hover:scale-125          h-5/6 text-blue-600"
            />
              :
            <Edit
              onClick={() => editar()}
              className="transform hover:scale-125          h-5/6 text-blue-600"
            />
            }
      </div>

    <div className="w-full h-96 border mt-6 p-1 flex rounded-md">
      

    {edit ? <textarea value={data['text']} onChange={handleChange} name="text" className="w-full h-full bg-gray-100 p-4">

      </textarea>
      :
      <p className="w-full h-full bg-gray-50 p-4 break-normal">{obj && obj.text}</p>
    }
         

      
    </div>
    </div>
  )
}

export default MailTemplateDetail;
