
import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import Stats from './stats/Stat.jsx'
import Days from './stats/Days.jsx'

const Resumen = ({id}) =>{
  const [data,setData] = useState(null)

  const getData = async () =>{
    axiosInstance.get(`sms_campaigns/${id}/resumen`).then(res=>{
      console.log(res)
      setData(res.data)
    })
  }

  useEffect(()=>{
    getData()
  },[])
  return ( 
    <div className="w-full flex flex-wrap mt-4 p-4 mx-auto gap-y-8">
    {/* {data !== null && <Stats data={data}></Stats>} */}
    {data !== null && <Days data={data}></Days>}
    
    </div>
)

}

export default Resumen;
