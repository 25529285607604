import React,{useEffect,useState} from 'react'
import axiosInstance from '../../axios'
import { toDate } from 'date-fns';





const AgentesResps = () => {

    var user = JSON.parse(localStorage.getItem('user'));
    const [resps,setResps] = useState([])
    const [filters,setFilters] = useState({
        add_date:new Date()
    })


    const handleChange = (e) =>{
        setFilters({...filters,[e.target.name]:e.target.value})
    }

    const getResps = async () =>{
        axiosInstance.get(`respuestas?agente=${user.id}&add_date=${filters.add_date.toISOString().slice(0, 10)}`).then(res=>{
            setResps(res.data)
            console.log(res.data)
        })
    }

    useEffect(()=>{
        getResps()
    },[])

  return (
    <div className='p-2 flex flex-wrap content-start'>
        <div className='w-full h-12 border rounded-sm bg-white flex items-center justify-end p-2'>
            <div className="rounded-sm border-t-2 flex bg-gray-100 w-auto px-2 border-blue-semi border">
                <input
                  name="add_date"
                  type="date"
                  value={filters['date']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
        </div>
        <div className='h-auto w-full '>
            

        </div>
    </div>
  )
}

export default AgentesResps