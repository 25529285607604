import React, { useState, useEffect } from 'react';
import '../assets/dia.css';

import Diagram, { createSchema, useSchema } from 'beautiful-react-diagrams';
import { Scale } from 'react-hero-icon/solid';
import GridLines from 'react-gridlines';

//onClick={()=>data.onClick(id)}

const CustomRender = ({ id, content, data, inputs, outputs }) => (
  <div className="bg-white rounded-lg px-5 border-2 w-64 border-blue-ligth">
    <div className="flex justify-center">
      {inputs.map((port) =>
        React.cloneElement(port, {
          className: 'w-16 h-6 rounded-sm  bg-gray-500 transform -translate-y-10',
        }),
      )}
    </div>
    <div className=" border-b">
      <h1 className="text-xl"> {content} </h1>
      <h3 className="text-gray-500 text-lg mr-5">({data.type})</h3>
    </div>
    <div className="overflow-scroll h-24 scroll">
      <p className="break-words">hola</p>
    </div>

    <div className="flex justify-center">
      {outputs.map((port) =>
        React.cloneElement(port, {
          className: ' w-16 h-6 rounded-sm  mx-1 bg-gray-500 transform translate-y-10',
        }),
      )}
    </div>
  </div>
);

const initialSchema = createSchema({
  nodes: [
    {
      id: 'node-1',
      content: 'Start',
      coordinates: [500, 150],
      render: CustomRender,
      outputs: [{ id: 'port-1', alignment: 'right' }],
      disableDrag: false,
      data: {
        type: 'Say',
        count: 0,
      },
    },
    {
      id: 'node-2',
      content: 'Middle',
      coordinates: [300, 300],
      render: CustomRender,
      inputs: [{ id: 'port-4', alignment: 'left' }],
      outputs: [
        { id: 'port-5', alignment: 'right' },
        { id: 'port-6', alignment: 'right' },
        { id: 'port-7', alignment: 'right' },
      ],
      data: {
        type: 'foo',
      },
    },
    {
      id: 'node-3',
      content: 'End',
      coordinates: [600, 900],
      render: CustomRender,
      inputs: [{ id: 'port-8', alignment: 'left' }],
      data: {
        foo: true,
        bar: false,
        some: {
          deep: {
            object: true,
          },
        },
      },
    },
  ],
  links: [{ input: 'port-1', output: 'port-4', className: '' }],
});

const UncontrolledDiagram = () => {
  // create diagrams schema
  const [schema, { onChange, addNode, removeNode }] = useSchema(initialSchema);
  const [nodes, setNodes] = useState([]);
  const [link, setLinks] = useState([]);

  const deleteNodeFromSchema = (id) => {
    const nodeToRemove = schema.nodes.find((node) => node.id === id);
    removeNode(nodeToRemove);
  };

  const saveModel = () => {
    console.log(schema);
  };

  const addNewNode = () => {
    var coors = [];

    if (schema.nodes.length == 0) {
      coors = [500, 100];
    } else {
      coors = [
        schema.nodes[schema.nodes.length - 1].coordinates[0],
        schema.nodes[schema.nodes.length - 1].coordinates[1] + 200,
      ];
    }

    const nextNode = {
      id: `node-${schema.nodes.length + 1}`,
      content: `Node ${schema.nodes.length + 1}`,
      coordinates: [coors[0], coors[1]],
      render: CustomRender,
      data: { onClick: deleteNodeFromSchema, type: 'Say' },
      inputs: [{ id: `port-${Math.random()}` }],
      outputs: [{ id: `port-${Math.random()}` }],
    };
    addNode(nextNode);
  };

  useEffect(() => {
    console.log('effect');
    return () => {
      //cleanup
    };
  }, [schema]);

  return (
    <div className="flex h-full flex-grow bg-scroll w-full">
      <div className="w-10/12 h-full w-full overflow-scroll">
        <div
          style={{ height: '4000px', width: '4000px' }}
          className="w-full bg-opacity-0 rounded-xl overflow-x-scroll overflow-y-scroll scroll"
        >
          <GridLines
            className="grid-area h-full w-full bg-gray-50"
            lineColor={'black'}
            cellWidth={80}
            strokeWidth={2}
          >
            <Diagram
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
              schema={schema}
              onChange={onChange}
              className="h-full w-full  bg-opacity-75"
            />
          </GridLines>
        </div>
      </div>
      <div className="flex flex-col h-full flex-shrink rounded-md  bg-white justify-between mr-3 w-2/12">
        <div className=" border shadow-md ">
          <button
            className="bg-white border-b border-gray-500 w-full p-2 rounded-sm  hover:bg-gray-300"
            onClick={addNewNode}
          >
            Add new node
          </button>
          <button
            className="bg-white border-b border-gray-500 w-full p-2 rounded-sm  hover:bg-gray-300"
            onClick={addNewNode}
          >
            Add new node
          </button>
          <button
            className="bg-white border-b border-gray-500 w-full p-2 rounded-sm  hover:bg-gray-300"
            onClick={addNewNode}
          >
            Add new node
          </button>
          <button
            className="bg-white border-b border-gray-500 w-full p-2 rounded-sm  hover:bg-gray-300"
            onClick={addNewNode}
          >
            Add new node
          </button>
        </div>
        <div className="p-1">
          <button
            className="bg-white border border-gray-500 bg-red-500 w-full p-2 rounded-md my-1   "
            onClick={saveModel}
          >
            Delete Model
          </button>
          <button
            className="bg-white border border-gray-500 bg-green-400 w-full p-2 rounded-md my-1 "
            onClick={saveModel}
          >
            Save Model
          </button>
        </div>
      </div>
    </div>
  );
};
export default UncontrolledDiagram;
