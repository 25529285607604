import { useParams } from 'react-router-dom';
import axiosInstance from '../../axios';

import toast from 'react-hot-toast'

const ActionsList = () =>{
  const { id } = useParams();

  const edadFromRut = async () =>{
    await axiosInstance.post(`listas/${id}/get_edad/`).then(res=>{
      toast.success('Listo')
    })
  }
  const genero = async () =>{
    await axiosInstance.post(`listas/${id}/get_genero/`).then(res=>{
      toast.success('Listo')
    })
  }

  

  return (
    <div className="w-full flex flex-wrap items-start mt-4 gap-y-3 gap-x-3">
      <div className="w-1/3 rounded-md border bg-white h-32 flex flex-wrap p-2  content-between">
          <p className="w-full text-xl font-semibold text-gray-700">Obtener Edad por Rut</p>
          {/* <p className="w-full text-lg font-semibold text-gray-700">Obtener Edad por Rut</p> */}
          <button className="px-2 py-1 rounded-md bg-blue-semi text-white font-semibold cursor-pointer transform hover:scale-110" onClick={()=>edadFromRut()}>Enviar</button>
      </div>  

      <div className="w-1/3 rounded-md border bg-white h-32 flex flex-wrap p-2  content-between">
          <p className="w-full text-xl font-semibold text-gray-700">Obtener Genero</p>
          {/* <p className="w-full text-lg font-semibold text-gray-700">Obtener Edad por Rut</p> */}
          <button className="px-2 py-1 rounded-md bg-blue-semi text-white font-semibold cursor-pointer transform hover:scale-110" onClick={()=>genero()}>Enviar</button>
      </div>

    </div>
  )
}

export default ActionsList;
