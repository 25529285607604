import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../axios';

import { useHistory, useLocation } from 'react-router-dom';

import { Device } from 'twilio-client';

import { useSelector, useDispatch } from 'react-redux';
import { setDevice } from '../redux/actions/deviceActions';
import { setState } from '../redux/actions/stateActions';
import { setDir, setLlamada, setModall } from '../redux/actions/llamadaActions';

import states from './twilio/states';
import toast, { Toaster } from 'react-hot-toast';

import SideBar from './SideBar'
import SideBar2 from './SideBar2'
import Nav from './Nav';
import Nav2 from './Nav2';
import PhoneModal from './twilio/PhoneModal';
import FinLlamada from './Modals/FinLlamada';

function Layout(props) {
  if (localStorage.getItem('user') == undefined) {
    window.location.href = '/login/';
  }
  //const [state, setState] = useState(states.CONNECTING);
  const history = useHistory();
  const location = useLocation();
  const [conn, setConn] = useState(null);
  const [llamada, setLlamada] = useState(null);

  const [respuestas, setRespuestas] = useState([]);
  const [animos, setAnimos] = useState([]);
  const [mandantes, setMandantes] = useState([]);

  const [modal, setModal] = useState(false);
  const [sound, setSound] = useState(true);
  const [mic, setMic] = useState(true);
  const [token, setToken] = useState(null);
  const [cl, setCl] = useState(null);

  const device = useSelector((state) => state.device);
  const data = useSelector((state) => state.llamada);
  const state = useSelector((state) => state.state);

  const dispatch = useDispatch();

  var user = JSON.parse(localStorage.getItem('user'));

  const changeUserStatus = (status) => {
    //1 Ready ; 2 Offline ; 3 On_call
    axiosInstance.patch(`users/${user.id}/`, { call_status: status });
  };
  const getRespuestas = async () => {
    await axiosInstance.get(`trespuestas/?status=1`).then((res) => {
    // await axiosInstance.get(`users/${user.id}/get_respuestas`).then((res) => {
      setRespuestas(res.data);
      console.log(res.data);
    });
  };
  const getAnimos = async () => {
    await axiosInstance.get(`animos/`).then((res) => {
      setAnimos(res.data);
    });
  };
  const getMandantes = async () => {
    await axiosInstance.get(`mandantes/`).then((res) => {
      setMandantes(res.data);
    });
  };

  const getToken = async () => {
    await axiosInstance
      .get(
        `http://localhost:8000/api/twilio/get_token?user=${user.id}&token=${localStorage.getItem(
          'access_token',
        )}`,
      )
      //await axios.get(`http://localhost:8000/twilio/get_token?user=${user.id}&token=${localStorage.getItem('access_token')}`)
      .then((response) => {
        console.log(response.data);
        localStorage.setItem('twilio_token', response.data.token);
        setToken(response.data.token);
        setDev(response.data.token)
      });
  };

  function expi(token) {
    try {
      const tokenParts = JSON.parse(atob(token.split('.')[1]));
      //const { exp } = decode(token);
      //console.log(tokenParts.exp)

      if (tokenParts.exp < (new Date().getTime() + 1) / 1000) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  const setDev = (tk) => {
    const dev = new Device();

    dev.setup(tk, { debug: true });

    dev.on('ready', () => {
      dispatch(setState(states.READY));
      changeUserStatus('Ready');
    });
    dev.on('connect', (connection) => {
      setConn(connection);
      changeUserStatus('Connecting');
      // connection.on('disconnect',connection=>{
      // console.log('#######################disconect################')
      // let sid = connection.parameters['CallSid']
      // var cl = ''
      // setCall(sid)
      //  console.log('#############')
      //  console.log(cl)
      //  dispatch(setLlamada(cl))
      //
      // })

      dispatch(setState(states.ON_CALL));
    });

    dev.on('disconnect', (connection) => {
      dispatch(setState(states.READY));
      // console.log('##################SDASDASD####');

      // var sid = connection.parameters['CallSid'];
      // axiosInstance.get(`llamadas?call_sid=${sid}`).then((res) => {
        // console.log(res.data.results[0]);
        // console.log(data.llamada == '');
        // if (data.llamada == '') {
          // setCl(res.data.results[0]);
        // }
      // });
      changeUserStatus('Ready');
      setConn(null);
    });
    dev.on('incoming', (connection) => {
      changeUserStatus(3);
      dispatch(setState(states.INCOMING));
      dispatch(setDir('in'));
      setConn(connection);

      //console.log(connection.customParameters)
      connection.on('reject', () => {
        dispatch(setState(states.READY));
        changeUserStatus(1);
        setConn(null);
      });
      connection.on('accept', () => {
        changeUserStatus(3);
        dispatch(setState(states.ON_CALL));
      });
    });
    dev.on('cancel', (connection) => {
      dispatch(setState(states.READY));
      changeUserStatus('Ready');
      console.log('cancel');

      setTimeout(() => {
        setModal(true);
      }, 2000);
      setConn(null);
    });
    dev.on('reject', () => {
      dispatch(setState(states.READY));
      changeUserStatus('Ready');
      setConn(null);
    });
    dev.on('offline', () => {
      //setState(states.READY);

      dispatch(setState(states.OFFLINE));
      changeUserStatus('Offline');
    });

    dispatch(setDevice(dev));
  };


  useEffect(() => {
    getMandantes();
    getRespuestas();
    getAnimos();
    dispatch(setModall(setModal));
    // var tt = localStorage.getItem('twilio_token')
    // getToken()
  }, []);


  return (
    <>
      <Toaster></Toaster>
      <div className="flex flex-col w-screen h-screen">
        <Nav
          state={state}
          setDev={setDev}
          mandantes={mandantes}
          tipos_respuestas={respuestas}
          setToken={setToken}
          getToken={getToken}
        ></Nav>
        {/* <Nav2 */}
        {/*   state={state} */}
        {/*   setDev={setDev} */}
        {/*   mandantes={mandantes} */}
        {/*   tipos_respuestas={respuestas} */}
        {/*   setToken={setToken} */}
        {/*   getToken={getToken} */}
        {/* ></Nav2> */}

        <div  className="flex flex-grow w-full overflow-hidden bg-blue-normal">
          {/* <SideBar></SideBar> */}
          <SideBar2></SideBar2>
          <div className="w-full overflow-y-scroll rounded-tl-md bg-gray-100">{props.children}</div>

          {modal === true && (
            <FinLlamada
              mandantes={mandantes}
              animos={animos}
              tipos_respuestas={respuestas}
              cl={cl}
              setCl={setCl}
              setModal={setModal}
            ></FinLlamada>
          )}
          {/*resp === true && <FinLlamada setResp={setResp}></FinLlamada>*/}
          {state.state === 'On call' && <PhoneModal conn={conn}></PhoneModal>}
          {state.state === 'Incoming' && <PhoneModal conn={conn}></PhoneModal>}
          {state.state === 'Connecting' && <PhoneModal conn={conn}></PhoneModal>}
        </div>
      </div>
    </>
  );
}

export default Layout;
