import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Filtro from '../Filtro';
import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';

import Select from 'react-select';
import { CommentsDollar } from 'styled-icons/fa-solid';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Filters = ({ pagination }) => {
  const [agentes, setAgentes] = useState([]);
  const [estados, setEstados] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [mandantes, setMandantes] = useState([]);
  const [camps, setCamps] = useState([]);
  const [query, setQuery] = useState('?');
  //const query = queryString.parse(location.search);
  let Q = useQuery();

  const optionsAgentes =
    agentes &&
    agentes.map((agente) => {
      return { value: agente.id, label: `${agente.first_name} ${agente.last_name}` };
    });

  const optionsStatus = [
    { value: 1, label: 'realizada', color: '#A7F3D0' },
    { value: 2, label: 'pendiente', color: '#FDE68A' },
    { value: 3, label: 'rellamada', color: '#BFDBFE' },
    { value: 4, label: 'inactiva', color: '#E5E7EB' },
    { value: 5, label: 'recibida', color: '#FCA5A5' },
    { value: 6, label: 'terminada', color: '#FCA5A9' },
  ];
  const camps_status = [
    { value: 1, label: 'activa' },
    { value: 2, label: 'Inactiva' },
  ];

  const filtrar = () => {
    window.location.href = `${window.location.pathname}?${query}`;
  };
  const limpiar = () => {
    window.location.href = `${window.location.pathname}`;
  };

  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };
  const getEstados = async () => {
    await axiosInstance.get('estados_cuenta/').then((res) => {
      setEstados(
        res.data.map((man) => {
          return { value: man.nombre, label: man.nombre };
        }),
      );
    });
  };

  const handleSelectStatus = (e) => {
    if (e.length > 0) {
      setQuery(
        `${query}&status__in=${e
          .map((mandante) => {
            return mandante.value;
          })
          .join()}`,
      );
    } else {
      setQuery(`${query}&status_in=`);
    }
    //console.log(query)
  };
  const handleSelectAgente = (e) => {
    if (e.length > 0) {
      setQuery(
        `${query}&agente__in=${e
          .map((mandante) => {
            return mandante.value;
          })
          .join()}`,
      );
    } else {
      setQuery(`${query}&agente__in=`);
    }
    //console.log(query)
  };
  const handleSelectMandante = (e) => {
    if (e.length > 0) {
      setQuery(
        `${query}&contacto__mandante__in=${e
          .map((mandante) => {
            return mandante.value;
          })
          .join()}`,
      );
    } else {
      setQuery(`${query}&contacto__mandante__in=`);
    }
    //console.log(query)
  };
  const handleSelectCamp = (e) => {
    if (e.length > 0) {
      setQuery(
        `${query}&campaign__in=${e
          .map((mandante) => {
            return mandante.value;
          })
          .join()}`,
      );
    } else {
      setQuery(`${query}&campaign__in=`);
    }
    //console.log(query)
  };
  const getMandantes = async () => {
    await axiosInstance.get('mandantes?is_active=true').then((res) => {
      setMandantes(
        res.data.map((man) => {
          return { value: man.codigo, label: man.nombre };
        }),
      );
    });
  };
  const getCamps = async () => {
    await axiosInstance.get('campaigns/').then((res) => {
      setCamps(
        res.data.map((camp) => {
          return { value: camp.id, label: camp.nombre };
        }),
      );

      console.log(res.data);
    });
  };
  const getAgentes = async () => {
    axiosInstance.get('users?is_active=true').then((res) => {
      if (res.data) {
        setAgentes(res.data);
      }
    });
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`);
    }
    //console.log(query)
  };

  useEffect(() => {
    getMandantes();
    getAgentes();
    getCamps();
    getEstados();
    setQuery(Q.toString().replace(/%2C/gi, ','));

    console.log(getQueryValue(query, 'page_size'));
    //console.log(Q.toString().replace('%2C',','))
  }, []);

  return (
    <Filtro
      pagination={pagination}
      filtrar={filtrar}
      handleChange={handleChange}
      limpiar={limpiar}
      page_size={
        getQueryValue(query, 'page_size') !== null
          ? getQueryValue(query, 'page_size')
          : pagination.page_size
      }
      nombre="Llamadas"
    >
      <div classname="w-auto h-auto border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-10">
          numero cuenta
        </label>
        <textarea
          name="contacto__nro_cuenta__in"
          value={getQueryValue(query, 'contacto__nro_cuenta__in')}
          onChange={handleChange}
          type="text"
          className=" w-36  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div classname="w-auto h-auto border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-10">
          Rut
        </label>
        <textarea
          name="contacto__rut__in"
          value={getQueryValue(query, 'contacto__rut__in')}
          onChange={handleChange}
          type="text"
          className=" w-36  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="select-filter">
        <label>Agentes</label>
        <Select
          onChange={handleSelect}
          name="agente__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsAgentes.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'agente__in', optionsAgentes)}
          options={optionsAgentes}
        />
      </div>
      <div className="select-filter">
        <label>Mandantes</label>
        <Select
          onChange={handleSelectMandante}
          name="contacto__mandante__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            mandantes.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'contacto__mandante__in', mandantes)}
          options={mandantes}
        />
      </div>
      <div className="select-filter">
        <label>Estados</label>
        <Select
          onChange={handleSelect}
          name="contacto__estado__in"
          className="w-full"
          closeMenuOnSelect={false}
          isMulti
          getOptionLabel={(option) =>
            estados.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'contacto__estado__in', estados)}
          options={estados}
        />
      </div>

      <div className="select-filter">
        <label>Status Campaña</label>
        <Select
          onChange={handleSelect}
          name="campaign__status__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            camps_status.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'campaign__status__in', camps_status)}
          options={camps_status}
        />
      </div>

      <div className="flex flex-wrap content-start w-2/12 h-auto ">
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Campaña
        </label>
        <Select
          onChange={handleSelect}
          name="campaign__in"
          className="w-full"
          closeMenuOnSelect={false}
          isMulti
          getOptionLabel={(option) =>
            camps.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'campaign__in', camps)}
          options={camps}
        />
      </div>
      <div className="flex flex-wrap content-start w-2/12 h-auto ">
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Status
        </label>
        <Select
          onChange={handleSelect}
          name="status__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsStatus.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'status__in', optionsStatus)}
          options={optionsStatus}
        />
      </div>

      {/*<div className="w-1/12 h-full border-b ">
                    <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2" >Rut</label>
                    <input name="contacto__rut__in" value={getQueryValue(query,'contacto__rut__in')} onChange={handleChange} type="text" className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"></input>
            </div>*/}
      {/*<div className="flex flex-wrap h-auto border-b content-start justify-end gap-y-1">
                <button onClick={()=>window.location.href = `${window.location.pathname}?${query}`} className="bg-blue-ligth h-10 text-white rounded-lg p-1">Filtrar</button>
                <select name="page_size" onChange={handleChange} value={getQueryValue(query,'page_size')} type="number" className="w-auto h-auto text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
                        <option key={1} value={100} label={'100'}></option>
                        <option key={2} value={500} label={'500'}></option>
                        <option key={3} value={1000} label={'1000'}></option>
                        <option key={4} value={5000} label={'5000'}></option>
                        <option key={5} value={10000} label={'10000'}></option>
                        <option key={5} value={10000} label={'20000'}></option>
                      </select>

          </div>*/}
    </Filtro>
  );
};

export default Filters;
