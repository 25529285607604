import React, { useEffect, useState } from 'react';

import axiosIntance from '../../../axios';
import { CSVLink } from 'react-csv';

import { FileExcel } from '@styled-icons/fa-solid/FileExcel';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { FileCsv } from '@styled-icons/fa-solid/FileCsv';
import { Save } from '@styled-icons/boxicons-regular/Save';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = ({ cols }) => {
  return (
    <ExcelFile
      element={
        <FileExcel size="32" className="text-green-600 cursor-pointer transform hover:scale-125" />
      }
    >
      <ExcelSheet data={[]} name="">
        {cols &&
          cols.length > 0 &&
          cols.map((col) => {
            return <ExcelColumn label={col} value={col} />;
          })}
      </ExcelSheet>
    </ExcelFile>
  );
};

const Plantillas = ({ setChange }) => {
  const [nombrePlantilla, setNombrePlantilla] = useState('');
  const [fields, setFields] = useState([]);
  const [selectedFields, setSFields] = useState(['nro_cuenta']);
  const [csv, setCsv] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);

  const getFields = async () => {
    await axiosIntance.get('contactos/fields').then((res) => {
      setFields(res.data.filter((x) => x !== 'nro_cuenta').concat(['numeros', 'emails']));
    });
  };

  const selectField = (f) => {
    setSFields([...selectedFields, f]);
    setFields(fields.filter((x) => x !== f));
  };

  const unselectField = (f) => {
    setFields([...fields, f]);
    setSFields(selectedFields.filter((x) => x !== f));
  };

  const getCSV = () => {
    return [selectedFields];
  };

  const createPlantilla = () => {
    axiosIntance
      .post('plantillas/', { nombre: nombrePlantilla, fields: selectedFields.join(',') })
      .then((res) => {
        setChange(new Date());
      });
  };

  const handleChangePlantilla = (e) => {
    setNombrePlantilla(e.target.value);
  };

  useEffect(() => {
    getFields();
    return () => {};
  }, []);
  return (
    <div className="h-full w-full flex ">
      <div className="w-full h-4/5 flex flex-wrap border">
        <div className="h-20 w-full py-1 px-4 flex justify-center flex-wrap">
          <input
            name="nombre"
            onChange={handleChangePlantilla}
            className="w-40 text-lg text-center font-bold  rounded-lg border-blue-ligth border-4 text-purple-700"
            placeholder="Nombre Plantilla"
          ></input>
          <table className="w-full border border-gray-dark">
            <thead className="h-8">
              {selectField.length > 0 &&
                selectedFields.map((field) => {
                  return (
                    <th
                      onClick={() => unselectField(field)}
                      className="cursor-pointer border border-gray-dark h-8 hover:bg-red-600"
                    >
                      {field}
                    </th>
                  );
                })}

              <th className="border border-gray-dark h-8 w-10">
                <CSVLink filename="CargaDatos.csv" data={getCSV()}>
                  <FileCsv
                    size="32"
                    className="text-green-600 cursor-pointer transform hover:scale-125"
                  />
                </CSVLink>
              </th>
              <th className="border border-gray-dark h-8 w-10">
                <Download cols={selectedFields}></Download>
              </th>
              <th className="border border-gray-dark h-8 w-10">
                <Save
                  onClick={() => createPlantilla()}
                  className="transform hover:scale-125 cursor-pointer text-blue-700"
                ></Save>
              </th>
            </thead>
          </table>
        </div>

        <div className="w-full h-4/5 bg-gray-200 rounded-lg flex">
          <div className="w-2/12 border-r border-gray-dark flex justify-center">
            <p className="my-auto text-lg font-bold text-gray-dark">Campos</p>
          </div>
          <div className="w-10/12 flex flex-wrap overflow-y-scroll scroll gap-2 p-1">
            {fields &&
              fields.length > 0 &&
              fields.map((field) => {
                return (
                  <button
                    onClick={() => selectField(field)}
                    className="w-32 h-10 bg-gray-dark font-semibold text-white rounded-lg flex justify-center hover:bg-gray-normal"
                  >
                    <p className="my-auto overflow-hidden">{field}</p>
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plantillas;
