import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';
import format from '../../utils/formatDate';
import { getLlamadaStatusP } from '../../utils/models';
import { useSelector, useDispatch } from 'react-redux';

import toast from 'react-hot-toast';

import { Check } from '@styled-icons/boxicons-regular/Check';
import { XCircle } from '@styled-icons/bootstrap/XCircle';
import { Circle } from '@styled-icons/boxicons-regular/Circle';

import { ArrowBack } from '@styled-icons/evaicons-solid/ArrowBack';

const Respuesta = ({ setModal, tresp, back, setSelectGrupo, respuestas }) => {
  const llamada = useSelector((state) => state.llamada);

  var user = JSON.parse(localStorage.getItem('user'));
  const [agendada, setAgendada] = useState(false);
  const [move, setMove] = useState(false);
  const [guardada, setGuardada] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [nextFecha, setNextFecha] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    llamada: null,
    tipo: null,
    agente: user.id,
    observacion: null,
  });
  const [dataA, setDataA] = useState({
    agente: user.id,
    fecha_asignada: null,
    contacto: llamada.llamada.contacto.id,
    status: 3,
    campaign: llamada.llamada.campaign,
  });

  var user = JSON.parse(localStorage.getItem('user'));
  const [sel, setSel] = useState(1);
  //const [respuestas, setRespuestas] = useState([]);
  const [grupo, setGrupo] = useState(1);
  const [prox, setProx] = useState([]);

  const verify = (v) => {
    axiosInstance.patch(`numeros/${llamada.numero.id}/`, { verified: v }).then((res) => {
      //console.log(res)
    });
  };

  const getProximasLlamadas = () => {
    axiosInstance.get(`llamadas?contacto_id=${llamada.contacto.id}&status__in=2,3`).then((res) => {
      setProx(res.data.results.filter((x) => x.id !== llamada.llamada.id));
    });
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeA = (e) => {
    setDataA({
      ...dataA,
      [e.target.name]: e.target.value,
    });
  };

  const sendRespuesta = (e) => {
    e.preventDefault();
    //console.log(respuestas[0].id)
    console.log(data);
    //console.log(llamada.llamada)

    if (guardada === false && loading === false) {
      setLoading(true);
      var toastId = toast.loading('guardando respuesta');
      axiosInstance
        .post('respuestas/', {
          ...data,
          llamada: llamada.llamada.id,
          numero: llamada.numero.id,
          contacto: llamada.llamada.contacto.id,
        })
        .then((res) => {
          //respuestas.filter(x=>x.id === data.tipo)[0]
          //console.log(res)
          //if(respuestas.filter(x=>x.id == data.tipo)[0].set_realizada){
          if (res.data['move'] === true) {
            //llamada.set_realizada(llamada.llamada.id)
            // show modal esta llamada pasara a realizada desea agendar otra llamada
            //setMove(true)
          }
          if (res.data['moves'].length > 0) {
            console.log(res.data['moves']);
            llamada.set_realizada(res.data['moves']);
          }
          //console.log(res.data['moves'])
          if (res.data['next_status']) {
            setNextStatus(res.data['next_status']);
          }
          if (res.data['next_fecha']) {
            setNextFecha(new Date(res.data['next_fecha']));
          }
          setGuardada(true);
          setLoading(false);
          toast.dismiss(toastId);
          toast.success('Respuesta Guardada', { duration: 4000 });
          //.set_realizada &&
        })
        .catch(() => {
          toast.dismiss(toastId);
          toast.error('Error', { duration: 4000 });
        });
    }
    //if(llamada.type === 'ext'){
    //    axiosInstance
    //        .patch('llamadas/'+llamada.llamada.id+'/',{date:getDateTime(),status:1,"numero_to":llamada.numero.numero})
    //}
    //setModal(false)
  };
  const agendar = () => {
    //console.log(dataA)
    if (dataA['fecha_asignada'] !== null) {
      axiosInstance.post('llamadas/', { ...dataA }).then((res) => {
        if (res !== undefined && res.status == 201) {
          setAgendada(true);
        }
      });
    }
  };

  const statusNum = (s) => {
    //console.log(s)
    //post to nums
  };
  const close = () => {
    setModal(false);
  };

  const getDateTime = () => {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;
    return dateTime;
  };

  useEffect(() => {
    // getProximasLlamadas();
    respuestas.length > 0 && setData({ ...data, tipo: respuestas[0].id });
  }, [grupo]);

  return (
    <div className="w-full h-full flex flex-wrap ">
      <div className="flex flex-wrap content-start h-2/6 w-full  px-1 pt-3">
        <div className="flex w-full justify-center bg-blue-semi text-white font-bold text-2xl">
          <h1 className="w-1/12">
            <ArrowBack
              onClick={() => {
                back(true);
              }}
              className="h-7 ml-2 text-white transform hover:scale-125"
            ></ArrowBack>
          </h1>
          <h1 className="w-11/12">Contacto</h1>
        </div>
        <div className="flex w-1/2 h-10 items-center p-1 border ">
          <h1 className="w-1/2 font-bold">Nombre:</h1>
          <h1 className="w-1/2">{llamada.contacto.nombre}</h1>
        </div>
        <div className="flex w-1/2 h-10 items-center p-1 border ">
          <h1 className="w-1/2 font-bold">Apellido:</h1>
          <h1 className="w-1/2">{llamada.contacto.apellido}</h1>
        </div>
        <div className="flex w-1/2 h-10 items-center p-1 border ">
          <h1 className="w-1/2 font-bold">Mandante:</h1>
          <h1 className="w-1/2">{llamada.contacto.mandante}</h1>
        </div>
        <div className="flex w-1/2 h-10 items-center p-1 border ">
          <h1 className="w-1/2 font-bold">Numero:</h1>
          <h1 className="w-1/2">{llamada.numero.numero}</h1>
        </div>
        <div className="flex w-full justify-center bg-blue-semi text-white font-bold text-2xl">
          <h1>Agente</h1>
        </div>
        <div className="flex w-1/2 h-10 items-center p-1 border ">
          <h1 className="w-1/2 font-bold">Nombre:</h1>
          <h1 className="w-1/2">{user && user.first_name}</h1>
        </div>
        <div className="flex w-1/2 h-10 items-center p-1 border ">
          <h1 className="w-1/2 font-bold">Apellido:</h1>
          <h1 className="w-1/2">{user && user.last_name}</h1>
        </div>
      </div>
      <div className="flex flex-wrap justify-start mt-5 pt-3 bg-white h-3/6 w-full px-1">
        <div className="flex w-full">
          {sel === 1 ? (
            <div className="flex justify-center w-1/3 h-10 bg-blue-semi rounded-t-lg">
              <h1 className="text-2xl text-white">Insertar</h1>
            </div>
          ) : (
            <div
              onClick={() => setSel(1)}
              className="flex border border-blue-500 justify-center w-1/3 h-10 bg-white      rounded-t-lg border-b border-black hover:bg-gray-200 cursor-pointer"
            >
              <h1 className="text-2xl ">Insertar</h1>
            </div>
          )}
          {/*sel === 2 ? <div  className="flex justify-center w-1/3 h-10 bg-blue-semi rounded-t-lg"><h1 className="text-2xl text-white">Agendar</h1></div> : <div onClick={()=>setSel(2)} className="flex border border-blue-500 justify-center w-1/3 h-10 bg-white     rounded-t-lg border-b border-black hover:bg-gray-200 cursor-pointer"><h1 className="text-2xl ">Agendar</h1></div>*/}
          {/*sel === 3 ? <div  className="flex justify-center w-1/3 h-10 bg-blue-semi rounded-t-lg"><h1 className="text-2xl text-white">Observaciones</h1></div> : <div onClick={()=>setSel(3)} className="flex justify-center w-1/3 h-10 bg-white rounded-t-lg border-b border-black hover:bg-gray-200"><h1 className="text-2xl ">Observaciones</h1></div>*/}
        </div>
        <div className=" w-full h-full  border-collapse ">
          {sel === 1 && (
            <form
              onSubmit={sendRespuesta}
              className="flex flex-wrap content-between pb-1 w-full h-full px-1"
            >
              <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-2">
                  <h1 className="w-full mb-1 font-bold">Respuesta</h1>
                  <select
                    className="w-5/6 h-full  border rounded-lg"
                    name="tipo"
                    onChange={handleChange}

                  >

                          <option value="-----">----------</option>;
                    {respuestas
                      ? respuestas.map((resp) => {
                          return <option value={resp.id}>{resp.nombre}</option>;
                        })
                      : ''}
                  </select>
                </label>
              </div>
              {tresp === 3 && (
                <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1">
                  <label className="w-11/12 h-1/2 ">
                    <h1 className="w-full mb-1 font-bold">Proxima Fecha</h1>
                    <input
                      type="date"
                      name="next_date"
                      onChange={handleChange}
                      className="w-full hidden"
                    ></input>
                  </label>
                </div>
              )}

              <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Llamador</h1>
                  <select
                    className="w-1/2 h-full appearance-none border-rounded-lg"
                    name="agente"
                    onChange={handleChange}
                  >
                    <option value={user.id}>{user.first_name + ' ' + user.last_name}</option>
                  </select>
                </label>
              </div>
              <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Numero</h1>
                  <div className="flex justify-around">
                    <div
                      onClick={() => verify(1)}
                      className="p-1 mb-1 rounded-full text-green-700 border border-green-700 hover:bg-green-700 hover:text-white"
                    >
                      <Check size="26" className="" />
                    </div>
                    <div
                      onClick={() => verify(2)}
                      className="p-1 mb-1 rounded-full text-gray-500 border border-gray-500 hover:bg-gray-500 hover:text-white"
                    >
                      <Circle size="26" className="" />
                    </div>
                    <div
                      onClick={() => verify(3)}
                      className="p-1 mb-1 rounded-full text-red-600 border border-red-600 hover:bg-red-600 hover:text-white"
                    >
                      <XCircle size="26" className="" />
                    </div>
                  </div>
                </label>
              </div>
              <div className="flex flex-wrap w-full h-32 bg-white place-self-end px-4">
                <label className="w-full h-full">
                  <h1 className="w-full mb-1 font-bold">Observacion</h1>
                  <textarea
                    type="text"
                    name="observacion"
                    onChange={handleChange}
                    className="bg-gray-200 w-full h-20 rounded-lg text-start"
                  ></textarea>
                </label>
              </div>

              <div className="flex justify-end w-full h-10 px-1 ">
                <div className="flex  w-full px-1 justify-around">
                  {guardada && (
                    <div className="flex flex-wrap w-1/5 mx-auto justify-center h-10 bg-green-500 place-self-end px-4">
                      <p className=" text-white  font-bold text-md xl:text-xl my-auto">Guardada</p>
                    </div>
                  )}
                  {nextStatus && (
                    <div className="flex flex-wrap w-2/5 mr-4 ml-4 justify-center h-full border border-blue-ligth place-self-end px-4 font-bold text-sm md:text-md lg:text-2xl xl:text-xl">
                      <p className="text-blue-ligth mr-1 my-auto">Status : </p>
                      {getLlamadaStatusP(nextStatus)}
                      <ArrowBack
                        size="30"
                        onClick={() => setSel(2)}
                        className="font-bold text-white my-auto transform hover:scale-125 rotate-180"
                      ></ArrowBack>
                    </div>
                  )}
                  {nextStatus !== 2 && nextStatus !== 6 && nextFecha && (
                    <div className="flex flex-wrap w-2/5 mx-auto justify-center h-10 bg-blue-ligth place-self-end px-4">
                      <p className=" text-white  font-bold text-md xl:text-xl my-auto">{`${(
                        '0' + nextFecha.getDate()
                      ).slice(-2)}-${('0' + (nextFecha.getMonth() + 1)).slice(-2)}   ${(
                        '0' + nextFecha.getHours()
                      ).slice(-2)}:${('0' + nextFecha.getMinutes()).slice(-2)}`}</p>
                    </div>
                  )}
                  {/*<div className="flex flex-wrap w-1/5 mr-4 ml-4 justify-center h-full bg-blue-normal place-self-end px-4">
                                 <p className=" text-gray-100  font-bold text-md xl:text-xl my-auto">Agendar</p>
                                <ArrowBack size="30" onClick={()=>setSel(2)} className="font-bold text-white my-auto transform hover:scale-125 rotate-180"></ArrowBack>   
                                    </div>*/}

                  {guardada === false && (
                    <button
                      type="submit"
                      className="w-24 hover:bg-green-600 border  bg-green-500 h-10 px-2 text-white font-bold rounded-lg"
                    >
                      Guardar
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
          {sel === 2 && (
            <div className="flex flex-wrap w-full h-full px-1">
              <div className="flex flex-wrap w-1/3 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Fecha</h1>
                  <input type="date" name="fecha_asignada" onChange={handleChangeA}></input>
                </label>
              </div>

              <div className="flex flex-wrap w-1/3 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Llamador</h1>
                  <select
                    className="w-1/2 h-full appearance-none border-rounded-lg"
                    name="llamador"
                    onChange={handleChangeA}
                  >
                    <option value={user.id}>{user.first_name + ' ' + user.last_name}</option>
                  </select>
                </label>
              </div>
              <div className="flex flex-wrap w-1/3 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Contacto</h1>
                  <h1 className="w-2/3 font-bold bg-gray-300 p-1">
                    {llamada.llamada.contacto.nombre + ' ' + llamada.llamada.contacto.apellido}
                  </h1>
                </label>
              </div>

              <div className="w-1/2 h-40 border flex flex-wrap bg-gray-100 rounded-lg overflow-hidden">
                <p className="h-8 text-xl font-bold w-full text-center border-b">
                  Proximas Llamadas
                </p>
                <div className="w-full h-32 overflow-y-scroll flex flex-wrap">
                  {prox &&
                    prox.length > 0 &&
                    prox.map((ll) => {
                      return (
                        <div className="w-full h-8 flex border-b">
                          <p className="my-auto w-1/2 text-center text-md ">
                            {format(ll.fecha_asignada)}
                          </p>
                          <p className="my-auto w-1/2 text-center text-md ">
                            {ll.agente.first_name} {ll.agente.last_name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="flex justify-end w-full px-1 border">
                {agendada && (
                  <div className="flex flex-wrap w-5/6 mx-auto justify-center h-10 rounded-md bg-green-400 place-self-end px-4">
                    <p className=" text-gray-100  font-bold text-xl my-auto">Llamada agendada</p>
                  </div>
                )}
                {loading === false && guardada === false && (
                  <button
                    onClick={() => {
                      loading === false && agendar();
                    }}
                    type="button"
                    className="w-24 hover:bg-green-600 border  bg-green-500 h-10 px-2 text-white font-bold rounded-lg"
                  >
                    Agendar
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Respuesta;
