import Bars from '../dashboardAdmin/graficos/Bars';
import Pies from '../dashboardAdmin/graficos/Pies';



const DataUser = ({data}) =>{


return (
  <div className="w-full mx-4 flex gap-x-2 mt-6">
    <div className="w-3/5  bg-white rounded-md border">
          <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Campañas</p></div>
          <div className="w-full h-96">
            {data && <Bars data={data.dias}></Bars>}
          </div> 
          
    </div>

    <div className="w-2/5  bg-white rounded-md border opacity-0">
          <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Campañas</p></div>
          <div className="w-full h-96 flex flex-wrap overflow-y-scroll">

            <div className="w-full">
            <table className="min-w-full divide-y divide-gray-300  table-fixed">
                <thead className="bg-gray-50">
                  <tr className="">
                    <th scope="col" className="px-1 py-2  text-left text-sm font-semibold text-gray-900 sm:pl-6 ">
    
                      Nombre
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      Enviados
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      Abiertos
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      Clicks
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      Rebotados
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {/* {data.map((camp) => ( */}
                    <tr className="border-b">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:text-blue-900">camp.nombre</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">1</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">2</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">3</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">4</td>
                    </tr>
                   {/* ))} */}
               </tbody>
              </table>
              </div>
            

          </div>
    </div>
  </div>
    )
}

export default DataUser;
