import {useState} from 'react'
import axiosInstance from '../../axios.js'

const NumerosTable = ({objs,getObjs}) =>{
  const [data,setData] = useState(null)
  const [edit,setEdit] = useState(false)

  const editar = (obj) =>{
    
    if(data !== null && obj.id !== data.id){
      setData(obj) 
      console.log('change')
    }else{
      if(edit === false){
        console.log('start edit')
        setData(obj)
        setEdit(true)
      }else{
        if(data !== obj){
          console.log('update')
          update(obj)
          setEdit(false)
        }else{
          setEdit(false)
          console.log('close')
        }
      }
    }
  }

  
  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }


  const update = async (obj) =>{
    await axiosInstance.put(`twilio/numeros/${obj.id}/`,data).then(res=>{
      getObjs()
      setData(null)
    })
  }
  

  const remove = async (obj) =>{
    await axiosInstance.delete(`twilio/numeros/${obj.id}/`).then(res=>{
      getObjs()
    })

  }

  return (
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
              >
                Nombre
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Numero
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Pais 
              </th>
              
              <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
              <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Remove</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {objs.map((obj) => (
              <tr key={obj.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-2 py-1" name="name" value={data['nombre']} onChange={handleChange}></input> 
                    : 
                    obj.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-2 py-1" name="numero" value={data['numero']} onChange={handleChange}></input> 
                    : 
                    obj.numero}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-2 py-1" name="pais" value={data['pais']} onChange={handleChange}></input> 
                    : 
                    obj.pais}
              </td>
              
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a onClick={()=>remove(obj)} className="text-red-600 hover:text-red-900 cursor-pointer">
                    Eliminar<span className="sr-only">, {obj.id}</span>
                  </a>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a onClick={()=>editar(obj)}  className={`${edit === true && data !== null && data.id === obj.id ? 'text-blue-super-ligth hover:text-blue-600' : 'text-blue-600 hover:text-blue-super-ligth'}  cursor-pointer`}>
                    Editar<span className="sr-only">, {obj.id}</span>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
          
  )
}
export default NumerosTable;
