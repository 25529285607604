import { combineReducers } from 'redux';
import { deviceReducer } from './deviceReducer';
import { stateReducer } from './stateReducer';
import { tokenReducer } from './tokenReducer';
import { llamadaReducer } from './llamadaReducer';

const reducers = combineReducers({
  device: deviceReducer,
  state: stateReducer,
  token: tokenReducer,
  llamada: llamadaReducer,
});

export default reducers;
