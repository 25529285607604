import React,{useEffect,useState} from 'react'
import axiosInstance from '../../../axios'
import format from '../../../utils/formatDate'
import toast from 'react-hot-toast'

    /* rut = models.CharField(max_length=15,null=True,blank=True)
    numero = models.CharField(max_length=50,null=True,blank=True)
    tipo = models.CharField(max_length=15,null=True,blank=True)
    fecha = models.DateField(null=True)
    monto = models.FloatField(null=True,blank=True)
    saldo = models.FloatField(null=True,blank=True)
    status = models.CharField(max_length=20,null=True,blank=True)
    numero_operacion = models.CharField(max_length=50,null=True,blank=True)
    mandante = models.CharField(max_length=50,null=True,blank=True) */

const Actions = ({ct}) => {
  const [tags,setTags] = useState([])
  const [tag,setTag] = useState(null)

    
  console.log(ct)
    const getTags = async () =>{
      await axiosInstance.get(`tags_contacto?agent_permission=true`).then((res) => {
      setTags(res.data)
    });
  }

  const addTag = async () =>{
    // console.log({
    //         contactos: [].append(ct.id),
    //         tag: tag.id,
    //       })
      if (tag !== null) {
        axiosInstance
          .post(`tags_contacto/add_tag_to_contacto/`, {
            contactos: [ct.id,],
            tag: tag.id,
          })
          .then((res) => {
            res.status == 200 &&
              toast.success(
                `Se agrego el tag: ${tag.nombre}.`,
                { duration: 3000 },
              );
          });
      }
  }

    useEffect(()=>{
        getTags()
    },[])

  return (
    <div className='w-full flex flex-wrap justify-around'>
      <div className="w-80 h-64 rounded-md border flex flex-wrap content-start gap-y-2 p-1">
        <p className="text-lg text-gray-700 text-center w-full">Agregar Tag</p>

        <div className="w-full h-40 border overflow-y-scroll flex flex-wrap content-start">
          {tags.map((tg) => {
                if (tag !== null && tag.id === tg.id) {
                  return (
                    <div
                      onClick={() => {
                        setTag(null);
                      }}
                      className="h-10 justify-start cursor-pointer w-full border-b bg-gray-200 flex"
                    >
                      <div
                    style={{ backgroundColor: tg.color }}
                      className="my-auto h-2 w-2 rounded-full mx-2"
                    ></div>
                      <p className="my-auto text-lg text-gray-500">{tg.nombre}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setTag(tg);
                      }}

                      className="h-10 flex justify-start cursor-pointer w-full border-b"
                    >
                <div
                    style={{ backgroundColor: tg.color }}
                      className="h-2 w-2 rounded-full my-auto mx-2"
                    ></div>
                      <p className="text-lg text-gray-500 my-auto">{tg.nombre}</p>
                    </div>
                  );
                }
              })}
        </div> 

        <button
              onClick={() => addTag()}
              className="rounded-lg mx-auto h-10 bg-blue-ligth px-3 py-1 text-gray-100 font-semibold transform hover:scale-105"
            >
              Enviar
        </button>
      </div>

        
    </div>
  )
}

export default Actions;
