import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast'

import InfoContacto from '../../components/contactos/InfoContacto';
import Nums from '../../components/contactos/Nums';
import Llamadas2 from '../../components/contactos/Llamadas2';
import Llamadas1 from '../../components/contactos/Llamadas1';
import Mails from '../../components/contactos/Mails';
import Emails from '../../components/contactos/Emails';
import Sms from '../../components/contactos/Sms';
// import Movs from '../../components/contactos/Movs';
import Movs from '../../components/contactos/Movs2.jsx';

import TagCt from '../../components/tags/Tag.jsx'

import Back from '../../components/utils/Back';

import { User } from '@styled-icons/boxicons-solid/User';
import {CaretDown} from '@styled-icons/boxicons-regular/CaretDown'

// const filters_to_text = (filter) =>{
//   let rows = 
//   var text = ''
//   for (const property in filter) {
//   text += `${property}: ${filter[property]}\n`;
//   
//   }
//   return text
// }

const DetalleContacto2 = () => {
  const [contacto, setContacto] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [change, setChange] = useState(false);
  const [page,setPage] = useState(6)
  const [load, setLoad] = useState(true);

  

  let history = useHistory();
  const { id } = useParams();

  const [mails,setMails] = useState([])
  const [calls,seCalls] = useState([])
  const [sms,setSms] = useState([])
  const [movs,setMovs] = useState([])
  const [resps,setResps] = useState([])
  const [llamadas,setLlamadas] = useState([])
  
  const getLlamadas = async () => {
    var tid = toast.loading('cargando llamadas')
    await axiosInstance.get(`llamadas?contacto=${contacto.id}&page_size=100`).then((res) => {
      setLlamadas(res.data.results.reverse());
      // setPag({
      //   next: res.data.next,
      //   'previous ': res.data.previous,
      //   count: res.data.count,
      // });
      console.log(res.data);
    });
    toast.remove(tid)
  };

  const getMails = async () => {
    var tid = toast.loading('cargando mails')
    await axiosInstance.get(`mails?contacto=${contacto.id}&page_size=100`).then((res) => {
      setMails(res.data.results);
    });
    toast.remove(tid)
  }
  const getMovs = async () => {
    var tid = toast.loading('cargando movimientos')
    await axiosInstance.get(`movs_contacto?contacto=${contacto.id}`).then((res) => {
      setMovs(res.data);
    });
    toast.remove(tid)
  };;

  const getSms = async () => {
    var tid = toast.loading('cargando sms')
    await axiosInstance.get(`sms?contacto=${contacto.id}&page_size=100`).then((res) => {
      setSms(res.data.results);
      console.log(res.data.results)
    });
    toast.remove(tid)
  };

  const getRespuestas= async () => {
    var tid = toast.loading('cargando respuestas')
    await axiosInstance.get(`respuestas?contacto=${contacto.id}&page_size=100`).then((res) => {
      setResps(res.data.results);
    });
    toast.remove(tid)
  };

  const getContacto = async () => {
    await axiosInstance
      .get(`contactos/` + id + '/')
      .then((res) => {
        if (res.data) {
          setContacto(res.data);
          console.log(res.data);
          setLoad(false);
          // setPage(1)
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          if (error.response.status == 404) {
            setNotFound(true);
            setLoad(false);
          }
        }
      });
  };
  useEffect(() => {
   if (contacto.id !== undefined) {
     if(page === 1){
        resps.length === 0 && getRespuestas();
     }else if(page === 2){
       mails.length === 0 && getMails()
     }else if(page === 3){
       sms.length === 0 && getSms()
     }else if(page === 5){
       calls.legth === 0 && getLlamadas()
     }else if(page === 4){
       movs.length === 0 && getMovs()
     }
    }
  }, [page]);

  useEffect(() => {
    console.log(id)
    getContacto();
  }, [id, change]);

  if (notFound == false) {
    return (
      <div className="w-full flex flex-col h-full">

        <div className="w-full h-20 border-b flex p-3 px-4 justify-between bg-white">
          <div className="w-1/2 flex justify-center items-center content-center ">
              {/* <Back></Back>  */}
            <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500 flex-grow-0 flex-shrink-0 mr-2">
              <span className="text-lg font-medium leading-none text-white">{contacto.nombre && contacto.nombre.charAt(0)}{ contacto.apellido && contacto?.apellido.charAt(0)}</span>
            </span>
            
            <div className="flex flex-wrap h-full flex-grow content-center">
              <p className="text-lg font-semibold w-full ">{contacto.nombre + ' ' + contacto.apellido}</p>
              <p className="text-md text-gray-400 w-full">{contacto.nro_cuenta}</p>

              <span className={`inline-flex items-center gap-x-1.5 rounded-full  px-2 py-0.5 text-xs font-medium ${contacto && contacto.is_active ? 'text-green-700 bg-green-100':'text-red-700 bg-red-100' }`}>
                <svg fill={contacto && contacto.is_active ? 'green' : 'red'} className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>
                {contacto && contacto.is_active ? 'activo' : 'inactivo'}
              </span>
            </div>

        
          </div>

          <div className="flex w-96  p-2 flex-wrap gap-2 h-full flex-grow justify-end overflow-y-scroll">
            {contacto && contacto.tags && contacto.tags.length > 0 &&
              contacto.tags.map((tag)=>{
                return <TagCt tag={tag}/>
              })
            }
          </div>

       </div>

      <div className="w-full flex-grow  flex flex-wrap">
        <div className="w-1/5 h-full border-r flex flex-wrap p-2 items-start content-start overflow-y-scroll bg-white">
          <p className="text-md  font-medium w-full mb-3"><CaretDown size="20" className="mr-1"/>Sobre este Contacto</p>
          <div className="w-full flex flex-wrap p-1 px-8 gap-y-1.5">
            {Object.keys(contacto).map((property)=>{
              if(typeof contacto[property] === "object"){
                return <></>
// return (Array.isArray(contacto[property]) ? null : <p className="w-full text-gray-700"><span className="text-gray-400 mr-1">{property}:   </span>{typeof contacto[property] === "boolean" ? (contacto[property] ? 'Si' : 'No') : contacto[property]}</p>)
              }else{
              return (Array.isArray(contacto[property]) ? null : <p className="w-full text-gray-700"><span className="text-gray-400 mr-1">{property}:   </span>{typeof contacto[property] === "boolean" ? (contacto[property] ? 'Si' : 'No') : contacto[property]}</p>)
              }
            })}

          </div>
        
        </div>
        <div className="w-3/4 bg-gray-100 h-full content-start flex flex-wrap p-4 mb-4">
            <div className="w-full flex justify-start gap-x-2">
            {/* <span onClick={()=>setPage(0)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 0 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>Todo</span> */}
            <span onClick={()=>setPage(6)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 6 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>Numeros</span>
            <span onClick={()=>setPage(1)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 1 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>Respuestas</span>
            <span onClick={()=>setPage(5)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 5 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>Llamadas</span>
            <span onClick={()=>setPage(2)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 2 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>Mails</span>
            <span onClick={()=>setPage(3)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 3 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>SMS</span>
            <span onClick={()=>setPage(4)} className={`py-1 h-8 px-4 text-md cursor-pointer ${page=== 4 ? 'bg-blue-ligth text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} rounded-full `}>Movimientos</span>
            </div> 
            <div className="w-full mt-12">
            {page === 1 && <Llamadas2 resps={resps} contacto={contacto} setChange={setChange}></Llamadas2>}
            {page === 2 && <Mails mails={mails} contacto={contacto} setChange={setChange}></Mails>}
            {page === 3 && <Sms sms={sms} contacto={contacto} setChange={setChange}></Sms>}
            {page === 4 && <Movs movs={movs} contacto={contacto} setChange={setChange}></Movs>}
            {page === 5 && <Llamadas1 objs={llamadas} contacto={contacto} setChange={setChange}></Llamadas1>}
            {page === 6 && <Nums contacto={contacto} setChange={setChange}></Nums>}
            {page === 6 && <Emails contacto={contacto} setChange={setChange}></Emails>}
            </div>
      </div>

      </div>
        
      </div>
    );
  } else {
    return (
      <div className="w-full h-full flex justify-center bg-gradient-to-b from-gray-400 to-gray-300">
        <div className="flex flex-wrap  content-start justify-center w-auto h-72 border mt-52 bg-white rounded-lg">
          <p className="text-8xl font-bold w-full text-center">404</p>
          <p className="text-6xl w-full text-center mt-10">No Encontrado</p>
          <button
            onClick={() => history.goBack()}
            className="mt-4 p-4 rounded-md text-white font-bold text-2xl bg-gradient-to-r from-black to-gray-900 transform hover:scale-110 hover:bg-gradient-to-r  hover:from-gray-900 hover:to-black"
          >
            Volver
          </button>
        </div>
      </div>
    );
  }
};

export default DetalleContacto2;
