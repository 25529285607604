import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast';
import Select from 'react-select';

import { PersonFill } from '@styled-icons/bootstrap/PersonFill';

const CustomOption = ({ innerProps, isDisabled }) =>
  // console.log(innerProps)
  !isDisabled ? (
    <div {...innerProps} className="flex items-center cursor-pointer p-2 border-b">
      {/*<img src={person.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />*/}
      <span className="ml-3 block truncate">person.name</span>
    </div>
  ) : null;

const formatOptionLabel = ({ value, label, picture }) => (
  <div className="flex items-center cursor-pointer hover:bg-gray-100 gap-x-2">
    {picture ? (
      <img src={picture} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
    ) : (
      <div className="shrink-0 inline-block h-6 w-6 rounded-full ring-2 ring-white bg-gray-300 flex justify-center items-center">
        <PersonFill size="20" className="text-gray-500" />
      </div>
    )}

    <div>{label}</div>
  </div>
);

const CreateModal = ({ open, setOpen, setChange }) => {
  const [listas, setListas] = useState([]);
  const [errors, setErrors] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [data, setData] = useState({
    nombre: null,
    status: 1,
    fecha_inicio: null,
    fecha_fin: null,
    lista: null,
    agentes: [],
  });

  const options =
    agentes &&
    agentes.map((agente) => {
      return {
        value: agente.id,
        label: `${agente.first_name} ${agente.last_name}`,
        picture: agente.picture,
      };
    });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['agentes']: e.map((agente) => {
          return agente.value;
        }),
      });
    }
  };

  const getListas = async () => {
    await axiosInstance.get(`listas/?status=1`).then((res) => {
      setListas(res.data);
    });
  };
  const getAgentes = async () => {
    await axiosInstance.get(`users/?is_active=true`).then((res) => {
      setAgentes(res.data);
    });
  };

  const create = () => {
    // console.log({ ...data, ['status']: toggle ? 1 : 2 });
    if (data['lista'] === null) {
      toast.error('elige una lista');
      return null;
    }
    if (data['nombre'] === null) {
      toast.error('elige un nombre');
      return null;
    }
    if (data['fecha_inicio'] === null) {
      toast.error('elige una fecha inicio');
      return null;
    }
    if (data['fecha_fin'] === null) {
      toast.error('elige una fecha fin');
      return null;
    }
    axiosInstance
      .post(`campaigns/`, data)
      .then((res) => {
        toast.success('Campaña creada');
        console.log(res.data);
        // setCamps([...camps, res.data]);
        setOpen(false);
        setChange(res.data);
      })
      .catch((err) => {
        console.log(err);
        var errs = [];
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (data['nombre'] == '') {
            console.log('error nombre');
            errs.push('nombre invalido');
            toast.error('nombre invalido');
          }
          if (data['fecha_inicio'] == null) {
            console.log('error fecha');
            errs.push('fecha inicio invalida');
            toast.error('fecha iniciio invalida');
          }
          if (data['fecha_fin'] == null) {
            console.log('error fecha');
            errs.push('fecha fin invalida');
            toast.error('fecha fin invalida');
          }
        } else {
          if (
            err.response !== undefined &&
            'data' in err.response &&
            'non_field_errors' in err.response.data
          ) {
            errs.push(err.response.data.non_field_errors);
          }
        }
        setErrors(errs);
      });
  };

  useEffect(() => {
    getListas();
    getAgentes();
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Nueva Campaña de Llamadas
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Empieza rellenando la informacion para tu nueva Campaña.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Nombre */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="nombre"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Nombre
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="nombre"
                              onChange={handleChange}
                              id="nombre"
                              className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        {/* Fechas */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="nombre"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Fechas
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <span className="text-gray-800 px-2">Inicio</span>
                            <input
                              type="date"
                              id="start"
                              name="fecha_inicio"
                              onChange={handleChange}
                              className="block  px-2 w-4/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            ></input>

                            <span className="text-gray-800 px-2 mt-4">Fin</span>
                            <input
                              type="date"
                              id="end"
                              name="fecha_fin"
                              onChange={handleChange}
                              className="block px-2 w-4/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            ></input>
                          </div>
                        </div>

                        {/* Lista */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="lista"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Lista
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                              name="lista"
                              onChange={handleChange}
                              className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            >
                              <option className="bg-white text-gray-300" key={0} value={null}>
                                ------------------
                              </option>
                              {listas &&
                                listas.map((lista) => {
                                  return (
                                    <option className="bg-white" key={lista.id} value={lista.id}>
                                      {lista.nombre}{' '}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        {/* Team members */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <h3 className="text-sm font-medium leading-6 text-gray-900">Agentes</h3>
                          </div>
                          <div className="sm:col-span-2">
                            <div className="flex space-x-2">
                              <Select
                                formatOptionLabel={formatOptionLabel}
                                closeMenuOnSelect={false}
                                onChange={handleSelect}
                                className="w-full"
                                isMulti
                                options={options}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Privacy */}
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => create()}
                          className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                          Crear
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CreateModal;
