import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';

import Bars from './graficos2/Bars';
import Pies from './graficos2/Pies';

import RAgents from './graficos2/RAgents';
import Efectividad from './graficos2/Efectividad';
import LAgents from './graficos2/LAgents';
import Statistics from './Statistics.jsx'

const Resumen = ({ camp, id}) => {
  const [data,setData] = useState(null)

  const getData = async () => {
    await axiosInstance.get(`campaigns/${id}/llamadas/`).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getData()
  }, []);

  return (
    <>
      
        
        <div className="w-full h-24 border rounded-md my-3 mx-2 flex divide-x-2 bg-white">
          <div className="w-1/5 h-full flex flex-wrap gap-y-1 p-1">
            <p className="w-full text-lg text-center font-semibold text-gray-700">Contacto Directo</p>
            <p className="w-full text-center text-xl font-semibold text-gray-900">{data && data.stats.contactos_con_contacto_directo.count} </p>
            <p className="w-full text-center text-md font-semibold text-blue-500 pl-3">{data && data.stats.contactos_con_contacto_directo.perc_lista}%</p>
          </div>
        <div className="w-1/5 h-full flex flex-wrap gap-y-1 p-1">
            <p className="w-full text-lg text-center font-semibold text-gray-700">Contacto Indirecto</p>
            <p className="w-full text-center text-xl font-semibold text-gray-900">{data && data.stats.contactos_con_contacto_indirecto.count} </p>
            <p className="w-full text-center text-md font-semibold text-blue-500 pl-3">{data && data.stats.contactos_con_contacto_indirecto.perc_lista}%</p>

          </div>
        <div className="w-1/5 h-full flex flex-wrap gap-y-1 p-1">
            <p className="w-full text-lg text-center font-semibold text-gray-700">Sin Contacto</p>
            <p className="w-full text-center text-xl font-semibold text-gray-900">{data && data.stats.contactos_sin_contacto.count} </p>
            <p className="w-full text-center text-md font-semibold text-blue-500 pl-3">{data && data.stats.contactos_sin_contacto.perc_lista}%</p>
        </div>
        <div className="w-1/5 h-full flex flex-wrap gap-y-1 p-1">
            <p className="w-full text-lg text-center font-semibold text-gray-700">Sin Gestion</p>
            <p className="w-full text-center text-xl font-semibold text-gray-900">{data && data.stats.contactos_sin_gestion.count} </p>
            <p className="w-full text-center text-md font-semibold text-blue-500 pl-3">{data && data.stats.contactos_sin_gestion.perc_lista}%</p>
        </div>
        <div className="w-1/5 h-full flex flex-wrap gap-y-1 p-1">
            <p className="w-full text-lg text-center font-semibold text-gray-700">Total</p>
            <p className="w-full text-center text-xl font-semibold text-gray-900">{data && data.stats.contactos_en_lista.count} </p>
            <p className="w-full text-center text-xl font-semibold text-gray-900"></p>
        </div>

        </div>

      <div className="flex justify-around gap-x-3 w-full h-10   rounded-r-lg">
        
        <div className="flex justify-center flex-wrap w-40  h-full bg-white border border-l-4 border-yellow-500  ">
          <div className="flex justify-start w-full p-1">
            <h1 className="text-lg">Pendientes: <span className='ml-2'>{camp.pendientes}</span></h1>
          </div>
        </div>
        <div className="flex justify-center flex-wrap w-40  h-full bg-white border border-l-4 border-blue-500  ">
          <div className="flex justify-start w-full p-1">
            <h1 className="text-lg">Rellamadas: <span className='ml-2'>{camp.rellamadas}</span></h1>
          </div>
        </div>
        <div className="flex justify-center flex-wrap w-40  h-full bg-white border border-l-4 border-green-500  ">
          <div className="flex justify-start w-full p-1">
            <h1 className="text-lg">Realizadas: <span className='ml-2'>{camp.realizadas}</span></h1>
          </div>
        </div>
        <div className="flex justify-center flex-wrap w-40  h-full bg-white border border-l-4 border-red-500  ">
          <div className="flex justify-start w-full p-1">
            <h1 className="text-lg">Terminadas: <span className='ml-2'>{camp.terminadas}</span></h1>
          </div>
        </div>
        <div className="flex justify-center flex-wrap w-40  h-full bg-white border border-l-4 border-purple-500">
          <div className="flex justify-start w-full p-1">
            <h1 className="text-lg">Total: <span className='ml-2'>{camp.llamadas}</span></h1>
          </div>
        </div>
      </div>

        <div className="w-full lg:w-1/2 h-96 p-2 ">
          <div className="w-full h-full rounded-xl bg-white shadow-lg">
            {data && <RAgents data={data.agentes_tipos} resps={data.resps_opts} />}
          </div>
        </div>
	  <div className="w-full  lg:w-1/2  p-2 h-96 overflow-y-scroll ">
          <div className="w-full h-full rounded-xl bg-white shadow-lg p-2">
            {data && <Efectividad data={data.efectividad} />}
          </div>
        </div>
        <div className="w-full  p-2  h-96">
          <div className="w-full h-full rounded-xl shadow-lg bg-white">
            {data && <LAgents data={data.agentes_dias} agentes={data.agentes} />}
          </div>
        </div>

        <div className="w-full lg:w-3/5  p-2 h-96 ">
          <div className="w-full h-full rounded-xl shadow-lg bg-white">
            {data && <Bars data={data.dias}></Bars>}
          </div>
        </div>
        <div className="w-full lg:w-2/5  p-2  h-96">
          <div className="w-full h-full rounded-xl shadow-lg bg-white">
            {data && <Pies grupos={data.grupos} tipos={data.tipos}></Pies>}
          </div>
        </div>

      <Statistics data={data}/>
    </>
  );
};

export default Resumen;

/*<ExpandMore size="40" className="w-1/5 my-auto text-blue-400"></ExpandMore>*/
