import React, { useState, useEffect } from 'react';

import axiosInstance from '../../../axios';

import RUTable from './RUTable';

import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';
import { PlusMedical } from '@styled-icons/boxicons-regular/PlusMedical';

const Reglas = ({ show, setShow, camp }) => {
  const [regla, setRegla] = useState([]);
  const [change, setChange] = useState(false);
  const [creatingRegla, setCreatingRegla] = useState(false);

  const getRegla = async () => {
    await axiosInstance.get(`reglas?is_active=True&campaign=${camp.id}`).then((res) => {
      console.log(res.data.results);
      if (res.data.count > 0) {
        setRegla(res.data.results);
      }
    });
  };

  const createRegla = async () => {
    if (regla.length === 0 && creatingRegla === false) {
      setCreatingRegla(true);
      axiosInstance.post(`reglas/`, { nombre: camp.nombre, campaign: camp.id }).then((res) => {
        setChange(new Date());
        setCreatingRegla(false);
      });
    }
  };

  useEffect(() => {
    getRegla();
  }, [change]);

  return (
    <div
      className={`${
        show === true ? 'h-56' : 'h-12'
      } w-full flex justify-between flex-wrap border content-start   bg-gray-100 rounded-lg overflow-y-hidden`}
    >
      <div className="w-full h-12 bg-gray-dark justify-between flex text-2xl font-bold text-white px-4">
        <h1 className="text-center my-auto">Reglas</h1>
        <div clasName="w-1/3 flex flex-wrap ">
          <button
            onClick={() => {
              createRegla();
            }}
            className="my-1 mx-2 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-green-600 hover:-translate-y-1 hover:scale-105   ring-transparent"
          >
            <PlusMedical
              size="23"
              className={`text-white transform transition-all ease-in-out duration-300`}
            />
          </button>
          <button
            onClick={() => setShow(!show)}
            className="my-1 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-blue-ligth hover:-translate-y-1 hover:scale-105   ring-transparent"
          >
            <ArrowBoldDown
              size="23"
              className={`text-white transform transition-all ease-in-out duration-300  ${
                show ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </button>
        </div>
      </div>
      {show && (
        <div className="h-full w-full flex justify-between flex-wrap border bg-gray-100 rounded-lg">
          <RUTable reglas={regla} setReglas={setRegla} setChange={setChange} />
        </div>
      )}
    </div>
  );
};

export default Reglas;
