import {useState,useEffect} from 'react'

import axiosInstance from '../../axios';

import Events from '../../components/dashboards/sms/Events.jsx'

import toast from 'react-hot-toast'

import {ArrowUpRight2} from '@styled-icons/icomoon/ArrowUpRight2'
import {ArrowDownRight2} from '@styled-icons/icomoon/ArrowDownRight2'
import {Search} from '@styled-icons/evaicons-solid/Search'

const SMSDashboard  = () =>{
  const [data,setData] = useState(null)
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });
  
  


  const getData = async  () =>{
    // await axiosInstance.get(`mails/dashboard/?date_from=2022-09-01&date_to=2022-09-30`).then(res=>{
    let tid = toast.loading("cargado datos")
    if(dates.desde === null){
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      await axiosInstance.get(`sms/dashboard/?date_from=${firstDay}&date_to=${lastDay}`).then(res=>{
        setData(res.data)
        // console.log(res.data)
      }).finally(toast.remove(tid))
    }else{
      await axiosInstance.get(`sms/dashboard/?date_from=${dates.desde}&date_to=${dates.hasta}`).then(res=>{
      setData(res.data)
    }).finally(toast.remove(tid))
    }
    

  }

  const handleChange = (e) =>{
    setDates({...dates,[e.target.name]:e.target.value})
  }

  useEffect(()=>{
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
      getData()
  },[])




  return (
    <div className="w-full h-full flex-wrap bg-gray-100">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Dashboard SMS</p>

        <div className="flex items-center gap-x-2  p-1">
              <div className="rounded-md flex bg-gray-50 w-auto px-2 border border-blue-semi border-t-2 ">
                <input
                  name="desde"
                  type="date"
                  value={dates['desde']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
                <span className="h-full w-0.5 bg-gray-500"></span>
              <div className="rounded-md flex bg-gray-50 border border-blue-semi border-t-2">
                <input
                  name="hasta"
                  type="date"
                  value={dates['hasta']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
              <div onClick={()=>getData()}className="cursor-pointer bg-blue-semi rounded-md transform hover:scale-105 text-white flex justify-center p-1"><Search size="25"/></div>
        </div>
      </div>

      <div className="w-full flex flex-wrap p-6 gap-6 overflow-y-scroll bg-gray-100">
      
          
         {/* -------stats--------  */}

          {/* events graph*/}
          <div className="w-full flex gap-x-4">
            <Events data={data}></Events>

          {/*<div className="flex-grow  bg-white rounded-md border  flex flex-wrap">

            {data && <Camps data={data && data.camps}></Camps>}
        </div>*/}
        </div>
    
          {/* <div className="w-3/5  bg-white rounded-md border  flex flex-wrap"> */}
          {/**/}
          {/*   <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Eventos</p></div> */}
          {/*   <div className="w-full h-96 p-4"> */}
          {/*     <EventsGrafico></EventsGrafico> */}
          {/*   </div> */}
          {/**/}
          {/* </div> */}

          {/* camps */}

          



    </div>


    </div>
  )
}

export default SMSDashboard;
