import React, { useEffect} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const LAgents = ({data,groups}) => {
  console.log(data)
  console.log(groups)

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
    useEffect(() => {
    }, [data])
     
    return (
      <ResponsiveContainer width="100%" height="100%">

        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 0,
          }}
        >
          <Legend />
          {/*<CartesianGrid strokeDasharray="1 1"/>*/}
          <XAxis dataKey="name" />
          {/*<YAxis />*/}
		<Tooltip itemSorter={item => item.value} />
          {groups && groups.length > 0 && groups.map((ag)=>(
              <Line type="monotone" dataKey={ag.name} stroke={getRandomColor()} />
              
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
}

export default LAgents
