import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import ScriptsTable from '../../components/scripts/ScriptsTable.jsx'


import CreateSModal from '../../components/scripts/CreateSModal';
import EditSModal from '../../components/scripts/EditSModal';
import EditModal from '../../components/scripts/EditModal';


import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import FastFiltro from '../../components/FastFiltro.jsx'

const AdminScripts  = () =>{
  const [objs,setObjs] = useState([])
  const [data,setData] = useState({nombre:'',dominio:''})
  const [create,setCreate] = useState(false)
  const [change,setChange] = useState(null)
  const [edit,setEdit] = useState(false)
  const [display,setDisplay] = useState([])

  const [script, setScript] = useState({});

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }
  const getObjs = async () =>{
    await axiosInstance.get(`scripts/`).then(res=>{
      setObjs(res.data) 
      setDisplay(res.data) 
    })
  }

  const addObj = async () =>{
    
    data.nombre !== '' && await axiosInstance.post(`scripts/`,data).then(res=>{
      setData({nombre:'',dominio:''})
      getObjs()
      setCreate(false)
    })
  }

  const filterDisplay = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    setDisplay(
      objs.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  useEffect(()=>{
    getObjs()

  },[change])

  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll ">
        {create === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateSModal
            setModal={setCreate}
            setChange={setChange}
          />
        </div>
      )}
      {edit=== true && script && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70 justify-center">
          <EditModal
            script={script}
            setModal={setEdit}
            setScript={setScript}
            setChange={setChange}
          />
        </div>
      )}
    
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Scripts de llamadas</p> <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
      </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12 gap-y-2">
            <FastFiltro>
              <input onChange={filterDisplay} name="nombre" className="w-64 border-2  bg-gray-100 px-2 py-1 rounded-md text-gray-700" placeholder="Buscar"></input>
            </FastFiltro>
            <ScriptsTable setEditText={setEdit} setObj={setScript} objs={display} getObjs={getObjs}/>
        </div> 

      </div>
  )
}

export default AdminScripts;

  
