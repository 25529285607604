import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Bars = ({ data }) => {
  console.log(data);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 3,
          left: 3,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"/>
        <YAxis />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        <Tooltip></Tooltip>
        <ReferenceLine y={0}  />
        <Brush dataKey="name" height={25}stroke="#5c7ffa" />
        <Bar name="enviados" dataKey="delivered" fill="#5bde5f" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Bars;
