import React, { useState, useEffect } from 'react';

import axiosInstance from '../../../axios';
import { Link } from 'react-router-dom';

import Select from 'react-select';

import { Eye } from '@styled-icons/heroicons-solid/Eye';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { StatusOffline } from '@styled-icons/heroicons-outline/StatusOffline';
import { StatusOnline } from '@styled-icons/heroicons-outline/StatusOnline';
import { EditOff } from '@styled-icons/material/EditOff';
import { Data } from 'styled-icons/boxicons-regular';
import { Minus } from '@styled-icons/fa-solid/Minus';

const RTable = ({ resps, setResps, setChange, campId }) => {
  const [confR, setConfR] = useState(false);
  const [idR, setIdR] = useState(null);
  const [resp, setResp] = useState({});
  const [mandantes, setMandantes] = useState([]);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };

  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };
  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.id, label: man.nombre };
    });
  const handleSelect = (e) => {
    //console.log(optionsMandantes.filter(x=>data['mandantes'].includes(x.value)))
    if (e.length > 0) {
      setData({
        ...data,
        ['mandantes']: e.map((selected) => {
          return selected.value;
        }),
      });
    } else {
      setData({ ...data, ['mandantes']: [] });
    }
  };

  const editR = (resp) => {
    console.log(resp.mandantes);
    setEdit(true);
    setData(resp);
    setResp(resp);
  };
  const endEdit = () => {
    if (JSON.stringify(data) !== JSON.stringify(resp)) {
      axiosInstance.patch(`trespuestas/${resp.id}/`, data).then((res) => {
        console.log(res.data);
        setChange(Date().toLocaleString());
      });
    }

    setEdit(false);
    setData({});
    setResp({});
  };

  const close = () => {
    setIdR(null);
    setConfR(false);
  };
  const rem = (obj) => {
    setResp(obj);
    setIdR(obj.id);
    setConfR(true);
  };

  const del = () => {
    axiosInstance.delete(`trespuestas/${idR}/`).then((res) => {
      console.log(res);
      setResps(resps.filter((x) => x.id !== idR));
      setChange(idR);
    });

    setConfR(false);
    setIdR(null);
    setResp({});
  };

  const removeResp = async (respId) => {
    axiosInstance
      .post(`campaigns/${campId}/remove_respuesta/`, { respuesta: respId })
      .then((res) => {
        setChange(new Date());
      });
  };

  useEffect(() => {
    getMandantes();
  }, []);

  return (
    <table className="w-full table-auto rounded-lg shadow-2xl overflow-hidden">
      {confR && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-gray-300 shadow-2xl rounded-xl pt-3  w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl h-1/3">Eliminar esta Respuestas:</p>
            <div className="flex justify-center w-full h-2/3 text-4xl font-bold">
              <p className="my-auto">{resp.nombre}</p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => close()}
              className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => del()}
              className=" focus:outline-none mx-4 py-2 px-2 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <thead>
        <tr>
          <th
            scope="col"
            className="px-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Nombre
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Codigo
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Grupo
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Color
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Id Externa
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Set Realizada
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Set Terminada
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Reagendacion
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Confimarcion
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Desc
          </th>
          {/* <th scope="col" className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-md  font-normal"> */}
          {/*    Mandantes */}
          {/* </th> */}
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-md  font-normal"
          >
            Editar
          </th>
          {/* <th scope="col" className="px-1 py-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-md  font-normal"> */}
          {/*     Eliminar */}
          {/* </th> */}
        </tr>
      </thead>
      <tbody>
        {resps &&
          resps.map((obj) => (
            <tr key={obj.id} className="">
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                <div className="flex items-center">
                  <div className="ml-3">
                    {edit && obj === resp ? (
                      <input
                        name="nombre"
                        value={data['nombre']}
                        onChange={handleChange}
                        className="w-28 ml-1 border px-1 my-1"
                      ></input>
                    ) : (
                      <p className="text-lg text-gray-900 whitespace-no-wrap">{obj.nombre}</p>
                    )}
                  </div>
                </div>
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <input
                    name="codigo"
                    value={data['codigo']}
                    onChange={handleChange}
                    className="w-10 ml-1 border px-1 my-1"
                  ></input>
                ) : (
                  <p className="text-gray-900 whitespace-no-wrap">{obj.codigo}</p>
                )}
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <select onChange={handleChange} name="grupo">
                    <option key="1" className="bg-white font-bold text-green-400" value={1}>
                      Positiva
                    </option>
                    <option key="2" className="bg-white font-bold text-red-400" value={2}>
                      Negativa
                    </option>
                    <option key="3" className="bg-white font-bold text-blue-400" value={3}>
                      Rellamada
                    </option>
                  </select>
                ) : (
                  <p className="text-gray-900 whitespace-no-wrap">
                    {obj.grupo === 1 && (
                      <span className="relative inline-block px-1 py-1 font-semibold text-green-900 leading-tight">
                        {' '}
                        <span
                          aria-hidden="true"
                          className="absolute inset-0 bg-white  opacity-50 rounded-full"
                        >
                          {' '}
                        </span>{' '}
                        <span className="relative"> Positiva </span>
                      </span>
                    )}
                    {obj.grupo === 2 && (
                      <span className="relative inline-block px-1 py-1 font-semibold text-red-800 leading-tight">
                        {' '}
                        <span
                          aria-hidden="true"
                          className="absolute inset-0 bg-white opacity-50 rounded-full"
                        >
                          {' '}
                        </span>{' '}
                        <span className="relative"> Negativa </span>
                      </span>
                    )}
                    {obj.grupo === 3 && (
                      <span className="relative inline-block px-1 py-1 font-semibold text-blue-800 leading-tight">
                        {' '}
                        <span
                          aria-hidden="true"
                          className="absolute inset-0 bg-white opacity-50 rounded-full"
                        >
                          {' '}
                        </span>{' '}
                        <span className="relative"> Rellamada </span>
                      </span>
                    )}
                  </p>
                )}
              </td>

              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <input
                    name="color"
                    value={data['color']}
                    onChange={handleChange}
                    type="color"
                  ></input>
                ) : (
                  <div
                    style={{ backgroundColor: obj.color }}
                    className="h-10 w-10 rounded-full"
                  ></div>
                )}
              </td>
              <td className="p-1 w-10 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <input
                    type="number"
                    className="w-14"
                    value={data['external_id']}
                    name="external_id"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <span className="font-bold">{obj && obj.external_id}</span>
                )}
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  data['set_realizada'] === true ? (
                    <label
                      for="unchecked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                          <input
                            id="unchecked1"
                            onClick={() => setData({ ...data, ['set_realizada']: false })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Activo</span>
                    </label>
                  ) : (
                    <label
                      for="checked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                          <input
                            id="checked1"
                            onClick={() => setData({ ...data, ['set_realizada']: true })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Inactivo</span>
                    </label>
                  )
                ) : obj && obj.set_realizada ? (
                  <span className="relative inline-block px-1 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">activo</span>
                  </span>
                ) : (
                  <span className="relative inline-block px-1 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">inactivo</span>
                  </span>
                )}
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  data['set_terminada'] === true ? (
                    <label
                      for="unchecked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                          <input
                            onClick={() => setData({ ...data, ['set_terminada']: false })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Activo</span>
                    </label>
                  ) : (
                    <label
                      for="checked1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                          <input
                            onClick={() => setData({ ...data, ['set_terminada']: true })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Inactivo</span>
                    </label>
                  )
                ) : obj && obj.terminada ? (
                  <span className="relative inline-block px-1 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">activo</span>
                  </span>
                ) : (
                  <span className="relative inline-block px-1 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">inactivo</span>
                  </span>
                )}
              </td>
              <td className="p-1 w-10 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <input
                    type="number"
                    value={data['reagendacion']}
                    className="w-8"
                    name="reagendacion"
                    onChange={handleChange}
                  ></input>
                ) : (
                  <span className="font-bold">{obj && obj.reagendacion}</span>
                )}
              </td>

              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  data['confirmation'] === true ? (
                    <label
                      for="confir2"
                      class="mt-3 w-12 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                          <input
                            id="confir2"
                            onClick={() => setData({ ...data, ['confirmation']: false })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Activa</span>
                    </label>
                  ) : (
                    <label
                      for="confir1"
                      class="mt-3 w-12 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                          <input
                            id="confir1"
                            onClick={() => setData({ ...data, ['confirmation']: true })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Inactiva</span>
                    </label>
                  )
                ) : obj && obj.confirmation === true ? (
                  <span className="relative inline-block px-1 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">activa</span>
                  </span>
                ) : (
                  <span className="relative inline-block px-1 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">inactiva</span>
                  </span>
                )}
              </td>
              <td className="p-1 px-2 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <textarea
                    type="text"
                    value={data['desc']}
                    className="w-32"
                    name="desc"
                    onChange={handleChange}
                  ></textarea>
                ) : (
                  <textarea className="break-all">{obj && obj.desc}</textarea>
                )}
              </td>

              {/* <td className="py-2 border-b w-3/12 border-gray-200 bg-white text-lg"> */}
              {/* {edit && obj === resp && */}
              {/* <Select */}
              {/*     closeMenuOnSelect={false} */}
              {/*     onChange={handleSelect} */}
              {/*     className="w-full" */}
              {/*     isMulti */}
              {/*     options={optionsMandantes.filter(x=>!data['mandantes'].includes(x.value))} */}
              {/*     value={optionsMandantes.filter(x=>data['mandantes'].includes(x.value))} */}

              {/* />} */}
              {/*    { edit == false && obj.mandantes && obj.mandantes.length > 0 &&<div className="w-42 h-12 overflow-y-scroll scroll">{optionsMandantes.filter(x=>obj.mandantes.includes(x.value)).map((x)=>{return <p className='overflow-y-scroll'>{x.label}<br/></p>})}</div> */}
              {/* } */}

              {/* </td> */}
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  data['status'] === 1 ? (
                    <label
                      for="check1"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                          <input
                            id="check1"
                            onClick={() => setData({ ...data, ['status']: 2 })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Activa</span>
                    </label>
                  ) : (
                    <label
                      for="check2"
                      class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                    >
                      <span class="relative">
                        <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                        <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                          <input
                            id="check2"
                            onClick={() => setData({ ...data, ['status']: 1 })}
                            type="checkbox"
                            class="absolute opacity-0 w-0 h-0"
                          />
                        </span>
                      </span>
                      <span class="ml-3 text-sm">Inactiva</span>
                    </label>
                  )
                ) : obj && obj.status === 1 ? (
                  <span className="relative inline-block px-1 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">activa</span>
                  </span>
                ) : (
                  <span className="relative inline-block px-1 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">inactiva</span>
                  </span>
                )}
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {edit && obj === resp ? (
                  <EditOff
                    onClick={() => endEdit()}
                    size="32"
                    className="my-auto text-blue-800 transform hover:scale-125"
                  />
                ) : (
                  <Edit
                    onClick={() => editR(obj)}
                    size="32"
                    className="my-auto text-blue-600 transform hover:scale-125"
                  />
                )}
                <button
                  onClick={() => {
                    removeResp(obj.id);
                  }}
                  className=" mx-2 transform w-auto p-1 px-2 text-gray-50 font-semibold h-10 rounded-lg  duration-75 transition ease-in-out bg-red-600 hover:-translate-y-1 hover:scale-105   ring-transparent"
                >
                  <Minus
                    size="23"
                    className={`text-white transform transition-all ease-in-out duration-300`}
                  />
                </button>
              </td>

              {/* <td className="p-1 border-b border-gray-200 bg-white text-lg"> */}
              {/* <button onClick={()=>rem(obj)}  className="text-red-500 hover:text-red-600 transform hover:scale-125"><TrashBin size="35" className= ""/></button> */}
              {/* </td> */}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default RTable;
