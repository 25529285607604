import { useState } from 'react'
import {Link} from 'react-router-dom'

import axiosInstance from '../../../axios'
import {ThreeDotsVertical} from '@styled-icons/bootstrap/ThreeDotsVertical'
import Actions from './Actions'
import { Template } from 'react-hero-icon/solid'



const TemplateCard = ({template,setChange}) =>{
  const [showActions,setShowActions] = useState(false)
  

  console.log(template)

  return (
      <div className="flex flex-wrap w-64 border bg-white rounded-md  ">
              <div className="aspect-w-4 aspect-h-3 overflow-hidden  bg-gray-50 w-full h-40 border-b p-2 overflow-hidden">

                <Link to={`sms_templates/${template.id}`} className="hover:bg-gray-300 hover:bg-opacity-75">
                    <p className="text-gray-700" >{template.text && template.text}</p>
                {/*<img src={template.picture} alt="" className="object-cover object-center "/> */}
                </Link>
              </div>

              <div className="text-base font-medium text-gray-900 w-full p-2 py-2 flex items-center static">
                <h3 className='flex-grow'>
                  <Link to={`sms_templates/${template.id}`}>
                    <span aria-hidden="true text-lg" className=" inset-0" />
                      {template.nombre}
                  </Link>
                </h3>

                
                <Actions setChange={setChange} setShowActions={setShowActions} template={template}></Actions>
                
              </div>
            <div className="w-full py-1 px-2">
            </div>

            </div>
  )
}

export default TemplateCard;
