import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setLlamada, setNumero, setContacto, setDir } from '../redux/actions/llamadaActions';
import axiosInstance from '../axios';

import logo from '../assets/logo/Original Logo.svg';
import Incoming from './Incoming';
import IncomingMail from './IncomingMail.jsx'
// import Script from './ odals/pages/Script'

import { setState } from '../redux/actions/stateActions';

import { User } from '@styled-icons/boxicons-solid/User';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { Record2Fill } from '@styled-icons/bootstrap/Record2Fill';
import { LogOut } from '@styled-icons/entypo/LogOut';
import { ExpandMore } from '@styled-icons/material/ExpandMore';
import { Phone } from '@styled-icons/fa-solid/Phone';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { ContactPhone } from '@styled-icons/material/ContactPhone';
import { PersonCheckFill } from '@styled-icons/bootstrap/PersonCheckFill';
import { PersonXFill } from '@styled-icons/bootstrap/PersonXFill';
import { NumberSymbol } from '@styled-icons/fluentui-system-filled/NumberSymbol';
import { PhoneIncoming } from '@styled-icons/boxicons-solid/PhoneIncoming';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Cross } from '@styled-icons/icomoon/Cross';
import {Mail} from '@styled-icons/entypo/Mail'

export function Nav(props) {
  const history = useHistory();
  const state = props.state;
  var user = JSON.parse(localStorage.getItem('user'));
  var nums = localStorage.getItem('nums');
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const [contactos, setContactos] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [ct, setCt] = useState(null);
  const [showCt, setShowCt] = useState(false);
  const [num, setNum] = useState(null);
  const [numsF, setNumsF] = useState([]);
  const [numF, setNumF] = useState(null);
  const [selNumF, setSelNumF] = useState(false);
  const [showIncoming, setShowIncoming] = useState(false);
  const [contacto, setContacto] = useState(null);
  const [tresp, setTresp] = useState(false);
  const [grupo, setGrupo] = useState(true);
  const [script, setScript] = useState(null);
  const [showIncomingMail, setShowIncomingMail] = useState(false);


  const dispatch = useDispatch();

  const device = useSelector((state) => state.device);

  const handleFocus = (e) => {
    console.log(typeof e.target.value);
    setFocus(e.target.value);
    //console.log(focus)
  };

  const changeNum = (e) => {
    e.target.value.length < 12 && setNum(e.target.value);
  };
  const filterTipos = () => {
    var mands = [];
    var mandante = null;
    if (props.mandantes.filter((x) => x.codigo === contacto.mandante).length > 0) {
      mandante = props.mandantes.filter((x) => x.codigo === contacto.mandante)[0];
    }
    if (mandante == null) {
      console.log();

      mands = props.tipos_respuestas.filter((x) => x.grupo == tresp && x.mandantes.length == 0);
    } else {
      mands = props.tipos_respuestas.filter(
        (x) => x.grupo == tresp && x.mandantes.includes(mandante.id),
      );
    }
    return mands;
  };

  const handleChange = (e) => {
    //console.log(e.target.value)
    setSearch(e.target.value);
    if (e.target.value.length > 3) {
      axiosInstance
        .get(`contactos/?search=${e.target.value}`)
        .then((res) => {
          console.log(res.data);
          setContactos(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getCTT = (e) => {
    console.log(e.key);
    if (e.key == 'Enter') {
      axiosInstance
        .get(`contactos/?search=${search}`)
        .then((res) => {
          console.log(res.data);
          setContactos(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const Call = () => {
    if (num && num.length >= 8 && device && state.state === 'Ready') {
      if (ct) {
        device.device.connect({ To: num, agente: user.id, contacto_id: ct.id });
      } else {
        device.device.connect({ To: num, agente: user.id });
      }
      dispatch(setNumero(num));
      ct && dispatch(setContacto(ct));

      dispatch(setDir('out'));
    }
  };

  function expi(token) {
    try {
      const tokenParts = JSON.parse(atob(token.split('.')[1]));
      //const { exp } = decode(token);
      //console.log(tokenParts.exp)

      if (tokenParts.exp < (new Date().getTime() + 1) / 1000) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  const getScript = async () => {
    if (contacto !== null) {
      axiosInstance.get(`contactos/${contacto.id}/get_script/`).then((res) => {
        setScript(res.data.script);
        console.log(res.data.script);
      });
    }
  };

  const changeSt = (user_id, status) => {
    if (!expi(device.device.token)) {
      console.log('####################');
      props.getToken();
      //props.setToken(null)
    } else {
      axiosInstance.patch(`users/${user_id}/`, { status: status });
      dispatch(setState('Ready'));
    }
  };

  const getContacto = async (id) => {
    axiosInstance.get(`contactos/${id}/`).then((res) => {
      setContacto(res.data);
    });
  };

  useEffect(() => {
    // setNumsF(nums.split(','));

    // nums.split(',').length > 0 && setNumF(nums.split(',')[0]);
  }, [ct]);

  useEffect(() => {
    getScript();
  }, [contacto]);
  useEffect(() => {
    if (showIncoming) {
    } else {
      setGrupo(true);
      setContacto(null);
    }
  }, [showIncoming]);

  return (
    <>
      <div  className="w-scren flex justify-between content-center items-center h-12 bg-blue-normal px-2">
        <IncomingMail open={showIncomingMail} setOpen={setShowIncomingMail} contacto={contacto}/>
          <div className="flex h-12 items-center justify-between ">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="block h-12 w-auto lg:hidden"
                    // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    src={logo}
                    alt="FullCall"
                  />
                  <img
                    className="hidden h-12 w-auto lg:block"
                    src={logo}
                    alt="FullCall"
                  />
                </div>
                
              </div>
          </div>
        <div className="flex flex-grow h-full justify-between ml-3 content-center items-center">
        <span class="static   w-72 h-8 hidden  cursor-pointer border border-gray-300 rounded-md mr-2 text-sm md:flex bg-white">
          <input
            type="search"
            onKeyDown={getCTT}
            onSubmit={() => getCTT()}
            name="serch"
            placeholder="Buscar Contactos"
            onFocus={() => setFocus(true)}
            onChange={handleChange}
            class="flex-grow px-4 rounded-l-md rounded-r-md text-sm focus:outline-none my-auto"
          />
          {focus === true && contactos.length > 0 && search.length > 0 && (
            <button
              onClick={() => {
                setFocus(false);
                setShowIncoming(false);
              }}
              className="w-7 self-end my-auto h-7 flex justify-center rounded-full bg-blue-normal transform hover:scale-110 text-white p-1"
            >
              <Close className="h-full w-full font-bold" />
            </button>
          )}
          {focus && search.length > 3 && contactos.length > 0 && (
            <div className="flex z-40 flex-wrap absolute  overflow-y-scroll no-scrollbar content-start bg-white border w-2/5 mt-10 rounded-lg h-64">
              {contactos.map((cto) => {
                return (
                  <div className=" flex border-b border-gray-300 justify-around w-full h-12    font-normal text-lg ">
                    <p className="my-auto w-1/5 text-center">{cto.nombre && cto.nombre}</p>
                    <p className="my-auto w-1/5 text-center">{cto.apellido && cto.apellido}</p>
                    <p className="my-auto w-1/5 text-center">{cto.mandante && cto.mandante}</p>
                    <UserDetail
                      onClick={() => {
                        setFocus(false);
                        history.push(`/contactos/${cto.id}`);
                      }}
                      size="32"
                      className="my-auto w-1/5 transform hover:scale-110 text-blue-ligth"
                    />
                    <PhoneIncoming
                      onClick={() => {
                        setShowIncoming(true);
                        getContacto(cto.id);
                        setFocus(false);
                      }}
                      size="32"
                      className="my-auto w-1/5 transform hover:scale-110 text-blue-600"
                    />
                    <Mail
                      onClick={() => {
                        setShowIncomingMail(true);
                        getContacto(cto.id);
                        setFocus(false);
                      }}
                      size="32"
                      className="my-auto w-1/5 transform hover:scale-110 text-blue-600"
                    />

                    {/* <ContactPhone  onClick={()=>{setFocus(false);setCt(cto)}} size="32" className="my-auto w-30 transform hover:scale-110 text-blue-900"/> */}
                  </div>
                );
              })}
            </div>
          )}
          {showIncoming && contacto != null && (
            <div className="flex z-40 flex-wrap absolute  content-start bg-gray-100 border w-4/6 h-5/6 mt-10 rounded-lg  p-1  overflow-hidden">
              <div className="w-full h-5 flex justify-end">
                <button
                  onClick={() => setShowIncoming(false)}
                  className="w-5 self-end my-auto h-5 flex justify-center rounded-full bg-red-500 transform hover:scale-110 text-white p-1"
                >
                  <Close className="h-full w-full font-bold" />
                </button>
              </div>
              <div
                className={`w-full ${
                  grupo ? 'h-1/5' : 'h-3/6'
                } border  content-start overflow-y-scroll scroll flex flex-wrap`}
              >
                <div className="flex w-full justify-center bg-blue-semi text-white font-bold text-2xl ">
                  <h1>Contacto</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2 w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Nombre:</h1>
                  <h1 className="w-2/2">{contacto.nombre}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 xl:w-1/2 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Apellido:</h1>
                  <h1 className="w-2/3">{contacto.apellido}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Rut:</h1>
                  <h1 className="w-2/3">{contacto.rut}</h1>
                </div>

                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Mandante:</h1>
                  <h1 className="w-2/3">{contacto.mandante}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">tarjeta:</h1>
                  <h1 className="w-2/3">{contacto.tarjeta}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Estado:</h1>
                  <h1 className="w-2/3">{contacto.estado}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Fase:</h1>
                  <h1 className="w-2/3">{contacto.fase}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Rol:</h1>
                  <h1 className="w-2/3">{contacto.rol}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Saldo Deuda:</h1>
                  <h1 className="w-2/3">{contacto.saldo_deuda}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Total Deuda:</h1>
                  <h1 className="w-2/3">{contacto.total_deuda}</h1>
                </div>
                <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
                  <h1 className="w-1/3 font-bold">Link:</h1>
                  <h1 className="w-2/3">
                    {contacto && (
                      <a
                        target="_blank"
                        className="text-md font-bold text-blue-900"
                        href={`http://astrea.fullpay.me/cuentas/view/${contacto.id_cuenta}`}
                      >
                        Ver en FullPay
                      </a>
                    )}
                  </h1>
                </div>
              </div>
              {grupo === true && (
                <div className="h-3/5 w-full ">
                  <div className="w-full h-full flex flex-wrap content-start p-5 overflow-y-scroll scroll rounded-xl">
                    {script &&
                      script.length > 0 &&
                      script.map((bloque) => {
                        return (
                          <div className="flex w-full h-auto border border-gray-300 mt-2 px-3 rounded-lg">
                            <div className="w-1/6 h-full flex border-r border-gray-300 p-2">
                              <p className=" break-word font-semibold my-auto ">
                                {bloque && bloque.nombre}
                              </p>
                            </div>
                            <div className="w-5/6 h-full p-3">
                              <p className="text-gray-900  text-2xl break-words">
                                {bloque && bloque.cuerpo}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>{' '}
                </div>
              )}
              {grupo === false ? (
                <Incoming
                  setShowIncoming={setShowIncoming}
                  respuestas={tresp != null && filterTipos()}
                  back={setGrupo}
                  grupo={grupo}
                  setGrupo={setGrupo}
                  tresp={tresp}
                  contacto={contacto}
                />
              ) : (
                <div className="flex justify-around content-center items-center h-1/5 w-full">
                  <button
                    onClick={() => {
                      setGrupo(false);
                      setTresp(1);
                    }}
                    className="flex flex-wrap justify-center w-24 h-24 border-2 border-green-500 transition duration-100 ease-in-out bg-white rounded-lg hover:bg-green-600 hover:text-white text-green-500"
                  >
                    <CheckCircle size="80" className="h-2/3" />
                    <p className="h-1/3 font-bold text-md xl:text-lg ">Positiva</p>
                  </button>
                  <button
                    onClick={() => {
                      setGrupo(false);
                      setTresp(2);
                    }}
                    className="flex flex-wrap justify-center w-24 h-24 border-2 border-red-600   transition duration-100 ease-in-out bg-white rounded-lg hover:bg-red-700   hover:text-white text-red-600              "
                  >
                    <Cross size="50" className="h-2/3" />
                    <p className="h-1/3 font-bold text-md xl-text-lg">Negativa</p>
                  </button>
                  <button
                    onClick={() => {
                      setGrupo(false);
                      setTresp(3);
                    }}
                    className="flex flex-wrap justify-center w-24 h-24 border-2 border-blue-500  transition duration-100 ease-in-out bg-white rounded-lg hover:bg-blue-600  hover:text-white text-blue-500              "
                  >
                    <Phone size="55" className="h-2/3" />
                    <p className="h-1/3 font-bold text-md xl-text-lg">Rellamada</p>
                  </button>
                </div>
              )}
            </div>
          )}
        </span>

        <div className=" w-1/6 h-full ml-4 hidden xl:flex">
          {/*<input type="number" value={num} onChange={changeNum} className="w-4/6 h-4/5 bg-gray-100 my-auto rounded-l-lg px-3 font-bold text-gray-800 "></input>
                          <div className="bg-gray-100 w-1/6 h-4/5 p-1 flex justify-center my-auto ">{ct ? <PersonCheckFill size="27" onClick={()=>setShowCt(!showCt)} className="transform hover:scale-110 hover:text-gray-900 my-auto text-gray-800"/> : <PersonXFill size="27" className="transform  my-auto text-red-800"/>}</div>
                          <div className="bg-gray-200 w-1/6 h-4/5 p-1 flex justify-center my-auto "><NumberSymbol size="27" onClick={()=>setSelNumF(!selNumF)} className="transform transform hover:scale-125 my-auto text-blue-800"/></div>
                          {ct && showCt && <div className="flex content-start flex-wrap absolute h-40 w-40 bg-gray-50 ml-40 mt-10 rounded-b-lg border-l border-r "> 
                            <p className="w-3/4 h-1/4 py-1 font-bold text-right">{ct.nombre && ct.nombre}</p>
<UserDetail onClick={()=>{setFocus(false);history.push(`/contactos/${ct.id}`)}} size="26" className="w-1/4 mt-1 transform hover:scale-110 text-gray-900"></UserDetail>
                            <p className="w-full h-1/4 py-1 font-bold text-center">{ct.apellido && ct.apellido}</p>
                            <p className="w-full h-1/4 py-1 font-bold text-center">{ct.mandante && ct.mandante}</p>
                            <button onClick={()=>{setShowCt(false);setCt(null)}} type="button" className="w-full h-1/4 py-1 font-bold text-red-500 text-center border border-red-500 rounded-b-lg hover:bg-red-500 hover:text-white bg-white"><p>Quitar contacto</p></button>
                            
                            
                            </div>}
                            {numsF.length > 0 && selNumF && <div className="flex content-start flex-wrap absolute h-40 w-40 bg-gray-100 ml-40 mt-10 rounded-b-lg"> 
                              {numsF.map((n)=>{
                                return (<button  onClick={()=>{setNumF(n);setSelNumF(false)}} className="flex justify-center hover:bg-gray-300 h-1/3 w-full border-b">
                                      {numF == n ? <p className="my-auto text-center text-lg font-bold text-green-500">{n}</p>:
                                                  <p className="my-auto text-center text-lg font-bold ">{n}</p>
                                      }

                                </button>)
                              })}
                            
                            </div>}
                          <div className="bg-gray-400 w-1/6 h-4/5 p-1 flex justify-center my-auto rounded-r-lg"><Phone onClick={()=>Call()} size="27" className="transform hover:scale-110 hover:text-green-600 my-auto text-gray-800"></Phone></div>
                            */}
        </div>
        <div className="text-gray-100 h-10 w-48 my-auto md:flex text-xl mr-6">
          {state.state === 'On call' && (
            <span className="my-auto">
              <Record2Fill size="30" className="text-yellow-500" />
              En Llamada
            </span>
          )}
          {state.state === 'Ready' && (
            <span className="my-auto">
              <Record2Fill
                onClick={() => {
                  axiosInstance.patch(`users/${user.id}/`, { status: 2 });
                  dispatch(setState('Offline'));
                }}
                size="30"
                className="text-green-500 transform hover:text-red-500 hover:scale-125 "
              />
              En Linea
            </span>
          )}
          {state.state === 'Connecting' && (
            <span className="animate-pulse">
              <Record2Fill size="30" className="text-yellow-500" />
              Llamando
            </span>
          )}
          {state.state === 'Cargando' && (
            <span className="animate-pulse my-auto">
              <Record2Fill size="30" className="text-blue-500" />
              Cargando
            </span>
          )}
          {state.state === 'Offline' && (
            <span className="font-bold my-auto">
              <Record2Fill
                onClick={() => changeSt(user.id, 1)}
                size="30"
                className="text-red-500 transform hover:text-green-500 hover:scale-125 "
              />
              Desconectado
            </span>
          )}
        </div>
        <div className="flex h-8  my-auto   justify-self-end">

          <div className="flex mr-2">
          {user.picture ? 
            <img
              onClick={()=>history.push(`/users/${user.id}`)}
              className="inline-block h-8 w-8 rounded-full cursor-pointer"
              src={user && user.picture}
              alt=""
            />

      : 
                  <div onClick={()=>history.push(`users/${user.id}`)} className="flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 mr-2 flex justify-center content-center items-center text-gray-700 uppercase text-sm cursor-pointer  font-bold">
                        {user && user.first_name.charAt(0)}{user && user.last_name.charAt(0)}
                    </div>
    }
          {/* <p className="xl:text-lg my-auto text-center  w-full md:text-lg text-gray-50 font-semibold"> */}
          {/*     {user && user.first_name + ' ' + user.last_name} */}
          {/*   </p> */}
                
          </div>
          <div className=" flex">
            <LogOut
              onClick={() => history.push('/logout')}
              size="24"
              className="text-blue-ligth my-auto transform  hover:translate-x-1 hover:scale-110 cursor-pointer "
            ></LogOut>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
export default Nav;
