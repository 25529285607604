
import Grafico from '../../Grafico';
import Bars from './Bars.js'

const Days = ({data}) =>{
  return (
    <div className="w-full  ">
        <div className="w-full h-full shadow-xl  rounded-lg bg-white">
          {data && <Grafico data={data.days} groups={data.days}>
            <Bars></Bars>
            </Grafico>}
        </div>
      </div>

  )
}

export default Days;
