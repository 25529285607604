import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast';

const ContactoAction = ({ contactos, setChange }) => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const [tipoS, setTipoS] = useState(null);
  const [listas, setListas] = useState([]);
  const [tags, setTags] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente] = useState(null);
  const [automations, setAutomations] = useState([]);
  const [lista, setLista] = useState(null);
  const [auto, setAuto] = useState(null);
  const [tag, setTag] = useState(null);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getListas = async () => {
    await axiosInstance.get(`listas?status=1`).then((res) => {
      setListas(res.data);
    });
  };
  const getTags = async () => {
    await axiosInstance.get(`tags_contacto?is_active=true`).then((res) => {
      setTags(res.data);
    });
  };

  const getAutomations = async () => {
    await axiosInstance.get(`automation_templates/`).then((res) => {
      setAutomations(res.data);
    });
  };
  const createAction = () => {
    if (tipoS === 1) {
      if (lista !== null && contactos.length > 0) {
        axiosInstance
          .post(`contactos/add_to_list/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
            lista: lista.id,
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(
                `se agregaron ${contactos.length} contactos a la lista ${lista.nombre} `,
                { duration: 3000 },
              );
          });
      }
    } else if (tipoS === 2) {
      if (lista !== null && contactos.length > 0) {
        axiosInstance
          .post(`contactos/remove_from_list/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
            lista: lista.id,
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(
                `se eliminaron ${contactos.length} contactos de la lista ${lista.nombre} `,
                { duration: 3000 },
              );
          });
      }
    } else if (tipoS === 3) {
      if (tag !== null && contactos.length > 0) {
        axiosInstance
          .post(`tags_contacto/add_tag_to_contacto/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
            tag: tag.id,
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(`Se agrego el tag: ${tag.nombre} a ${contactos.length} contactos.`, {
                duration: 3000,
              });
          });
      }
    } else if (tipoS === 4) {
      if (tag !== null && contactos.length > 0) {
        axiosInstance
          .post(`tags_contacto/remove_tag_from_contacto/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
            tag: tag.id,
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(`Se removio el tag: ${tag.nombre} a ${contactos.length} contactos.`, {
                duration: 3000,
              });
          });
      }
    } else if (tipoS === 5) {
      if (auto !== null && contactos.length > 0) {
        axiosInstance
          .post(`automation_templates/${auto.id}/start/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
          })
          .then((res) => {
            setShow(false);
            setChange(Date.now());
            res.status == 200 && toast.success(`Automatization Started`, { duration: 3000 });
          });
      }
    } else if (tipoS === 6) {
      if (contactos.length > 0) {
        axiosInstance
          .post(`contactos/multiple_delete/`, {
            contactos: contactos.map((c) => {
              return c.id;
            }),
          })
          .then((res) => {
            setShow(false);
            console.log(res.data);
            setChange(Date.now());
            res.status == 200 &&
              toast.success(`${res.data.total} Contactos Eliminados`, { duration: 3000 });
          });
      }
    }
  };

  useEffect(() => {
    show && [1, 2].includes(tipoS) && getListas();
    show && [3, 4].includes(tipoS) && getTags();
    show && [5].includes(tipoS) && getAutomations();
  }, [show]);

  return (
    <div className="absolute w-48 flex flex-wrap  z-30 bg-white transform -translate-x-40 h-auto rounded-md border shadow-md">
      <div
        onClick={() => {
          setTipoS(1);
          setShow(!show);
        }}
        className={`w-full h-12 border-b flex px-2 hover:bg-gray-100 cursor-pointer ${
          tipoS === 1 ? 'bg-gray-200' : 'bg-white'
        } text-gray-500 hover:text-gray-600`}
      >
        <p className="my-auto text-center">Añadir a lista</p>
      </div>

      <div
        onClick={() => {
          setTipoS(2);
          setShow(!show);
        }}
        className={`w-full h-12 border-b flex px-2 hover:bg-gray-100 cursor-pointer ${
          tipoS === 2 ? 'bg-gray-200' : 'bg-white'
        } text-gray-500 hover:text-gray-600`}
      >
        <p className="my-auto text-center">Eliminar de lista</p>
      </div>

      <div
        onClick={() => {
          setTipoS(3);
          setShow(!show);
        }}
        className={`w-full h-12 border-b flex px-2 hover:bg-gray-100 cursor-pointer ${
          tipoS === 3 ? 'bg-gray-200' : 'bg-white'
        } text-gray-500 hover:text-gray-600`}
      >
        <p className="my-auto text-center">Agregar Tag</p>
      </div>
      <div
        onClick={() => {
          setTipoS(4);
          setShow(!show);
        }}
        className={`w-full h-12 border-b flex px-2 hover:bg-gray-100 cursor-pointer ${
          tipoS === 4 ? 'bg-gray-200' : 'bg-white'
        } text-gray-500 hover:text-gray-600`}
      >
        <p className="my-auto text-center">Remover Tag</p>
      </div>
      <div
        onClick={() => {
          setTipoS(5);
          setShow(!show);
        }}
        className={`w-full h-12 border-b flex px-2 hover:bg-gray-100 cursor-pointer ${
          tipoS === 5 ? 'bg-gray-200' : 'bg-white'
        } text-gray-500 hover:text-gray-600`}
      >
        <p className="my-auto text-center">Iniciar Automation</p>
      </div>
      <div
        onClick={() => {
          setTipoS(6);
          setShow(!show);
        }}
        className={`w-full h-12 border-b flex px-2 hover:bg-gray-100 cursor-pointer ${
          tipoS === 6 ? 'bg-gray-200' : 'bg-white'
        } text-gray-500 hover:text-gray-600`}
      >
        <p className="my-auto text-center">Eliminar Contactos</p>
      </div>

      {show && (
        <div className="rounded-md rounded-tr-none shadow flex p-2 flex-wrap content-start w-80 h-80 bg-white absolute transform -translate-x-80 overflow-y-scroll no-scrollbar">
          <div className="flex flex-wrap content-start h-4/5 w-full border-b overflow-y-scroll no-scrollbar border">
            {[1, 2].includes(tipoS) &&
              listas.length > 0 &&
              listas.map((li) => {
                if (lista !== null && li.id === lista.id) {
                  return (
                    <div
                      onClick={() => {
                        setLista(null);
                      }}
                      className="h-auto  justify-center cursor-pointer w-full border-b bg-gray-200 flex"
                    >
                      <p className="my-auto text-lg text-gray-500">{li.nombre}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setLista(li);
                      }}
                      className="h-auto flex justify-center  cursor-pointer w-full border-b"
                    >
                      <p className="text-lg text-gray-500 my-auto">{li.nombre}</p>
                    </div>
                  );
                }
              })}

            {[3, 4].includes(tipoS) &&
              tags.length > 0 &&
              tags.map((tg) => {
                if (tag !== null && tag.id === tg.id) {
                  return (
                    <div
                      onClick={() => {
                        setTag(null);
                      }}
                      className="h-auto  justify-start cursor-pointer w-full border-b bg-gray-200 flex"
                    >
                      <div
                        style={{ backgroundColor: tg.color }}
                        className="my-auto h-2 w-2 rounded-full mx-2"
                      ></div>
                      <p className="my-auto text-lg text-gray-500">{tg.nombre}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setTag(tg);
                      }}
                      className="h-auto flex justify-start cursor-pointer w-full border-b"
                    >
                      <div
                        style={{ backgroundColor: tg.color }}
                        className="h-2 w-2 rounded-full my-auto mx-2"
                      ></div>
                      <p className="text-lg text-gray-500 my-auto">{tg.nombre}</p>
                    </div>
                  );
                }
              })}
            {[5].includes(tipoS) &&
              automations.length > 0 &&
              automations.map((at) => {
                if (auto !== null && at.id === auto.id) {
                  return (
                    <div
                      onClick={() => {
                        setAuto(null);
                      }}
                      className="h-auto  justify-center cursor-pointer w-full border-b bg-gray-200 flex"
                    >
                      <p className="my-auto text-lg text-gray-500">{at.name}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setAuto(at);
                      }}
                      className="h-auto flex justify-center  cursor-pointer w-full border-b"
                    >
                      <p className="text-lg text-gray-500 my-auto">{at.name}</p>
                    </div>
                  );
                }
              })}
          </div>

          <div className="w-full   flex justify-around py-2">
            <button
              onClick={() => setShow(false)}
              className="rounded-lg h-10 bg-red-500 px-3 py-1 text-gray-100 font-semibold transform hover:scale-110 "
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="rounded-lg h-10 bg-blue-ligth px-3 py-1 text-gray-100 font-semibold transform hover:scale-110"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactoAction;
