import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Filtro from '../Filtro';
import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';

import Select from 'react-select';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Filters = ({ pagination, getResps }) => {
  const [agentes, setAgentes] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [mandantes, setMandantes] = useState([]);
  const [estados, setEstados] = useState([]);
  const [listas, setListas] = useState([]);
  const [query, setQuery] = useState('?');
  //const query = queryString.parse(location.search);
  let Q = useQuery();

  const filtrar = () => {
    window.location.href = `${window.location.pathname}?${query}`;
  };
  const limpiar = () => {
    // window.location.href = `${window.location.pathname}`;
    setQuery("")
  };

  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };
  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      setMandantes(
        res.data.map((man) => {
          return { value: man.codigo, label: man.nombre };
        }),
      );
    });
  };
  const getEstados = async () => {
    await axiosInstance.get('estados_cuenta/').then((res) => {
      setEstados(
        res.data.map((man) => {
          return { value: man.nombre, label: man.nombre };
        }),
      );
    });
  };
  const getListas = async () => {
    await axiosInstance.get('listas?status=1').then((res) => {
      setListas(
        res.data.map((man) => {
          return { value: man.id , label: man.nombre };
        }),
      );
    });
  };

  const handleChangeN = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
    //console.log(query)
  };
  const handleChange = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              // return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`;
              return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`);
    }
    //console.log(query)
  };

  const filter = () => {
    //if(query.length)
    console.log(query.length);
    if (query.length <= 6058) {
      window.location.href = `${window.location.pathname}?${query}`;
    } else {
      getResps(query);
    }
  };
  const filtrar2 = () =>{
    getResps(query.toString())
  }

  useEffect(() => {
    getMandantes();
    getEstados();
    getListas();
    setQuery(Q.toString().replace(/%2C/gi, ','));
    //console.log(Q.toString().replace('%2C',','))
  }, []);

  return (
    <Filtro
      pagination={pagination}
      filtrar={filtrar2}
      handleChange={handleChange}
      limpiar={limpiar}
      page_size={
        getQueryValue(query, 'page_size') !== null
          ? getQueryValue(query, 'page_size')
          : pagination.page_size
      }
      nombre="Contactos"
    >
      <div className="w-32 h-24 border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">
          Numero Cuenta
        </label>
        <input
          name="nro_cuenta__in"
          value={getQueryValue(query, 'nro_cuenta__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></input>
      </div>
      <div className="select-filter">
        <label>Mandantes</label>
        <Select
          onChange={handleSelect}
          name="mandante__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            mandantes.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'mandante__in', mandantes)}
          options={mandantes}
        />
      </div>
      <div className="select-filter">
        <label>Estados</label>
        <Select
          onChange={handleSelect}
          name="estado__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            estados.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'estado__in', estados)}
          options={estados}
        />
      </div>

      <div className="w-1/12 h-full border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">Rut</label>
        <textarea
          name="rut__in"
          value={getQueryValue(query, 'rut__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-1/12 h-full border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">Notificado</label>
        <select name="notificado" default="" value={getQueryValue(query,'notificado')} onChange={handleChangeN}>
            <option key={0} value="">--------</option> 
            <option key={1} value={true}>Notificado</option> 
            <option key={2} value={false}>No Notificado</option> 
        </select>
      </div>
      <div className="w-1/12 h-full border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">Embargado</label>
        <select name="embargado" default="" value={getQueryValue(query,'embargado')} onChange={handleChangeN}>
            <option key={0} value="">--------</option> 
            <option key={1} value={true}>Embargado</option> 
            <option key={2} value={false}>No Embargado</option> 
        </select>
      </div>
    </Filtro>
  );
};

export default Filters;
