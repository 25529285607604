import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Filtro from '../Filtro.js';

import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';

import Select from 'react-select';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Filters = ({ pagination }) => {
  const [expand, setExpand] = useState(false);
  const [agentes, setAgentes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [mandantes, setMandantes] = useState([]);
  const [camps, setCamps] = useState([]);
  const [query, setQuery] = useState('?');
  //const query = queryString.parse(location.search);
  let Q = useQuery();
  const filtrar = () => {
    window.location.href = `${window.location.pathname}?${query}`;
  };
  const limpiar = () => {
    window.location.href = `${window.location.pathname}`;
  };
  //make util
  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.codigo, label: man.nombre };
    });
  
  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };

  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };
  const getCamps = async () => {
    await axiosInstance.get('sms_campaigns?status=true').then((res) => {
      setCamps(
        res.data.map((camp) => {
          return { value: camp.id, label: camp.nombre };
        }),
      );
    });
  };

  const handleChangeCheck = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
  };

  const handleChange = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
  }

  useEffect(() => {
    getCamps();
    getMandantes();
    setQuery(Q.toString().replace(/%2C/gi, ','));
  }, []);

  return (
    <Filtro
      pagination={pagination}
      filtrar={filtrar}
      handleChange={handleChange}
      limpiar={limpiar}
      page_size={
        getQueryValue(query, 'page_size') !== null
          ? getQueryValue(query, 'page_size')
          : pagination.page_size
      }
      nombre="VoiceMsg"
    >
      <div className={`select-filter`}>
        <label>Mandante</label>
        <Select
          onChange={handleSelect}
          className="w-full"
          name="contacto__mandante__in"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsMandantes.map((item) => {
              //console.log(option);
              //console.log(item)
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  console.log(option);
                  console.log(item);
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'contacto__mandante__in', optionsMandantes)}
          options={optionsMandantes}
        />
      </div>
      <div className="w-32 h-24  flex flex-wrap">
        <div className="w-full h-1/2  ">
          <label className="text-gray-50 font-semibold text-sm flex justify-between w-full h-1/2">
            Fecha Desde
          </label>
          <input
            name="add_date__gte"
            value={getQueryValue(query, 'add_date__gte')}
            onChange={handleChange}
            type="date"
            className=" w-full text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-full h-1/2  ">
          <label className="text-gray-50 font-semibold flex text-sm justify-between w-full h-1/2">
            Fecha Hasta
          </label>
          <input
            name="add_date__lte"
            value={getQueryValue(query, 'add_date__lte')}
            onChange={handleChange}
            type="date"
            className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
      </div>
      <div className="w-1/12 h-32 border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full text-sm  mb-1">
          Rut
        </label>
        <textarea
          name="contacto__rut__in"
          value={getQueryValue(query, 'contacto__rut__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-1/12 h-32 border-b ">
        <label className="text-gray-50 font-semibold flex justify-between w-full text-sm  mb-1">
          Numero Cuenta
        </label>
        <textarea
          name="contacto__nro_cuenta__in"
          value={getQueryValue(query, 'contacto__nro_cuenta__in')}
          onChange={handleChange}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      {/*<div className="w-1/12 h-full border-b ">
                    <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2" >Rut</label>
                    <input name="contacto__rut__in" value={getQueryValue(query,'contacto__rut__in')} onChange={handleChange} type="text" className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"></input>
            </div>*/}

      <div className={`select-filter`}>
        <label>Campaña</label>
        <Select
          onChange={handleSelect}
          name="campaign__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            camps.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'campaign__in', camps)}
          options={camps}
        />
      </div>

    <div className="flex h-5 items-center flex flex-wrap">
        
        <label className="mr-4 font-bold text-white">Abierto</label>
          <input
            id="comments"
            type="checkbox"
            name="opened"
            onChange={handleChange}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
        </div>

    </Filtro>
  );
};

export default Filters;

{
  /*
getOptionLabel={(option) =>
                      optionsGrupo.map((item) => {
                        //console.log(option);
                        //console.log(item)
                        if(typeof option === 'number' || typeof option === 'string'){
                            if (item.value == option) {
                              return item.label;
                            }
                        }else if(typeof option === 'object'){
                            if (item.value == option.value) {
                              return item.label;
                            }

                        }
                        
                      })
                    }

    value={getQueryValueSelect(query,'tipo__grupo__in',optionsGrupo)}
*/
}
