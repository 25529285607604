import React from 'react';

const Script = (llamada) => {
  return (
    <div className="w-full h-full flex flex-wrap content-start p-5  rounded-xl">
      {llamada.llamada.script &&
        llamada.llamada.script.length > 0 &&
        llamada.llamada.script.map((bloque) => {
          return (
            <div className="flex w-full h-auto border border-gray-300 mt-2 px-3 rounded-lg">
              <div className="w-1/6 h-full flex border-r border-gray-300 p-2">
                <p className=" break-word font-semibold my-auto ">{bloque && bloque.nombre}</p>
              </div>
              <div className="w-5/6 h-full p-3">
                <p className="text-gray-900  text-2xl break-words">{bloque && bloque.cuerpo}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Script;
