import React, { useState, useEffect } from 'react';

import OperacionRow from './OperacionsRow';

import axiosInstance from '../../axios';

import format from '../../utils/formatDate';
import { CSVLink, CSVDownload } from 'react-csv';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';

const OperacionesT = ({ ops, pagination, move, removeOp }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [agentes,setAgentes] = useState([])

  const addSelected = (op) => {
    setSelected([...selected, op]);
  };
  const removeSelected = (op) => {
    setSelected(selected.filter((x) => x.id !== op.id));
  };
  const getAgentes = async () => {
    await axiosInstance.get(`users/?rol__in=1,2&status=1`).then((res) => {
      setAgentes(res.data);
    });
  };
 

  const handleCheckAll = (e) => {
    if (checkedAll === false) {
      setSelected(ops);
    } else {
      setSelected([]);
    }

    setCheckedAll(!checkedAll);
  };

  const getData = () => {
    return selected.map((row) => {
      return {
        fecha: `${format(row.fecha)}`,
        agente: `${row.user.first_name} ${row.user.last_name}`,
        operador: `${row.user.first_name} ${row.user.last_name}`,
        tipo: `${row.tipo}`,
        comentario: `${row.comentario}`,
      };
    });
  };

  useEffect(()=>{
    getAgentes()

  },[])


  return (
    <table className="w-full ">
      <thead className="bg-gray-200">
        <th className="border w-10">
          <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} />
        </th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Contacto</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Fecha</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Agente</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Operador</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Tipo</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
          Reasignar A
        </th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
          Comentario
        </th>
        
        {/* <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Respuesta</th> */}
        <th className="border p-1 w-10">
          {pagination.previous && (
            <button
              onClick={() => move(pagination.previous)}
              className="border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-lg"
            >
              Prev
            </button>
          )}
        </th>
        <th className="border p-1 w-10">
          <div className="p-1 rounded-sm text-white bg-blue-700">{pagination.page_number}</div>
        </th>
        <th className="border p-1 w-10">
          {pagination.next && (
            <button
              onClick={() => move(pagination.next)}
              className="border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-lg"
            >
              next
            </button>
          )}
        </th>
        <th className="border p-1 w-10">
          <CSVLink filename="respuestas.csv" data={getData()}>
            <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
          </CSVLink>
        </th>
      </thead>
      <tbody>
        {ops &&
          ops.length > 0 &&
          ops.map((o) => {
            return (
              <OperacionRow
                removeOp={removeOp}
                selected={selected}
                o={o}
                addSelected={addSelected}
                removeSelected={removeSelected}
                agentes={agentes}
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default OperacionesT;
