import { Fragment } from 'react'
import format from '../../utils/formatDate';
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import {PersonFill} from '@styled-icons/bootstrap/PersonFill'
import {Database} from '@styled-icons/fa-solid/Database'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const Movs = ({movs}) =>{
  console.log(movs)
  return (

    <div className="w-full flex justify-center gap-x-3">
    <div className="flow-root w-2/3">
      <ul role="list" className="-mb-8">
        {movs.length > 0 && movs.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id}>
            <div className="relative pb-8">
              {activityItemIdx !== movs.length - 1 ? (
                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {activityItem.tipo === 'comment' ? (
                  <>
                    <div className="relative">
                      {activityItem.user && activityItem.user.picture ? 
                        <img
                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                        src={activityItem.user.picture}
                        alt=""
                      />
                      :
                      <div
                          className="h-10 w-10 rounded-full  bg-gray-300 flex justify-center items-center ring-8 ring-white"
                          // src={agente.picture}
                        ><PersonFill size="24" className="text-gray-500"/></div>


                      }
                      

                      <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                        <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <a  className="font-medium text-gray-900">
                            {activityItem.user && `${activityItem.user.first_name} ${activityItem.user.last_name}` }
                          </a>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">Comento {format(activityItem.add_datetime)}</p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{activityItem.description}</p>
                      </div>
                    </div>
                  </>
                ) : activityItem.tipo === 'data' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                          <Database className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1.5">
                      <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white">
                  <div className="flex justify-between gap-x-4">

                      <div className="py-0.5 text-xs leading-5 text-gray-500">
                            <span className="font-medium text-gray-900">{activityItem.user && `${activityItem.user.first_name} ${activityItem.user.last_name}` } </span>
                            
                          </div>
                            <span className="text-xs leading-5 font-medium text-gray-500">{activityItem.origen }</span>
                          <time dateTime={activityItem.add_datetime} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                            {format(activityItem.add_datetime)}
                          </time>
                        </div>
                        {activityItem.data.changes && activityItem.data.changes.map((change)=>{
                          return <p className="text-sm leading-6 text-gray-500 w-full justify-between flex">{change.field}:   {change.old} -> {change.new}</p>
                          })
                        }
                        
                      </div>

                    </div>
                  </>
                ) : activityItem.tipo === 'tags' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                          <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-0">
                      <div className="text-sm leading-8 text-gray-500">
                        <span className="mr-0.5">
                          <a  className="font-medium text-gray-900">
                            {activityItem.user && `${activityItem.user.first_name} ${activityItem.user.last_name}` }
                          </a>{' '}
                              {activityItem.description}
                        </span>{' '}
                        <span className="mr-0.5">
                          {activityItem.tags.map((tag) => (
                            <Fragment key={tag.nombre}>
                              <a
                                className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 bg-white"
                              >
                                <svg
                                  fill={tag.color}
                                  className={classNames('', 'h-1.5 w-1.5')}
                                  viewBox="0 0 6 6"
                                  aria-hidden="true"
                                >
                                  <circle cx={3} cy={3} r={3} />
                                </svg>
                                {tag.nombre}
                              </a>{' '}
                            </Fragment>
                          ))}
                        </span>
                        <span className="whitespace-nowrap">{format(activityItem.add_datetime)}</span>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    </div>

  )
}
export default Movs;
