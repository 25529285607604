import { ActionTypes } from '../constants/action-types';

const initialState = {
  device: null,
};

export const deviceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_DEVICE:
      return { ...state, device: payload };
    default:
      return state;
  }
};
