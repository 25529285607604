import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import UsersTable from '../../components/admin/UsersTable.jsx'

// import CreateUModal from '../../components/users/CreateUModal';
// import CreateModal from '../../components/campaigns/CreateModal';
import CreateModal from '../../components/callCampaigns/CreateModal';
import TestBar from '../../components/users/TestBar';

import ViewOrder from '../../components/callCampaigns/ViewOrder'

import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import FastFiltro from '../../components/FastFiltro'

const statusCamps = [{name:'Activas',value:1},{name:'Inactivas',value:2}]
const Campaigns2 = () =>{
  const [objs,setObjs] = useState([])
  const [data,setData] = useState({nombre:'',dominio:''})
  const [create,setCreate] = useState(false)
  const [change,setChange] = useState(null)
  const [display,setDisplay] = useState([])

  const [status,setStatus] = useState(1)
  const [archivada,setArchivada] = useState(false)

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }

  const getObjs = async () =>{
    await axiosInstance.get(`campaigns?status=${status}&archivada=${archivada}`).then(res=>{
      setObjs(res.data) 
      setDisplay(res.data) 
    })
  }

  const addObj = async () =>{
    
    data.nombre !== '' && await axiosInstance.post(`mail_domains/`,data).then(res=>{
      setData({nombre:'',dominio:''})
      getObjs()
      setCreate(false)
    })
  }

  const filterDisplay = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    setDisplay(
      objs.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  useEffect(()=>{
    getObjs()

  },[change,status,archivada])

  return (
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5 overflow-y-scroll ">
      <CreateModal open={create} setOpen={setCreate} setChange={setChange}/>
        {/*create === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateModal
            setModal={setCreate}
            setChange={setChange}
            setCamps={setObjs}
            camps={objs}
          />
        </div>
      )*/}

      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Camps. Llamadas</p> <div onClick={()=>setCreate(!create)} className="rounded-md w-auto px-2 py-1 bg-blue-ligth text-gray-50 transform hover:scale-105 cursor-pointer">Crear</div>
      </div>

        <div className="px-4  w-full flex flex-wrap justify-start content-start pb-12 gap-y-2">
            <FastFiltro>
              <input onChange={filterDisplay} name="nombre" className="w-64 border-2  bg-gray-100 px-2 py-1 rounded-md text-gray-700" placeholder="Buscar"></input>

              <select className={`border-l-8 p-2 bg-gray-200 cursor-pointer border-${status === 1 ? 'green-500' : 'red-500'}`} name="status" value={status} onChange={(e)=>setStatus(e.target.value)} >
                  {statusCamps.map((op)=>{
                    return <option value={op.value} >{op.name}</option>
                  })}
              </select>
              <select className={`border-l-8 p-2 bg-gray-200 cursor-pointer border-${archivada === true ? 'blue-500' : 'green-500'}`} name="archivada" value={archivada} onChange={(e)=>setArchivada(e.target.value)} >
                    <option value={false} >No Archivada</option>
                    <option value={true} >Archivada</option>
              </select>
            </FastFiltro>
            <ViewOrder objs={display} setChange={setChange}></ViewOrder>
            {/* <UsersTable objs={objs} getObjs={getObjs}/> */}
        </div> 

          {/* <TestBar></TestBar>  */}
      </div>
  )
}

export default Campaigns2;

  
