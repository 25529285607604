import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import { useLocation } from 'react-router-dom';

import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';

import Select from 'react-select';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Filters = ({ setModal }) => {
  const [agentes, setAgentes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [mandantes, setMandantes] = useState([]);
  const [camps, setCamps] = useState([]);
  const [estados, setEstados] = useState([]);
  const [query, setQuery] = useState('?');
  //const query = queryString.parse(location.search);
  let Q = useQuery();

  //make util
  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.codigo, label: man.nombre };
    });
  const optionsEstados =
    estados &&
    estados.map((man) => {
      return { value: man.nombre, label: man.nombre };
    });

  const optionsTipos =
    tipos &&
    tipos.map((tipo) => {
      return { value: tipo.id, label: `${tipo.nombre}` };
    });
  const optionsGrupo = [
    { value: 1, label: 'Positiva' },
    { value: 2, label: 'Negativa' },
    { value: 3, label: 'rellamada' },
  ];
  const statuss = [
    { value: 1, label: 'Realizada' },
    { value: 2, label: 'Pendiente' },
    { value: 3, label: 'Rellamada' },
  ];
  const autorizacion= [
    { value: 'true', label: 'autorizado' },
    { value: 'false', label: 'sin autorizacion' }
  ];

  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };

  const getTipos = async () => {
    await axiosInstance.get(`trespuestas/`).then((res) => {
      setTipos(res.data);
    });
  };
  const getCamps = async () => {
    await axiosInstance.get('campaigns?status=1').then((res) => {
      setCamps(
        res.data.map((camp) => {
          return { value: camp.id, label: camp.nombre };
        }),
      );
    });
  };
  const getMandantes = async () => {
    await axiosInstance.get('mandantes?is_active=true').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };
  const getEstados = async () => {
    await axiosInstance.get('estados_cuenta/').then((res) => {
      setEstados(res.data);
    });
  };

  const handleChangeF = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              // return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`;
              return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',').replace(new RegExp('[\r\n]', 'gm'), ',')}`
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`);
    }
    //console.log(query)
  };
  const handleChange = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
  };

  useEffect(() => {
    getCamps();
    getTipos();
    getMandantes();
    getEstados();
    setQuery(Q.toString().replace(/%2C/gi, ','));
  }, []);

  return (
    <div className="absolute z-30 w-10/12 flex gap-x-3 justify-start gap-x-4 flex-wrap content-start h-auto px-3 py-2 bg-gray-dark rounded-md gap-2">
      <div className="flex flex-wrap content-start w-3/12 h-auto ">
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          mandante
        </label>
        <Select
          onChange={handleSelect}
          className="w-full"
          name="contacto__mandante__in"
          isMulti
          closeMenuOnSelect={false}
          
          getOptionLabel={(option) =>
            optionsMandantes.map((item) => {
              //console.log("option",option);
              //console.log("item",item)
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'contacto__mandante__in', optionsMandantes)}
          options={optionsMandantes}
        />
      </div>
      <div className="flex flex-wrap content-start w-3/12 h-auto ">
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Estados
        </label>
        <Select
          onChange={handleSelect}
          className="w-full"
          name="contacto__estado__in"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsEstados.map((item) => {
              console.log(option);
              console.log(item);
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'contacto__estado__in', optionsEstados)}
          options={optionsEstados}
        />
      </div>

      <div className="w-1/12 h-36 ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">
          Numero Cuenta
        </label>
        <textarea
          name="contacto__nro_cuenta__in"
          value={getQueryValue(query, 'contacto__nro_cuenta__in')}
          onChange={handleChangeF}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-1/12 h-36  ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">Rut</label>
        <textarea
          name="contacto__rut__in"
          value={getQueryValue(query, 'contacto__rut__in')}
          onChange={handleChangeF}
          type="text"
          className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring break-words"
        ></textarea>
      </div>
      <div className="w-1/12 h-36  ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">
          Nombre
        </label>
        <input
          value={getQueryValue(query, 'contacto__nombre__icontains')}
          name="contacto__nombre__icontains"
          onChange={handleChange}
          type="text"
          className="w-full rounded-sm p-1"
          placeholder="Nombre"
        ></input>
      </div>
      <div className="w-1/12 h-36  ">
        <label className="text-gray-50 font-semibold flex justify-between w-full h-1/2">
          Apellido
        </label>
        <input
          value={getQueryValue(query, 'contacto__apellido__icontains')}
          name="contacto__apellido__icontains"
          onChange={handleChange}
          type="text"
          className="w-full rounded-sm p-1"
          placeholder="Nombre"
        ></input>
      </div>

      <div className="flex flex-wrap w-1/12 h-36  content-start justify-end gap-y-2">
        <button
          onClick={() => (window.location.href = `${window.location.pathname}?${query}`)}
          className="bg-blue-ligth h-6 w-full text-white rounded-lg  flex justify-center"
        >
          <p className="my-auto">Filtrar</p>
        </button>
        <button
          onClick={() => {
            setQuery('?');
            window.location.href = `${window.location.pathname}`;
          }}
          className="bg-blue-400 h-6 w-full text-white rounded-lg  flex justify-center"
        >
          <p className="my-auto">Limpiar</p>
        </button>
        <button
          onClick={() => setModal(false)}
          className="bg-red-400 h-6 w-full text-white rounded-lg  flex justify-center"
        >
          <p className="my-auto">Cerrar</p>
        </button>
      </div>

      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Campaña
        </label>
        <Select
          onChange={handleSelect}
          name="campaign__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            camps.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'campaign__in', camps)}
          options={camps}
        />
      </div>
      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Status llamada
        </label>
        <Select
          onChange={handleSelect}
          name="status__in"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            statuss.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'status__in', statuss)}
          options={statuss}
        />
      </div>
      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Ultima Respuesta
        </label>
        <Select
          onChange={handleSelect}
          name="ultima_resp"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsTipos.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'ultima_resp', optionsTipos)}
          options={optionsTipos}
        />
      </div>
      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Tiene Respuesta
        </label>
        <Select
          onChange={handleSelect}
          name="has_resp"
          className="w-full"
          isMulti
          closeMenuOnSelect={false}
          getOptionLabel={(option) =>
            optionsTipos.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'has_resp', optionsTipos)}
          options={optionsTipos}
        />
      </div>
      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Excluir Respuesta
        </label>
        <Select
          onChange={handleSelect}
          name="exclude_resp"
          className="w-full"
          closeMenuOnSelect={false}
          isMulti
          getOptionLabel={(option) =>
            optionsTipos.map((item) => {
              if (typeof option === 'number' || typeof option === 'string') {
                if (item.value == option) {
                  return item.label;
                }
              } else if (typeof option === 'object') {
                if (item.value == option.value) {
                  return item.label;
                }
              }
            })
          }
          value={getQueryValueSelect(query, 'exclude_resp', optionsTipos)}
          options={optionsTipos}
        />
      </div>

      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Embargado
        </label>
        <select name="contacto__embargado" onChange={handleChange} value={getQueryValue(query,'contacto__embargado')}>
            <option value={''} key={0}>------</option>
            <option value={true} key={1}>Embargado</option>
            <option value={false} key={2}>No Embargado</option>
        </select>
              </div>
    <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
         Notificado 
        </label>
        <select name="contacto__notificado" onChange={handleChange} value={getQueryValue(query,'contacto__notificado')}>
            <option value={''} key={0}>------</option>
            <option value={true} key={1}>Notificado</option>
            <option value={false} key={2}>No Notificado</option>
        </select>
              </div>
      <div className={`flex flex-wrap content-start w-2/12 h-auto `}>
        <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-full mb-1">
          Autorizacion
        </label>
        <select name="contacto__autorizacion" onChange={handleChange} value={getQueryValue(query,'contacto__autorizacion')}>
            <option value={''} key={0}>------</option>
            <option value={'true'} key={1}>Autorizado</option>
            <option value={'false'} key={2}>No Autorizado</option>
        </select>
              </div>


      <div className="w-32 h-24  flex flex-wrap">
        <div className="w-full h-1/2  ">
          <label className="text-gray-50 font-semibold text-sm flex justify-between w-full h-1/2">
            Asignacion Desde
          </label>
          <input
            name="contacto__fecha_asignacion__gte"
            value={getQueryValue(query, 'contacto__fecha_asignacion__gte')}
            onChange={handleChange}
            type="date"
            className=" w-full text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-full h-1/2  ">
          <label className="text-gray-50 font-semibold flex text-sm justify-between w-full h-1/2">
            Asignacion Hasta
          </label>
          <input
            name="contacto__fecha_asignacion__lte"
            value={getQueryValue(query, 'contacto__fecha_asignacion__lte')}
            onChange={handleChange}
            type="date"
            className=" w-full  text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
      </div>
    </div>
  );
};

export default Filters;

{
  /*
getOptionLabel={(option) =>
                      optionsGrupo.map((item) => {
                        //console.log(option);
                        //console.log(item)
                        if(typeof option === 'number' || typeof option === 'string'){
                            if (item.value == option) {
                              return item.label;
                            }
                        }else if(typeof option === 'object'){
                            if (item.value == option.value) {
                              return item.label;
                            }

                        }
                        
                      })
                    }

    value={getQueryValueSelect(query,'tipo__grupo__in',optionsGrupo)}
*/
}
