import React, { useEffect, useState } from 'react';

import InsertExcel from '../../components/carga/datos/InsertExcel';

import axiosIntance from '../../axios';
import { CSVLink } from 'react-csv';

import { FileExcel } from '@styled-icons/fa-solid/FileExcel';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const Download = ({ cols }) => {
  return (
    <ExcelFile
      element={<FileExcel size="32" className="text-green-600 transform hover:scale-125" />}
    >
      <ExcelSheet data={[]} name="">
        {cols &&
          cols.length > 0 &&
          cols.map((col) => {
            return <ExcelColumn label={col} value={col} />;
          })}
      </ExcelSheet>
    </ExcelFile>
  );
};

const CargaDatos = () => {
  const [fields, setFields] = useState([]);
  const [selectedFields, setSFields] = useState(['nro_cuenta']);
  const [csv, setCsv] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);

  const getFields = async () => {
    await axiosIntance.get('contactos/fields').then((res) => {
      setFields(res.data.filter((x) => x !== 'nro_cuenta'));
    });
  };

  const selectField = (f) => {
    setSFields([...selectedFields, f]);
    setFields(fields.filter((x) => x !== f));
  };

  const unselectField = (f) => {
    setFields([...fields, f]);
    setSFields(selectedFields.filter((x) => x !== f));
  };

  const getCSV = () => {
    return [selectedFields];
  };

  useEffect(() => {
    getFields();
    return () => {};
  }, []);
  return (
    <div className="relative h-full w-full bg-white flex flex-wrap">
      {uploadModal && <InsertExcel setModal={setUploadModal}></InsertExcel>}
      <div className="h-1/6 w-full border-b flex p-4">
        <div className="w-1/2 h-full bg-gray-200 rounded-lg flex">
          <div className="w-2/12 border-r border-gray-dark flex justify-center">
            <p className="my-auto text-lg font-bold text-gray-dark">Campos</p>
          </div>
          <div className="w-10/12 flex flex-wrap overflow-y-scroll scroll gap-2 p-1">
            {fields &&
              fields.length > 0 &&
              fields.map((field) => {
                return (
                  <button
                    onClick={() => selectField(field)}
                    className="w-32 h-10 bg-gray-dark font-semibold text-white rounded-lg flex justify-center hover:bg-gray-normal"
                  >
                    <p className="my-auto overflow-hidden">{field}</p>
                  </button>
                );
              })}
          </div>
        </div>
        <div className="w-1/2 justify-around flex h-full">
          <div className="w-32 w-32 rounded-lg">
            <CloudUpload
              onClick={() => setUploadModal(!uploadModal)}
              size=""
              className="h-5/6 w-5/6 transform hover:scale-110 hover:text-blue-500 text-blue-ligth"
            ></CloudUpload>
          </div>
        </div>
      </div>
      <div className="h-5/6 w-full py-2 px-4">
        <table className="w-full border border-gray-dark">
          <thead className="h-10">
            {selectField.length > 0 &&
              selectedFields.map((field) => {
                return (
                  <th
                    onClick={() => unselectField(field)}
                    className="border border-gray-dark h-10 hover:bg-red-100"
                  >
                    {field}
                  </th>
                );
              })}

            <th className="border border-gray-dark h-10 w-10">
              <CSVLink filename="CargaDatos.csv" data={getCSV()}>
                <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
              </CSVLink>
            </th>
            <th className="border border-gray-dark h-10 w-10">
              <Download cols={selectedFields}></Download>
            </th>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default CargaDatos;
