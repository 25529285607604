import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';

import { Delete } from '@styled-icons/typicons/Delete';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { XCircle } from '@styled-icons/bootstrap/XCircle';
import { Circle } from '@styled-icons/boxicons-regular/Circle';
import { create } from 'lodash';

const CreateRModal = ({ setModal, setChange }) => {
  const [pas, setPas] = useState(false);
  const [status, setStatus] = useState(1);
  const [mandantes, setMandantes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    nombre: null,
    mandante: null,
    week: null,
    month: null,
    is_active: true,
  });

  const close = () => {
    setModal(false);
  };
  //setNumeros(numeros.filter(x=> x.numero !== numero)
  const handleChange = (e) => {
    console.log(e.target.value);

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getMandantes = () => {
    axiosInstance.get('mandantes/').then((res) => {
      setMandantes(res.data);
      if (res.data.length > 0) {
        setData({ ...data, ['mandante']: res.data[0].id });
      }
    });
  };

  const addRegla = () => {
    //console.log(data)
    var errs = [];
    axiosInstance
      .post(`reglas/`, { ...data, ['is_active']: status })
      .then((res) => {
        setChange(res.data);
        setModal(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              errs.push(`${property} : ${err.response.data[property]}`);
            }
          }
        } else {
        }
        setErrors(errs);
      });
  };

  useEffect(() => {
    getMandantes();
  }, []);

  return (
    <div className="flex flex-wrap ml-96 mt-6 flex-col h-4/6 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
      <div className="bg-white h-5/6 w-full shadow overflow-hidden sm:rounded-lg overflow-y-scroll border border-gray-300">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Nombre</dt>
              <input
                type="text"
                placeholder="..."
                name="nombre"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Intentos x Semana</dt>
              <input
                type="number"
                placeholder="itentos por semana"
                name="week_tries"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Intentos x Mes</dt>
              <input
                type="number"
                placeholder="Intentos por mes"
                name="month_tries"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Confirmaciones x Semana</dt>
              <input
                type="number"
                placeholder="confirmaciones por semana"
                name="week_confirms"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Confirmaciones x Mes</dt>
              <input
                type="number"
                placeholder="cofirmaciones por semana"
                name="month_confirms"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Pendientes x Semana</dt>
              <input
                type="number"
                placeholder="pendients por semana"
                name="week_pendientes"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Pendientes x Mes</dt>
              <input
                type="number"
                placeholder="pendientes por mes"
                name="month_pendientes"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Maximas Pendientes</dt>
              <input
                type="number"
                placeholder="maximas pendientes"
                name="pendientes"
                onChange={handleChange}
                className="mt-1 py-1 px-1 text-sm bg-white border border-gray-800 text-gray-900 sm:mt-0 sm:col-span-2"
              ></input>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Mandante</dt>
              <select
                name="mandante"
                onChange={handleChange}
                className="border-none w-full font-bold bg-gray-100 inline-block py-2 px-2 rounded leading-tight w-full"
              >
                {mandantes.map((man) => {
                  return (
                    <option
                      key={man.id}
                      className="bg-white font-bold text-green-400"
                      value={man.id}
                    >
                      {man.nombre}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">status</dt>
              {status == 1 ? (
                <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                      <input
                        id="unchecked"
                        onClick={() => setStatus(2)}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Activa</span>
                </label>
              ) : (
                <label for="checked" class="mt-3 inline-flex items-center cursor-pointer ">
                  <span class="relative">
                    <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                    <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                      <input
                        id="checked"
                        onClick={() => setStatus(1)}
                        type="checkbox"
                        class="absolute opacity-0 w-0 h-0"
                      />
                    </span>
                  </span>
                  <span class="ml-3 text-sm">Inactiva</span>
                </label>
              )}
            </div>
          </dl>
        </div>
      </div>

      <div className="h-1/6 w-full flex flex-wrap justify-center items-center">
        <div className="flex justify-center h-1/3 w-full">
          <button
            onClick={() => close()}
            className="h-full  focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
          >
            Cancelar
          </button>

          <button
            onClick={() => addRegla()}
            className="h-full focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Crear
          </button>
        </div>
        {errors.length > 0 && (
          <div className="flex flex-wrap h-2/3 px-20 pt-2 overflow-y-scroll w-full">
            {errors.length > 0 &&
              errors.map((error) => {
                return (
                  <div
                    className="bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                    role="alert"
                  >
                    <p className="font-bold w-full">Error</p>
                    <p clasName="w-full">{error}</p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateRModal;
