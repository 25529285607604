import React, { useState, useEffect } from 'react';

import axiosInstance from '../axios';
import format from '../utils/formatDate';
import { getLlamadaStatusP } from '../utils/models';
import { useSelector, useDispatch } from 'react-redux';

import toast from 'react-hot-toast';

import CreateNumModal from './contactos/modals/createNumModal';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { XCircle } from '@styled-icons/bootstrap/XCircle';
import { Circle } from '@styled-icons/boxicons-regular/Circle';

import { ArrowBack } from '@styled-icons/evaicons-solid/ArrowBack';

const Incoming = ({ tresp, setGrupo, grupo, respuestas, contacto, setShowIncoming }) => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [numero, setNumero] = useState(null);
  const [numeros, setNumeros] = useState([]);
  const [agendada, setAgendada] = useState(false);
  const [move, setMove] = useState(false);
  const [guardada, setGuardada] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [nextFecha, setNextFecha] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    llamada: null,
    tipo: null,
    agente: user.id,
    observacion: null,
  });
  const [dataA, setDataA] = useState({
    agente: user.id,
    fecha_asignada: null,
    contacto: contacto.id,
    status: 3,
  });
  const [addNumModal, setAddNumModal] = useState(false);

  var user = JSON.parse(localStorage.getItem('user'));
  const [sel, setSel] = useState(1);
  //const [respuestas, setRespuestas] = useState([]);
  const [prox, setProx] = useState([]);

  const agendar = () => {
    //console.log(dataA)
    if (dataA['fecha_asignada'] !== null) {
      axiosInstance.post('llamadas/', { ...dataA }).then((res) => {
        if (res !== undefined && res.status == 201) {
          setAgendada(true);
        }
      });
    }
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeA = (e) => {
    setDataA({
      ...dataA,
      [e.target.name]: e.target.value,
    });
  };

  const sendRespuesta = (e) => {
    e.preventDefault();
    //console.log(respuestas[0].id)
    console.log(data);
    //console.log(llamada.llamada)

    if (guardada === false && loading === false) {
      setLoading(true);
      var toastId = toast.loading('guardando respuesta');
      axiosInstance
        .post('respuestas/incoming/', { ...data, contacto: contacto.id })
        .then((res) => {
          setGuardada(true);
          setLoading(false);
          toast.dismiss(toastId);
          toast.success('Respuesta Guardada', { duration: 4000 });
        })
        .catch(() => {
          toast.dismiss(toastId);
          toast.error('Error', { duration: 4000 });
        });
    }
    //if(llamada.type === 'ext'){
    //    axiosInstancj [
    //        .patch('llamadas/'+llamada.llamada.id+'/',{date:getDateTime(),status:1,"numero_to":llamada.numero.numero})
    //}
    //setModal(false)
  };

  const close = () => {
    //setModal(false)
  };

  const getNumeros = async () => {
    axiosInstance.get(`numeros?contacto=${contacto.id}`).then((res) => {
      setNumeros(res.data);
    });
  };

  const getDateTime = () => {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;
    return dateTime;
  };

  useEffect(() => {
    respuestas.length > 0 && setData({ ...data, tipo: respuestas[0].id });
    getNumeros();
  }, [grupo]);

  return (
    <div className="w-full h-1/3 flex flex-wrap cotent-start relative">
      {addNumModal && (
        <CreateNumModal
          contacto={contacto}
          getNumeros={getNumeros}
          setAddNumModal={setAddNumModal}
        />
      )}
      <div className="flex flex-wrap justify-start content-start items-start  pt-1 bg-white h-full w-full px-1 pt-5">
        <div className=" w-full h-full flex   border-collapse ">
          {sel === 1 && (
            <form
              onSubmit={sendRespuesta}
              className="flex flex-wrap content-start pb-1 w-full h-full px-1"
            >
              <div className="flex flex-wrap w-3/12 h-16  bg-white mb-1">
                <label className="w-full h-1/2 ml-2">
                  <h1 className="w-full mb-1 font-bold">Respuesta</h1>
                  <select
                    className="w-5/6 h-full  border rounded-lg"
                    name="tipo"
                    onChange={handleChange}
                  >
                    {respuestas
                      ? respuestas.map((resp) => {
                          return <option value={resp.id}>{resp.nombre}</option>;
                        })
                      : ''}
                  </select>
                </label>
              </div>
              {/* {tresp === 3 && <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1"> */}
              {/* <label className="w-11/12 h-1/2 "> */}
              {/* <h1 className="w-full mb-1 font-bold">Proxima Fecha</h1> */}
              {/* <input type="date" name="next_date" onChange={handleChange} className="w-full hidden"></input> */}
              {/* </label> */}

              {/* </div>} */}

              <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Llamador</h1>
                  <select
                    className="w-1/2 h-full appearance-none border-rounded-lg"
                    name="agente"
                    onChange={handleChange}
                  >
                    <option value={user.id}>{user.first_name + ' ' + user.last_name}</option>
                  </select>
                </label>
              </div>
              <div className="flex flex-wrap w-3/12 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold flex justify-between">
                    Numero
                    <PlusCircle
                      onClick={() => setAddNumModal(true)}
                      size="20"
                      className="text-green-600 mt-1 justify-self-end self-end transform hover:scale-125"
                    />
                  </h1>

                  <div className="flex justify-around">
                    <select
                      name="numero"
                      type="number"
                      onChange={handleChange}
                      className="border-none inline-block py-2 px-2 rounded leading-tight w-full"
                    >
                      <option key={0} className="bg-green-200" value={0}>
                              -------------------
                            </option>
                      {numeros.map((num) => {
                        if (num.verified === 1 && num.status === 1) {
                          return (
                            <option key={num.id} className="bg-green-200" value={num.id}>
                              {num.numero}
                            </option>
                          );
                        }
                        if (num.verified === 2 && num.status === 1) {
                          return (
                            <option key={num.id} className="" value={num.id}>
                              {num.numero}
                            </option>
                          );
                        }
                        if (num.verified === 3 && num.status === 1) {
                          return (
                            <option key={num.id} className="bg-red-300" value={num.id}>
                              {num.numero}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </label>
              </div>
              <div className="flex flex-wrap w-full h-20 bg-white place-self-end px-4">
                <label className="w-full h-full">
                  <h1 className="w-full mb-1 font-bold">Observacion</h1>
                  <textarea
                    type="text"
                    name="observacion"
                    onChange={handleChange}
                    className="bg-gray-200 w-full h-20 rounded-lg text-start"
                  ></textarea>
                </label>
              </div>

              <div className="flex justify-end w-full h-10 px-1 ">
                <div className="flex  w-full px-1 justify-around ">
                  {/*<div className="flex flex-wrap w-1/5 mr-4 ml-4 justify-center h-full bg-blue-normal place-self-end px-4">
				 <p className=" text-gray-100  font-bold text-md xl:text-xl my-auto">Agendar</p>
				<ArrowBack size="30" onClick={()=>setSel(2)} className="font-bold text-white my-auto transform hover:scale-125 rotate-180"></ArrowBack>   
				    </div>*/}

                  {/*<button onClick={()=>{setShowIncoming(false)}} type="button" className="w-24 hover:bg-red-600 border  bg-red-500 h-10 px-2 text-white font-bold rounded-lg mt-8">Cerrar</button>*/}
                  <button
                    onClick={() => {
                      setGrupo(true);
                    }}
                    type="button"
                    className="w-24 hover:bg-blue-500 border  bg-blue-ligth h-10 px-2 text-white font-bold rounded-lg mt-8"
                  >
                    Volver
                  </button>
                  {guardada === false && (
                    <button
                      type="submit"
                      className="w-24 hover:bg-green-600 border  bg-green-500 h-10 px-2 text-white font-bold rounded-lg mt-8"
                    >
                      Guardar
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
          {sel === 2 && (
            <div className="flex flex-wrap w-full h-full px-1">
              <div className="flex flex-wrap w-1/3 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Fecha</h1>
                  <input type="date" name="fecha_asignada" onChange={handleChangeA}></input>
                </label>
              </div>

              <div className="flex flex-wrap w-1/3 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Llamador</h1>
                  <select
                    className="w-1/2 h-full appearance-none border-rounded-lg"
                    name="llamador"
                    onChange={handleChangeA}
                  >
                    <option value={user.id}>{user.first_name + ' ' + user.last_name}</option>
                  </select>
                </label>
              </div>
              <div className="flex flex-wrap w-1/3 h-16 bg-white mb-1">
                <label className="w-full h-1/2 ml-4">
                  <h1 className="w-full mb-1 font-bold">Contacto</h1>
                  <h1 className="w-2/3 font-bold bg-gray-300 p-1">
                    {contacto.nombre + ' ' + contacto.apellido}
                  </h1>
                </label>
              </div>

              <div className="w-1/2 h-40 border flex flex-wrap bg-gray-100 rounded-lg overflow-hidden">
                <p className="h-8 text-xl font-bold w-full text-center border-b">
                  Proximas Llamadas
                </p>
                <div className="w-full h-32 overflow-y-scroll flex flex-wrap">
                  {prox &&
                    prox.length > 0 &&
                    prox.map((ll) => {
                      return (
                        <div className="w-full h-8 flex border-b">
                          <p className="my-auto w-1/2 text-center text-md ">
                            {format(ll.fecha_asignada)}
                          </p>
                          <p className="my-auto w-1/2 text-center text-md ">
                            {ll.agente.first_name} {ll.agente.last_name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="flex justify-end w-full px-1 border">
                {agendada && (
                  <div className="flex flex-wrap w-5/6 mx-auto justify-center h-10 rounded-md bg-green-400 place-self-end px-4">
                    <p className=" text-gray-100  font-bold text-xl my-auto">Llamada agendada</p>
                  </div>
                )}
                {loading === false && guardada === false && (
                  <button
                    onClick={() => {
                      loading === false && agendar();
                    }}
                    type="button"
                    className="w-24 hover:bg-green-600 border  bg-green-500 h-10 px-2 text-white font-bold rounded-lg"
                  >
                    Agendar
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Incoming;
