import React, { Component } from 'react';

import PanelAlgo from '../components/dashboardAgente/PanelAlgo';

export class DashboardAgente2 extends Component {
  render() {
    return <PanelAlgo />;
  }
}

export default DashboardAgente2;
