import React, { useState } from 'react';
import axiosInstance from '../axios';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast'

import {API} from '../vars.js'
import { UserCircle } from '@styled-icons/boxicons-solid/UserCircle';
import { LockOpenAlt } from '@styled-icons/boxicons-regular/LockOpenAlt';
const Forgot = () => {
  // const url = 'http://localhost:8000/api/token/'
  // const url = 'https://apicall.cob.cl/api/token/';
  const history = useHistory();
  const [enviado,setEnviado] = useState(false)
  const initialFormData = Object.freeze({
    email: '',
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios //Instance
      .post(`${API}api/password_reset/`, {
        email: formData.email,
      })
      .then((res) => {
        console.log(res.data.status)
        if(res.data.status == 'OK'){
          // console.log('enviado')
          setEnviado(true)
          // toast.success('Te enviamos un email')
        }
      });
  };

  return (
    <div className="min-h-full h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                alt="Workflow"
              />
              <h2 className="mt-6 text-3xl tracking-tight font-bold text-gray-900">{enviado ? 'Revisa tu Email': 'Recupera tu contraseña'}</h2>
              <p className="mt-2 text-sm text-gray-600">
                {/* Or{' '} */}
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  {/* start your 14-day free trial */}
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div>
                          </div>

                <div className="mt-6 relative">
                          </div>
              </div>

              {enviado === false && <div className="mt-6">
                <form onSubmit={handleSubmit}  className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        onChange={handleChange}
                        type="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>}
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <div className="absolute inset-0 h-full w-full object-cover bg-gradient-to-r from-indigo-500 to-blue-500"></div>
        </div>
      </div>  );
};

export default Forgot;


