import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../axios';

import { useLocation } from 'react-router-dom';
import { getQueryValue } from '../../utils/forms';
import { getQueryValueSelect } from '../../utils/forms';

import formatter from '../../utils/formatMoney';
import PhoneModal from '../twilio/PhoneModal';
import LlamadaRow from './LlamadaRow';
import LlamadaAction from './LlamadaAction';
import Filters from './Filters';

import { CSVLink, CSVDownload } from 'react-csv';

import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { setRealizada } from '../../redux/actions/llamadaActions';

import { Phone } from '@styled-icons/feather/Phone';

import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';
import { MagnifyingGlass } from '@styled-icons/entypo/MagnifyingGlass';
import { Filter } from '@styled-icons/fa-solid/Filter';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Panel = ({ setF }) => {
  const dispatch = useDispatch();
  let Q = useQuery();
  const [query, setQuery] = useState('');

  const [filter, setFilter] = useState(false);

  const apiUrl = 'http://localhost:8000/api/';
  const [actionModal, setActionModal] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [prevLlamadas, setPrevLlamadas] = useState([]);
  const [nextLlamadas, setNextLlamadas] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pendientes, setPendientes] = useState({
    next: null,
    previous: null,
    count: null,
    page_number: 0,
    results: [],
  });
  const [realizadas, setRealizadas] = useState({
    next: null,
    previous: null,
    count: null,
    page_number: 0,
    results: [],
  });
  const [recibidas, setRecibidas] = useState({
    next: null,
    previous: null,
    count: null,
    page_number: 0,
    results: [],
  });
  const [rellamadas, setRellamadas] = useState({
    next: null,
    previous: null,
    count: null,
    page_number: 0,
    results: [],
  });
  const [llamadas, setLlamadas] = useState([]);
  const [pag, setPag] = useState({
    next: null,
    count: 0,
    previous: null,
    page_number: 0,
  });
  const [type, setType] = useState('2');
  const [mandantes, setMandantes] = useState([]);

  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.codigo, label: man.nombre };
    });

  var user = JSON.parse(localStorage.getItem('user'));
  var today = new Date().toISOString().slice(0, 10);

  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };

  const get_page_number = () => {
    if (type === '2') {
      return pendientes.page_number;
    } else if (type === '1') {
      return realizadas.page_number;
    } else if (type === '3') {
      return rellamadas.page_number;
    } else if (type === '5') {
      return recibidas.page_number;
    }
  };

  const handleChangeQ = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
    //console.log(query)
  };
  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };

  const getLlamadas = (t) => {
    //axios.get(apiUrl + `llamadas?agente=1&status=${t}`).then((res) => {
    console.log('GETTTTT');

    if (t == '1') {
      var url = `llamadas?agente=${user.id}&status=${t}&ordering=-date&fecha_asignada__lte=${today}`;
    } else {
      var url = `llamadas/?${Q.toString()}&agente=${
        user.id
      }&status=${t}&fecha_asignada__lte=${today}`;
    }
    setQuery(Q.toString());

    axiosInstance.get(url).then((res) => {
      console.log(res.data);
      //setLlamadas(res.data.results);
      if (type === '2') {
        setPendientes(res.data);
      } else if (type === '1') {
        setRealizadas(res.data);
      } else if (type === '3') {
        setRellamadas(res.data);
      } else if (type === '5') {
        setRecibidas(res.data);
      }
    });
  };
  const handleCheckAll = (e) => {
    if (checkedAll === false) {
      setSelected(pendientes.results);
    } else {
      setSelected([]);
    }

    setCheckedAll(!checkedAll);
  };
  const addSelected = (call) => {
    setSelected([...selected, call]);
  };
  const removeSelected = (call) => {
    setSelected(selected.filter((x) => x.id !== call.id));
  };

  const prev = () => {
    var url = '';
    if (type === '2') {
      url = pendientes.previous;
    }
    if (type === '1') {
      url = realizadas.previous;
    }
    if (type === '3') {
      url = rellamadas.previous;
    }
    if (type === '5') {
      url = recibidas.previous;
    }
    axiosInstance.get(url).then((res) => {
      if (type === '2') {
        setPendientes(res.data);
      } else if (type === '1') {
        setRealizadas(res.data);
      } else if (type === '3') {
        setRellamadas(res.data);
      } else if (type === '5') {
        setRecibidas(res.data);
      }
    });
  };

  const next = () => {
    var url = '';
    if (type === '2') {
      url = pendientes.next;
    } else if (type === '1') {
      url = realizadas.next;
    } else if (type === '3') {
      url = rellamadas.next;
    } else if (type === '5') {
      url = recibidas.next;
    }

    axiosInstance.get(url).then((res) => {
      if (type === '2') {
        setPendientes(res.data);
      } else if (type === '1') {
        setRealizadas(res.data);
      } else if (type === '3') {
        setRellamadas(res.data);
      } else if (type === '5') {
        setRecibidas(res.data);
      }
    });
  };

  const getData = () => {
    var numeros = '';
    return selected.map((row) => {
      return {
        id: `${row.id}`,
        contacto: `${row.contacto.nombre} ${row.contacto.apellido}`,
        rut: `${row.contacto.rut}`,
        mandante: `${row.contacto.mandante}`,
        'Saldo Deuda': `${formatter.format(row.contacto.saldo_deuda)}`,
        fecha_asignada: `${row.fecha_asignada}`,
        agente: `${row.agente.first_name} ${row.agente.last_name}`,
        numeros: row.contacto.numeros
          .map((n) => {
            return n.numero;
          })
          .join(', '),
      };
    });
  };

  const handleType = (type) => {
    setType(type);
  };
  const moveLlamada = (ids) => {
    //console.log(id)
    //console.log(pendientes)
    //console.log(pendientes.results.length)
    //console.log(pendientes.results.filter(x=>x.id != id).length)
    if (type === '2') {
      //setPendientes({...pendientes,results:pendientes.results.filter(x=>x.id != id)})
      //console.log(ids)
      //console.log(pendientes.results.filter(x=>!ids.include(x.id)))
      setPendientes({
        ...pendientes,
        results: pendientes.results.filter((x) => !ids.includes(x.id)),
      });
    } else if (type === '3') {
      //setRellamadas({...rellamadas,results:rellamadas.results.filter(x=>x.id != id)})
      setRellamadas({
        ...rellamadas,
        results: rellamadas.results.filter((x) => !ids.includes(x.id)),
      });
    }
  };

  useEffect(() => {
    getMandantes();
    dispatch(setRealizada(moveLlamada));
    if (user === null) {
      window.location.href = '/login/';
    }
    let set = false;
    if (
      type === '1' &&
      realizadas.results.length === 0 &&
      realizadas.page_number === 0 &&
      set === false
    ) {
      console.log('1');
      getLlamadas(type);
      set = true;
    }
    if (
      type === '5' &&
      recibidas.results.length === 0 &&
      recibidas.page_number === 0 &&
      set === false
    ) {
      console.log('5');
      getLlamadas(type);
      set = true;
    }
    if (
      type === '2' &&
      pendientes.results.length === 0 &&
      pendientes.page_number === 0 &&
      set === false
    ) {
      console.log('2');
      getLlamadas(type);
      set = true;
    }
    if (
      type === '3' &&
      rellamadas.results.length === 0 &&
      rellamadas.page_number === 0 &&
      set === false
    ) {
      console.log('3');
      getLlamadas(type);
      set = true;
    }
  }, [type, pendientes, rellamadas]);

  return (
    <div style={{ backgroundColor: '#FFFFFF' }} className="w-full h-full rounded-tl-xl">
      <div className="flex flex-wrap content-start w-full h-full px-3 pt-5">
        <div className="w-full h-12 flex">
          {type === '2' ? (
            <button className="focus:outline-none border-b-4 p-1 border-blue-900  rounded-tl-xl w-1/4 bg-yellow-200 text-md lg:text-xl font-bold">
              Pendientes
            </button>
          ) : (
            <button
              onClick={() => setType('2')}
              className="w-1/4 bg-yellow-200 text-md lg:text-xl font-bold rounded-tl-xl "
            >
              Pendientes
            </button>
          )}
          {/*type === '1' ? <button  className="focus:outline-none border-b-4 p-1 border-blue-900                w-1/4 bg-green-300  text-md lg:text-xl font-bold">Realizadas</button> :  <button  onClick={()=>setType('1')} className="w-1/4 bg-green-300  text-md lg:text-xl font-bold   ">Realizadas</button>*/}
          {type === '3' ? (
            <button className="focus:outline-none border-b-4 p-1 border-blue-900                w-1/4 bg-blue-300   text-md lg:text-xl font-bold">
              Rellamadas
            </button>
          ) : (
            <button
              onClick={() => setType('3')}
              className="w-1/4 bg-blue-300   text-md lg:text-xl font-bold      "
            >
              Rellamadas
            </button>
          )}
          {/*type === '5' ? <button  className="focus:outline-none border-b-4 p-1 border-blue-900  rounded-tr-xl w-1/4 bg-red-300    text-md lg:text-xl font-bold">Recibidas</button> :  <button   onClick={()=>setType('5')} className="w-1/4 bg-red-300    text-md lg:text-xl font-bold  rounded-tr-xl    ">Recibidas</button>*/}
        </div>
        <div style={{ height: '80vh' }} className=" w-full overflow-y-scroll no-scrollbar">
          {filter && <Filters setModal={setFilter}></Filters>}
          <table
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                window.location.href = `${window.location.pathname}?${query}`;
              }
            }}
            className="table-auto p-4 bg-white w-full  mx-auto overflow-hidden shadow"
          >
            {type === '2' && (
              <thead className="bg-gray-100">
                <th className="border w-10">
                  <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} />
                </th>
                <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__nombre__icontains')}
                      name="contacto__nombre__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Nombre"
                    ></input>
                  ) : (
                    'Nombre'
                  )}
                </th>
                <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__apellido__icontains')}
                      name="contacto__apellido__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Apellido"
                    ></input>
                  ) : (
                    'Apellido'
                  )}
                </th>
                <th className="border lg:w-36 px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__rut__in')}
                      name="contacto__rut__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Rut"
                    ></input>
                  ) : (
                    'Rut'
                  )}
                </th>
                <th className="border  py-1 whitespace-nowrap fornt-normal text-gray-900">
                  {' '}
                  Mandante
                </th>
                <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  Saldo Deuda
                </th>
                <th className="border px-4 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  Estado
                </th>
                <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  Numeros
                </th>
                <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">
                  Fecha
                </th>
                {/*<th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Llamar</th>*/}
                <th className="border w-10">
                  <Filter
                    onClick={() => {
                      setFilter(!filter);
                    }}
                    size="32"
                    className="transform hover:scale-110 text-gray-800"
                  />
                  {false && (
                    <MagnifyingGlass
                      onClick={() =>
                        (window.location.href = `${window.location.pathname}?${query}`)
                      }
                      size="32"
                      className="transform hover:scale-110 text-blue-500"
                    />
                  )}
                </th>
                {/*<th className="w-10 "><MagnifyingGlass onClick={()=>window.location.href = `${window.location.pathname}?${query}`} size="32" className="transform hover:scale-110 text-blue-500"/></th>*/}
                <th className="border p-1 w-10">
                  <CSVLink filename="llamadas.csv" data={getData(selected)}>
                    <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
                  </CSVLink>
                </th>
                <th className="border p-1 w-10">
                  <MoreVerticalOutline
                    onClick={() => {
                      setActionModal(!actionModal);
                    }}
                    size="38"
                    className="text-blue-semi transform hover:scale-125"
                  />
                  {actionModal && <LlamadaAction llamadas={selected}></LlamadaAction>}
                </th>
              </thead>
            )}
            {type === '3' && (
              <thead className="">
                <th className="border w-10 ">
                  <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} />
                </th>
                <th className="border w-44 px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__nombre__icontains')}
                      name="contacto__nombre__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Nombre"
                    ></input>
                  ) : (
                    'Nombre'
                  )}
                </th>
                <th className="border w-44 px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__apellido__icontains')}
                      name="contacto__apellido__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Apellido"
                    ></input>
                  ) : (
                    'Apellido'
                  )}
                </th>
                <th className="border w-36 px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__rut__in')}
                      name="contacto__rut__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Rut"
                    ></input>
                  ) : (
                    'Rut'
                  )}
                </th>
                <th className="border w-40 px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  {filter ? (
                    <input
                      value={getQueryValue(query, 'contacto__mandante__icontains')}
                      name="contacto__mandante__icontains"
                      onChange={handleChangeQ}
                      type="text"
                      className="w-full border rounded-lg"
                      className="w-full border rounded-lg"
                      placeholder="Mandante"
                    ></input>
                  ) : (
                    'Mandante'
                  )}
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Saldo Deuda
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Estado
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Numeros
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Fecha
                </th>
                {/*<th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Llamar</th>*/}
                <th className="border p-1 w-10">
                  <Filter
                    onClick={() => {
                      setFilter(!filter);
                    }}
                    size="32"
                    className="transform hover:scale-110 text-gray-800"
                  />
                </th>
                {/*<th className="border p-1 w-10"><MagnifyingGlass onClick={()=>window.location.href = `${window.location.pathname}?${query}`} size="32" className="transform hover:scale-110 text-blue-500"/></th>*/}
                <th className="border p-1 w-10">
                  <CSVLink filename="llamadas.csv" data={getData(selected)}>
                    <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
                  </CSVLink>
                </th>
                <th className="border p-1 w-10">
                  <MoreVerticalOutline
                    onClick={() => {
                      setActionModal(!actionModal);
                    }}
                    size="38"
                    className="text-blue-800 transform hover:scale-125"
                  />
                  {actionModal && <LlamadaAction llamadas={selected}></LlamadaAction>}
                </th>
              </thead>
            )}
            {type === '1' && (
              <thead className="">
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Nombre
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Apellido
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Mandante
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Numero
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Fecha
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Respuesta
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Historial
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Detalle
                </th>
              </thead>
            )}
            {type === '5' && (
              <thead className="">
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Nombre
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Apellido
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Mandante
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Numero
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Fecha
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Respuesta
                </th>
                <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">
                  Detalle
                </th>
              </thead>
            )}
            <tbody className="">
              {type === '1' &&
                realizadas.results.length > 0 &&
                realizadas.results.map((llamada) => (
                  <LlamadaRow
                    moveLlamada={moveLlamada}
                    llamada={llamada}
                    selAll={checkedAll}
                    sel={selected}
                    removeSelected={removeSelected}
                    addSelected={addSelected}
                    key={llamada.id}
                    type={type}
                  />
                ))}
              {type === '2' &&
                pendientes.results.length > 0 &&
                pendientes.results.map((llamada) => (
                  <LlamadaRow
                    moveLlamada={moveLlamada}
                    llamada={llamada}
                    selAll={checkedAll}
                    sel={selected}
                    removeSelected={removeSelected}
                    addSelected={addSelected}
                    key={llamada.id}
                    type={type}
                    sP={setPendientes}
                    pen={pendientes}
                  />
                ))}
              {type === '3' &&
                rellamadas.results.length > 0 &&
                rellamadas.results.map((llamada) => (
                  <LlamadaRow
                    moveLlamada={moveLlamada}
                    llamada={llamada}
                    selAll={checkedAll}
                    sel={selected}
                    removeSelected={removeSelected}
                    addSelected={addSelected}
                    key={llamada.id}
                    type={type}
                  />
                ))}
              {type === '5' &&
                recibidas.results.length > 0 &&
                recibidas.results.map((llamada) => (
                  <LlamadaRow
                    moveLlamada={moveLlamada}
                    llamada={llamada}
                    selAll={checkedAll}
                    sel={selected}
                    removeSelected={removeSelected}
                    addSelected={addSelected}
                    key={llamada.id}
                    type={type}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div style={{ height: '4vh' }} className="flex justify-end gap-x-4 w-full h-10 pt-1 mt-1">
          {type === '2' && (
            <>
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-32 mr-auto">
                Total: {pendientes.count}
              </div>
              {pendientes.previous && (
                <button
                  onClick={() => prev(pag.previous)}
                  className=" w-14 border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm"
                >
                  Prev
                </button>
              )}
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-10">
                {get_page_number()}
              </div>
              {pendientes.next && (
                <button
                  onClick={() => next(pag.next)}
                  className=" w-16 border shadow-xl bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm text-xl font-bold"
                >
                  next
                </button>
              )}
            </>
          )}
          {type === '1' && (
            <>
              {realizadas.previous && (
                <button
                  onClick={() => prev(pag.previous)}
                  className=" w-14 border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm"
                >
                  Prev
                </button>
              )}
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-10">
                {get_page_number()}
              </div>
              {realizadas.next && (
                <button
                  onClick={() => next(pag.next)}
                  className=" w-16 border shadow-xl bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm text-xl font-bold"
                >
                  next
                </button>
              )}
            </>
          )}
          {type === '3' && (
            <>
              {rellamadas.previous && (
                <button
                  onClick={() => prev(pag.previous)}
                  className=" w-14 border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm"
                >
                  Prev
                </button>
              )}
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-10">
                {get_page_number()}
              </div>
              {rellamadas.next && (
                <button
                  onClick={() => next(pag.next)}
                  className=" w-16 border shadow-xl bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm text-xl font-bold"
                >
                  next
                </button>
              )}
            </>
          )}
          {type === '5' && (
            <>
              {realizadas.previous && (
                <button
                  onClick={() => prev(pag.previous)}
                  className=" w-14 border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm"
                >
                  Prev
                </button>
              )}
              <div className="p-1 rounded-sm bg-blue-semi text-white font-bold flex justify-center text-xl w-10">
                {get_page_number()}
              </div>
              {realizadas.next && (
                <button
                  onClick={() => next(pag.next)}
                  className=" w-16 border shadow-xl bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-sm text-xl font-bold"
                >
                  next
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Panel;
