import React, { useState, useEffect } from 'react';

import { Link,useParams} from 'react-router-dom';

import axiosInstance from '../../axios';
import format from '../../utils/formatDate';

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { Verified } from '@styled-icons/material/Verified';
import { Unverified } from '@styled-icons/octicons/Unverified';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';


import {EmailOutline} from '@styled-icons/evaicons-outline/EmailOutline'
import {Phone } from '@styled-icons/boxicons-solid/Phone'
import {SendPlane} from '@styled-icons/remix-fill/SendPlane'
import {CursorClick} from '@styled-icons/heroicons-outline/CursorClick'
import {MailRead} from '@styled-icons/fluentui-system-filled/MailRead'
import {ArrowBounce} from '@styled-icons/fluentui-system-filled/ArrowBounce'


const Mails = ({ contacto, setChange,mails }) => {

  // const [mails,setMails] = useState([])
  
  const { id } = useParams();

  const getRespuestas = () => {
    axiosInstance.get(`mails?contacto${id}&page_size=100`).then((res) => {
      // setMails(res.data.results);
      console.log(res.data)
      // setPag({
      //   next: res.data.next,
      //   'previous ': res.data.previous,
      //   count: res.data.count,
      // });
    });
  };

  useEffect(() => {
    // if (contacto.id !== undefined) {
    //   getRespuestas();
    // }
    // //
  }, [contacto]);

  return (
    <div className="w-full flex justify-center gap-x-3">
      
      <div className="flex flex-wrap content-start w-full md:w-3/4 p-1 ">
      {mails.map((mail)=>{
        return <><div className="flex w-full content-start  flex-wrap gap-x-4 ">
              
              <div className="w-full">
              <div className="w-8 h-8 rounded-full bg-indigo-500 flex justify-center p-1 ">
                <EmailOutline size="25" className="text-white"/>
              </div>
              </div>
          <div className="w-full flex gap-x-6">

              <span className="ml-4 h-auto w-0.5 bg-indigo-400"> </span>
              <div className="flex-grow flex flex-wrap bg-white rounded-md p-3">
                <div className="w-full flex  justify-between">
                  {mail.tipo_template === 1 && <p className="text-gray-400 text-sm">Mail de <span className="text-gray-800"> {mail.template_l && mail.template_l.nombre}</span></p>}
                  {mail.tipo_template === 2 && <p className="text-gray-400 text-sm">Mail de <span className="text-gray-800"> {mail.template_f && mail.template_f.nombre}</span></p>}
                  <p className="text-gray-500 text-sm">{format(mail.add_date)} </p>
                </div>
                <div className="w-full flex  justify-between mt-2">
                  <p className="text-gray-400 font-semibold"><span className="text-gray-500"> {mail.subject}</span></p>
                  <p className="text-gray-400 font-semibold"><span className="text-gray-500"> {mail.sender}</span></p>
                  <p className="text-gray-400 font-semibold"><span className="text-gray-500"> {mail.address}</span></p>
                </div>

                <div className="w-full flex  justify-end mt-2">
                  {mail.delivered && <span className="text-gray-500"><SendPlane size="24" className="text-blue-400"></SendPlane></span>}
                  {mail.opened && <span className="text-gray-500"><MailRead Open size="24" className="text-blue-400"></MailRead></span>}
                  {mail.clicked && <span className="text-gray-500"><CursorClick size="24" className="text-green-400"></CursorClick></span>}
                  {mail.bounced && <span className="text-gray-500"><ArrowBounce size="24" className="text-red-400"></ArrowBounce></span>}
                  
                </div>

              </div>
          </div>
                
          </div>

          </>
      })}
      </div>
    </div>
      
  );
};

export default Mails;
