import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import showAction from './modals/showAction';

import { useHistory } from 'react-router-dom';
import format from '../../utils/formatDate';

import axiosInstance from '../../axios';
import { Calendar, getAllDatesInRange, DateObject } from 'react-multi-date-picker';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
import { SignalWifiStatusbarNull } from 'styled-icons/material-outlined';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { PeopleCall } from '@styled-icons/fluentui-system-filled/PeopleCall';
import { FileText } from '@styled-icons/bootstrap/FileText';
import { BarGraph } from '@styled-icons/entypo/BarGraph';

const Llamadas = ({ camp, setChange }) => {
  const [actionModal, setActionModal] = useState(false);
  const [actionMSG, setActionMSG] = useState(null);
  const [scriptModal, setScriptModal] = useState(false);
  const [script, setScript] = useState({});
  const [action, setAction] = useState({});
  const [modal, setModal] = useState(false);
  const [dates, setDates] = useState(null);
  const [wkends, setWkends] = useState(false);
  const [typeD, setTypeD] = useState(2);
  const [response, setResponse] = useState({ total_ll: 0, total_ct: 0 });
  const [mandantes, setMandantes] = useState([]);
  const [tiposRespuestas, setTiposRespuestas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [data, setData] = useState({
    llc: 0,
    periodo: 0,
    lld: 0,
    dates: [],
    tipo_llamada: 1,
    agentes: [],
    rut: [],
    mandantes: [],
    nro_cuenta: [],
    estados: [],
    agente_asignado: false,
    asignar_agente: false,
    script: 0,
    tipos_in: [],
    exclude_tipos_in: [],
    sin_resp: false,
    terminar_pendientes: false,
    hora: '00:00:00',
  });
  const [scripts, setScripts] = useState([]);

  let history = useHistory();
  //  const estados = [
  //    {value:'Alzamiento',label:'Alzamiento'},
  //    {value:'Rechazos',label:'Rechazos'},
  //    {value:'Detenido Judicialmente',label:'Detenido Judicialmente	'},
  //    {value:'Apelación',label:'Apelación'},
  //    {value:'Quiebra',label:'Quiebra'},
  //    {value:'Devuelto',label:'Devuelto'},
  //    {value:'Exhorto',label:'Exhorto'},
  //    {value:'Terminado',label:'Terminado'},
  //    {value:'Suspendido',label:'Suspendido'},
  //    {value:'Abonando',label:'Abonando'},
  //    {value:'Vigente',label:'Vigente'},
  //  ]

  const options =
    camp.agentes &&
    camp.agentes.map((agente) => {
      return { value: agente.id, label: `${agente.first_name} ${agente.last_name}` };
    });
  const optionsRespuestas =
    tiposRespuestas &&
    tiposRespuestas.map((r) => {
      return { value: r.id, label: `${r.nombre}` };
    });

  const finalizar = () => {
    axiosInstance.post(`campaigns/${camp.id}/finalizar_llamadas/`).then((res) => {
      setActionMSG(res.data);
    });
  };

  const handleChangeH = (e) => {
    var h = data['hora'].split(':');

    if (e.target.name === 'hora') {
      setData({ ...data, hora: `${('0' + e.target.value).slice(-2)}:${h[1]}:${h[2]}` });
    } else if (e.target.name === 'minutos') {
      setData({ ...data, hora: `${h[0]}:${('0' + e.target.value).slice(-2)}:${h[2]}` });
    }
  };
  const handleChange = (e) => {
    console.log(data);
    if (e.target.name == 'rut' || e.target.name == 'nro_cuenta') {
      if (e.target.value.length > 0) {
        //var val  = e.target.value.replace(/[ ,]+/g, ",")
        var val = `${e.target.value
          .replace(/[ ,]+/g, ',')
          .replace(new RegExp('[\r\n]', 'gm'), ',')}`;
        setData({
          ...data,
          [e.target.name]: val.split(',').filter((x) => x !== ''),
        });
      } else {
        setData({
          ...data,
          [e.target.name]: [],
        });
      }
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
    //console.log(e.target.name)
    //
    //console.log(data)
  };

  const goToCalls = (action) => {
    history.push(`/llamadas?page_size=100&action=${action.id}`);
  };
  const goToDashboard = (action) => {
    history.push(`/dashboard?action=${action.id}`);
  };

  const handleChangeDP = (e) => {
    console.log(e.target.value);
    console.log(typeD);
    setTypeD(e.target.value);
  };

  const handleSelect = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['agentes']: e.map((agente) => {
          return agente.value;
        }),
      });
    } else {
      setData({ ...data, ['agentes']: [] });
    }
  };
  const handleSelectEstado = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['estados']: e.map((estado) => {
          return estado.value;
        }),
      });
    } else {
      setData({ ...data, ['estados']: [] });
    }
  };
  const handleSelectTiposExclude = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['exclude_tipos_in']: e.map((mandante) => {
          return mandante.value;
        }),
      });
    } else {
      setData({ ...data, ['exclude_tipos_in']: [] });
    }
  };
  const handleSelectTiposIn = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['tipos_in']: e.map((mandante) => {
          return mandante.value;
        }),
      });
    } else {
      setData({ ...data, ['tipos_in']: [] });
    }
  };
  const handleSelectMandante = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['mandantes']: e.map((mandante) => {
          return mandante.value;
        }),
      });
    } else {
      setData({ ...data, ['mandantes']: [] });
    }
  };

  const generarLlamadas = (e) => {
    e.preventDefault();
    //console.log(data)
    // console.log({...data,['dates']:dates})
    // console.log(data)
    //console.log(dates.map((d)=>`${d} ${hora}`))
    axiosInstance
      .post(`campaigns/${camp.id}/generar/`, {
        ...data,
        ['dates']: dates,
        ['llc']: data['tipo_llamada'] === 1 ? 1 : data['llc'],
        ['script']: parseInt(data['script']),
      })
      .then((res) => {
        console.log(res);
        res.data && setResponse(res.data);
        setChange(Date.now());
        setModal(true);
      });
  };

  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      setMandantes(
        res.data.map((man) => {
          return { value: man.codigo, label: man.nombre };
        }),
      );
    });
  };
  const getEstados = async () => {
    await axiosInstance.get('estados_cuenta/').then((res) => {
      setEstados(
        res.data.map((man) => {
          return { value: man.nombre, label: man.nombre };
        }),
      );
    });
  };

  const Copiar = () => {
    //copiar action
    if (action != {}) {
      console.log('asdasdasdasdasd');
      setData({ ...action, ['agentes']: action.agentes.map((ag) => ag.id) });
    }

    setDates(action.dates.split(','));
    setActionModal(false);
  };

  const LimpiarFiltros = () => {
    setData({
      llc: 0,
      periodo: 0,
      lld: 0,
      dates: [],
      agentes: [],
      rut: [],
      mandantes: [],
      nro_cuenta: [],
      estados: [],
      tipos_in: [],
      exclude_tipos_in: [],
    });
  };

  const getScripts = async () => {
    await axiosInstance.get('scripts/').then((res) => {
      if (res.data.length > 0) {
        setScripts(res.data);
        //setData({...data,['script']:res.data[0].id})
      }
    });
  };
  const getTipos = async () => {
    await axiosInstance.get('trespuestas/').then((res) => {
      if (res.data.length > 0) {
        setTiposRespuestas(res.data);
      }
    });
  };

  useEffect(() => {
    getMandantes();
    getEstados();
    getScripts();
    getTipos();
  }, []);

  return (
    <>
      <div
        style={{ height: '100%' }}
        className="flex flex-wrap w-full xl:w-4/6 h-full  overflow-y-hidden bg-gray-100 rounded-lg px-2"
      >
        {modal === true && (
          <div className="flex  z-50 justify-center absolute w-full h-full ">
            <div className="flex flex-wrap w-80 h-80 mr-40 mt-24  bg-blue-normal absolute rounded-lg">
              <div className="w-full h-2/3">
                <p className="text-gray-50 font-bold text-2xl text-center mt-20">
                  Se generaron {response.total_ll} llamadas
                </p>
                <p className="text-gray-50 font-bold text-2xl text-center mt-5">
                  Entre {response.total_ct} contactos
                </p>
              </div>
              <div className="w-full h-1/3 flex justify-center">
                <button
                  onClick={() => setModal(false)}
                  className="p-4 px-8 my-auto trasform hover:scale-120 hover:bg-green-500 bg-green-600 font-bold text-gray-100 rounded-lg"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {scriptModal === true && (
          <div className="flex flex-wrap z-50 justify-center  bg-gray-800 p-5 pb-1 bg-opacity-50 absolute w-4/6  h-4/6 p-6">
            <div className="h-5/6 w-full bg-white flex flex-wrap content-start overflow-y-scroll no-scrollbar">
              <div className="flex w-full h-20 border-b-4 border-gray-900 ">
                <p className="my-auto w-full text-blue-800 text-4xl text-center font-bold uppercase">
                  {script && script.nombre}
                </p>
              </div>
              {script.bloques &&
                script.bloques.length > 0 &&
                script.bloques.map((bloque) => {
                  return (
                    <div className="flex h-1/4 w-full border mt-5">
                      <>
                        <div className="w-2/12 border h-full flex justify-center">
                          <p className="my-auto text-2xl font-bold capitalize">
                            {bloque && bloque.nombre}
                          </p>
                        </div>
                        <div className="w-10/12 border h-full flex p-2">
                          <p className="w-full h-full text-xl text-gray-900  break-words">
                            {bloque && bloque.cuerpo}
                          </p>
                        </div>
                      </>
                    </div>
                  );
                })}
            </div>

            <button
              onClick={() => setScriptModal(false)}
              className="py-4 w-24 px-4 my-auto trasform hover:scale-120 hover:bg-green-500 bg-green-600 font-bold text-gray-100 rounded-lg"
            >
              OK
            </button>
          </div>
        )}
        {actionModal === true && (
          <div className="flex z-50 justify-center bg-gray-100 bg-opacity-50 absolute w-4/6  h-4/6 ">
            <div className="flex flex-wrap w-full  bg-blue-normal rounded-lg">
              <div className="flex flex-wrap content-start w-full h-5/6 p-5 gap-y-6">
                <div className="flex w-2/6 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    LLamadas X Contacto :
                  </label>
                  <label className="w-1/2 h-1/2 text-4xl font-bold text-gray-50 ">
                    {action && action.llc}
                  </label>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Periodo
                  </label>
                  <label className="w-1/2 h-1/2 text-4xl font-bold text-gray-50 ">
                    {action && action.periodo && action.periodo}
                  </label>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Fechas:
                  </label>
                  <DatePicker
                    multiple
                    value={action.dates.split(',')}
                    className="w-1/2"
                    plugins={[<DatePanel />, weekends(wkends ? [] : [0, 6])]}
                    style={{
                      backgroundColor: 'aliceblue',
                      height: '50px',
                      borderRadius: '8px',
                      fontSize: '14px',
                      padding: '3px 10px',
                    }}
                  ></DatePicker>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5  pr-1 pb-2">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Rut
                  </label>
                  <label className="w-1/2 h-full text-xl font-bold break-words oveflow-scroll no-scrollbar bg-white text-black">
                    {action && action.rut && action.rut.map((rut) => rut + ',')}
                  </label>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5   pr-1 pb-2">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Mandantes
                  </label>
                  <label className="w-1/2 h-full text-xl font-bold break-words oveflow-scroll no-scrollbar bg-white text-black">
                    {action && action.mandantes && action.mandantes.map((man) => man + ',')}
                  </label>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5  pr-1 pb-2">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Nro. Cuenta
                  </label>
                  <label className="w-1/2 h-full text-xl font-bold break-words oveflow-scroll no-scrollbar bg-white text-black">
                    {action && action.nro_cuenta && action.nro_cuenta.map((nro) => nro + ',')}
                  </label>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5 border-b border-gray-100 pr-1 pb-2">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Estados
                  </label>
                  <label className="w-1/2 h-full text-xl font-bold break-words oveflow-scroll no-scrollbar bg-white text-black">
                    {action && action.estados && action.estados.map((nro) => nro + ',')}
                  </label>
                </div>
                <div className="flex w-2/6 h-1/6 pl-5 border-b border-gray-100 pr-1 pb-2"></div>
                <div className="flex w-2/6 h-1/6 pl-5 border-b border-gray-100 pr-1 pb-2"></div>
                <div className="flex w-3/6 h-1/6 pl-5 border-b border-gray-100 pr-1 pb-2">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Agentes
                  </label>
                  <label className="w-1/2 h-full text-xl font-bold break-words oveflow-scroll no-scrollbar bg-white text-black">
                    {action &&
                      action.agentes &&
                      action.agentes.map((ag) => ag.first_name + ' ' + ag.last_name + ',')}
                  </label>
                </div>
                <div className="flex w-3/12 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    LLamadas Diarias
                  </label>
                  <label className="w-1/2 h-1/2 text-4xl font-bold text-gray-50 ">
                    {action && action.lld}
                  </label>
                </div>
                <div className="flex w-3/12 h-1/6 pl-5 border-b border-gray-100"></div>
                <div className="flex w-3/12 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Usuario
                  </label>
                  <label className="w-1/2 h-full break-word text-xl font-bold text-gray-50 break-all">
                    {action && action.user && action.user.first_name}
                  </label>
                </div>
                <div className="flex w-3/12 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Total LLamadas
                  </label>
                  <label className="w-1/2 h-1/2 text-4xl font-bold text-gray-50 ">
                    {action && action.total_ll}
                  </label>
                </div>
                <div className="flex w-3/12 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Total Contactos
                  </label>
                  <label className="w-1/2 h-1/2 text-4xl font-bold text-gray-50 ">
                    {action && action.total_ct}
                  </label>
                </div>
                <div className="flex w-3/12 h-1/6 pl-5 border-b border-gray-100">
                  <label className="text-gray-50 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-full">
                    Fecha
                  </label>
                  <label className="w-1/2 h-1/2 text-xl font-bold text-gray-50 ">
                    {action && format(action.date)}
                  </label>
                </div>
              </div>
              <div className="w-full h-1/6 flex justify-around">
                {/*<button onClick={()=>Copiar()} className="py-4 px-4 w-24 my-auto trasform hover:scale-120 hover:bg-blue-500 bg-blue-600 font-bold text-gray-100 rounded-lg">Copiar</button>*/}
                <button
                  onClick={() => setActionModal(false)}
                  className="py-4 w-24 px-4 my-auto trasform hover:scale-120 hover:bg-green-500 bg-green-600 font-bold text-gray-100 rounded-lg"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="w-full flex flex-wrap  overflow-y-scroll -scrollbar pt-3">
          <form
            onSubmit={generarLlamadas}
            className="flex flex-wrap items-start content-start w-full h-full "
          >
            {/* <div className="w-1/6 h-40 border-b pl-5"> */}
            {/*     <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2" >LLamadas X Contacto</label> */}
            {/*     <input name="llc"  value={data['llc']} onChange={handleChange} type="number" className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"></input> */}
            {/* </div> */}
            {/* <div className="w-2/6 h-40 border-b pl-5"> */}
            {/*     <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2" >tipo</label> */}
            {/*     <select name="tipo_llamada"  value={data['tipo_llamada']} onChange={handleChange} type="number" className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"> */}
            {/*       <option value={1} key={1}>Recurrente</option> */}
            {/*       <option value={2} key={2}>Unica</option> */}
            {/*     </select> */}
            {/* </div> */}
            {/* {data['tipo_llamada'] !== 1 && <div className="w-1/6 h-40 border-b"> */}
            {/*     <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2" >Periodo (dias)</label> */}
            {/*     <input name="periodo" value={data['periodo']}  onChange={handleChange} type="number" className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"></input> */}
            {/* </div>} */}

            <div className="flex flex-wrap w-2/6 h-40  border-b">
              <select onChange={handleChangeDP} className="w-full h-10">
                <option key={1} value={2}>
                  Dias especificos
                </option>

                <option key={2} value={1}>
                  Rango de dias
                </option>
              </select>
              {typeD == 1 && (
                <DatePicker
                  range
                  value={dates}
                  onChange={(dateObjects) => {
                    setDates(
                      getAllDatesInRange(dateObjects).map((d) => {
                        return d.format();
                      }),
                    );
                  }}
                  plugins={[<DatePanel />, weekends(wkends ? [] : [0, 6])]}
                  style={{
                    backgroundColor: 'aliceblue',
                    height: '50px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    padding: '3px 10px',
                  }}
                />
              )}
              {typeD == 2 && (
                <DatePicker
                  multiple
                  value={dates}
                  onChange={(dateObjects) => {
                    setDates(
                      dateObjects.map((d) => {
                        return d.format();
                      }),
                    );
                    console.log(
                      dateObjects.map((d) => {
                        return d.format();
                      }),
                    );
                  }}
                  plugins={[<DatePanel />, weekends(wkends ? [] : [0, 6])]}
                  style={{
                    backgroundColor: 'aliceblue',
                    height: '50px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    padding: '3px 10px',
                  }}
                />
              )}

              <div className="flex flex-wrap w-full content-start ">
                <p className="w-font font-bold my-auto mr-4">Fin de semanas</p>
                {wkends ? (
                  <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer ">
                    <span class="relative">
                      <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                      <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                        <input
                          id="unchecked"
                          onClick={() => setWkends(false)}
                          type="checkbox"
                          class="absolute opacity-0 w-0 h-0"
                        />
                      </span>
                    </span>
                    <span class="ml-3 text-sm">Activados</span>
                  </label>
                ) : (
                  <label for="checked" class="mt-3 inline-flex items-center cursor-pointer ">
                    <span class="relative">
                      <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                      <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                        <input
                          id="checked"
                          onClick={() => setWkends(true)}
                          type="checkbox"
                          class="absolute opacity-0 w-0 h-0"
                        />
                      </span>
                    </span>
                    <span class="ml-3 text-sm">Desactivados</span>
                  </label>
                )}
              </div>
            </div>
            <div className="h-40 w-1/6 flex  content-start flex-wrap justify-around px-2 border-r-2 border-blue-ligth">
              <p className="w-full text-xl font-bold text-center ">Hora</p>
              <p className="text-2xl text-blue-semi my-auto">
                <input
                  type="number"
                  onChange={handleChangeH}
                  name="hora"
                  className="w-8 border border-blue-ligth"
                ></input>
                :
                <input
                  type="number"
                  onChange={handleChangeH}
                  name="minutos"
                  className="w-8 border border-blue-ligth"
                ></input>
              </p>
            </div>
            {/*filtros*/}
            <div className="flex flex-wrap w-full h-2/6  border-t-2 border-blue-ligth">
              <div className="w-2/6 h-1/2 pl-5 pt-2">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2">
                  Rut
                </label>
                <input
                  name="rut"
                  value={data['rut']}
                  onChange={handleChange}
                  type="text"
                  className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                ></input>
              </div>
              <div className="w-2/6 h-1/2 pt-2 ">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                  Mandante
                </label>
                <Select
                  name="mandante"
                  value={data['mandantes'].map((man) => ({ value: man, label: man }))}
                  onChange={handleSelectMandante}
                  className="w-11/12 h-4/6"
                  isMulti
                  options={mandantes}
                />
              </div>
              <div className="w-3/12 h-1/2 pt-2">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2">
                  Nro. Cuenta
                </label>

                <input
                  name="nro_cuenta"
                  value={data['nro_cuenta']}
                  onChange={handleChange}
                  type="text"
                  className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                ></input>
              </div>
              <div className="w-3/12 h-1/2 pt-2 px-4">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                  Estado
                </label>
                <Select
                  name="estado"
                  value={
                    data['estados'] && data['estados'].map((man) => ({ value: man, label: man }))
                  }
                  onChange={handleSelectEstado}
                  className="w-11/12 h-4/6"
                  isMulti
                  options={estados}
                />
              </div>
              <div className="w-3/12 h-1/2 pt-2 px-4">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                  Tiene
                </label>
                <Select
                  name="tipos_in"
                  onChange={handleSelectTiposIn}
                  className="w-11/12 h-4/6"
                  isMulti
                  options={optionsRespuestas}
                />
              </div>
              <div className="w-2/6 h-1/2 pt-2 px-4">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                  Excluir si tiene
                </label>
                <Select
                  name="exclude_tipos_in"
                  onChange={handleSelectTiposExclude}
                  className="w-11/12 h-4/6"
                  isMulti
                  options={optionsRespuestas}
                />
              </div>
              <div className="w-2/12  flex flex-wrap">
                <label className="inline-flex items-center my-auto">
                  <input
                    type="checkbox"
                    value={data['sin_resp']}
                    onChange={() => setData({ ...data, sin_resp: !data['sin_resp'] })}
                    name="sin_resp"
                    className="form-checkbox"
                  />
                  <span className="ml-2">Sin Respuesta</span>
                </label>
                <label className="inline-flex items-center my-auto">
                  <input
                    type="checkbox"
                    value={data['terminar_pendientes']}
                    onChange={() =>
                      setData({ ...data, terminar_pendientes: !data['terminar_pendientes'] })
                    }
                    name="terminar_pendientes"
                    className="form-checkbox"
                  />
                  <span className="ml-2">Terminar pendientes</span>
                </label>
              </div>
            </div>
            <div className="flex flex-wrap content-start gap-y-5 w-full h-2/5 p-1">
              <label className="w-full">
                <p className="w-full mb-1 font-bold p-1 font-bold text-lg text-center">Agentes</p>
                <Select onChange={handleSelect} className="w-/2" isMulti options={options} />
              </label>
              <div className="w-2/6 h-1/6 flex flex-wrap justify-around">
                <div>
                  <label className="inline-flex items-center mb-2">
                    <input
                      type="checkbox"
                      value={data['agente_asignado']}
                      onChange={() =>
                        setData({ ...data, agente_asignado: !data['agente_asignado'] })
                      }
                      name="agente_asignado"
                      className="form-checkbox"
                    />
                    <span className="ml-2">Agentes Asignados</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value={data['asignar_agente']}
                      onChange={() => setData({ ...data, asignar_agente: !data['asignar_agente'] })}
                      name="asignar_agente"
                      className="form-checkbox"
                    />
                    <span className="ml-2">Asignar Agente</span>
                  </label>
                </div>
              </div>
              <div className="w-2/6 h-1/6 ">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2">
                  Llamadas Diarias
                </label>
                <input
                  name="lld"
                  value={data['lld']}
                  onChange={handleChange}
                  type="number"
                  className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                ></input>
              </div>
              <div className="w-2/6 h-1/6 ">
                <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2">
                  Script{' '}
                  <FileText
                    onClick={() => {
                      setScript(
                        data['script'] !== 0 && scripts.filter((x) => x.id == data['script'])[0],
                      );
                      setScriptModal(true);
                    }}
                    size="24"
                    className="text-blue-700 transform hover:scale-125 "
                  />
                </label>
                <select
                  name="script"
                  onChange={handleChange}
                  type="number"
                  className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                >
                  <option key={0} value={0} label={'-----------'}></option>
                  {scripts.length > 0 &&
                    scripts.map((sc) => {
                      return <option key={sc.id} value={sc.id} label={sc.nombre}></option>;
                    })}
                </select>
              </div>
              <div className="w-2/6 h-1/6  flex justify-around">
                <button
                  type="button"
                  onClick={() => LimpiarFiltros()}
                  className="w-24 hover:bg-green-600  bg-blue-500 h-10 px-2 text-white font-bold rounded-lg"
                >
                  Limpiar
                </button>
                <button
                  type="submit"
                  className="w-24 hover:bg-green-600 bg-green-500 h-10 px-2 text-white font-bold rounded-lg"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="w-2/6 h-5/6 flex flex-wrap overflow-y-scroll no-scrollbar ">
        <div className=" content-start lg:hidden xl:flex flex-wrap w-full h-3/6 px-2 overflow-y-scroll">
          <div className="h-10 w-full  bg-blue-semi flex rounded-t-lg">
            <p className="w-full text-white text-center font-bold text-xl my-auto">Historial</p>
          </div>
          <div className=" flex w-full h-10 border-b border-l border-r border bg-gray-100">
            <div className="w-2/6 h-full flex flex-wrap pt-1  border-black overflow-hidden">
              <p className="w-full h-full font-bold my-auto text-center">Usuario</p>
            </div>
            <div className="w-1/6 h-full flex flex-wrap pt-1 border-black overflow-hidden">
              <p className="w-full h-full font-bold my-auto text-center ">Llamadas</p>
            </div>
            {/*<div className="w-1/6 h-full flex flex-wrap pt-1 border-black overflow-hidden">
                            <p className="w-full h-full font-bold my-auto text-center "></p>  
                          </div>*/}
            <div className="w-1/6 h-full flex flex-wrap pt-1  border-black overflow-hidden">
              <p className="w-full h-full font-bold my-auto text-center ">Fecha</p>
            </div>
            <div className="w-1/6 h-full flex flex-wrap pt-1 overflow-hidden">
              <p className="w-full h-full font-bold my-auto text-center ">Ver</p>
            </div>
            <div className="w-1/6 h-full flex flex-wrap pt-1 overflow-hidden">
              <p className="w-full h-full font-bold my-auto text-center ">Llamadas</p>
            </div>
          </div>
          {camp &&
            camp.actions &&
            camp.actions.length > 0 &&
            camp.actions.map((action) => {
              return (
                <div className=" flex w-full h-10 border-b-4 border-l border-r border-gray-100 bg-gray-100 overflow-hidden">
                  <p className="w-2/6 h-full font-bold my-auto text-center  text-sm">
                    {action.user && action.user.first_name + ' ' + action.user.last_name}
                  </p>
                  <p className="w-1/6 h-full font-bold my-auto text-center  text-sm">
                    {action.total_ll}
                  </p>
                  {/*<p className="w-1/6 h-full font-bold my-auto text-center  text-lg">{action.total_ct}</p>*/}
                  <p className="w-1/6 h-full font-bold my-auto text-center text-sm">
                    {format(action.date)}
                  </p>
                  <p className="w-1/6 h-full font-bold my-auto text-center ">
                    <Detail
                      onClick={() => {
                        setAction(action);
                        setActionModal(true);
                      }}
                      size="32"
                      className="transform hover:scale-125 text-blue-700"
                    ></Detail>
                  </p>
                  <p className="w-1/6 h-full font-bold my-auto text-center ">
                    <PeopleCall
                      onClick={() => goToCalls(action)}
                      size="32"
                      className="transform hover:scale-125 text-blue-700"
                    />
                    <BarGraph
                      onClick={() => goToDashboard(action)}
                      size="32"
                      className="transform hover:scale-125 text-purple-700"
                    />
                  </p>
                </div>
              );
            })}
        </div>

        <div className=" content-start lg:hidden xl:flex flex-wrap w-full h-3/6 px-2 border ">
          <div className="w-full h-1/6 rounded-t-lg flex flex-wrap justify-center bg-blue-semi">
            <p className="w-full text-white text-center font-bold text-xl my-auto">acciones</p>
          </div>

          <div className="w-full h-4/6 p-3 flex flex-wrap overflow-y-scroll scroll">
            <div className="w-full flex justify-between h-20 p-1 gap-x-2">
              <button
                onClick={() => finalizar()}
                className="w-auto h-10  px-3 py-2 rounded-lg font-bold text-white hover:bg-opacity-75 text-xl bg-red-700"
              >
                Finalizar llamadas
              </button>
              <p className="text-gray-700 font-semibold text-right">
                Todas las llamadas pasaran a Terminada
              </p>
            </div>
          </div>

          <div className="w-full h-10 flex justify-center bg-blue-ligth">
            <p className="my-auto text-lg font-semibold text-white">
              {actionMSG !== null && actionMSG}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Llamadas;
