import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';

import Enviar from '../../components/voicemsgCampaigns/Enviar.jsx'
import Config from '../../components/voicemsgCampaigns/Config.jsx'
import Resumen from '../../components/voicemsgCampaigns/Resumen.jsx'


import {TitleActions} from '../../structs/layout/Title'
import {MultiPageSelectorChange3} from '../../structs/layout/MultiPage'
import TitleBack from '../../structs/layout/TitleBack'

const VoiceMSGCampaign = () => {
  const { id } = useParams();
  const [camp, setCamp] = useState({});
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(null);
  //const [llamadas,setLlamadas] = useState([])
  const [data, setData] = useState(null);


  const getCamp = async () => {
    await axiosInstance.get(`voice_msg_campaigns/` + id).then((res) => {
      setCamp(res.data);
    });
  };


  useEffect(() => {
    getCamp();
  }, [change]);

  return (
	
	  <div className="w-full flex flex-wrap h-auto ">

    <TitleBack title={camp && camp.nombre}></TitleBack>
    {/* <MultiPageSelectorChange setPage={setPage} tabs={[{name:'Resumen',page:1,current:page == 1 ? true : false},{name:'Enviar Mails',page:2,current:page == 2 ? true : false},{name:'Configuracion',page:4,current:page == 4 ? true : false}]}></MultiPageSelectorChange> */}
    <MultiPageSelectorChange3 setPage={setPage} tabs={[{name:'Resumen',page:1,current:page == 1 ? true : false},{name:'Enviar VoiceMsg',page:2,current:page == 2 ? true : false},{name:'Configuracion',page:4,current:page == 4 ? true : false}]}></MultiPageSelectorChange3>
    {/* <MultiPageSelectorChange2 setPage={setPage} tabs={[{name:'Resumen',page:1,icon:<UserDetail size="20" className={iconStyles}/>,current:page == 1 ? true : false},{name:'Enviar',page:2,current:page == 2 ? true : false}]}></MultiPageSelectorChange2> */}
    <div className="w-full h-auto p-4">
      { page==1 && <Resumen id={id}></Resumen>}
      {page==2 && <Enviar></Enviar>}
      {page==4 && <Config camp={camp}></Config>}

    </div>
      

    </div>
  );
};

export default VoiceMSGCampaign;

