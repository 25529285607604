import {useState,useEffect} from 'react'
import axiosInstance from '../../../axios.js'


import Select from 'react-select';

import ReactTooltip from 'react-tooltip';
import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';
import { EditOff } from '@styled-icons/material/EditOff';
import { Edit } from '@styled-icons/boxicons-regular/Edit';

const Table = ({objs,getObjs}) =>{
  const [data,setData] = useState(null)
  const [edit,setEdit] = useState(false)
  const [mandantes,setMandantes] = useState([])

  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.id, label: man.nombre };
    });

  const getMandantes = async () => {
    await axiosInstance.get('mandantes?is_active=true').then((res) => {
      setMandantes(res.data);
    });
  };


  const editar = (obj) =>{
    
    if(data !== null && obj.id !== data.id){
      setData(obj) 
      console.log('change')
    }else{
      if(edit === false){
        console.log('start edit')
        setData(obj)
        setEdit(true)
      }else{
        if(data !== obj){
          console.log('update')
          update(obj)
          setEdit(false)
        }else{
          setEdit(false)
          console.log('close')
        }
      }
    }
  }


  const handleSelect = (e) => {
    //console.log(optionsMandantes.filter(x=>data['mandantes'].includes(x.value)))
    if (e.length > 0) {
      setData({
        ...data,
        ['mandantes']: e.map((selected) => {
          return selected.value;
        }),
      });
    } else {
      setData({ ...data, ['mandantes']: [] });
    }
  };
  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }


  const update = async (obj) =>{
    await axiosInstance.put(`trespuestas/${obj.id}/`,data).then(res=>{
      getObjs()
      setData(null)
      setEdit(false)
    })
  }
  
  const cambiar= (obj,field) => {
    setData({...data,[field]:!data[field]})
    // axiosInstance.patch(`trespuestas/${obj.id}/`, {field: !obj[field]}).then((res) => {
      // setChange(new Date());
      // getObjs()
    // });
  };

  const cambiarPermission= (obj) => {
    axiosInstance.patch(`tags_contacto/${obj.id}/`, { agent_permission : !obj.agent_permission}).then((res) => {
      // setChange(new Date());
      getObjs()
    });
  };
  const remove = async (obj) =>{
    await axiosInstance.delete(`tags_contacto/${obj.id}/`).then(res=>{
      getObjs()
    })

  }

  useEffect(() => {
    getMandantes()
  }, [])

  return (
        <div className=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg w-full overflow-scroll">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-1  text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
              >
                Nombre
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Color
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Realizada
                    <InfoCircle size={16} data-tip data-for="set_realizada" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="set_realizada" effect="solid">
                        <p className="break-words">Al ingresar esta respuesta llamada pasara a realizada.</p>
                    </ReactTooltip>
              </th>
              <th
                scope="col"
                className=" py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Terminada
                      <InfoCircle size={16} data-tip data-for="set_terminada" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="set_terminada" effect="solid">
                        <p className="break-words">Al ingresar esta respuesta la llamada pasara a terminada.</p>
                    </ReactTooltip>
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Dias
                    <InfoCircle size={16} data-tip data-for="reagendacion" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="reagendacion" effect="solid">
                        <p className="break-words">Al ingresar esta respuesta la fecha de la llamada se le sumaran los dias indicados. </p>
                    </ReactTooltip>

              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Confirmacion
                  <InfoCircle size={16} data-tip data-for="confirmation" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="confirmation" effect="solid">
                        <p className="break-words">Se tuvo contacto con la persona. </p>
                    </ReactTooltip>
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Mandantes
              </th>

            <th
                scope="col"
                className="py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Codigo
              </th>
              <th
                scope="col"
                className="py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                CA
              </th>
              <th
                scope="col"
                className=" py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                CR
              </th>
              <th
                scope="col"
                className=" py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                CCO
              </th>
              <th
                scope="col"
                className=" py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                CCI
              </th> 
              <th
                scope="col"
                className=" py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                IdExt
              </th> 
              <th
                scope="col"
                className=" py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Grupo
              </th>
              <th
                scope="col"
                className=" py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Dir.
                  <InfoCircle size={16} data-tip data-for="dir" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="dir" effect="solid">
                        <p className="break-words">Direccion O (outbound) - I (inbound)</p>
                    </ReactTooltip>
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Tipo
                    <InfoCircle size={16} data-tip data-for="tipo" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="tipo" effect="solid">
                        <p className="break-words">Tipo de contacto D (Directo) - I (Indirecto)</p>
                    </ReactTooltip>
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Status
              </th>

              <th scope="col" className="relative py-3 px-0 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
              
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {objs.map((obj) => (
              <tr key={obj.id}>
                <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 w-36  py-1" name="nombre" value={data['nombre']} onChange={handleChange}></input> 
                    : 
                    <p className="w-36 overflow-hidden text-clip">{obj.nombre}</p>}
                </td>
                <td className="whitespace-nowrap  py-2 text-sm text-gray-500">
                  {edit && data.id === obj.id ? 
                    <input
                      name="color"
                      value={data['color']}
                      onChange={handleChange}
                      type="color"
                     className="w-6"
                    ></input>
                    : 
                 <div
                    style={{ backgroundColor: obj.color }}
                    className="h-6 w-6 rounded-full"
                  ></div>
                }
              </td>
                <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 text-center">

                     
                    <span onClick={() => {edit && data.id === obj.id && cambiar(obj,'set_realizada')}} className={edit && data.id === obj.id ? 
                  `cursor-pointer relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${data.set_realizada ? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600' : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'}`
                  : 
                  ` relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${obj.set_realizada ? 'text-green-600 bg-green-100 border-green-600' : 'text-red-600 bg-red-100 border-red-600'}`}> 
                  {edit && data.id === obj.id ? data['set_realizada'] ? 'SI' : 'NO' : obj.set_realizada? 'SI' : 'NO'}
                    </span>
                     

                </td>

              

                <td className="whitespace-nowrap  py-2 text-sm text-gray-500 text-center">
                    <span onClick={() => {edit && data.id === obj.id && cambiar(obj,'set_terminada')}} className={edit && data.id === obj.id ? 
                  `cursor-pointer relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${data.set_terminada ? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600' : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'}`
                  : 
                  ` relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${obj.set_terminada? 'text-green-600 bg-green-100 border-green-600' : 'text-red-600 bg-red-100 border-red-600'}`}> 
                  {edit && data.id === obj.id ? data['set_terminada'] ? 'SI' : 'NO' : obj.set_terminada? 'SI' : 'NO'}
                    </span>
                </td>
                <td className="whitespace-nowrap py-1   text-sm font-medium text-gray-900 sm:pl-6">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-2 py-1 w-10" type="number" name="reagendacion" value={data['reagendacion']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.reagendacion}</p>}
                </td>
                <td className="whitespace-nowrap  py-2 text-sm text-gray-500 text-center">
                    <span onClick={() => {edit && data.id === obj.id && cambiar(obj,'confirmation')}} className={edit && data.id === obj.id ? 
                  `cursor-pointer relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${data.confirmation? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600' : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'}`
                  : 
                  ` relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${obj.confirmation ? 'text-green-600 bg-green-100 border-green-600' : 'text-red-600 bg-red-100 border-red-600'}`}> 
                  {edit && data.id === obj.id ? data['confirmation'] ? 'SI' : 'NO' : obj.confirmation? 'NO' : 'NO'}
                    </span>
                </td>
            <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 sm:pl-6">
                {edit && data.id === obj.id ?
                  <Select
                    closeMenuOnSelect={false}
                    onChange={handleSelect}
                    className="w-32 z-30"
                    isMulti
                    options={optionsMandantes.filter((x) => !data['mandantes'].includes(x.value))}
                    value={optionsMandantes.filter((x) => data['mandantes'].includes(x.value))}
                  />
                :
                <>
                    {obj.mandantes.length > 0 && 
                  <div className="w-42 h-12 overflow-y-scroll scroll">
                    {optionsMandantes
                      .filter((x) => obj.mandantes.includes(x.value))
                      .map((x) => {
                        return (
                          <p className="overflow-y-scroll">
                            {x.label}
                            <br />
                          </p>
                        );
                      })}
                  </div>
                }
                  </>
                  }
              </td>


              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-1 py-1 w-10" type="text" name="codigo" value={data['codigo']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.codigo}</p>}
                </td>
              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0  py-1 w-10" type="text" name="codigo_accion" value={data['codigo_accion']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.codigo_accion}</p>}
                </td>
              <td className="whitespace-nowrap py-4  text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-1 py-1 w-10" type="text" name="codigo_resultado" value={data['codigo_resultado']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.codigo_resultado}</p>}
                </td>
                <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-r py-1 w-10" type="text" name="codigo_carta_out" value={data['codigo_carta_out']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.codigo_carta_out}</p>}
                </td>
              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 px-1 py-1 w-10" type="text" name="codigo_carta_in" value={data['codigo_carta_in']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.codigo_carta_in}</p>}
                </td>
                
              
              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <input className="ring-0 py-1 w-10" type="number" name="external_id" value={data['external_id']} onChange={handleChange}></input> 
                    : 
                    <p className="font-medium text-gray-500 text-sm">{obj.external_id}</p>}
                </td>
                <td className="whitespace-nowrap py-2  text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <select value={data['grupo']} onChange={handleChange} name="grupo">
                    <option value={1} key={1} className="text-green-600 font-semibold">positiva</option> 
                    <option value={2} key={2} className="text-red-600 font-semibold">negativa</option> 
                    <option value={3} key={3} className="text-blue-600 font-semibold">rellamada</option> 
                  </select> 
                    : 
                  <>
                  {obj.grupo === 1 && <p className="font-semibold text-green-600 text-sm">Positiva</p>}
                  {obj.grupo === 2 && <p className="font-semibold text-red-600 text-sm">Negativa</p>}
                  {obj.grupo === 3 && <p className="font-semibold text-blue-600 text-sm">Rellamada</p>}
                  </>

              }
                </td>
                <td className="whitespace-nowrap py-2  text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <select value={data['dir']} onChange={handleChange} name="dir">
                    <option value={1} key={1} className="text-green-600 font-semibold">O</option> 
                    <option value={2} key={2} className="text-red-600 font-semibold">I</option> 
                  </select> 
                    : 
                  <>
                  {obj.dir === 1 && <p className="font-semibold text-green-600 text-sm">O</p>}
                  {obj.dir === 2 && <p className="font-semibold text-red-600 text-sm">I</p>}
                  </>

              }
                </td>
                <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900 sm:pl-6 text-center">
                  {edit && data.id === obj.id ? 
                    <select value={data['tipo_contacto']} onChange={handleChange} name="tip_contacto">
                    <option value={1} key={1} className="text-green-600 font-semibold">D</option> 
                    <option value={2} key={2} className="text-blue-600 font-semibold">I</option> 
                  </select> 
                    : 
                  <>
                  {obj.tipo_contacto === 1 && <p className="font-semibold text-green-600 text-sm">D</p>}
                  {obj.tipo_contacto === 2 && <p className="font-semibold text-blue-600 text-sm">I</p>}
                  </>

              }
                </td>

                <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                    <span onClick={() => {edit && data.id === obj.id && cambiar(obj,'is_active')}} className={edit && data.id === obj.id ? 
                  `cursor-pointer relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${data.is_active? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600' : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'}`
                  : 
                  ` relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${obj.is_active? 'text-green-600 bg-green-100 border-green-600' : 'text-red-600 bg-red-100 border-red-600'}`}> 
                  {edit && data.id === obj.id ? data['is_active'] ? 'SI' : 'NO' : obj.is_active? 'SI' : 'NO'}
                    </span>
                </td>
                <td className="relative whitespace-nowrap py-2 text-right text-sm font-medium sm:pr-6">
                {/* <a onClick={()=>editar(obj)}  className={`${edit === true && data !== null && data.id === obj.id ? 'text-blue-super-ligth hover:text-blue-600' : 'text-blue-600 hover:text-blue-super-ligth'}  cursor-pointer`}>
                    Editar<span className="sr-only">, {obj.id}</span>
                  </a>*/}
                  {edit && data !== null && data.id === obj.id ? 
                  <EditOff
                    onClick={() => editar(obj)}
                    size="26"
                    className="my-auto cursor-pointer text-blue-800 transform hover:scale-125"
                  />
                 : 
                  <Edit
                    onClick={() => editar(obj)}
                    size="26"
                    className="my-auto cursor-pointer text-blue-600 transform hover:scale-125"
                  />
                }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
          
  )
}
export default Table;
