import { Disclosure } from '@headlessui/react';
import React, { useState } from 'react';
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  PhoneIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import {
  CircleStackIcon,
  EnvelopeIcon,
  ChatBubbleLeftEllipsisIcon,
  StopIcon,
} from '@heroicons/react/24/outline';

import { useLocation, Link } from 'react-router-dom';
import { ChevronDoubleRight } from '@styled-icons/bootstrap/ChevronDoubleRight';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import { PersonCall } from '@styled-icons/fluentui-system-regular/PersonCall';
import { BookContacts } from '@styled-icons/fluentui-system-regular/BookContacts';
import { CardList } from '@styled-icons/bootstrap/CardList';
import { TasksApp } from '@styled-icons/fluentui-system-regular/TasksApp';
import { StatsChart } from '@styled-icons/ionicons-sharp/StatsChart';
import { Template } from '@styled-icons/heroicons-outline/Template';
import { ChatVoice } from '@styled-icons/remix-line/ChatVoice';
import { SendPlane } from '@styled-icons/remix-fill/SendPlane';
import { Preview } from '@styled-icons/material-rounded/Preview';
import { ArrowFromLeft } from '@styled-icons/boxicons-solid/ArrowFromLeft';
import { Table } from '@styled-icons/bootstrap/Table';
import { SupportAgent } from '@styled-icons/material/SupportAgent';
import { DocumentOnePage } from '@styled-icons/fluentui-system-filled/DocumentOnePage';

import { Transfer } from '@styled-icons/open-iconic/Transfer';

import { MODULES } from '../vars.js';

const navigation = [
  {
    name: 'Dashboard',
    group: 'AGENTE',
    module: 'LLAMADAS',
    icon: HomeIcon,
    current: true,
    href: '/',
    rol: 1,
  },

  // { name: 'Estadisticas', icon:StatsChart, current: false, href: '/stats', rol:1},

  {
    name: 'Operaciones',
    group: 'OPERACIONES',
    module: 'LLAMADAS',
    icon: TasksApp,
    current: false,
    href: '/ops',
    rol: 2,
  },

  {
    name: 'Contactos',
    icon: UsersIcon,
    current: false,
    module: 'BASE',
    group: 'CONTACTOS',
    rol: 2,
    children: [
      { name: 'Contactos', href: '/contactos', icon: BookContacts },
      { name: 'Listas', href: '/listas', icon: CardList },
      { name: 'Dashboard Man', href: '/mandantes_dashboard', icon: StatsChart },
    ],
  },
  {
    name: 'Agentes',
    icon: SupportAgent,
    current: false,
    module: 'LLAMADAS',
    group: 'AGENTES_ADMIN',
    rol: 2,
    children: [
      { name: 'Dashboard', href: '/agentes_dashboard2', icon: StatsChart },
      { name: 'Transferencias', href: '/agentes_transfers', icon: Transfer },
    ],
  },
  {
    name: 'Llamadas',
    icon: PhoneIcon,
    current: false,
    group: 'LLAMADAS',
    module: 'LLAMADAS',
    rol: 2,
    children: [
      { name: 'Campañas', href: '/camps_llamadas', icon: SendPlane },
      { name: 'Llamadas', href: '/llamadas', icon: PersonCall },
      { name: 'Respuestas', href: '/respuestas', icon: TelephoneAccessible },
      { name: 'Dashboard', href: '/llamadas_dashboard2', icon: StatsChart },
    ],
  },

  {
    name: 'Mails',
    icon: EnvelopeIcon,
    current: false,
    module: 'MAILS',
    group: 'MAILS',
    rol: 2,
    children: [
      { name: 'Campañas', href: '/camps_mails', icon: SendPlane },
      { name: 'Mails', href: '/mails', icon: EnvelopeIcon },
      // { name: 'Templates', href: '/mail_templates',icon:Template},
      { name: 'Templates', href: '/mail_templates_designs', icon: Template },
      { name: 'Pruebas', href: '/mail_pruebas', icon: Preview },
      { name: 'Dashboard', href: '/mail_dashboard', icon: StatsChart },
    ],
  },
  {
    name: 'SMS',
    icon: ChatBubbleLeftEllipsisIcon,
    current: false,
    module: 'SMS',
    group: 'SMS',
    rol: 2,
    children: [
      { name: 'Campañas', href: '/camps_sms', icon: SendPlane },
      { name: 'SMS', href: '/sms', icon: ChatBubbleLeftEllipsisIcon },
      { name: 'Templates', href: '/sms_templates', icon: Template },
      { name: 'Pruebas', href: '/sms_pruebas', icon: Preview },
      { name: 'Dashboard', href: '/sms_dashboard', icon: StatsChart },
    ],
  },
  {
    name: 'VoiceMsg',
    icon: ChatVoice,
    current: false,
    module: 'VOICE',
    group: 'VOICE',
    rol: 2,
    children: [
      { name: 'Campañas', href: '/camps_voicemsg', icon: SendPlane },
      { name: 'VoiceMsg', href: '/voicemsg', icon: ChatVoice },
      { name: 'Templates', href: '/voicemsg_templates', icon: Template },
      { name: 'Pruebas', href: '/voicemsg_pruebas', icon: Preview },
      { name: 'Dashboard', href: '/voicemsg_dashboard', icon: StatsChart },
    ],
  },
  {
    name: 'Carga',
    icon: CircleStackIcon,
    current: false,
    module: 'BASE',
    group: 'DB',
    rol: 2,
    children: [
      { name: 'Contactos', href: '/carga2', icon: BookContacts },
      { name: 'Plantillas', href: '/carga/plantillas', icon: Table },
      { name: 'Documentos', href: '/carga/docs', icon: DocumentOnePage },
    ],
  },
  {
    name: 'Admin',
    group: 'ADMIN',
    module: 'BASE',
    icon: Settings2Outline,
    current: false,
    href: '/admin',
    rol: 2,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SideBar2 = () => {
  console.log(MODULES.includes('SMS'));

  var user = JSON.parse(localStorage.getItem('user'));
  // var user_modules = localStorage.getItem('modules') !== null ? localStorage.getItem('modules').split(',') : []
  var user_modules =
    localStorage.getItem('modules') === null ? [] : localStorage.getItem('modules').split(',');
  const location = useLocation();
  const [expand, setExpand] = useState(false);
  console.log(user_modules);

  return (
    <div
      className={`flex ${
        expand ? 'w-60' : 'w-14'
      } flex-grow flex-col overflow-y-auto  overflow-x-hidden border-gray-200 bg-blue-normal pt-2 pb-4`}
    >
      <div className="flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 bg-blue-normal px-2" aria-label="Sidebar">
          {navigation.map((item) =>
            !item.children
              ? user.rol >= item.rol &&
                user_modules.includes(item.group) &&
                MODULES.includes(item.module) === true && (
                  <div key={item.name}>
                    <Link
                      href={item.href}
                      as="a"
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'bg-white text-blue-normal hover:bg-gray-50 hover:text-gray-900',
                        'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-blue-800'
                            : 'text-blue-normal group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </div>
                )
              : user.rol >= item.rol &&
                user_modules.includes(item.group) &&
                MODULES.includes(item.module) === true && (
                  <Disclosure as="div" key={item.name} className="space-y-1">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-blue-normal hover:bg-gray-50 hover:text-gray-900',

                            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                          )}
                        >
                          <item.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-blue-normal group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="flex-1">{item.name}</span>
                          <svg
                            className={classNames(
                              open ? 'text-blue-400 rotate-90' : 'text-gray-300',
                              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                            )}
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {item.children.map((subItem) =>
                            expand === true ? (
                              <Link to={subItem.href}>
                                <Disclosure.Button
                                  key={subItem.name}
                                  href={subItem.href}
                                  className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-300 hover:bg-gray-50 hover:text-gray-900"
                                >
                                  {subItem.name}
                                </Disclosure.Button>
                              </Link>
                            ) : (
                              <Link
                                href={subItem.href}
                                to={subItem.href}
                                className={classNames(
                                  subItem.current
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'bg-white text-blue-normal hover:bg-gray-50 hover:text-gray-900',
                                  'group w-5/6 flex items-center pl-1 py-1 text-sm font-medium rounded-md',
                                )}
                              >
                                {subItem.icon ? (
                                  <subItem.icon
                                    className={classNames(
                                      item.current
                                        ? 'text-blue-800'
                                        : 'text-blue-normal group-hover:text-gray-500',
                                      'mr-3 flex-shrink-0 h-6 w-6',
                                    )}
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <StopIcon
                                    className={classNames(
                                      item.current
                                        ? 'text-blue-800'
                                        : 'text-blue-normal group-hover:text-gray-500 ',
                                      'mr-3 flex-shrink-0 h-6 w-6',
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                              </Link>
                            ),
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ),
          )}
        </nav>
      </div>
      <div className="w-full flex justify-center content-center px-2 mt-3">
        <div className="bg-white rounded-md w-full h-9 mb-1 pl-1">
          {expand ? (
            <ArrowFromLeft
              size="32"
              onClick={() => setExpand(false)}
              className="transform hover:scale-110 cursor-pointer rotate-180 w-full h-full"
            />
          ) : (
            <ArrowFromLeft
              size="32"
              onClick={() => setExpand(true)}
              className="transform hover:scale-110 cursor-pointer h-full w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar2;
