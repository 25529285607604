
import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'


import toast from 'react-hot-toast'
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import {useParams} from 'react-router-dom'

import ReactTooltip from 'react-tooltip';
import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';

 

const Enviar = () => {
  const [data,setData] = useState({
    numxct:1,
    number:null,
    template_id:null
  })
  const [templates,setTemplates] = useState([])
  const [nums,setNums] = useState([])
  const [errors,setErrors] = useState([])
  const { id } = useParams();

  const [ct_recieved,setCt_received] = useState(false)

  const handleChangeCheck = (e) => {
      setData({
        ...data,
        [e.target.name]: !ct_recieved,
      });

      setCt_received(!ct_recieved);
    }
  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }

  const getNums = async () => {
    await axiosInstance.get(`twilio/numeros`).then((res) => {
      setNums(res.data);
      // if(res.data.length > 0){
      //   setData({...data,['number']:res.data[0].numero})
      // }

    });
  };

  const getTemplates = async () => {
    await axiosInstance.get(`voice_msg_templates?status=true`).then((res) => {
      setTemplates(res.data);
      // if(res.data.length > 0){
      //   setData({...data,['template_id']:res.data[0].id_template})
      // }

    });
  };
  const send_mails = () =>{
    var errs = []
    if(data['number'] === null){
      toast.error('Seleciona un numero')
      return null
    }
    if(data['template_id'] === null){
      toast.error('Seleciona un template')
      return null
    }

    var tid = toast.loading('Cargando')
    axiosInstance.post(`voice_msg_campaigns/${id}/send/`,data).then(res=>{
      toast.remove(tid)
      toast.success('Enviando Mensajes de voz')
    })//.catch((err)=>{toast.remove(tid);toast.error('error')})
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              errs.push(`${property} : ${err.response.data[property]}`);
              toast.error(`${property} : ${err.response.data[property]}`);
            }
          }
        } else {
        }
        setErrors(errs);
      })
      .finally(()=>toast.remove(tid))
  }


  useEffect(()=>{
    getTemplates()
    getNums()
  },[]) 
  

  return (
      <div className="w-full flex flex-wrap mt-4 bg-white rounded-md shadow p-4 mx-auto gap-y-8">
        <div className="flex w-full">
        <div className="w-40 min-w-32 border-r px-4 flex items-center">
            <h3 className="text-xl font-medium leading-6 text-gray-900">Configuracion</h3>
        </div>
        <div className="w-5/6 h-auto  flex flex-wrap px-4 gap-x-4 justify-around">
          <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3">Template</span>
            <select
              onChange={handleChange}
              type="text"
              name="template_id"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >
              <option key={0} value="">---------</option>
            {templates.map((template)=>{
              return <option key={template.id} value={template.id}>{template.nombre}</option>
            })}

            </select>
          </div>          
        <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3">Numero</span>
            <select
              onChange={handleChange}
              type="text"
              name="number"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >

            <option key={0} value="">---------</option>
            {nums.map((num)=>{
              return <option key={num.id} value={num.numero}>{num.numero}</option>
            })}

            </select>
          </div>
        <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3 ">Tiempo <InfoCircle size={16} data-tip data-for="tiempo" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="tiempo" effect="solid">
                        <p className="break-words">Tiempo a esperar entre llamadas</p>
                    </ReactTooltip>
</span>
            <input
              onChange={handleChange}
              type="number"
              name="timer"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            />
          </div>
         <div className="font-semibold leading-6 rounded-md px-3 py-2 flex justify-center flex-wrap w-42">
            <span className="ml-3 w-full">Numeros x Contacto <InfoCircle size={16} data-tip data-for="numxct" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="numxct" effect="solid">
                        <p className="break-words">Cuantos numeros por contacto llamar.</p>
                    </ReactTooltip></span>
            <input
              onChange={handleChange}
              value={data['numxct']}
              type="number"
              name="numxct"
              className="w-12 border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >
            </input>
          </div>

        </div>
      </div>
      <div className="flex w-full  ">
        <div className="w-40 border-r px-4 flex items-center ">
            <h3 className="text-xl font-medium leading-6 text-gray-900">Filtros</h3>
        </div>
        <div className="w-5/6 h-auto  flex flex-wrap px-4 gap-x-4 justify-around">
         <div className="font-semibold leading-6 rounded-md px-3 py-2">
            <span className="ml-3 ">Enviados <InfoCircle size={16} data-tip data-for="enviados" className="ml-1 transform hover:scale-105 text-gray-500 hover:text-gray-500 cursor-pointer mr-1"/>
                      <ReactTooltip id="enviados" effect="solid">
                        <p className="break-words">Activar para llamar a los contactos que ya han recibido llamadas en esta campaña.</p>
                    </ReactTooltip></span>
            <input
              onChange={handleChangeCheck}
              type="checkbox"
              name="ct_recieved"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            />
          </div> 

        </div>
      </div>

      <div className="flex w-full mt-4 ">
        <div className="w-40 border-r px-4 flex items-center ">
            <h3 className="text-xl font-medium leading-6 text-gray-900">Enviar</h3>
        </div>
        <div className="w-5/6 h-auto  flex flex-wrap px-4 gap-x-4 justify-center">
          <button
          onClick={()=>send_mails()}
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Enviar
        <EnvelopeIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>

        </div>
      </div>
   </div>
  )
}

export default Enviar;


