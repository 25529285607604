import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast';

const LlamadaAction = ({ llamada }) => {
  var user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const [tipoS, setTipoS] = useState(null);
  const [operators, setOperators] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente] = useState(null);
  const [operator, setOperator] = useState(null);
  const [data, setData] = useState({
    user: user.id,
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getOperators = () => {
    axiosInstance.get(`users/?rol__in=2,3`).then((res) => {
      setOperators(res.data);
    });
  };
  const getAgentes = () => {
    axiosInstance.get(`users/?rol__in=1&is_active=True`).then((res) => {
      setAgentes(res.data);
    });
  };

  const createAction = () => {
    console.log({
      ...data,
      ['operador']: operator.id,
      ['llamada']: llamada.id,
      ['tipo']: tipoS,
      new_agente: agente ? agente.id : agente,
    });
    if (operator !== null && tipoS !== null && llamada !== null) {
      axiosInstance
        .post(`lactions/`, {
          ...data,
          ['operador']: operator.id,
          ['llamada']: llamada.id,
          ['tipo']: tipoS,
          ['new_agente']: agente ? agente.id : agente,
        })
        .then((res) => {
          setShow(false);
        });
    }
  };

  useEffect(() => {
    show && getOperators();
    show && tipoS === 2 && getAgentes();
  }, [show]);

  return (
    <div className="absolute w-48 flex flex-wrap  z-30 bg-blue-normal transform -translate-x-40 h-auto">
      <div
        onMouseOverCapture={() => console.log('asd')}
        onClick={() => {
          setTipoS(1);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Pedir Eliminacion</p>
      </div>
      <div
        onMouseOverCapture={() => console.log('asd')}
        onClick={() => {
          setTipoS(2);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Pedir Reasignacion</p>
      </div>
      {show && (
        <div className="rounded-lg flex p-2 flex-wrap content-start w-80 h-80 bg-blue-normal absolute transform -translate-x-80 overflow-y-scroll no-scrollbar">
          <div className="flex flex-wrap h-1/5 w-full border-b overflow-y-scroll no-scrollbar border">
            <div className="h-6 w-full bg-white flex justify-center font-bold">Operador</div>
            {operators.length > 0 &&
              operators.map((ope) => {
                if (operator !== null && ope.id === operator.id) {
                  return (
                    <div
                      onClick={() => {
                        setOperator(null);
                      }}
                      className="h-1/2 cursor-pointer w-full border-b bg-gray-700"
                    >
                      <p className="text-lg text-white">
                        {ope.first_name} {ope.last_name}
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setOperator(ope);
                      }}
                      className="h-1/2  cursor-pointer w-full border-b"
                    >
                      <p className="text-lg text-white">
                        {ope.first_name} {ope.last_name}
                      </p>
                    </div>
                  );
                }
              })}
          </div>
          {tipoS === 2 && (
            <div className="flex flex-wrap h-1/5 w-full border-b overflow-y-scroll no-scrollbar border mt-3">
              <div className="h-6 w-full bg-white flex justify-center font-bold">Agente</div>
              {agentes.length > 0 &&
                agentes.map((age) => {
                  if (agente !== null && age.id === agente.id) {
                    return (
                      <div
                        onClick={() => {
                          setAgente(null);
                        }}
                        className="h-1/2 cursor-pointer w-full border-b bg-gray-700"
                      >
                        <p className="text-lg text-white">
                          {age.first_name} {age.last_name}
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        onClick={() => {
                          setAgente(age);
                        }}
                        className="h-1/2  cursor-pointer w-full border-b"
                      >
                        <p className="text-lg text-white">
                          {age.first_name} {age.last_name}
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          )}
          <div className="w-full h-2/5 p-2">
            <input
              type="text"
              name="comentario"
              onChange={handleChange}
              placeholder="comentario"
              className="w-full h-full text-centera "
            ></input>
          </div>
          <div className="w-full h-1/6  flex justify-around">
            <button
              onClick={() => setShow(false)}
              className="rounded-lg bg-red-600 px-3 py-1 text-gray-100"
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="rounded-lg bg-green-600 px-3 py-1 text-gray-100"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LlamadaAction;
