import React, { useEffect, useState } from 'react';

import axiosInstance from '../../axios';

import UTable from '../../components/admin/UTable';

import CreateUModal from '../../components/users/CreateUModal';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

import Select from 'react-select';

function Users() {
  const [users, setUsers] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [change, setChange] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [filtro, setFiltro] = useState({
    first_name: '',
    last_name: '',
    roles: [],
  });

  useEffect(() => {
    console.log('asdas');

    axiosInstance.get('users/').then((res) => {
      if (res.data) {
        setUsers(res.data);
        setDisplay(res.data);
        console.log(res.data);
      }
    });
  }, [change]);

  const options = [
    { value: 1, label: 'Agente' },
    { value: 2, label: 'Operador' },
    { value: 3, label: 'Administrador' },
  ];

  const handleToggle = (is_active) => {
    setToggle(is_active);
    setDisplay(users.filter((x) => x.is_active == is_active));
  };

  const handleChange = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    setDisplay(
      users.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  const handleSelect = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    //console.log(e.map((rol)=>rol.value))
    console.log(e.map((rol) => rol.value).length);
    if (e.map((rol) => rol.value).length > 0) {
      console.log(users.map((u) => u.rol));
      setDisplay(users.filter((x) => e.map((rol) => rol.value).includes(x.rol)));
    } else {
      setDisplay(users.filter((x) => x.rol in [1, 2, 3]));
    }

    //if(e.length > 0){
    //    setData({
    //        ...data,
    //        ["agentes"] : e.map((agente)=>{return agente.value})
    //    })
    //}
  };

  return (
    <div className="flex flex flex-wrap w-full h-full bg-white px-2">
      {addModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateUModal
            setModal={setAddModal}
            users={users}
            setUsers={setUsers}
            setChange={setChange}
          />
        </div>
      )}
      <div className="flex justify-between w-full h-16 bg-white border-b px-5 pt-1">
        <h1 className="text-4xl font-bold mt-2">Usuarios</h1>
        <PlusCircle
          onClick={() => setAddModal(true)}
          size="55"
          className="text-green-600 mt-1 transform hover:scale-125"
        />
      </div>
      <div className="flex w-full mb-3 px-2 bg-gray-dark py-4 gap-4 pt-7 rounded-lg">
        <input
          type="text"
          name="first_name"
          onChange={handleChange}
          placeholder="Nombre"
          className="p-1 border border rounded-sm w-1/12"
        ></input>
        <input
          type="text"
          name="last_name"
          onChange={handleChange}
          placeholder="Apellido"
          className="p-1 border border rounded-sm w-1/12"
        ></input>
        <Select
          onChange={handleSelect}
          className="w-3/12 h-10"
          isMulti
          placeholder="Rol.."
          options={options}
        />
        <input
          type="text"
          name="last_name"
          onChange={handleChange}
          placeholder="Email"
          className="p-1 border border rounded-sm w-1/12"
        ></input>
        {toggle ? (
          <label for="unchecked" class="inline-flex items-center cursor-pointer">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                <input
                  id="unchecked"
                  onClick={() => handleToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm text-white">Activo</span>
          </label>
        ) : (
          <label for="checked" class="inline-flex items-center cursor-pointer">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                <input
                  id="checked"
                  onClick={() => handleToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Inactivo</span>
          </label>
        )}
      </div>
      <div
        style={{ height: '78vh' }}
        className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
      >
        <UTable users={display} setUsers={setUsers} setChange={setChange}></UTable>
      </div>
    </div>
  );
}
export default Users;
