import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import Calls from './Bar.jsx';
import AddAgent from './AddAgent.jsx';
import toast from 'react-hot-toast';

import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';

import axiosInstance from '../../axios';

import { PersonFill } from '@styled-icons/bootstrap/PersonFill';
import { PersonAdd } from '@styled-icons/fluentui-system-filled/PersonAdd';

const Agentes2 = ({ camp }) => {
  const [agentes, setAgentes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [change, setChange] = useState(null);
  const [add, setAdd] = useState(false);

  const getAgentes = async () => {
    await axiosInstance.get(`campaigns/${camp.id}/dataAgentes2`).then((res) => {
      if (res.data) {
        setAgentes(res.data.agentes);
        console.log(res.data);
      }
    });
  };

  console.log(camp);
  useEffect(() => {
    getAgentes();
  }, [change]);

  return (
    <div className="w-full h-full flex flex-wrap gap-8 bg-gray-100 justify-start content-start p-8">
      <AddAgent open={add} setOpen={setAdd} setChange={setChange} camp={camp} />

      {agentes.length > 0 &&
        agentes.map((agente) => {
          return (
            <div
              className={`w-72 p-2  h-40 rounded-lg border bg-white flex flex-wrap gap-x-2 content-start p-4 shadow`}
            >
              <div className="w-full flex items-center gap-x-4">
                {agente.agente.picture ? (
                  <img
                    className="inline-block h-14 w-14 rounded-full"
                    src={agente.agente.picture}
                    alt=""
                  />
                ) : (
                  <div
                    key={agente.agente.id}
                    className="shrink-0 inline-block h-14 w-14 rounded-full ring-2 ring-white bg-gray-300 flex justify-center items-center"
                  >
                    <PersonFill size="25" className="text-gray-500" />
                  </div>
                )}

                <p className="text-lg font-medium text-gray-700 group-hover:text-gray-900">{`${agente.agente.first_name} ${agente.agente.last_name}`}</p>
              </div>
              <div className="w-full h-16 mt-2 ">
                <Calls data={agente.llamadas} />
              </div>
            </div>
          );
        })}

      <div
        onClick={() => setAdd(true)}
        className={`w-72 p-2 cursor-pointer transform hover:scale-105  h-40 rounded-lg border justify-center border-gray-400 border-dashed border-2 bg-gray-50 flex flex-wrap gap-x-2 content-center p-4 `}
      >
        <PersonAdd size="50" className="text-gray-400" />
      </div>
    </div>
  );
};

export default Agentes2;
