import React from 'react';

import { useHistory } from 'react-router-dom';

const Cards = (props) => {
  const history = useHistory();
  return (
    <div style={{ width: 470 }} className="sm:h-96 lg:h-52 py-2">
      <div
        onClick={() =>
          props.dir == 'in' ? history.push(props.to) : (window.location.href = props.to)
        }
        class="flex flex-wrap shadow-lg rounded-2xl md:w-3/6 sm:h-1/3 md:h-full mx-auto bg-white hover:bg-gray-400 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105"
      >
        <div className="flex justify-center w-full h-4/5 ">
          <div className="flex justify-center w-3/6 h-4/6  my-auto text-blue-ligth rounded-full ">
            {props.children}
          </div>
        </div>
        <div className="flex justify-center w-full">
          <h1 className="text-2xl font-bold">{props.name}</h1>
        </div>
      </div>
    </div>
  );
};

export default Cards;
