import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';

import Table from '../../components/voicemsgCampaigns/Table';
import CreateModal from '../../components/voicemsgCampaigns/CreateModal';
import {MultiPageSelector} from '../../structs/layout/MultiPage'

import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

const VoiceMsgCampaigns = () => {
  const [camps, setCamps] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [status, setStatus] = useState(true);

  const [change, setChange] = useState(false);

  const iconStyles = '-ml-0.5 mr-2 text-gray-400'

  const getCamps = () => {
    axiosInstance.get(`voice_msg_campaigns?status=${status}`).then((res) => {
      setCamps(res.data);
      setDisplay(res.data);
    });
  };

  useEffect(() => {
    getCamps();
  }, [change, status]);

  return (
    <div className="flex flex-wrap w-full h-auto bg-white p-1 gap-2">

	    <MultiPageSelector  tabs={[{name:'Campañas',href:'camps_voicemsg',current:true},{name:'Voice Msg',href:'voicemsg',current:false},{name:'Templates',href:'voicemsg_templates',current:false}]}/> 
      {addModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateModal
            setModal={setAddModal}
            setChange={setChange}
            setCamps={setCamps}
            camps={camps}
          />
        </div>
      )}
      {/* <div className="flex justify-between w-full h-12 bg-white border-b px-5 pt-1 "> */}
      {/*   <h1 className="text-2xl font-bold  w-11/12">Campañas</h1> */}
      {/*    <PlusCircle  */}
      {/*      onClick={() => setAddModal(true)}  */}
      {/*      size="55"  */}
      {/*     className="w-1/12 text-green-600 mt-1 transform hover:scale-125" */}
      {/*    />  */}
      {/*   <div className="px-2 p-1 bg-blue-ligth h-10 font-bold text-white transform hover:scale-110"> */}
      {/*     <p>Crear</p> */}
      {/*   </div> */}
      {/* </div> */}
    <div className="md:flex md:items-center md:justify-between w-full pr-4">
      <div className="min-w-0 flex-1">
        {/* <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"> */}
        {/*   Campañas */}
        {/* </h2> */}
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <button
          type="button"
          className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setAddModal(true)}
        >
          Crear
        </button>
      </div>
    </div>
	  {/* <MultiPageSelector  tabs={[{name:'Llamadas',href:'camps_llamadas',current:false},{name:'Mails',href:'camps_mails',current:false},{name:'SMS',href:'camps_sms',current:true}]}/>  */}
      <div
        className="px-3 flex flex-wrap content-start h-auto w-full "
      >
        <div className="w-full h-10 flex wrap justify-end p-2 gap-x-4">
          <div
            onClick={() => setStatus(true)}
            className={`w-32 h-full ${
              status !== 1 ? 'bg-green-400' : 'bg-green-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Activas
          </div>
          <div
            onClick={() => setStatus(false)}
            className={`w-32 h-full ${
              status !== 2 ? 'bg-red-400' : 'bg-red-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Inactivas
          </div>
        </div>
        <Table camps={display} setChange={setChange}></Table>
      </div>
    </div>
  );
};

export default VoiceMsgCampaigns;
