import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';



const Bars = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 1,
          right: 1,
          left: 1,
          bottom: 1,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name"/>
        {/* <YAxis /> */}
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        <Tooltip></Tooltip>
        <ReferenceLine y={0}  />
        <Brush dataKey="name" height={25}stroke="#5c7ffa" />
        <Bar name="abiertos" dataKey="opened" fill="#4be0eb" />
        <Bar name="clicks" dataKey="clicked" fill="#353ef0" />
        <Bar name="enviados" dataKey="delivered" fill="#5bde5f" />
        <Bar name="rebotados" dataKey="bounced" fill="#64686e" />
        <Bar name="spam" dataKey="spam" fill="#fc8888" />
        <Bar name="unsub" dataKey="unsub" fill="#83a7de" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Bars;
