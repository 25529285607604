import React, { useEffect, useState } from 'react';

import axiosInstance from '../../axios';

import STable from '../../components/admin/STable';

import CreateSModal from '../../components/scripts/CreateSModal';
import EditSModal from '../../components/scripts/EditSModal';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

import Select from 'react-select';

function Scripts() {
  const [scripts, setScripts] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [script, setScript] = useState({});
  const [status,setStatus] = useState(true)

  const [change, setChange] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [filtro, setFiltro] = useState({
    first_name: '',
    last_name: '',
    roles: [],
  });

  useEffect(() => {
    //console.log(script)

    axiosInstance.get(`scripts?is_active=${status}`).then((res) => {
      if (res.data) {
        setScripts(res.data);
        setDisplay(res.data);
        //console.log(res.data);
      }
    });
  }, [change,status]);

  const handleToggle = (is_active) => {
    setToggle(is_active);
    //setDisplay(resps.filter(x=>x.is_active == is_active))
  };

  const handleChange = (e) => {
    //console.log(e.target.name)
    console.log(typeof e.target.value);
    if (e.target.value === '') {
      setDisplay();
    }

    setDisplay(
      scripts.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  //const handleSelect = (e) => {
  //    if(e.length > 0){
  //      setDisplay(resps.filter(x=>x.grupo in e.map((group)=>{return group.value})))
  //    } else{
  //      setChange('asdsad')
  //    }
  //
  //}

  return (
    <div className="flex flex flex-wrap w-full h-auto bg-white">
      {addModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateSModal setModal={setAddModal} setChange={setChange} />
        </div>
      )}
      {editModal === true && script && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70 justify-center">
          <EditSModal
            script={script}
            setModal={setEditModal}
            setScript={setScript}
            setChange={setChange}
          />
        </div>
      )}
      <div className="flex justify-between w-full h-16 bg-white border-b px-5 pt-1">
        <h1 className="text-4xl font-bold mt-2">Scripts</h1>
        <PlusCircle
          onClick={() => setAddModal(true)}
          size="55"
          className="text-green-600 mt-1 transform hover:scale-125"
        />
      </div>
      <div className="flex w-full mb-3 px-2 bg-gray-dark py-4 gap-4 pt-7">
        <input
          type="text"
          name="nombre"
          onChange={handleChange}
          placeholder="Nombre"
          className="p-1 border border rounded-sm w-1/12"
        ></input>
        {/*<Select
                        onChange={handleSelect}
                        className="w-2/12"
                        isMulti
                        placeholder="Grupo"
                        options={[{value:1,label:'positiva'},{value:2,label:'negativa'},{value:3,label:'rellamada'},]}
                        />*/}
        {toggle ? (
          <label for="unchecked" class="inline-flex items-center cursor-pointer">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                <input
                  id="unchecked"
                  onClick={() => handleToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Activo</span>
          </label>
        ) : (
          <label for="checked" class="inline-flex items-center cursor-pointer">
            <span class="relative">
              <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
              <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                <input
                  id="checked"
                  onClick={() => handleToggle(!toggle)}
                  type="checkbox"
                  class="absolute opacity-0 w-0 h-0"
                />
              </span>
            </span>
            <span class="ml-3 text-sm">Inactivo</span>
          </label>
        )}
      </div>
        <div className="w-full h-10 flex wrap justify-end p-2 gap-x-4">
          <div
            onClick={() => setStatus(true)}
            className={`w-32 h-full ${
              status !== 1 ? 'bg-green-400' : 'bg-green-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Activas
          </div>
          <div
            onClick={() => setStatus(false)}
            className={`w-32 h-full ${
              status !== 2 ? 'bg-red-400' : 'bg-red-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Inactivas
          </div>
        </div>
      <div
        style={{ height: '78vh' }}
        className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
      >
        <STable
          scripts={display}
          setScrips={setScripts}
          setEditModal={setEditModal}
          setScript={setScript}
          setChange={setChange}
        />
      </div>
    </div>
  );
}
export default Scripts;
