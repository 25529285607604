export const getLlamadaStatusP = (status) => {
  if (status === 1) {
    return <p className="my-auto text-center text-green-600">Realizada</p>;
  }
  if (status === 2) {
    return <p className="my-auto text-center text-yellow-400">Pendiente</p>;
  }
  if (status === 3) {
    return <p className="my-auto text-center text-blue-600">Rellamada</p>;
  }
  if (status === 4) {
    return <p className="my-auto text-center text-gray-600">Inactiva</p>;
  }
  if (status === 5) {
    return <p className="my-auto text-center text-red-500">Recibida</p>;
  }
  if (status === 6) {
    return <p className="my-auto text-center text-red-700">Terminada</p>;
  }
};

export const getStatusColor = (status) => {
  console.log(status)
  if (status === 1) {
    return 'bg-green-100';
  }
  if (status == 2) {
    return 'bg-yellow-100';
  }
  if (status == 3) {
    return 'bg-blue-100';
  }
  if (status == 4) {
    return 'bg-gray-500';
  }
  if (status == 5) {
    return 'bg-red-100';
  }
  if (status == 6) {
    return 'bg-red-200';
  }
};

export const getStatusColor2 = (status) => {
  console.log(status)
  if (status === 1) {
    return '-green-500';
  }
  if (status == 2) {
    return '-yellow-500';
  }
  if (status == 3) {
    return '-blue-500';
  }
  if (status == 4) {
    return '-gray-500';
  }
  if (status == 5) {
    return '-red-500';
  }
  if (status == 6) {
    return '-red-500';
  }
};

export const get = (status) => {
  if (status === 1) {
    return <p className="my-auto text-center text-green-600">Realizada</p>;
  }
  if (status === 2) {
    return <p className="my-auto text-center text-yellow-400">Pendiente</p>;
  }
  if (status === 3) {
    return <p className="my-auto text-center text-blue-600">Rellamada</p>;
  }
  if (status === 4) {
    return <p className="my-auto text-center text-gray-600">Inactiva</p>;
  }
  if (status === 5) {
    return <p className="my-auto text-center text-red-500">Recibida</p>;
  }
  if (status === 6) {
    return <p className="my-auto text-center text-red-700">Terminada</p>;
  }
};

export const getGrupo = (grupo) => {
  if (grupo === 1) {
    return 'Positiva';
  }
  if (grupo === 2) {
    return 'Negativa';
  }
  if (grupo === 3) {
    return 'Rellamada';
  }
};

export const getImportanciaP = (imp) => {
  if (imp === 3) {
    return <p className="my-auto text-center text-green-500 font-bold text-left">Alta</p>;
  }
  if (imp === 2) {
    return <p className="my-auto text-center text-blue-500 font-bold text-left">Media</p>;
  }
  if (imp === 1) {
    return <p className="my-auto text-center text-yellow-500 font-bold text-left">Baja</p>;
  }
};

export const getImportanciaColor = (imp) => {
  if (imp === 3) {
    return 'bg-green-300';
  }
  if (imp === 2) {
    return 'bg-blue-300';
  }
  if (imp === 1) {
    return 'bg-yellow-300';
  }
}

export const getImportanciaColorPlain = (imp) => {
  if (imp === 3) {
    return '-green-500';
  }
  if (imp === 2) {
    return '-blue-500';
  }
  if (imp === 1) {
    return '-yellow-500';
  }
};;

export const getTipoCarga = (tipo) => {
  if (tipo === 2) {
    return <p className="my-auto text-center text-blue-500 font-bold text-center">SQL</p>;
  }
  if (tipo === 1) {
    return <p className="my-auto text-center text-green-500 font-bold text-center">Excel</p>;
  }
};
export const getStatusCarga = (tipo) => {
  if (tipo === 2) {
    return <p className="my-auto text-center text-red-500 font-bold text-center">TERMINADA</p>;
  }
  if (tipo === 1) {
    return <p className="my-auto text-center text-yellow-500 font-bold text-center">PENDIENTE</p>;
  }
};
