import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast'
import {getUserRol} from '../../utils/users'

const MODULES = ["AGENTE","OPERACIONES","CONTACTOS","AGENTES_ADMIN","LLAMADAS","MAILS","SMS","VOICE","DB","ADMIN"]

const EditUser = () => {
  const [data, setData] = useState({});
  const [user,setUser] = useState(null)
  const [errors, setErrors] = useState([]);
  const [picture,setPicture] = useState(null)
  const [userModules,setUserModules] = useState([])

  const { id } = useParams();
  const history = useHistory()


    console.log(userModules)
  const changeModule = (module) =>{
    console.log(userModules)
    if(userModules.includes(module)){
      setUserModules(userModules.filter(m => m !== module))
    }else{
      setUserModules([...userModules,module])
    }
    console.log(userModules)
  }
  const handleChangeFile = (event) => {
    setPicture(event.target.files[0])
  }
  const getUser = async () => {
    await axiosInstance
      .get(`users/` + id + '/')
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          setData(res.data)
          setUserModules(res.data.modules)
        }
      })
      .catch((error) => {
        console.log(error) 
      });
  };

  const updateModules = async () =>{
   await axiosInstance
      .patch(`users/${user.id}/`,{modules:userModules})
      .then((res) => {
        toast.success('modulos actualizados')
      })

  }
  const submitForm = () => {
    const formData = new FormData();
    console.log(data) 
    delete data['picture'] 
    delete data['modules'] 
    picture !== null && formData.append("picture", picture);
    
    Object.keys(data).map((key)=>{
      formData.append(key, data[key]);
    })
    //console.log(formData.keys())
    

    axiosInstance
      .patch(`users/${user.id}/`, formData)
      .then((res) => {
        console.log(res);
        history.push(`/users/${user.id}`)
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        var errs = [];
        if (err.response !== undefined && err.response.status == 500) {
          setErrors(['Error de sistema']);
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              errs.push(`${property} : ${err.response.data[property]}`);
              toast.error(`${property} : ${err.response.data[property]}`);
            }
          }
        } else {
        }
        setErrors(errs);
        console.log(errs);
      });
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getUser()
    // setData({ ...user }); //,['rol']:user.rol.id})
    // console.log(user);
  }, []);

  return (
    <div className="flex flex-wrap w-full h-full p-6 overflow-y-scroll">
      <div className="h-full w-full flex justify-between flex-wrap border pl-8 py-5   bg-white rounded-lg">
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Username <span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.username} </p>
          </label>
          <input
            name="username"
            value={data['username']}
            onChange={handleChange}
            type="text"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Nombre<span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.first_name} </p>
          </label>
          <input
            name="first_name"
            value={data['first_name']}
            onChange={handleChange}
            type="text"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Apellido<span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.last_name} </p>
          </label>
          <input
            name="last_name"
            value={data['last_name']}
            onChange={handleChange}
            type="text"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Email<span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.email} </p>
          </label>
          <input
            name="email"
            value={data['email']}
            onChange={handleChange}
            type="text"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Rut<span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.rut} </p>
          </label>
          <input
            name="rut"
            value={data['rut']}
            onChange={handleChange}
            type="text"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Telefono<span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.phone_number} </p>
          </label>
          <input
            name="phone_number"
            value={data['phone_number']}
            onChange={handleChange}
            type="text"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2">
            Rol
          </label>
          <select
            name="rol"
            value={data['rol']}
            onChange={handleChange}
            className="w-1/2 h-1/3 mt-2"
          >
            <option value={1} key={1}>
              Agente
            </option>
            <option value={2} key={2}>
              Operador
            </option>
            <option value={3} key={3}>
              Administrador
            </option>
          </select>
        </div>
        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for=""
          >
            External_id<span className="text-black font-bold">||</span>{' '}
            <p className="">{user && user.external_id} </p>
          </label>
          <input
            name="external_id"
            value={data['external_id']}
            onChange={handleChange}
            type="number"
            className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          ></input>
        </div>
      <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for=""
          >
            Foto<span className="text-black font-bold">||</span>{' '}
            <p className=""> </p>
          </label>
          <input
            name="picture"
            onChange={handleChangeFile}
            type="file"
          ></input>
        </div>


        <div className="w-2/6 h-1/6 ">
          <label
            className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2"
            for="username"
          >
            Status
          </label>
          {user && data['is_active'] ? (
            <label for="unchecked" class="mt-3 inline-flex items-center cursor-pointer ">
              <span class="relative">
                <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                  <input
                    id="unchecked"
                    onClick={() => setData({ ...data, ['is_active']: false })}
                    type="checkbox"
                    class="absolute opacity-0 w-0 h-0"
                  />
                </span>
              </span>
              <span class="ml-3 text-sm">Activo</span>
            </label>
          ) : (
            <label for="checked" class="mt-3 inline-flex items-center cursor-pointer ">
              <span class="relative">
                <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                  <input
                    id="checked"
                    onClick={() => setData({ ...data, ['is_active']: true })}
                    type="checkbox"
                    class="absolute opacity-0 w-0 h-0"
                  />
                </span>
              </span>
              <span class="ml-3 text-sm">Inactivo</span>
            </label>
          )}
        </div>
        <div className="w-2/6 h-1/6 justify-self-end self-end ">
          <button
            onClick={() => submitForm()}
            className="focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Guardar
          </button>
        </div>
      </div>

      <div className="flex flex-wrap grap-2 w-full h-1/2 border rounded-md mt-4 bg-white p-4">
        <div className="w-full flex flex-wrap gap-2">
          {MODULES.map((mod)=>{
            return <div onClick={()=>changeModule(mod)}  className={`h-10 bg-gray-100 rounded-md p-2 border cursor-pointer transform hover:scale-110 font-semibold ${userModules.includes(mod) ? 'bg-green-100 hover:bg-red-100' : 'hover:bg-green-100'}`}><span>{mod}</span></div>
          })}
        </div>
        <button
            onClick={() => updateModules()}
            className="focus:outline-none h-10 mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-green-500 cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Guardar
          </button>
      </div>
    </div>
  );
};

export default EditUser;
