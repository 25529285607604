import { CalendarIcon, ChevronRightIcon,UsersIcon } from '@heroicons/react/20/solid'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { es, ru } from 'date-fns/locale'

import { getImportanciaP } from '../../utils/models';
import toast from 'react-hot-toast';


import axiosInstance from '../../axios'
import {Link} from 'react-router-dom'

import { ArrowDown } from '@styled-icons/bootstrap/ArrowDown';
import { ArrowUp } from '@styled-icons/bootstrap/ArrowUp';
import {ArchiveFill} from '@styled-icons/bootstrap/ArchiveFill'
import {PersonFill} from '@styled-icons/bootstrap/PersonFill'


const ViewOrder = ({objs,setChange}) => {

  const cambiarStatus = (camp) => {
    if (camp.status === 1) {
      var status = 2;
    } else if (camp.status === 2) {
      var status = 1;
    }
    axiosInstance.patch(`campaigns/${camp.id}/`, { status: status }).then((res) => {
      setChange(new Date());
      toast.success(`Cambio de status en ${camp.nombre}`, { duration: 3000 });
    });
  };

  const cambiarPrioridad = (camp) => {
    axiosInstance.patch(`campaigns/${camp.id}/`, { prioridad: !camp.prioridad }).then((res) => {
      setChange(new Date());
      toast.success(`Cambio de prioridad en ${camp.nombre}`, { duration: 3000 });
    });
  };
  const cambiarArchivo = (obj) => {
    console.log(obj)
    axiosInstance.patch(`campaigns/${obj.id}/`, { archivada: !obj.archivada}).then((res) => {
      setChange(new Date());
      toast.success(`${obj.nombre} ${obj.archivada === true ? 'desarchivada': 'archivada'}`, { duration: 3000 });
    });
  };

  const cambiarImportancia = (camp, dir) => {
    if (dir === 'up') {
      if (camp.importancia < 3) {
        axiosInstance
          .patch(`campaigns/${camp.id}/`, { importancia: camp.importancia + 1 })
          .then((res) => {
            setChange(new Date());
            toast.success(`Cambio de importancia en ${camp.nombre}`, { duration: 3000 });
          });
      }
    } else if (dir === 'down') {
      if (camp.importancia > 1) {
        axiosInstance
          .patch(`campaigns/${camp.id}/`, { importancia: camp.importancia - 1 })
          .then((res) => {
            setChange(new Date());
            toast.success(`Cambio de importancia en ${camp.nombre}`, { duration: 3000 });
          });
      }
    }
  };
  return (
    <div className="w-full bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {objs.map((obj) => (
          <li key={obj.id}>
            <a href="#" className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-2 sm:px-6 justify-between">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between ">
                  <div className="truncate ">
                    <div className="flex text-lg">
                      <p className="truncate font-medium text-blue-ligth">{obj.nombre}</p>
                    </div>

                    <div className="flex text-sm mt-2">
                    <Link to={`/listas/${obj.lista?.id}`} className="flex items-center text-sm text-gray-500 hover:text-gray-800 ">
                      <UsersIcon className="mr-1.5 h-5 w-5 flex-shrink-0 " aria-hidden="true" />
                      {obj.lista?.nombre}
                    </Link>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <p>
                          Termina {formatDistance(subDays(new Date(obj.fecha_fin), 3), new Date(), { addSuffix: true ,locale:es})}
                        </p>
                      </div>
                    </div>
                  </div>

                                 


                  <div className="mt-1 flex  w-1/2 flex-shrink-0 sm:mt-0 sm:ml-5 items-center justify-between">
                  <div className="flex justify-start w-auto gap-x-2">

                    

                  <span onClick={() => cambiarPrioridad(obj)} className={`relative inline-block px-3 py-1 font-semibold text-gray-800  leading-tight w-auto ${
                        obj.prioridad
                          ? 'hover:bg-red-200 bg-green-200'
                          : 'hover:bg-green-200 bg-red-200'
                      }`}>
                    <span
                      aria-hidden="true"
                                        ></span>
                    <span
                      className="relative cursor-pointer"
                    >
                      Prioridad
                    </span>
                  </span>
                    <p className="text-start w-16">{getImportanciaP(obj.importancia)}</p>
                        <ArrowUp
                          onClick={() => cambiarImportancia(obj, 'up')}
                          size="20"
                          className="font-bold  transform hover:scale-110 text-blue-700"
                        />
                        <ArrowDown
                          onClick={() => cambiarImportancia(obj, 'down')}
                          size="20"
                          className="transform hover:scale-110 text-blue-700"
                        />
                  </div>

                    <span onClick={() => cambiarStatus(obj)} className={`relative inline-block px-3 py-1 font-semibold border rounded-full leading-tight ${obj.status == 1 ? 'text-green-600 bg-green-100 border-green-600 hover:bg-red-100 hover:text-red-600' : 'text-red-600 bg-red-100 border-red-600 hover:bg-green-100 hover:text-green-600'}`}>
                        {obj.status == 1 ? 'activa' : 'inactiva'}
                    </span>
                    {obj.status === 2 && <ArchiveFill onClick={()=>cambiarArchivo(obj)} size="24" className="text-blue-ligth transform hover:scale-110"></ArchiveFill>}

                    <div className="flex -space-x-1 overflow-hidden w-1/3 justify-end">
                      {obj.agentes && obj.agentes.map((agente) => (
                        agente.picture ?
                        <img
                          key={agente.id}
                          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                          src={agente.picture}
                          alt={agente.first_name}
                        />
                        :
                        <div
                          key={agente.id}
                          className="inline-block h-8 w-8 rounded-full ring-2 ring-white bg-gray-300 flex justify-center items-center"
                          // src={agente.picture}
                          alt={agente.first_name}
                        ><PersonFill size="20" className="text-gray-500"/></div>
                        
                      ))}
                    </div>
                  </div>
                </div>

                  <Link className="ml-5  flex-shrink-0" to={`/camps_llamadas/${obj.id}`}>
                  <ChevronRightIcon className="h-10 w-5 text-gray-400" aria-hidden="true" />
                  </Link>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default ViewOrder;
