
import {useState,useEffect} from 'react'
import axiosInstance from '../../../axios';
import toast from 'react-hot-toast'

import fileDownload from 'js-file-download'

import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import {ArrowDownload} from '@styled-icons/fluentui-system-regular/ArrowDownload'
import {ChevronRightCircle} from '@styled-icons/boxicons-regular/ChevronRightCircle'

const CargaFile = () =>{
  const [nro_cuentas, setNro] = useState('');
  const [digitos, setDigitos] = useState(0);
  const [change,setChange] = useState(null)

  const [file, setFile] = useState(null);
  const [templates,setTemplates] = useState([])
  const [template,setTemplate] = useState(null)
  const [filter,setFilter] = useState("")

  const handleChangeDigitos = (e) => {
    setDigitos(e.target.value);
  };
  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const close = () => {
    setFile(null);
  };

  
  const getTemplates = async () =>{
    await axiosInstance.get(`plantillas_carga?page_size=100`).then(res=>{
      console.log(res.data)
      setTemplates(res.data.results)
    })
  }
  const downloadPlantilla = async (p) =>{
    await axiosInstance.post(`plantillas_carga/${p.id}/download/`,{responseType:'blob'}).then(res=>{
        // console.log(res.headers)
        fileDownload(res.data,`${p.nombre}.csv`);
    })
  }
  const cargar_excel = () => {
    if(file === null){
      toast.error('Seleciona un archivo')
      return null
    }
    if(template === null){
      toast.error('Seleciona una plantilla')
      return null
    }
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('digitos', digitos);
    data.append('template', template.id);
    console.log(data.values())
    var toastId = toast.loading('carga enviada');
    axiosInstance
      .post(`carga/cuentas_excel2/`, data)
      .then((res) => {

        setChange(new Date());
        toast.remove(toastId);
        toast.success('carga finalizada');
      })
      .catch((err) => toast.remove(toastId))
      .finally(() => toast.remove(toastId))
    //setFile(null)
  };

 

  useEffect(()=>{
    getTemplates()
  },[])



  return (
        <div className="flex-grow flex  h-96 rounded-md border shadow-md bg-white">

          <div className="border-r w-1/2 flex flex-wrap content-between p-4 " >
            <div className="w-full flex-wrap content-start h-42">
              <p className="text-lg ">Carga Excel</p>
              <p className="text-md text-gray-600 pl-2">1. Seleccione una plantilla a la derecha</p>
              <p className="text-md text-gray-600 pl-2">2. Eliga su archivo</p>
              <p className="text-md text-gray-600 pl-2">3. Ingrese numero de digitos nro_cuenta</p>
              <p className="text-md text-gray-600 pl-2">4. Click el boton Cargar</p>
            </div>

            <div className="w-full flex flex-wrap justify-center px-4 gap-y-3 items-center">
              <div className="w-full bg-gray-100 rounded-md h-10 flex justify-center items-center">
              <p className="text-lg font-semibold">{template && template.nombre}</p>
              </div>
              <label className="my-auto  w-full flex flex-col items-center p-1  bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150">
                <CloudUpload size="40"></CloudUpload>
                <span className="mt-1 text-base leading-normal">Select a file</span>
                <input
                  type="file"
                  onChange={handleChangeFile}
                  className="hidden"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx  xls, .xlsb , .xltx , xls "
                />
              </label>
              <h1 className="font-bold text-blue-500  ">{file && file.name}</h1>
              <p className="text-gray-700 font-semibold mx-2">Digitos :</p>
              <input
                type="number"
                onChange={handleChangeDigitos}
                value={digitos}
                className="w-12  px-2 h-8 rounded-md shadow-lg font-bold border border-blue-800 text-blue-500"
              ></input>
              </div>

              

            <button  onClick={()=>cargar_excel()} className="px-2 py-1 rounded-md bg-blue-ligth text-gray-50 mx-auto transform hover:scale-105 hover:bg-blue-600">
              Cargar
            </button>
          </div>  
          
          <div className="w-1/2 flex flex-wrap p-4 content-start overflow-hidden">
              <p className="text-lg w-full">Plantillas</p>
              <input onChange={(e)=>setFilter(e.target.value)} className="bg-gray-200 rounded-md w-full h-10 p-4">
              </input>

              <div className="w-full flex flex-wrap p-2 mt-2 h-4/6  overflow-y-scroll gap-y-2 content-start">
                {templates && templates.filter(x=>x.nombre.toLowerCase().includes(filter)).map((t)=>{
                  return (
                  <div className={`${template && template.id === t.id && 'border border-blue-500'} h-8 w-full bg-gray-100 rounded-md flex px-2 py-1 justify-between items-center`}>
                    <p className="w-1/2 overflow-hidden truncate">{t.nombre}</p>
                    <div className="flex text-sm items-center text-gray-700">
                      <ArrowDownload  size="26" className="text-gray-900 "></ArrowDownload>
                      <p onClick={()=>downloadPlantilla(t)} className="text-blue-ligth cursor-pointer transform hover:scale-105">Plantilla</p>
                      {/* <p className="text-gray-700 mx-1">/</p> */}
                      {/* <p className="text-blue-ligth cursor-pointer transform hover:scale-105">Instrucciones</p> */}
                    </div>

                    <ChevronRightCircle onClick={()=>setTemplate(t)} size="26" className="text-blue-normal transform hover:scale-105 cursor-pointer"/>
                </div>
                  )
                })}
                
        
              </div>
          </div>

    </div>
  )

}

export default CargaFile;
