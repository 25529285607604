import {useState,useEffect} from 'react'

import axiosInstance from '../../axios';
import LAgents from '../../components/dashboardAdmin/graficos/LAgents';
import RAgents from '../../components/dashboardAdmin/graficos/RAgents';
import PAgents from '../../components/dashboardAdmin/graficos/PAgents';
import AStats from '../../components/dashboardAdmin/graficos/AStats';
import Grafico from '../../components/Grafico';

const AgentesDashboard2  = () =>{
  const [data,setData] = useState(null)
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });
  
  



  const getData = async () => {
    if (dates['desde'] !== null && dates['hasta'] !== null) {
      await axiosInstance
        .get(`llamadas/dashboard_agentes/?desde=${dates['desde']}&hasta=${dates['hasta']}`)
        .then((res) => {
          setData(res.data);
          console.log(res.data);
        });
    }
  };

  const handleChange = (e) =>{
    setDates({...dates,[e.target.name]:e.target.value})
  }

  useEffect(()=>{
    if (dates['desde'] === null || dates['hasta'] === null) {
      var date = new Date();

      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
    }else{
      getData()
    }
  
  },[dates])




  return (
    <div className="w-full h-full flex-wrap bg-gray-100">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Dashboard Agentes</p>

        <div className="flex items-center gap-x-2  p-1">
              <div className="rounded-md flex bg-gray-50 w-auto px-2 border border-blue-semi border-t-2 ">
                <input
                  name="desde"
                  type="date"
                  value={dates['desde']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
                <span className="h-full w-0.5 bg-gray-500"></span>
              <div className="rounded-md flex bg-gray-50 border border-blue-semi border-t-2">
                <input
                  name="hasta"
                  type="date"
                  value={dates['hasta']}
                  onChange={handleChange}
                  className=" bg-gray-50 rounded-md"
                ></input>
              </div>
        </div>
      </div>

      <div className='w-full flex-wrap  gap-x-4 gap-y-4 p-4 justify-center items-center'>
          <div className="h-full w-full">
          {data && <Grafico nombre="Diario" data={data.hour} groups={data.agentes}><LAgents/></Grafico>}
          </div>
        <div className='flex w-full gap-x-3'>
          <div className='w-1/2 flex-shrink-0 h-2/3 mt-4'>
          {data && <Grafico nombre="Horas" data={data.dias} groups={data.agentes}><LAgents/></Grafico>}
        </div>
        <div className='flex flex-grow  h-2/3 mt-4 '>
          {data && <Grafico nombre="Llamadas Asignadas" data={data.agentes_pending} groups={data.status_opts}><PAgents/></Grafico>}
        </div>
        </div>
      </div>

     


    </div>
  )
}

export default AgentesDashboard2;
