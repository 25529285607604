import React, { useState, useEffect } from 'react';

import axiosInstance from '../../axios';

import { UpArrowAlt } from '@styled-icons/boxicons-regular/UpArrowAlt';
import { DownArrowAlt } from '@styled-icons/boxicons-regular/DownArrowAlt';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';
import { TextBulletListAdd } from '@styled-icons/fluentui-system-filled/TextBulletListAdd';
import { SignalWifiStatusbarNull } from 'styled-icons/material-twotone';

const EditSModal = ({ setModal, setChange, script, setScript }) => {
  const [vars, setVars] = useState([]);
  const [pas, setPas] = useState(false);
  const [reload, setReload] = useState(false);
  const [status, setStatus] = useState(1);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    nombre: null,
  });

  const [editBData, setEditBData] = useState({
    id: null,
    cuerpo: null,
  });

  const [bData, setBData] = useState({
    script: script.id,
    nombre: null,
    cuerpo: null,
    position: script.bloques.length == 0 ? 1 : script.bloques.length + 1,
  });

  const getVariables = () => {
    axiosInstance.get('contactos/fields_and_props/').then((res) => {
      setVars(res.data);
    });
  };

  const addVar = (v) => {
    var cuerpo = bData['cuerpo'];
    cuerpo === null ? (cuerpo = `{${v}}`) : (cuerpo = cuerpo + `{${v}}`);
    setBData({ ...bData, ['cuerpo']: cuerpo });
  };

  const close = () => {
    setModal(false);
  };
  //setNumeros(numeros.filter(x=> x.numero !== numero)
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeBloque = (e) => {
    setBData({ ...bData, [e.target.name]: e.target.value });
    console.log(bData);
  };
  const handleChangeEditBloque = (e) => {
    setEditBData({ ...editBData, [e.target.name]: e.target.value });
  };

  const endEditBloque = () => {
    axiosInstance.patch(`bloques/${editBData.id}/`, editBData).then((res) => {
      setScript({
        ...script,
        ['bloques']: script.bloques.map((bloque) => {
          if (bloque.id !== res.data.id) {
            return bloque;
          } else {
            return res.data;
          }
        }),
      });
      setEditBData({ id: null });
    });
  };

  const moveBloque = (dir, bloque) => {
    if (dir === 'up' && bloque.position != 1) {
      console.log('up');
      axiosInstance
        .patch(`bloques/${bloque.id}/`, { position: bloque.position - 1 })
        .then((res) => {
          setChange(res.data);
        });
      var prev_bloque = script.bloques.filter((x) => x.position == bloque.position - 1)[0];

      axiosInstance
        .patch(`bloques/${prev_bloque.id}/`, { position: bloque.position })
        .then((res) => {
          setChange(res.data);
        });
      setScript({
        ...script,
        ['bloques']: script.bloques
          .map((b) => {
            if (b.id === bloque.id) {
              return { ...b, ['position']: bloque.position - 1 };
            } else if (b.id === prev_bloque.id) {
              return { ...b, ['position']: bloque.position };
            } else {
              return b;
            }
          })
          .sort((a, b) => (a.position > b.position ? 1 : -1)),
      });
    } else if (
      dir === 'down' &&
      script.bloques.filter((x) => x.position > bloque.position).length > 0
    ) {
      console.log('up');
      axiosInstance
        .patch(`bloques/${bloque.id}/`, { position: bloque.position + 1 })
        .then((res) => {
          //setChange(res.data)
        });
      var next_bloque = script.bloques.filter((x) => x.position == bloque.position + 1)[0];

      axiosInstance
        .patch(`bloques/${next_bloque.id}/`, { position: bloque.position })
        .then((res) => {
          //setChange(res.data)
        });
      setScript({
        ...script,
        ['bloques']: script.bloques
          .map((b) => {
            if (b.id === bloque.id) {
              return { ...b, ['position']: bloque.position + 1 };
            } else if (b.id === next_bloque.id) {
              return { ...b, ['position']: bloque.position };
            } else {
              return b;
            }
          })
          .sort((a, b) => (a.position > b.position ? 1 : -1)),
      });
    }
  };

  const deleteBloque = (bloque) => {
    axiosInstance.delete(`bloques/${bloque.id}/`).then((res) => {
      setScript({ ...script, ['bloques']: script.bloques.filter((x) => x.id != bloque.id) });
    });
  };
  const addBloque = () => {
    //console.log(data)
    var errs = [];

    bData['cuerpo'] == null && errs.push('Texto : El Texto del bloque no puede ser nulo');

    if (errs.length === 0) {
      axiosInstance
        .post(`bloques/`, bData)
        .then((res) => {
          setScript({ ...script, ['bloques']: [...script.bloques, res.data] });
          //see how to reload the bloque data
          setBData({
            script: script.id,
            nombre: null,
            cuerpo: null,
            position: script.bloques.length == 0 ? 1 : script.bloques.length + 2,
          });
          setChange(res.data);
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status == 500) {
            setErrors(['Error de sistema']);
          } else if (err.response !== undefined && err.response.status == 400) {
            if (err.response !== undefined && 'data' in err.response) {
              for (let property in err.response.data) {
                //console.log(`${property} : ${err.response.data[property]}`)
                errs.push(`${property} : ${err.response.data[property]}`);
              }
            }
          } else {
          }
          setErrors(errs);
        });
    } else {
      setErrors(errs);
    }
    console.log(errs);
    console.log(bData);
  };

  useEffect(() => {
    console.log(vars);
    //console.log(script.bloques.sort((a,b)=>(a.position < b.position) ? 1: -1))
    getVariables();

    //setBData({script:script.id,nombre:null,cuerpo:null,position:script.bloques.length == 0 ? 1 : script.bloques.length+1})
  }, []);

  return (
    <div className="flex flex-wrap  mt-6 flex-col mr-32 h-5/6 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-8/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
      <div className="flex flex-wrap content-start w-full h-5/6 overflow-y-scroll no-scrollbar ">
        <div className="flex w-full h-20 border-b-4 border-gray-900 ">
          <p className="my-auto w-full text-blue-800 text-4xl text-center font-bold uppercase">
            {script && script.nombre}
          </p>
        </div>
        {script &&
          script.bloques.length > 0 &&
          script.bloques.map((bloque) => {
            return (
              <div className="flex h-1/4 w-full border mt-5">
                {editBData.id !== bloque.id ? (
                  <>
                    <div className="w-2/12 border h-full flex justify-center">
                      <p className="my-auto text-2xl font-bold capitalize">
                        {bloque && bloque.nombre}
                      </p>
                    </div>
                    <div className="w-9/12 border h-full flex p-2">
                      <p className="w-full h-full text-xl text-gray-900  break-words overflow-y-scroll scroll">
                        {bloque && bloque.cuerpo}
                      </p>
                    </div>
                    <div className="w-1/12 border h-full flex flex-wrap content-start">
                      <div className="w-full h-1/4 flex justify-center">
                        <UpArrowAlt
                          onClick={() => moveBloque('up', bloque)}
                          className="transform hover:scale-125   h-5/6 text-purple-600"
                        />
                      </div>
                      <div className="w-full h-1/4 flex justify-center">
                        <DownArrowAlt
                          onClick={() => moveBloque('down', bloque)}
                          className="transform hover:scale-125 h-5/6 text-purple-600"
                        />
                      </div>
                      <div className="w-full h-1/4 flex justify-center">
                        <Edit
                          onClick={() => setEditBData(bloque)}
                          className="transform hover:scale-125          h-5/6 text-blue-600"
                        />
                      </div>
                      <div className="w-full h-1/4 flex justify-center">
                        <TrashBin
                          onClick={() => deleteBloque(bloque)}
                          className="transform hover:scale-125      h-5/6 text-red-600"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-2/12 border h-full flex justify-center">
                      <input
                        value={editBData['nombre']}
                        onChange={handleChangeEditBloque}
                        name="nombre"
                        className="my-auto text-2xl font-bold capitalize w-full"
                        placeholder="Nombre"
                      ></input>
                    </div>
                    <div className="w-8/12 border h-full flex p-2">
                      <textarea
                        name="cuerpo"
                        value={editBData['cuerpo']}
                        onChange={handleChangeEditBloque}
                        className="w-full h-full text-xl text-gray-900  break-words"
                        placeholder="Texto"
                      ></textarea>
                    </div>
                    <div className="w-1/12 border h-full flex flex-wrap  overflow-y-scroll p-1 gap-y-1 no-scrollbar">
                      {vars.length > 0 &&
                        vars.map((v) => {
                          return (
                            <div
                              onClick={() => addVar(v)}
                              className="flex justify-center cursor-pointer transform hover:scale-110 hover:bg-gray-400 w-full h-6 rounded-xl  bg-gray-300 text-gray-800 px-1"
                            >
                              <p className="my-auto">{v}</p>
                            </div>
                          );
                        })}
                    </div>
                    <div className="w-1/12 border h-full flex flex-wrap content-start">
                      <div className="w-full h-1/4 flex justify-center">
                        <UpArrowAlt
                          onClick={() => moveBloque('up', bloque)}
                          className="transform hover:scale-125   h-5/6 text-purple-600"
                        />
                      </div>
                      <div className="w-full h-1/4 flex justify-center">
                        <DownArrowAlt
                          onClick={() => moveBloque('down', bloque)}
                          className="transform hover:scale-125 h-5/6 text-purple-600"
                        />
                      </div>
                      <div className="w-full h-1/4 flex justify-center">
                        <EditOff
                          onClick={() => endEditBloque()}
                          className="transform hover:scale-125          h-5/6 text-blue-600"
                        />
                      </div>
                      <div className="w-full h-1/4 flex justify-center">
                        <TrashBin
                          onClick={() => deleteBloque(bloque)}
                          className="transform hover:scale-125      h-5/6 text-red-600"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        <div className="flex h-1/4 w-full border mt-5">
          <div className="w-2/12 border h-full flex justify-center">
            <input
              value={bData['nombre']}
              onChange={handleChangeBloque}
              name="nombre"
              className="my-auto text-2xl font-bold capitalize w-full"
              placeholder="Nombre"
            ></input>
          </div>
          <div className="w-8/12 border h-full flex p-2">
            <textarea
              name="cuerpo"
              value={bData['cuerpo']}
              onChange={handleChangeBloque}
              className="w-full h-full text-xl text-gray-900  break-words"
              placeholder="Texto"
            ></textarea>
          </div>

          <div className="w-1/12 border h-full flex flex-wrap  overflow-y-scroll p-1 gap-y-1 no-scrollbar">
            {vars.length > 0 &&
              vars.map((v) => {
                return (
                  <div
                    onClick={() => addVar(v)}
                    className="flex justify-center cursor-pointer transform hover:scale-110 hover:bg-gray-400 w-full h-6 rounded-xl  bg-gray-300 text-gray-800 px-1"
                  >
                    <p className="my-auto">{v}</p>
                  </div>
                );
              })}
          </div>
          <div className="w-1/12 border h-full flex flex-wrap content-start">
            <div className="w-full h-full flex justify-center text-green-600">
              <TextBulletListAdd
                onClick={() => addBloque()}
                className="transform hover:scale-125 my-auto h-1/3 "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-1/6">
        <button
          onClick={() => setModal(false)}
          className="bg-red-500 text-white font-bold py-2 w-20"
        >
          Salir
        </button>
      </div>
    </div>
  );
};

export default EditSModal;
