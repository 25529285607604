import { Fragment } from 'react'
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import {Link} from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const TitleActions = ({actions=[],title="",path=[],children}) =>{
  return (
    <div className="lg:flex lg:items-center lg:justify-between w-full">
      <div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {path.map((path)=>{
              return <li>
              <div className="flex">
                <Link to={path.path} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                {path.name}
                </Link>
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              </div>
            </li>
            })} 
            
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {title && title}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          {children}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   Full-time */}
          {/* </div> */}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   Remote */}
          {/* </div> */}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   $120k &ndash; $140k */}
          {/* </div> */}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   Closing on January 9, 2020 */}
          {/* </div> */}
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        {actions.map((action)=>{
          return action
        })}
        {/* <span className="hidden sm:block"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" */}
        {/*   > */}
        {/*     <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" /> */}
        {/*     Edit */}
        {/*   </button> */}
        {/* </span> */}
        {/**/}
        {/* <span className="ml-3 hidden sm:block"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" */}
        {/*   > */}
        {/*     <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" /> */}
        {/*     View */}
        {/*   </button> */}
        {/* </span> */}
        {/**/}
        {/* <span className="sm:ml-3"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" */}
        {/*   > */}
        {/*     <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> */}
        {/*     Publish */}
        {/*   </button> */}
        {/* </span> */}

        {/* Dropdown */}
        <Menu as="div" className="relative ml-3 sm:hidden">
          <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            More
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    View
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}

export const Breadcrumb = ({actions=[],path=[],children}) =>{
  return (
    <div className="lg:flex lg:items-center lg:justify-between w-full">
      <div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {path.map((path)=>{
              return <li>
              <div className="flex">
                <Link to={path.path} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                {path.name}
                </Link>
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              </div>
            </li>
            })} 
            
          </ol>
        </nav>
        
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          {children}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   Full-time */}
          {/* </div> */}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   Remote */}
          {/* </div> */}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   $120k &ndash; $140k */}
          {/* </div> */}
          {/* <div className="mt-2 flex items-center text-sm text-gray-500"> */}
          {/*   <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
          {/*   Closing on January 9, 2020 */}
          {/* </div> */}
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        {actions.map((action)=>{
          return action
        })}
        {/* <span className="hidden sm:block"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" */}
        {/*   > */}
        {/*     <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" /> */}
        {/*     Edit */}
        {/*   </button> */}
        {/* </span> */}
        {/**/}
        {/* <span className="ml-3 hidden sm:block"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" */}
        {/*   > */}
        {/*     <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" /> */}
        {/*     View */}
        {/*   </button> */}
        {/* </span> */}
        {/**/}
        {/* <span className="sm:ml-3"> */}
        {/*   <button */}
        {/*     type="button" */}
        {/*     className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" */}
        {/*   > */}
        {/*     <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> */}
        {/*     Publish */}
        {/*   </button> */}
        {/* </span> */}

        {/* Dropdown */}
        <Menu as="div" className="relative ml-3 sm:hidden">
          <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            More
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    View
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}
