import { Fragment, useState,useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import axiosInstance from '../../axios';
import toast from 'react-hot-toast'
import Select from 'react-select';

const CreateModal = ({open,setOpen,setChange}) => {
  const [listas, setListas] = useState([]);
  const [errors, setErrors] = useState([]);
  const [agentes, setAgentes] = useState([]);
  const [mandantes,setMandantes] = useState([])
  const [data, setData] = useState({
    nombre: null,
    color: null,
    set_realizada: false,
    set_terminada: false,
    reagendacion: 0,
    confirmation: false,
    codigo:null,
    tipo_contacto:1,
    external_id:null,
    grupo:null,
    dir:1,
    mandantes:[],
  });



  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.id, label: man.nombre };
    });

  const getMandantes = async () => {
    await axiosInstance.get('mandantes?is_active=true').then((res) => {
      setMandantes(res.data);
    });
  };
  

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

const handleSelect = (e) => {
    if (e.length > 0) {
      setData({
        ...data,
        ['mandantes']: e.map((selected) => {
          return selected.value;
        }),
      });
    } else {
      setData({ ...data, ['mandantes']: [] });
    }
  };

  const getListas =async  () => {
    await axiosInstance.get(`listas/?status=1`).then((res) => {
      setListas(res.data);
    });
  };

  const create = () => {
    // console.log({ ...data, ['status']: toggle ? 1 : 2 });
    // if(data['nombre'] === null){
    //   toast.error('ingresa una nombre')
    //   return null
    // }
    if(data['color'] === null){
      toast.error('elige un color')
      return null
    }
    if(data['grupo'] === null){
      toast.error('elige un grupo')
      return null
    }
    
    
    axiosInstance
      .post(`trespuestas/`, data)
      .then((res) => {
        console.log(res.data);
        toast.success('respuestas creada')
        setOpen(false);
        setChange(res.data);
        setData({nombre: null,color: null,set_realizada: false,set_terminada: false,reagendacion: 0,confirmation: false,codigo:null,tipo_contacto:1,external_id:null,grupo:null,dir:1,mandantes:[]})
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        if (err.response !== undefined && err.response.status == 500) {
          // setErrors(['Error de sistema']);
          toast.error('error de sistema')
        } else if (err.response !== undefined && err.response.status == 400) {
          if (err.response !== undefined && 'data' in err.response) {
            for (let property in err.response.data) {
              //console.log(`${property} : ${err.response.data[property]}`)
              // errs.push(`${property} : ${err.response.data[property]}`);
              toast.error(`${property} : ${err.response.data[property]}`);
            }
          }
        }
        // setErrors(errs);
      })
  };

  useEffect(() => {
    getListas();
    getMandantes();
  }, []);


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-200 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Nueva Respuesta de Llamadas
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Nombre */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="nombre"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Nombre 
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="nombre"
                              onChange={handleChange}
                              value={data['nombre']}
                              id="nombre"
                              className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="codigo"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Codigo
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="codigo"
                              onChange={handleChange}
                              value={data['codigo']}
                              id="nombre"
                              className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                      {/* Color */}

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="color"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Color 
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="color"
                              name="color"
                              value={data['color']}
                              onChange={handleChange}
                              id="color"
                              className="block  px-2 w-full rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="grupo"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Grupo
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                                  name="grupo"
                                  value={data['grupo']}
                                  onChange={handleChange}
                                  className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >

                              <option className="bg-white text-gray-300" key={0} value={null}>------------------</option>
                              <option className="bg-white" key={1} value={1}>Positiva</option>
                              <option className="bg-white" key={1} value={2}>Negativa</option>
                              <option className="bg-white" key={1} value={3}>Rellamada</option>
                                </select>

                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="dir"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Direccion 
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                                  name="dir"
                                  value={data['dir']}
                                  onChange={handleChange}
                                  className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >

                              <option className="bg-white" key={1} value={1}>Outbound</option>
                              <option className="bg-white" key={2} value={2}>Inbound</option>
                                </select>

                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="tipo_contacto"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Tipo Contacto
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                                  name="tipo_contacto"
                                  value={data['tipo_contacto']}
                                  onChange={handleChange}
                                  className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >

                              <option className="bg-white" key={1} value={1}>Directo</option>
                              <option className="bg-white" key={2} value={2}>Indirecto</option>
                                </select>

                          </div>
                        </div>

                      <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="reagendacion"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Reagendacion
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="number"
                              name="reagendacion"
                              value={data['reagendacion']}
                              onChange={handleChange}
                              id="reagendacion"
                              className="block  px-2 w-full rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                      <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="tipo_contacto"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Mandantes
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Select
                                closeMenuOnSelect={false}
                                onChange={handleSelect}
                                className="w-full z-30"
                                name="mandantes"
                                isMulti
                                options={optionsMandantes.filter((x) => !data['mandantes'].includes(x.value))}
                                value={optionsMandantes.filter((x) => data['mandantes'].includes(x.value))}
                              />

                          </div>
                        </div>
                    
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="set_realizada"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Realizada
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                                  name="set_realizada"
                                  value={data['set_realizada']}
                                  onChange={handleChange}
                                  className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >

                              <option className="bg-white" key={1} value={true}>Si</option>
                              <option className="bg-white" key={2} value={false}>No</option>
                                </select>

                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="set_terminada"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Terminada
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                                  name="set_terminada"
                                  value={data['set_terminada']}
                                  onChange={handleChange}
                                  className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >

                              <option className="bg-white" key={1} value={true}>Si</option>
                              <option className="bg-white" key={2} value={false}>No</option>
                                </select>

                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="confirmation"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Confirmacion
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                                  name="confirmation"
                                  value={data['confirmation']}
                                  onChange={handleChange}
                                  className="block  px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                >

                              <option className="bg-white" key={1} value={true}>Si</option>
                              <option className="bg-white" key={2} value={false}>No</option>
                                </select>

                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="external_id"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Id Externa
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="number"
                              name="external_id"
                              value={data['external_id']}
                              onChange={handleChange}
                              id="external_id"
                              className="block  px-2 w-full rounded-md border-0 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        {/* Privacy */}
                        
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={()=>create()}
                          className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                          Crear
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default CreateModal;
