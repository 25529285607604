import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
const CargasAction = ({ carga }) => {
  const history = useHistory();

  var user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const [tipoS, setTipoS] = useState(null);
  const [listas, setListas] = useState([]);
  const [lista, setLista] = useState(null);
  const [data, setData] = useState({
    user: user.id,
  });
  const [nombreL,setNombreL] = useState(null)

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getListas = () => {
    axiosInstance.get(`listas?status=1`).then((res) => {
      setListas(res.data);
    });
  };

  const VerContactos = () => {
    //history.push(`/contactos?id__in=${carga.contactos.join(',')}`)
    history.push(`/contactos?carga=${carga.id}`);
  };

  const createAction = () => {
    if (tipoS === 1) {
      if (lista !== null && carga.contactos.length > 0) {
        axiosInstance
          .post(`contactos/add_to_list/`, { contactos: carga.contactos, lista: lista.id })
          .then((res) => {
            setShow(false);
            res.status == 200 &&
              toast.success(
                `se agregaron ${carga.contactos.length} contactos a la lista ${lista.nombre} `,
                { duration: 3000 },
              );
          });
      }
    } else if (tipoS === 2) {
	if ( nombreL !== null){
	axiosInstance.post(`listas/`, { 'nombre':nombreL, ['status']:1})
      	.then((res) => {
		console.log(res)
		console.log(res.status)
	res.status == 201 &&
              toast.success(
                `se creo la lista ${nombreL}`,
                { duration: 3000 },
              );
	setNombreL(null)
	setShow(false)
	setTipoS(null)
	})
		}


    }
  };

  useEffect(() => {
    getListas();
  }, [show]);

  return (
    <div className="absolute w-48 flex flex-wrap  z-30 bg-blue-normal transform -translate-y-40 -translate-x-52 h-auto">
      <div
        onClick={() => {
          setTipoS(1);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Añadir a lista</p>
      </div>
	<div
        onClick={() => {
          setTipoS(2);
          setShow(!show);
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Crear Lista</p>
      </div>
      <div
        onClick={() => {
          VerContactos();
        }}
        className="w-full h-12 border-b flex pl-1 hover:bg-blue-semi cursor-pointer"
      >
        <p className="text-white my-auto text-center">Ver En Contactos</p>
      </div>
      {show && tipoS ==1 && (
        <div className="rounded-lg flex p-2 flex-wrap content-start w-80 h-80 bg-blue-normal absolute transform -translate-x-80 overflow-y-scroll no-scrollbar">
	
		<div className="flex flex-wrap content-start h-4/5 w-full border-b overflow-y-scroll no-scrollbar border">
            <div className="h-6 w-full bg-white flex justify-center font-bold">Lista</div>
            {listas.length > 0 &&
              listas.map((li) => {
                if (lista !== null && li.id === lista.id) {
                  return (
                    <div
                      onClick={() => {
                        setLista(null);
                      }}
                      className="h-auto  justify-center cursor-pointer w-full border-b bg-gray-700 flex"
                    >
                      <p className="my-auto text-lg text-white">{li.nombre}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() => {
                        setLista(li);
                      }}
                      className="h-auto flex justify-center  cursor-pointer w-full border-b"
                    >
                      <p className="text-lg text-white my-auto">{li.nombre}</p>
                    </div>
                  );
                }
              })}
          </div>

          <div className="w-full h-1/5  flex justify-around py-1">
            <button
              onClick={() => setShow(false)}
              className="rounded-lg h-10 bg-red-600 px-3 py-1 text-gray-100"
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="rounded-lg h-10 bg-green-600 px-3 py-1 text-gray-100"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
	{show && tipoS ==2 && (
        <div className="rounded-lg flex p-2 flex-wrap content-between w-80 h-80 bg-blue-normal absolute transform -translate-x-80 overflow-y-scroll no-scrollbar">
	
		<div className="flex flex-wrap content-start h-24 w-full border-b overflow-y-scroll content-around no-scrollbar border p-2">

            <div className="h-6 w-full text-white flex justify-center font-bold">Nombre Lista</div>
	    <input name="nombreLista" onChange={(e)=>setNombreL(e.target.value)} type="text" className="bg-white text-gray-800  w-full h-8 rounded-md px-2" placeholder="nombre lista"></input>

        </div>

          <div className="w-full h-1/5  flex justify-around py-1">
            <button
              onClick={() => setShow(false)}
              className="rounded-lg h-10 bg-red-600 px-3 py-1 text-gray-100"
            >
              Cerrar
            </button>
            <button
              onClick={() => createAction()}
              className="rounded-lg h-10 bg-green-600 px-3 py-1 text-gray-100"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CargasAction;
