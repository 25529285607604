import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import toast from 'react-hot-toast';
// import showAction from './modals/showAction';
import Toggle from '../Toggle.jsx';
import ReactTooltip from 'react-tooltip';

import { InfoCircle } from '@styled-icons/bootstrap/InfoCircle';

import { getQueryValue, getQueryValueSelect } from '../../utils/forms.js';

import format from '../../utils/formatDate';

import Preview2 from '../scripts/Preview2.jsx';
import axiosInstance from '../../axios';

import { FileText } from '@styled-icons/bootstrap/FileText';
import { PersonFill } from '@styled-icons/bootstrap/PersonFill';

const Llamadas2 = ({ camp, setChange }) => {
  const [wkends, setWkends] = useState(false);
  const [scripts, setScripts] = useState([]);
  const [estados, setEstados] = useState([]);
  const [showScript, setShowScript] = useState(false);
  const [data, setData] = useState({
    date: null,
    agentes: [],
    script: null,
    agente_asignado: false,
    asignar_agente: false,
    filter: '',
    rut: '',
    // estados: [],
    // mandantes: [],
    nro_cuenta: '',
    // tipos_in: [],
    // exclude_tipos_in: [],
    sin_resp: false,
    terminar_pendientes: true,
  });

  const handleChangeFilters = (e) => {
    if (data.filter.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setData({
        ...data,
        filter: data.filter
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              // return `${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`;
              return `${e.target.name}=${e.target.value
                .replace(/[ ,]+/g, ',')
                .replace(new RegExp('[\r\n]', 'gm'), ',')}`;
            } else {
              return x;
            }
          })
          .join('&'),
      });
      //setQuery(`${query}&${e.target.name}=${e.target.value}`)
    } else {
      setData({
        ...data,
        filter: `${data.filter}&${e.target.name}=${e.target.value.replace(/[ ,]+/g, ',')}`,
      });
    }
    console.log(data.filter);
  };

  const getOpLabel = (option, list) => {
    console.log(option, list);
    list &&
      list.map((item) => {
        if (typeof option === 'number' || typeof option === 'string') {
          if (item.value == option) {
            return item.label;
          }
        } else if (typeof option === 'object') {
          if (item.value == option.value) {
            return item.label;
          }
        }
      });
  };

  const handleSelect = (e, name) => {
    console.log(data.filter);
    // console.log(e);
    if (data.filter.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setData({
        ...data,
        filter: data.filter
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      });
    } else {
      setData({ ...data, filter: `${data.filter}&${name.name}=` });
    }
    console.log(data.filter);
  };
  const handleChange = (e) => {
    if (e.target.name == 'rut' || e.target.name == 'nro_cuenta') {
      if (e.target.value.length > 0) {
        //var val  = e.target.value.replace(/[ ,]+/g, ",")
        var val = `${e.target.value
          .replace(/[ ,]+/g, ',')
          .replace(new RegExp('[\r\n]', 'gm'), ',')}`.replace(/\s/g, '');
        setData({
          ...data,
          [e.target.name]: val.split(',').filter((x) => x !== ''),
        });
      } else {
        setData({
          ...data,
          [e.target.name]: [],
        });
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
    console.log(e);
  };

  const handleChangeT = (value, name) => {
    setData({ ...data, [name]: value });
  };
  const handleChangeA = (id) => {
    console.log(data.agentes.length);

    if (data.agentes.length > 0 && data.agentes.includes(id)) {
      setData({ ...data, ['agentes']: data.agentes.filter((x) => x != id) });
    } else {
      if (data.agentes.length == 0) {
        setData({ ...data, ['agentes']: [id] });
      } else {
        setData({ ...data, ['agentes']: [...data.agentes, id] });
      }
    }
    console.log(data);
  };
  const getScripts = async () => {
    await axiosInstance.get('scripts?is_active=true').then((res) => {
      if (res.data) {
        setScripts(res.data);
      }
    });
  };

  const getEstados = async () => {
    await axiosInstance.get('estados_cuenta/').then((res) => {
      setEstados(
        res.data.map((man) => {
          return { value: man.nombre, label: man.nombre };
        }),
      );
    });
  };

  const generarLlamadas = () => {
    var finalData = data;
    // e.preventDefault();
    //console.log(data)
    // console.log({...data,['dates']:dates})
    // console.log(data)
    //console.log(dates.map((d)=>`${d} ${hora}`))
    // finalData.rut = finalData.rut.split(',').filter((x) => x !== '');
    // finalData.nro_cuenta = finalData.nro_cuenta.split(',').filter((x) => x !== '');
    axiosInstance.post(`campaigns/${camp.id}/generar2/`, finalData).then((res) => {
      console.log(res);
      // res.data && setResponse(res.data);
      toast.success('Llamadas asignadas.');
      // setChange(Date.now());
      // setModal(true);
    });
  };

  console.log(camp);
  useEffect(() => {
    // getMandantes();
    getEstados();
    getScripts();
    // getTipos();
  }, []);

  return (
    <div className="w-full h-full flex gap-4 bg-gray-100">
      <Preview2
        script={scripts.filter((x) => x.id == data.script)[0]}
        open={showScript}
        setOpen={setShowScript}
      />
      <div className="w-3/4 h-full flex flex-wrap content-start shrink-0 border rounded-md bg-white overflow-y-scroll pb-4">
        <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6 w-full">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Asignar Llamadas</h3>
        </div>

        <div className="w-full flex p-4 py-6 gap-x-4 divide-x">
          <div className="w-1/4 px-2 shrink-0">
            <h2 className="text-base font-semibold leading-7 text-gray-700">Configuracion</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Elige una fecha , agentes y un script.
            </p>
          </div>

          <div className="flex w-3/4 flex justify-center flex-wrap content-start gap-y-6 p-2">
            <div className="w-full flex justify-center flex-wrap">
              <label
                htmlFor="date"
                className="w-full text-md text-center mb-1 font-medium leading-6 text-gray-900 px-2"
              >
                Fecha
              </label>
              <input
                name="date"
                type="date"
                value={data['date']}
                onChange={handleChange}
                className=" bg-gray-50 rounded-md px-2 py-2 bg-white border border-blue-ligth"
              ></input>
            </div>

            <div className="w-full flex justify-center flex-wrap">
              <label
                htmlFor="date"
                className="w-full text-md text-center mb-1 font-medium leading-6 text-gray-900 px-2"
              >
                Agentes
              </label>
              <div className="w-full rounded-md border bg-gray-50 h-40 flex flex-wrap overflow-y-scroll p-3 gap-2">
                {camp.agentes &&
                  camp.agentes.length > 0 &&
                  camp.agentes.map((agent) => {
                    return (
                      <div
                        onClick={() => handleChangeA(agent.id)}
                        className={`${
                          data.agentes.length > 0 &&
                          data.agentes.includes(agent.id) &&
                          'border-green-300'
                        } w-36 p-2 h-16 rounded-lg border bg-white flex items-center gap-x-2 transform cursor-pointer hover:scale-105`}
                      >
                        {agent.picture ? (
                          <img
                            className="inline-block h-9 w-9 rounded-full"
                            src={agent.picture}
                            alt=""
                          />
                        ) : (
                          <div
                            key={agent.id}
                            className="shrink-0 inline-block h-9 w-9 rounded-full ring-2 ring-white bg-gray-300 flex justify-center items-center"
                          >
                            <PersonFill size="20" className="text-gray-500" />
                          </div>
                        )}

                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{`${agent.first_name} ${agent.last_name}`}</p>
                      </div>
                    );
                  })}
              </div>

              <div className="w-full flex justify-between mt-4 px-4">
                <div className="flex items-center">
                  <InfoCircle
                    size={20}
                    data-tip
                    data-for="agente_asignado"
                    className="transform hover:scale-105 text-gray-400 hover:text-gray-500 cursor-pointer mr-1"
                  />
                  <ReactTooltip id="agente_asignado" effect="solid">
                    <p className="break-words">
                      Si el contacto tiene un agente previamente asignado, a este se le asignara la
                      llamada
                    </p>
                  </ReactTooltip>
                  <p className="text-gray-600 mr-1">Agente asignado:</p>
                  <Toggle
                    name="agente_asignado"
                    onChange={(e) => handleChangeT(!data.agente_asignado, 'agente_asignado')}
                    value={data.agente_asignado}
                  ></Toggle>
                </div>

                <div className="flex items-center">
                  <InfoCircle
                    size={20}
                    data-tip
                    data-for="terminar_pendientes"
                    className="transform hover:scale-105 text-gray-400 hover:text-gray-500 cursor-pointer mr-1"
                  />
                  <ReactTooltip id="terminar_pendientes" effect="solid">
                    <p className="break-words">Terminara las llamadas activas del contacto.</p>
                  </ReactTooltip>
                  <p className="text-gray-600 mr-1">Terminar anteriores:</p>
                  <Toggle
                    name="terminar_pendientes"
                    onChange={(e) =>
                      handleChangeT(!data.terminar_pendientes, 'terminar_pendientes')
                    }
                    value={data.terminar_pendientes}
                  ></Toggle>
                </div>

                <div className="flex items-center">
                  <InfoCircle
                    size={20}
                    data-tip
                    data-for="asignar_agente"
                    className="transform hover:scale-105 text-gray-400 hover:text-gray-500 cursor-pointer mr-1"
                  />
                  <ReactTooltip id="asignar_agente" effect="solid">
                    <p className="break-words">
                      Se le asignara el agente al contacto (futuras asignaciones)
                    </p>
                  </ReactTooltip>
                  <p className="text-gray-600 mr-1">Asignar Agente:</p>
                  <Toggle
                    name="asignar_agente"
                    onChange={(e) => handleChangeT(!data.asignar_agente, 'asignar_agente')}
                    value={data.asignar_agente}
                  ></Toggle>
                </div>
              </div>
            </div>

            <div className="w-full flex justify-center flex-wrap items-center">
              <label
                htmlFor="date"
                className="w-full text-md text-center mb-1 font-medium leading-6 text-gray-900 px-2"
              >
                Script
              </label>
              <select
                name="script"
                type="number"
                onChange={handleChange}
                type="number"
                className="block w-1/2 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              >
                <option key={0} value={0} label={'-----------'}></option>
                {scripts.length > 0 &&
                  scripts.map((sc) => {
                    return <option key={sc.id} value={sc.id} label={sc.nombre}></option>;
                  })}
              </select>
              <FileText
                onClick={() => setShowScript(true)}
                size="30"
                className="ml-2 text-blue-ligth transform cursor-pointer hover:scale-105"
              />
            </div>
          </div>
          <div></div>
        </div>

        {/* ------------------FILTROS-------------------*/}
        <div className="w-full flex p-4 py-6 gap-x-4 divide-x">
          <div className="w-1/4 px-2 shrink-0">
            <h2 className="text-base font-semibold leading-7 text-gray-700">Filtros</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Elige una fecha , agentes y un script.
            </p>
          </div>

          <div className="flex w-3/4  justify-center flex-wrap content-start gap-6 p-2">
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Rut
              </label>
              <div className="mt-2">
                <textarea
                  id="rut"
                  name="rut__in"
                  onChange={handleChangeFilters}
                  rows={4}
                  className="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={getQueryValue(data['filter'], 'rut__in')}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nro. Cuenta
              </label>
              <div className="mt-2">
                <textarea
                  id="nro_cuenta"
                  name="nro_cuenta__in"
                  onChange={handleChangeFilters}
                  rows={4}
                  className="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={getQueryValue(data['filter'], 'nro_cuenta__in')}
                />
              </div>
            </div>

            <div className="">
              <label className="text-gray-700 dark:text-gray-200 font-semibold flex justify-between w-1/2 h-2/6">
                Estado
              </label>
              <Select
                name="estado__in"
                value={getQueryValueSelect(data.filter, 'estado__in', estados)}
                onChange={handleSelect}
                getOptionLabel={(option) => getOpLabel(option, estados)}
                className=""
                isMulti
                options={estados}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end border-t px-8">
          <button
            onClick={() => generarLlamadas()}
            type="button"
            className="inline-flex mt-4 items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Enviar
          </button>
        </div>
      </div>

      {/* --------- History ---------- */}
      <div className="flex flex-grow h-full flex-wrap">
        <div className="w-full h-3/5 shrink-0 border rounded-md bg-white"></div>
      </div>
    </div>
  );
};

export default Llamadas2;
