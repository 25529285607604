import {useState} from 'react'
import axiosInstance from '../../axios.js'

import { Link } from 'react-router-dom';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import {Edit} from '@styled-icons/fluentui-system-filled/Edit'

const UsersTable = ({objs,getObjs}) =>{
  const [data,setData] = useState(null)
  const [edit,setEdit] = useState(false)

  const editar = (obj) =>{
    
    if(data !== null && obj.id !== data.id){
      setData(obj) 
      console.log('change')
    }else{
      if(edit === false){
        console.log('start edit')
        setData(obj)
        setEdit(true)
      }else{
        if(data !== obj){
          console.log('update')
          update(obj)
          setEdit(false)
        }else{
          setEdit(false)
          console.log('close')
        }
      }
    }
  }

  
  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }


  const update = async (obj) =>{
    await axiosInstance.put(`mail_domains/${obj.id}/`,data).then(res=>{
      getObjs()
      setData(null)
    })
  }
  

  const remove = async (obj) =>{
    await axiosInstance.delete(`mail_domains/${obj.id}/`).then(res=>{
      getObjs()
    })

  }

  return (
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-1  pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
              >
                id 
              </th>

              <th
                scope="col"
                className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
              >
                Nombre
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Apellido 
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Rol 
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
                Email 
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
              >
               status 
              </th>

              <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
              
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {objs.map((obj) => (
              <tr key={obj.id}>
                <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 text-center">
                  {obj.id}
              </td>
                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex items-center">
                    {obj.picture ? <img className="h-8 w-8 rounded-full mr-2" src={obj.picture} alt="" /> : <div className="h-8 w-8 rounded-full mr-2 bg-gray-400"></div>}
                    <p className="font-medium text-gray-900">{obj.first_name}</p>
                    </div>
                  
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                  {obj.last_name}
              </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                  <p className="text-gray-900 whitespace-no-wrap">
                  {obj.rol === 1 && 'Agente'}
                  {obj.rol === 2 && 'Operador'}
                  {obj.rol === 3 && 'Administrador'}
                </p>
              </td>
              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                  {obj.email}
              </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                  <span className={`${obj.is_active ? 'text-green-800 bg-green-100 ' : 'text-red-800 bg-red-100'} inline-flex rounded-full  px-2 text-xs font-semibold leading-5 `}>
                    {obj.is_active ? 'Activo': 'Inactivo' }
                  </span>
                </td>

                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                  <p className="text-blue-ligth whitespace-no-wrap">
                  <Link to={`/users/${obj.id}`}>
                    <button className="text-blue-normal hover:text-blue-500 transform hover:scale-125">
                      <UserDetail size="35" className="" />
                    </button>
                  </Link>
                  <Link to={`/users/${obj.id}/edit`}>
                    <button className="text-blue-normal hover:text-blue-500 transform hover:scale-125">
                      <Edit size="35" className="" />
                    </button>
                  </Link>
                </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
          
  )
}
export default UsersTable;
