import axiosInstance from '../../axios'
import {useState,useEffect} from 'react'
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import DragAgent from '../../components/agentes/DragAgent.jsx'
import {PersonFill} from '@styled-icons/bootstrap/PersonFill'


const Transfers = () => {
  const [agentes,setAgentes] = useState([])
  const [from,setFrom] = useState([])
  const [to,setTo] = useState([])


  const getAgentes = async () =>{
    axiosInstance.get(`users?is_active=true`).then(res=>{
      setAgentes(res.data)
    })
  }

  const [{ canDropF, isOverF }, dropFrom] = useDrop(() => ({
    accept: 'agent',
    drop: () => ({ name: 'From' }),
    collect: (monitor) => ({
      isOverF: monitor.isOver(),
      canDropF: monitor.canDrop(),
    }),
  }))
  const isActiveF = canDropF && isOverF

  const [{ canDropT, isOverT }, dropTo] = useDrop(() => ({
    accept: 'agent',
    drop: () => ({ name: 'To' }),
    collect: (monitor) => ({
      isOverT: monitor.isOver(),
      canDropT: monitor.canDrop(),
    }),
  }))
  const isActiveT = canDropT && isOverT
  // let backgroundColor = '#222'
  // if (isActive) {
  //   backgroundColor = 'darkgreen'
  // } else if (canDrop) {
  //   backgroundColor = 'darkkhaki'
  // }
  useEffect(() => {
    
  }, [agentes,from,to])
  useEffect(() => {
    getAgentes()
  }, [])
  return (
    <div className="w-full h-full flex flex-col bg-gray-100">
      <div className="w-full h-14 shrink-0 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Transferencia de Llamadas</p>
      </div>

      <div className="w-full flex-grow flex">
        <div className="flex-grow flex flex-col content-start p-6 gap-4">
          <div className="w-full h-20 shrink-0 rounded-md border bg-white shadow-sm"></div>
          
          
          <div className="w-full flex-grow flex justify-between">
            <div ref={dropFrom} className={`${isActiveF ? 'bg-gray-300' : 'bg-gray-50'} w-1/3 h-full rounded-md border border-dashed  shadow-sm flex flex-wrap p-2 gap-4`}>
              {from.length > 0 && from.map((agent)=>{
                      return <DragAgent agent={agent} setFrom={setFrom} setTo={setTo} setAgentes={setAgentes}/>})}
            </div>

            <div className="w-1/4 h-2/3 my-auto rounded-md border border-dashed  shadow-sm bg-gray-50"></div>

            <div ref={dropTo} className={`${isActiveT ? 'bg-gray-300' : 'bg-gray-50'} w-1/3 h-full rounded-md border border-dashed  shadow-sm flex flex-wrap p-2 gap-4`}>
              {to.length > 0 && to.map((agent)=>{
                      return <DragAgent agent={agent} setFrom={setFrom} setTo={setTo} setAgentes={setAgentes}/>})}
            </div>
          </div>
          
        </div>

        <div className="w-56 h-full bg-white border-l flex flex-wrap content-start overflow-y-scroll">
          <div className="w-full bg-white px-4 h-10 py-2 sm:px-6 border-b">
            <h3 className="text-base text-center w-full font-semibold leading-6 text-gray-900 ">Agentes</h3>
          </div>
          <div className="w-full p-2 gap-4 flex flex-wrap content-start justify-center">
          {agentes.length > 0 && agentes.map((agent)=>{
                      return <DragAgent agent={agent} agentes={agentes} from={from} to={to} setFrom={setFrom} setTo={setTo} setAgentes={setAgentes}/>})}
            </div>
        </div>

      </div>
    </div>
  )
}

export default Transfers
