const getGrupo = (g) => {
  return (
    <>
      {g === 1 && (
        <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
          {' '}
          <span aria-hidden="true" className="absolute inset-0 bg-white  opacity-50 rounded-full">
            {' '}
          </span>{' '}
          <span className="relative"> Positiva </span>
        </span>
      )}
      {g === 2 && (
        <span className="relative inline-block px-3 py-1 font-semibold text-red-800 leading-tight">
          {' '}
          <span aria-hidden="true" className="absolute inset-0 bg-white opacity-50 rounded-full">
            {' '}
          </span>{' '}
          <span className="relative"> Negativa </span>
        </span>
      )}
      {g === 3 && (
        <span className="relative inline-block px-3 py-1 font-semibold text-blue-800 leading-tight">
          {' '}
          <span aria-hidden="true" className="absolute inset-0 bg-white opacity-50 rounded-full">
            {' '}
          </span>{' '}
          <span className="relative"> Rellamada </span>
        </span>
      )}
    </>
  );
};

export default getGrupo;
