import { Fragment, useRef, useState,useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import axiosInstance from '../../axios';
import toast from 'react-hot-toast'


import Select from 'react-select';

import {PersonAdd} from '@styled-icons/fluentui-system-filled/PersonAdd'

const AddAgent = ({open,setOpen,camp,setChange})=> {
  const [agents,setAgentes] = useState([])
  const [selected,setSelected] = useState([])

  const options =
    agents &&
    agents.filter((x) => !(x.id in camp.agentes.map((a) => a.id))).map((agente) => {
      return { value: agente.id, label: `${agente.first_name} ${agente.last_name}` };
    });

  const handleSelect = (e) => {
    if (e.length > 0) {
      setSelected(e.map((agente) => {return agente.value}))
      }
    }

   const addAgentes = () => {
    if (selected.length > 0) {
      axiosInstance.post(`campaigns/${camp.id}/addAgentes/`, {agentes:selected}).then((res) => {
        setOpen(false);
        setChange(new Date())
        toast.success('Agentes agregados')
      });
    }
  };

  const getAgentes = async () => {
    await axiosInstance.get(`users?is_active=true`).then((res) => {
      setAgentes(res.data)
    });
  };

  useEffect(() => {
    getAgentes()
  }, [])

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-72 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PersonAdd size="20" className=" text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Agregar Agentes
                    </Dialog.Title>
                    <div className="mt-2">
                   <Select
                          closeMenuOnSelect={false}
                          onChange={handleSelect}
                          className="w-72"
                          isMulti
                          options={options}
                        />                     
                    </div>
                  </div>
                </div>
                <div className="mt-10 sm:flex sm:flex-row-reverse justify-center">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => addAgentes()}
                  >
                    Agregar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default AddAgent;
