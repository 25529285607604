import React, { useEffect, useState, Component } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Back from '../../components/utils/Back';
import ContactTable from '../../components/listas/ContactTable';
import ContactosT from '../../components/contactos/ContactosT';
import InserExcel from '../../components/listas/InsertExcel';
import CreateCModal from '../../components/contactos/CreateCModal';

import Resumen from '../../components/listas/Resumen.jsx'
import Contactos from '../../components/listas/Contactos.jsx'
import ActionsList from '../../components/listas/Actions'
import Stats from '../../components/listas/Stats.jsx'

import {TitleActions} from '../../structs/layout/Title.jsx'
import {MultiPageSelectorChange} from '../../structs/layout/MultiPage'

import {PencilIcon} from '@heroicons/react/20/solid'
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { PersonAdd } from '@styled-icons/evaicons-solid/PersonAdd';
import {PersonFill} from '@styled-icons/bootstrap/PersonFill'
import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ListaView2 = () => {
  const { id } = useParams();
  const [lista, setLista] = useState({
    id: '',
  });
  const [contactos, setContactos] = useState([]);
  const [excelModal, setExcelModal] = useState(false);
  const [contactoModal, setContactoModal] = useState(false);
  const [page, setPage] = useState(2);
  const [change, setChange] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState(false);
  const [size, setSize] = useState(100);

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setContactos(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const getContactos = async (q = null) => {
    await axiosInstance
      .get(`contactos/?lista=${id}&${q !== null ? q : query.toString()}`)
      .then((res) => {
        setContactos(res.data.results);
        setPagination(res.data);
        console.log(res.data);
      });
  };

  const getLista = async (p) => {
    //await axiosInstance.get('/listas/'+id+'?page='+p.toString()).then((res)=>{
    await axiosInstance.get('/listas/' + id).then((res) => {
      console.log(res.data);
      setLista(res.data);
      //setContactos(res.data.contactos)
    });
  };

  useEffect(() => {
    getLista(page);
    getContactos();
  }, [change, page]);

  return (
    <div className="w-full flex flex-wrap p-3 bg-gray-100">
      <TitleActions title={lista && lista.nombre} path={[{name:'Listas',path:'/camps_mails'},{name:'Detalle',path:`/listas/${lista ? lista.id : '1'}`},]}
      actions={[
        <span className="hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
            Edit
          </button>
        </span>
      ]}

    >
          <p className="text-gray-400 text-lg mt-1 flex content-center"><PersonFill size="24" className=""/>{lista && lista.total}</p>

    </TitleActions>

    {/* <MultiPageSelectorChange setPage={setPage} tabs={[{name:'Resumen',page:1,current:page == 1 ? true : false},{name:'Contactos',page:2,current:page == 2 ? true : false},{name:'Estadisticas',page:3,current:page == 3 ? true : false},{name:'Acciones',page:4,current:page == 4 ? true : false}]}></MultiPageSelectorChange> */}
    <MultiPageSelectorChange setPage={setPage} tabs={[{name:'Contactos',page:2,current:page == 2 ? true : false},{name:'Estadisticas',page:3,current:page == 3 ? true : false},{name:'Acciones',page:4,current:page == 4 ? true : false}]}></MultiPageSelectorChange>
    {/* {page === 1 && <Resumen id={id}></Resumen>} */}
    {page === 2 && <Contactos id={id}/>}
    {page == 3 && <Stats id={id}></Stats>}
    {page == 4 && <ActionsList id={id}></ActionsList>}


    </div>
  );
};

export default ListaView2;
