import React, { useEffect, useState, useRef } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { Table } from '@styled-icons/bootstrap/Table';
import { FileBarGraph } from '@styled-icons/bootstrap/FileBarGraph';
import { FilePdf } from '@styled-icons/boxicons-solid/FilePdf';
import { FileExcel } from '@styled-icons/fa-regular/FileExcel';
import {Filter} from '@styled-icons/fa-solid/Filter'

import ReactExport from 'react-export-excel';
import { FilterDimensions } from '@styled-icons/boxicons-regular/Filter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadE = ({ cols, dat}) => {
  return (
    <ExcelFile
      element={
        <FileExcel size="24" className="text-blue-normal cursor-pointer transform hover:scale-125 cursor-pointer" />
      }
    >
      <ExcelSheet data={dat} name="grafico">
        {cols &&
          cols.length > 0 &&
          cols.map((col) => {
            return <ExcelColumn label={col} value={col} />;
          })}
      </ExcelSheet>
    </ExcelFile>
  );
};







const Grafico = ({ data,groups,children,filterAxis,nombre}) => {
  const [show, setShow] = useState(1);
  const [filter,setFilter] = useState(false)
  const [display,setDisplay] = useState([])
  const [filtered,setFiltered] =useState([])

    console.log(data)
    console.log(groups)
    const filterName = (name) =>{
        if(filtered.includes(name)){
            setFiltered(filtered.filter(x=>x != name))
        }else{
            setFiltered([...filtered,name])
        }

    }

  useEffect(() => {
      //setDisplay(data)
  }, [data]);
  return (
    <div className="w-full h-full overflow-scroll p-2 relative bg-white rounded-md border">
        { filter && <div className='flex flex-wrap absolute w-full content-start gap-2 transform translate-y-10 h-full  bg-white z-20'>
            {data && data.length > 0 && data.map((row)=> {
                return <div onClick={()=>filterName(row.name)} className={`flex border rounded-md w-24 h-14 justify-center cursor-pointer ${filtered.includes(row.name) ? 'text-red-500 line-through ' : 'text-green-400'}`}>
                            <p className='text-center my-auto'>{row.name}</p>
                        </div>
                })}
                <div className='w-full h-1 bg-blue-normal'></div>
            {groups && groups.length > 0 && groups.map((row)=> {
                return <div onClick={()=>filterName(row.name)} className={`flex border rounded-md w-24 h-14 justify-center cursor-pointer ${filtered.includes(row.name) ? 'text-red-500 line-through ' : 'text-green-400'}`}>
                            <p className='text-center my-auto'>{row.name}</p>
                        </div>
                })}

        </div>}
      <div className="w-full h-10 border-b flex justify-between items-center px-2 gap-x-2">
        <p className="text-lg text-gray-700">{nombre && nombre}</p>
        <div className='px-2 gap-x-2 flex'>
        <Filter
          onClick={() => setFilter(!filter)}
          size="24"
          className="text-blue-normal my-auto transform hover:scale-110 cursor-pointer"
        />

        <DownloadE
          cols={data && data.length > 0 && Object.keys(data[0])}
          dat={data && data.length > 0 && data.filter(x=>!(filtered.includes(x.name)))}
          size="24"
          className="transform hover:scale-125 text-blue-normal "
        />
        <FileBarGraph
          onClick={() => setShow(1)}
          size="24"
          className="text-blue-normal my-auto transform hover:scale-110 cursor-pointer"
        ></FileBarGraph>
        <Table
          onClick={() => setShow(2)}
          size="24"
          className="text-blue-normal my-auto transform hover:scale-110 cursor-pointer"
        ></Table>
        </div>
        
      </div>
      <div className='w-full h-96 pb-1'>
      {show == 2 && <table className="table-auto border-colapse border border-blue-ligth p-1 bg-white w-full ">
      <thead className="border ">
        {data && data.length > 0 && Object.keys(data[0]).map((th)=> {
          return <th className="border border-blue-ligth p-1 ">{th != 'name' ? th : '-'}</th>
        })}
      </thead>
      <tbody>
        {data && data.length > 0 && data.filter(x=>!(filtered.includes(x.name))).map((row) => {
            return  <tr className="border border-blue-ligth p-1">
                {Object.values(row).map((td)=> {
                  return <td className="border border-blue-ligth p-1 text-center">{td}</td>
                })}
            </tr> })}
      </tbody>
    </table>}
      {show == 1 && React.cloneElement(children, { data:data.filter(x=>!(filtered.includes(x.name))),groups: groups.filter(x=>!(filtered.includes(x.name))) })}

      </div>
          </div>
  );
};

export default Grafico;
