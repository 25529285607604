import {useEffect,useState} from 'react'

import axiosInstance from '../../axios'
import toast from 'react-hot-toast'

const TestVoiceMsg = () =>{
  const [templates,setTemplates] = useState([])
  const [data,setData] = useState({})
  const [template,setTemplate] = useState(null)
  const [nums,setNums] = useState([])

  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      })
      if(e.target.name === 'template_id'){
        setTemplate(templates.filter(x=>x.id==e.target.value)[0])
      }
  }
  const send = async () => {
    await axiosInstance.post(`voice_msg/test/`,data).then((res) => {
      toast.success('En unos segundos le llegara una llamada')  

    })}

  const getTemplates = async () => {
    await axiosInstance.get(`voice_msg_templates?status=true`).then((res) => {
      setTemplates(res.data);
      if(res.data.length > 0){
        setData({...data,['template_id']:res.data[0].id_template})
      }

    })}

  const getNums = async () => {
    await axiosInstance.get(`twilio/numeros`).then((res) => {
      setNums(res.data);
      if(res.data.length > 0){
        setData({...data,['number']:res.data[0].numero})
      }

    });
  };


  useEffect(()=>{
    getTemplates()
    getNums()
  },[])

  return (

	  <div className="w-full h-full flex flex-wrap p-4 gap-x-10 bg-gray-100  gap-y-10 content-start">
      <div className="w-full h-20 bg-white flex border rounded-md">
      </div>

      <div className="w-96 flex flex-wrap border bg-white rounded-md">
        <div className="w-full h-12 border-b rounded-t-md justify-start p-2 content-center">
          <p className="text-lg text-gray-900 p-1">Datos</p>
        </div>
        
        <div className="w-full h-96 flex flex-wrap p-2 gap-y-3 content-start">
          

      <div className="w-full h-14 flex  p-1 content-center border-b">
            <p className="w-1/2 ">Template</p>
            <div className="w-1/2">
            <select
              onChange={handleChange}
              type="text"
              name="template_id"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >
              <option key={0} value="">---------</option>
            {templates.map((template)=>{
              return <option key={template.id} value={template.id}>{template.nombre}</option>
            })}

            </select>
            </div>

          </div>
        <div className="w-full h-14 flex  p-1 content-center border-b overflow-hidden">
            <p className="w-1/2 ">Numero</p>
            <div className="w-1/2">
            <select
              onChange={handleChange}
              type="text"
              name="number"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >
              <option key={0} value="">---------</option>
            {nums.map((num)=>{
              return <option key={num.id} value={num.numero}>{num.numero}</option>
            })}

            </select>
            </div>

          </div>

        <div className="w-full h-14 flex  p-1 content-center border-b">
            <p className="w-1/2 ">Telefono</p>
            <div className="w-1/2">
            <input
              onChange={handleChange}
              type="text"
              name="num"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >

            </input>
            </div>
          </div>

        <div className="w-full h-14 flex  p-1 content-center border-b">
            <p className="w-1/2 ">Numero Cuenta</p>
            <div className="w-1/2">
            <input
              onChange={handleChange}
              type="text"
              name="nro_cuenta"
              className="w-auto h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >

            </input>
            </div>
          </div>
        
          <div onClick={()=>send()} className="rounded-md cursor-pointer w-auto px-3 py-1 bg-blue-ligth text-gray-50">Enviar</div>

        </div>

    
      </div>

      <div className=" flex flex-grow flex-wrap border bg-white rounded-md">
        <div className="w-full h-12 border-b rounded-t-md justify-start p-2 content-center">
          <p className="text-lg text-gray-900">Preview</p>
        </div>
        
        <div className="w-full h-96 flex flex-wrap p-2">
          <p className="text-gray-600 font-mono">{template && template.text}</p>

        </div>
      </div>

	  </div>
  )

}
export default TestVoiceMsg;
