import {Link} from 'react-router-dom'
const Camps = ({data}) =>{


  return (
    <>
        <div className="w-full h-14 border-b flex justify-between content-center items-center px-4"><p className="text-lg">Campañas</p></div>
          <div className="w-full h-96 flex flex-wrap overflow-y-scroll">

            <div className="w-full">
            <table className="min-w-full divide-y divide-gray-300 ">
                <thead className="bg-gray-50">
                  <tr className="">
                    <th scope="col" className="px-1 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Nombre
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      Total
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      CT
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      SR
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      OCU
                    </th>
                    <th scope="col" className="px-1 py-2 text-left text-sm font-semibold text-gray-900">
                      FALLI
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((camp) => (
                    <tr className="border-b">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:text-blue-900 cursor-pointer"><Link to={`/camps_voicemsg/${camp.id}`} target="_blank" rel="noopener noreferrer">{camp.nombre}</Link></td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{camp.stats.total}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{camp.stats.answered}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{camp.stats.no_answer}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{camp.stats.busy}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{camp.stats.failed}</td>
                    </tr>
                   ))}
               </tbody>
              </table>
              </div>
            

          </div>
    </>
  )
}

export default Camps;
