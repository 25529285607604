import React, { useState, useEffect } from 'react';

import Respuesta from '../Respuesta';
import axiosInstance from '../../../axios';
import Script from './Script';
import { useSelector, useDispatch } from 'react-redux';

import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Cross } from '@styled-icons/icomoon/Cross';
import { Phone } from '@styled-icons/fa-solid/Phone';
import Mandantes from '../../../pages/admin/Mandantes';

const Emoji = React.memo(({ className, label, symbol }) => (
  <span className={className} role="img" aria-label={label}>
    {String.fromCodePoint(symbol)}
  </span>
));

const Resultado = ({ setModal, cl, setCl, tipos_respuestas, animos, mandantes, mand,ct,setChange }) => {
  const [grupo, setGrupo] = useState(true);
  const [tresp, setTresp] = useState(null);
  const [animo, setAnimo] = useState(null);
  const [mandante, setMandante] = useState(null);
  const [autorizacion, setAutorizacion] = useState(false);
  const [showAnimos, setShowAnimos] = useState(false);
  const [tipos, setTipos] = useState([]);

  const llamada = useSelector((state) => state.llamada);

  // if  dir == incoing show to identificate contacto

  const changeAnimo = (a) => {
    console.log(ct)
    axiosInstance.patch(`contactos/${llamada.contacto.id}/`, { animo: a.id }).then((res) => {
      setAnimo(a);
    });
  };

  const changeAutorizacion = () => {
    console.log(ct)
    axiosInstance.patch(`contactos/${llamada.contacto.id}/`, { autorizacion: !ct.autorizacion }).then((res) => {
      setChange(new Date)
    });
  };

  const filterTipos = () => {
    return tipos_respuestas.filter((x)=>x.grupo == tresp)
    // var mands = [];
    // var camps = [];
    // if (mandante == null) {
    //   console.log();
    //   tipos_respuestas.map((x) => {
    //     console.log('x', x.mandantes);
    //     console.log('mandante', mandante);
    //     console.log(x.mandantes);
    //   });
    //   mands = tipos_respuestas.filter((x) => x.grupo == tresp && x.mandantes.length == 0);
    // } else {
    //   mands = tipos_respuestas.filter((x) => x.grupo == tresp && x.mandantes.includes(mandante.id));
    // }
    // console.log(llamada.llamada);
    //
    // camps = tipos_respuestas.filter(
    //   (x) => x.grupo == tresp && x.camps.includes(llamada.llamada.campaign),
    // );
    // return mands.concat(camps);
  };

  
 

  useEffect(() => {
    // getTags()
    //console.log(animos)
    setAnimo(llamada.contacto.animo);
    // setAutorizacion(llamada.contacto.autorizacion);
    var man = mandantes.filter((x) => x.codigo == llamada.contacto.mandante);
    //console.log(mandantes)
    //console.log(llamada.contacto.mandante)
    //console.log(tipos_respuestas)
    // console.log(mandantes.filter((x) => x.codigo == mand));
    man.length > 0 && setMandante(man[0]);
    //man.length > 0 && tipos_respuestas.filter(x=>x.grupo == tresp &&  x.mandantes.includes(man[0].id))
    //man.length > 0 &&tipos_respuestas.map((t) => {
    //    console.log(man[0].id)
    //    console.log(t.mandantes.includes(man[0].id ))
    //    console.log(t.mandantes)})
    //man.length > 0 && console.log(man[0].id)
    //man.length > 0 && console.log(tipos_respuestas.filter(x=>x.grupo == tresp && x.mandantes.includes(man[0].id)))
  }, [ct]);

  return (
    <>
      {grupo ? (
        <div className="w-full h-full  flex flex-wrap content-start">
          <div className="flex flex-wrap w-full h-auto ">
            <div className="flex flex-wrap w-full    h-auto  content-start">
              <div className="flex p-1 px-4 justify-between w-full justify-around rounded-lg  text-white font-bold text-xl ">
                <div
                  style={{ width: '180px' }}
                  onClick={() => setShowAnimos(!showAnimos)}
                  className="justify-self-end w-40 h-7 overflow-hidden  border border-blue-semi rounded-full bg-white flex hover:bg-gray-100 cursor-pointer"
                >
                  {showAnimos && (
                    <div
                      style={{ width: '185px', transform: 'translate(0px,30px)' }}
                      className="absolute content-start gap-y-1 rounded-md h-32 flex flex-wrap  overflow-y-scroll scroll z-20 bg-gray-50 border  transform -translate-x-100 "
                    >
                      {animos &&
                        animos.length > 0 &&
                        animos.map((anim) => {
                          return (
                            <div
                              style={{ width: '175px' }}
                              onClick={() => changeAnimo(anim)}
                              className="justify-self-end w-40 h-7 overflow-hidden border border-blue-semi rounded-full bg-white flex hover:bg-gray-100 cursor-pointer"
                            >
                              <div className="w-9/12 h-full border-r flex justify-center">
                                <h1 className="my-auto text-sm text-gray-700">
                                  {anim && anim.nombre}
                                </h1>
                              </div>
                              <div
                                style={{ backgroundColor: anim ? anim.color : '#fffff' }}
                                className="w-3/12 h-full "
                              ></div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                  <div className="w-9/12 h-full border-r flex justify-center">
                    <h1 className="my-auto text-sm text-gray-700">{animo && animo.nombre}</h1>
                  </div>
                  <div
                    style={{ backgroundColor: animo ? animo.color : '#fffff' }}
                    className="w-3/12 h-full "
                  ></div>
                  {/*<div className="w-3/12 h-full ">{animo && animo.emoji !== null && animo.emoji}</div>*/}
                </div>

                <div
                  onClick={() => {
                    changeAutorizacion();
                  }}
                  className={`w-32 h-7 cursor-pointer flex justify-center rounded-lg text-sm ${
                    ct && ct.autorizacion ? 'hover:bg-red-500' : 'hover:bg-green-500'
                  } text-white font-semibold ${ct && ct.autorizacion ? 'bg-green-500' : 'bg-red-500'} px-1`}
                >
                  {ct && <p className="my-auto">{ct.autorizacion ? 'Autorizado' : 'Sin Autorizacion'}</p>}
                </div>
                <div className="w-32 h-7 flex justify-center border border-blue-900 rounded-lg text-blue-900 hover:text-white hover:bg-blue-900">
                  {llamada.contacto && llamada.contacto.id_cuenta && (
                    <a
                      target="_blank"
                      className="text-md my-auto font-bold "
                      href={`http://astrea.fullpay.me/cuentas/view/${llamada.contacto.id_cuenta}`}
                    >
                      Link
                    </a>
                  )}
                </div>
              </div>
              
            </div>

              <Script llamada={llamada.llamada}></Script>
            <div className="flex justify-around py-3 bg-white h-1/6 w-full">
              <button
                onClick={() => {
                  setGrupo(false);
                  setTresp(1);
                }}
                className="flex flex-wrap justify-center w-24 h-24 border-2 border-green-500 transition duration-100 ease-in-out bg-white rounded-lg hover:bg-green-600 hover:text-white text-green-500"
              >
                <CheckCircle size="80" className="h-2/3" />
                <p className="h-1/3 font-bold text-md xl:text-lg ">Positiva</p>
              </button>
              <button
                onClick={() => {
                  setGrupo(false);
                  setTresp(2);
                }}
                className="flex flex-wrap justify-center w-24 h-24 border-2 border-red-600   transition duration-100 ease-in-out bg-white rounded-lg hover:bg-red-700   hover:text-white text-red-600              "
              >
                <Cross size="50" className="h-2/3" />
                <p className="h-1/3 font-bold text-md xl-text-lg">Negativa</p>
              </button>
              <button
                onClick={() => {
                  setGrupo(false);
                  setTresp(3);
                }}
                className="flex flex-wrap justify-center w-24 h-24 border-2 border-blue-500  transition duration-100 ease-in-out bg-white rounded-lg hover:bg-blue-600  hover:text-white text-blue-500              "
              >
                <Phone size="55" className="h-2/3" />
                <p className="h-1/3 font-bold text-md xl-text-lg">Rellamada</p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Respuesta
            respuestas={tresp != null && filterTipos()}
            setModal={setModal}
            back={setGrupo}
            tresp={tresp}
            llamada={llamada}
          ></Respuesta>
        </>
      )}
    </>
  );
};

export default Resultado;
