const ProgressBar = ({ progress }) => {
    return (
        <div className='h-3 flex-grow bg-gray-300 rounded-full overflow-hidden '>
            <div
                style={{ width: `${progress}%`}}
                className={`h-full transition bg-blue-ligth `}>
            </div>
        </div>
    );
};

export default ProgressBar
