import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axiosInstance from '../../axios';

import MandantesT from '../../components/carga/mandantes/MandantesT';
import Filters from '../../components/carga/mandantes/Filters';

import { getQueryValue } from '../../utils/forms';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CargaMandantes = () => {
  const [resps, setResps] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const getResps = async () => {
    await axiosInstance.get(`mandantes/?${query.toString()}`).then((res) => {
      setResps(res.data);
      //setPagination(res.data)
      console.log(res.data);
    });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const cargar = (q) => {
    if (getQueryValue(q, 'codigo__in') !== '') {
      axiosInstance.post(`carga/mandantes/`, { codigo: getQueryValue(q, 'codigo__in').split(',') });
    }
  };

  useEffect(() => {
    getResps();
    //console.log(query)
    //console.log(query.toString())

    return () => {};
  }, []);

  return (
    <div className="flex flex flex-wrap w-full h-full bg-white">
      <div className="flex flex-wrap h-1/5 w-full  border-black pb-2 px-2">
        <div className="w-full flex h-1/5  border-black ">
          <p className="text-3xl font-bold my-auto">Carga Cuentas</p>
        </div>
        <Filters cargar={cargar} count={pagination.count}></Filters>
      </div>

      <div
        style={{ height: '72vh' }}
        className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
      >
        <MandantesT move={move} pagination={pagination} resps={resps} />
      </div>
    </div>
  );
};

export default CargaMandantes;
