import React from 'react';

import { useHistory } from 'react-router-dom';

import Card from '../../components/admin/Cards';
import Back from '../../components/utils/Back';

import { Users } from '@styled-icons/fa-solid/Users';
import { CallEnd } from '@styled-icons/material-rounded/CallEnd';
import { BookNumber } from '@styled-icons/fluentui-system-filled/BookNumber';
import { RuleFolder } from '@styled-icons/material/RuleFolder';
import { TextDescription } from '@styled-icons/fluentui-system-filled/TextDescription';
import { BookContacts } from '@styled-icons/fluentui-system-regular/BookContacts';

const Carga = () => {
  const history = useHistory();

  return (
    <div className="h-full w-full ">
      <div className="flex flex-wrap w-full h-full bg-white">
        <div className="flex h-1/6 w-full border-b px-3 pt-3">
          <Back></Back>
          <h1 className="text-4xl font-bold h-1/3">Carga de Datos</h1>
        </div>
        <div className="w-full h-5/6 bg-gray-50 flex flex-wrap overflow-y-hidden no-scrollbar">
          <Card name="Cuentas" dir="in" to="/carga/cuentas">
            <BookContacts size="80" className="my-auto text-blue-ligth" />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Carga;
