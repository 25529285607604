import React, { useState, useEffect } from 'react';
import getGrupo from './utils/getGrupo';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { Link } from 'react-router-dom';
import { Campaign } from '@styled-icons/material/Campaign';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';

const RespuestaRow = ({ r, addSelected, removeSelected, selected }) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(r);
    } else {
      removeSelected(r);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((rr) => rr.id === r.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
    <tr className="text-gray-700 h-12">
      <td className="border p-1">
        <input className="  mx-1 h-11" type="checkbox" checked={checked} onChange={handleCheck} />
      </td>
      <td className="border p-1">{r.add_date && r.add_date}</td>
      <td className="border p-1">{r.add_datetime && r.add_datetime}</td>
      <td className="border p-1">{r.agente && r.agente.first_name + ' ' + r.agente.last_name}</td>
      <td className="border p-1">{r.contacto && r.contacto.nombre + ' ' + r.contacto.apellido}</td>
      <td className="border p-1">{r.contacto && r.contacto.mandante}</td>
      <td className="border p-1">{r.contacto && r.contacto.nro_cuenta}</td>
      <td className="border p-1">{r?.tipo?.grupo && getGrupo(r.tipo.grupo)}</td>
      <td style={{ color: r.tipo && r.tipo.color }} className="border p-1">
        {r.tipo && r.tipo.nombre}
      </td>
      <td className="border p-1">{r.numero && r.numero.numero}</td>
      <td className="border p-1 overflow-y-scroll scroll">{r.observacion && r.observacion}</td>
      <td className="border p-1">
        <Link to={r.contacto && `/contactos?id=${r.contacto.id}`}>
          <UserDetail size="35" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border p-1"></td>
      <td className="border p-1"></td>
      <td className="border p-1"></td>
      <td className="border p-1"></td>
    </tr>
  );
};

export default RespuestaRow;
