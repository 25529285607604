import React, { useState, useEffect } from 'react';

import axiosInstance from '../../../axios';

const CreateNumModal = ({ contacto, setAddNumModal, getNumeros }) => {
  const [data, setData] = useState({
    contacto: contacto.id,
    numero: '+',
    verified: 2,
    status: 1,
  });
  const [errors, setErrors] = useState([]);

  const handleChangeN = (e) => {
    // for create num form
    e.preventDefault();
    if ('+' == e.target.value[0]) {
      setData({ ...data, ['numero']: e.target.value });
    } else {
      setData({ ...data, ['numero']: `${e.target.value}` });
    }
  };

  const addNum = () => {
    //console.log(data)
    var errs = [];
    if (data['numero'] === '+') {
      setErrors(['numero invalido']);
    } else {
      axiosInstance
        .post(`numeros/`, data)
        .then((res) => {
          console.log(res.data);
          setErrors([]);
          getNumeros();
          setAddNumModal(false);
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status == 500) {
            setErrors(['Error de sistema']);
          } else if (err.response !== undefined && err.response.status == 400) {
            if (err.response !== undefined && 'data' in err.response) {
              for (let property in err.response.data) {
                //console.log(`${property} : ${err.response.data[property]}`)
                errs.push(`${property} : ${err.response.data[property]}`);
              }
            }
          } else {
          }
          setErrors(errs);
        });
    }
  };

  return (
    <div className="fixed pin z-50 overflow-auto  flex w-full h-full justify-center">
      <div className="flex flex-wrap mt-6 flex-col h-96 bg-blue-dark shadow-2xl md:mr-64 sm:mr-0  rounded-lg pt-3 px-2 w-96 fixed pin z-50 overflow-auto bg-smoke-light flex">
        <div className="flex flex-wrap content-start h-3/5 w-full p-4">
          <input
            type="text"
            className="w-full h-2/6 border"
            onChange={handleChangeN}
            placeholder="+56912345678"
          ></input>
          <div class="flex items-center gap-8 w-full mt-8">
            <label class="inline-flex items-center">
              <input
                type="radio"
                name="vehicle"
                onClick={() => setData({ ...data, ['verified']: 1 })}
                class="h-5 w-5 text-red-600"
              />
              <span class="ml-2 text-gray-50">verificado</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="radio"
                name="vehicle"
                onClick={() => setData({ ...data, ['verified']: 2 })}
                class="h-5 w-5 text-red-600"
              />
              <span class="ml-2 text-gray-50">Sin verificar</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="radio"
                name="vehicle"
                onClick={() => setData({ ...data, ['verified']: 3 })}
                class="h-5 w-5 text-red-600"
              />
              <span class="ml-2 text-gray-50">invalido</span>
            </label>
          </div>
        </div>
        <div className="flex flex-wrap w-full justify-center h-2/5">
          <div className="w-full h-1/2 flex justify-center">
            <button
              onClick={() => setAddNumModal(false)}
              className=" focus:outline-none mx-4  px-4 h-1/2 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={() => addNum()}
              className="bg-green-500 h-1/2 w-2/6 py-1 px-4 rounded-lg text-white font-bold hover:bg-black"
            >
              Crear
            </button>
          </div>
          {errors.length > 0 && (
            <div className="flex flex-wrap h-1/2  pt-2 overflow-y-scroll no-scrollbar w-full">
              {errors.length > 0 &&
                errors.map((error) => {
                  return (
                    <div
                      className="bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                      role="alert"
                    >
                      <p className="font-bold w-full">Error</p>
                      <p clasName="w-full">{error}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateNumModal;
