import React from 'react';

const FilterTable = ({ filters, table, nombre,title }) => {
  return (
    <div className="flex flex-wrap w-full h-auto content-start min-h-full  mb-10 px-4">
	{title && <div className="w-full flex h-auto  border-black  justify-center text-blue-dark">
        <p className="text-2xl font-bold my-auto">{nombre}</p>
      </div>}
      {filters}
      <div
        className=" flex transition-height ease-in-out flex-wrap content-start  w-full bg-white "
      >
        {table}
      </div>
    </div>
  );
};
export default FilterTable;
