import React, { useState, useEffect } from 'react';
import {Link,useParams, useHistory } from 'react-router-dom';
import axiosInstance from '../../axios';

import {getUserRol} from '../../utils/users'

import UserStats from '../../components/users/DetailStats'
import DataUser from '../../components/users/DataUser'
import StatsUser from '../../components/dashboardAgente/StatsUser';

const DetailUser = () => {
  var us = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [change, setChange] = useState(1);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });
  const { id } = useParams();
  
  const getUser = async () => {
    await axiosInstance
      .get(`users/` + id + '/')
      .then((res) => {
        if (res.data) {
          setUser(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status == 404) {
            setNotFound(true);
          }
        }
      });
  };
  

  const handleChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  };

  const getData = async () => {
    if (dates['desde'] !== null && dates['hasta'] !== null) {
      await axiosInstance
        .get(
          `llamadas/dashboard_agente/?user=${id}&desde=${dates['desde']}&hasta=${dates['hasta']}`,
        )
        .then((res) => {
          setData(res.data);
          console.log(res.data)
        });
    }
  };

  useEffect(() => {
    if (dates['desde'] === null || dates['hasta'] === null) {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
    }

    getData();
  }, [dates]);

  useEffect(() => {
    getUser();
  }, [id, change]);
  return  (
             
    <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-3 overflow-y-scroll">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Detalle Usuario</p> 
      </div>

        <div className="w-full mx-4 px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8 bg-white py-2 rounded-md border">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  {user && user.picture ? <img
                    className="h-16 w-16 rounded-full"
                    src={user.picture}
                    alt=""
                  />
                  :
                  <div className="flex h-16 w-16 rounded-full bg-gray-300 text-2xl text-gray-800 items-center justify-center font-semibold ">
                    {user && user.first_name.charAt(0)}{user && user.last_name.charAt(0)}
                  </div> 
                  }
                  <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{user && `${user.first_name} ${user.last_name}` }</h1>
                <p className="text-sm font-medium text-gray-500">
                  <a href="#" className="text-gray-900">
                    {user && getUserRol(user.rol)} 
                  </a>{' '}
                </p>
              </div>
            </div>
            <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
              
              {us.rol == 3 && <Link
                to={`/users/${id}/edit`}
                type="button"
                className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Editar
              </Link>}

            </div>
          </div>

            <div className="flex w-full items-center justify-end gap-x-2 pt-1 mx-4">
              <div className="rounded-md flex w-auto px-2">
                <input
                  name="desde"
                  type="date"
                  value={dates['desde']}
                  onChange={handleChange}
                  className=" bg-white rounded-md p-1 border"
                ></input>
              </div>
              <div className="rounded-md flex bg-gray-50 ">
                <input
                  
                  name="hasta"
                  type="date"
                  value={dates['hasta']}
                  onChange={handleChange}
                  className=" bg-white p-1 rounded-md border"
                ></input>
              </div>
        </div>

          {data && <UserStats user={user} data={data}></UserStats>}
          {data && <DataUser data={data}></DataUser>}
    </div>
  )

}

export default DetailUser;
