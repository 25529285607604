import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import fileDownload from 'js-file-download'
import toast from 'react-hot-toast';
import axiosInstance from '../../axios';

import {MultiPageSelector} from '../../structs/layout/MultiPage.js'
import FilterTable from '../../structs/layout/Filter-Table';
import RespuestasT from '../../components/respuestas/RespuestasT';
import Filters from '../../components/respuestas/Filters';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RespuestasD = () => {
  const [resps, setResps] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const getResps = async () => {
    await axiosInstance.get(`respuestas/?${query.toString()}`).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

    
  const downloadResps = () =>{
    var prom = downloadRespsReq()
    toast.promise(prom, {
      loading: 'Descargando Informe',
      success: 'Listo',
      error: 'Error'
    },{position:'top-right'});
  }

  const downloadRespsReq = async () => {
     await axiosInstance.get(`respuestas/download_list_admin?${query.toString()}`).then((res) => {
          // console.log(res.headers)
          // fileDownload(res.data,res.headers.filename)
        fileDownload(res.data,`Report.xlsx`);
    });
  };

  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    const myPromise = getResps();

    toast.promise(myPromise, {
      loading: 'Cargando',
      success: 'Listo',
      error: 'Error',
    });

    //console.log(query)
    //console.log(query.toString())

    return () => {};
  }, []);

  return (
    <div className="w-full flex flex-wrap p-1 gap-y-3">

	    <MultiPageSelector  tabs={[{name:'Campañas',href:'camps_llamadas',current:false},{name:'Llamadas',href:'llamadas',current:false},{name:'Respuestas',href:'respuestas',current:true}]}/> 


	    <FilterTable
	      filters={<Filters pagination={pagination}></Filters>}
	      table={<RespuestasT move={move} pagination={pagination} resps={resps} download={downloadResps}></RespuestasT>}
	      nombre="Respuestas"
	    />
	</div>
  );
};

export default RespuestasD;
