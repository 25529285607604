
import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'


import toast from 'react-hot-toast'
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import {useParams} from 'react-router-dom'

import {ArrowRight} from '@styled-icons/material-rounded/ArrowRight'

 

const EnviarMails2 = () => {
  const [data,setData] = useState({
    timer:1,
    domain:null,
    subject:null,
    sender:null,
    template_id:null,

    ct_not_sended:true,
    ct_recieved:false,
    ct_opened:false,
    ct_clicked:false,
  })
  const [templates,setTemplates] = useState([])
  const [templates2,setTemplates2] = useState([])
  const [domains,setDomains] = useState([])
  

  const { id } = useParams();
  const [typeTemplate,setTypeTemplate] = useState(2)

  const [ct_recieved,setCt_received] = useState(true)

  const [showDatos,setShowDatos] = useState(true)
  const [showFilters,setShowFilters] = useState(false)

  const handleChangeCheck = (e) => {
      setData({
        ...data,
        [e.target.name]: !data[e.target.name],
      });

      setCt_received(!ct_recieved);
    }
  const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }

  const getTemplates = async () => {
    await axiosInstance.get(`mail_templates?status=true`).then((res) => {
      setTemplates(res.data);
      if(res.data.length > 0){
        setData({...data,['template_id']:res.data[0].id_template})
      }

    });
  };
  const getTemplates2 = async () => {
    await axiosInstance.get(`mail_templates_designs?status=true`).then((res) => {
      setTemplates2(res.data);
      if(res.data.length > 0){
        setData({...data,['template_id']:res.data[0].id})
      }

    });
  };

  const getDomains = async () =>{
    await axiosInstance.get(`mail_domains`).then(res=>{
      setDomains(res.data) 
    })
  }
  const send_mails = () =>{
    // domain:null,
    // subject:null,
    // sender:null,
    // template_id:null,
    // var tid = toast.loading('Cargando')
    if(data['domain'] === null){
      toast.error('elige una dominio')
      return null
    }
    if(data['subject'] === null){
      toast.error('ingresa un asunto')
      return null
    }
    if(data['sender'] === null){
      toast.error('ingresa un emisor')
      return null
    }
    if(data['template_id'] === null){
      toast.error('seleciona un template')
      return null
    }
    console.log(data)
    var tid = toast.loading('Cargando')
    axiosInstance.post(`mail_campaigns/${id}/send_mail/`,{...data,template_type:Number(typeTemplate)})
      .then(res=>{
      toast.remove(tid)
      toast.success('Enviando emails')
    })
      .catch(err=>{toast.remove(tid);toast.error('error')})

      .finally(err=>toast.remove(tid))

    }


  useEffect(()=>{
    //getTemplates()
    getTemplates2()
    getDomains()
  },[]) 
  

  return (
      <div className="w-full flex    rounded-md gap-x-2">

      <div className="w-96 flex flex-shrink-0 flex-wrap border bg-white rounded-md content-start">
        <div className="w-full h-12 border-b rounded-t-md justify-start py-2 px-4 content-center">
          <p className="text-lg text-gray-700">Configuracion</p>
        </div>

        <div className='w-full h-10 border-b flex justify-between items-center px-2'>
          <p className="text-md text-gray-800 font-semibold flex items-center"><div className='h-2 w-2 rounded-full bg-blue-semi mr-1'/>Datos</p>
          <ArrowRight onClick={()=>setShowDatos(!showDatos)} size="40" className={`
          text-blue-semi transform hover:scale-125 cursor-pointer transition ${showDatos && 'rotate-90'}`}></ArrowRight>
        </div>

        {showDatos && <div className='w-full border-b flex flex-wrap content-start py-1 px-2 bg-gray-100 gap-y-2'>
          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Emisor</p>
            <input value={data['sender']} type="text" name="sender" onChange={handleChange} className='p-1 border w-1/2 rounded-md'></input> 
          </div>

          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Dominio</p>
          <select
              onChange={handleChange}
              type="text"
              name="domain"
              className="border w-1/2 rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >
            <option key={0} value={data['domain']}>---------</option>
            {domains.map((dom)=>{
              return <option key={dom.id} value={dom.dominio}>{dom.dominio}</option>
            })}
            </select>
          </div>

          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Asunto</p>
            <input value={data['subject']} type="text" name="subject" onChange={handleChange} className='p-1 border w-1/2 rounded-md'></input> 
          </div>
          
          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Template</p>
          <select
              onChange={handleChange}
              value={data['template_id']}
              type=""
              name="template_id"
              className="border w-1/2 rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            >
            <option key={0} >------------</option>
            {templates2.map((template)=>{
              return <option key={template.id} value={template.id}>{template.nombre}</option>
            })}
            </select>
            </div>

        </div>}

        {/*             filtros               */}
        <div className='w-full h-10 border-b flex justify-between items-center px-2'>
          <p className="text-md text-gray-800 font-semibold flex items-center"><div className='h-2 w-2 rounded-full bg-blue-semi mr-1'/>  Filtros</p>
          <ArrowRight onClick={()=>setShowFilters(!showFilters)} size="40" className={`
          text-blue-semi transform hover:scale-125 cursor-pointer transition ${showFilters&& 'rotate-90'}`}></ArrowRight>
        </div>
        {showFilters && <div className='w-full border-b flex flex-wrap content-start py-1 px-2 bg-gray-100 gap-y-2'>

          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Sin Recibir</p>
            <input
              onChange={handleChangeCheck}
              type="checkbox"
              name="ct_not_sended"
              value={data['ct_not_sended']}
              checked={data['ct_not_sended']}
              className="w-1/2 border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            />

          </div>
          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Recibidos</p>
            <input
              onChange={handleChangeCheck}
              type="checkbox"
              value={data['ct_recieved']}
              checked={data['ct_recieved']}
              name="ct_recieved"
              className="w-1/2 border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            />
          </div>
          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Abiertos</p>
            <input
              onChange={handleChangeCheck}
              type="checkbox"
              value={data['ct_opened']}
              checked={data['ct_opened']}
              name="ct_opened"
              className="w-1/2 border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            />

          </div>
          <div className='flex flex-wrap justify-between w-full items-center'>
            <p className='text-gray-600 text-md'>Clickeados</p>
            <input
              onChange={handleChangeCheck}
              type="checkbox"
              value={data['ct_clicked']}
              checked={data['ct_clicked']}
              name="ct_clicked"
              className="w-1/2 border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            />

          </div>
              
        </div>}

        <button
          onClick={()=>send_mails()}
        type="button"
        className="inline-flex mx-auto mt-4 items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Enviar
        <EnvelopeIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>

      </div>

      <div className="flex flex-grow flex-wrap borde bg-white rounded-md items-start content-start gap-y-2 pb-4">

        <div className="w-full h-12 border-b rounded-t-md justify-start py-2 px-4 content-center">
          <p className="text-lg text-gray-700">Preview</p>
        </div>
        <div className='w-full h-8 bg-gray-50 border rounded-lg mx-2 flex items-center px-4 gap-x-4'>
          <p className='text-gray-800'>De :</p>
          <p className='w-auto border text-gray-500 bg-white rounded-full px-4'>{data['sender']}@{data['domain']}</p>
        </div>
        <div className='w-full h-8 bg-gray-50 border rounded-lg mx-2 flex items-center px-4 gap-x-4'>
          <p className='text-gray-800'>Para :</p>
          <p className='w-auto border text-gray-500 bg-white rounded-full px-4'>dest@fullcall.cl</p>
        </div>

        <div className='w-full h-8 bg-gray-50 border rounded-lg mx-2 flex items-center px-4 gap-x-4'>
          <p className='text-gray-800'>Asunto :</p>
          <p className='w-auto  text-gray-500 rounded-full px-4'>{data['subject']}</p>
        </div>

        <div className="w-full h-96  flex  justify-center flex-wrap pb-2  ">
           {data['template_id'] && <img
            className="inline-block h-full w-96 object-cover object-center"
            src={templates2.filter(x=>x.id == data['template_id'])[0]?.picture}
            alt=""
          />}

        </div>
      </div>     
   </div>
  )
}

export default EnviarMails2;


