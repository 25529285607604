import React from 'react';

import { useHistory,Link } from 'react-router-dom';

import Card from '../../components/admin/Cards';

import TitleContent from '../../structs/layout/Title-Content';
import { Users } from '@styled-icons/fa-solid/Users';
import { CallEnd } from '@styled-icons/material-rounded/CallEnd';
import { BookNumber } from '@styled-icons/fluentui-system-filled/BookNumber';
import { RuleFolder } from '@styled-icons/material/RuleFolder';
import { TextDescription } from '@styled-icons/fluentui-system-filled/TextDescription';
import { EmojiHappy } from '@styled-icons/heroicons-solid/EmojiHappy';

const Li = ({name,link}) =>{
  return (
      <Link to={link} className="w-96 flex h-16 rounded-r-md border bg-white hover:border-blue-200 transform hover:scale-105 transition">
          <div className="w-2 bg-blue-ligth"></div>
          <div className="flex-grow text-xl text-gray-700 flex items-center h-full px-2"><p>{name}</p></div>
      </Link>
  )

}
const Main = () => {
  const history = useHistory();

  return (
      <div className="w-full h-full bg-gray-100 flex flex-wrap justify-between content-start gap-y-5   ">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Configuracion</p>
      </div>
        <div className="px-4  w-full flex flex-wrap justify-start content-start  gap-y-5 gap-x-5">
        <Li name={'Reglas Lllamadas'} link={'/admin/reglas'}></Li> 
        <Li name={'Tipos de Respuestas'} link={'/admin/respuestas'}></Li> 
        <Li name={'Scripts'} link={'/admin/scripts'}></Li> 
        <Li name={'Bloqueos'} link={'/admin/bloqueos'}></Li> 
        <Li name={'Numeros'} link={'/admin/numeros'}></Li> 
        <Li name={'Reglas SMS'} link={'/admin/sms_rules'}></Li> 
        <span className="w-full h-1 bg-gray-300"></span>
        <Li name={'Reglas Mails'} link={'/admin/mail_rules'}></Li> 
        <Li name={'Dominios'} link={'/admin/dominios'}></Li> 

        <span className="w-full h-1 bg-gray-300"></span>

        <Li name={'Animos'} link={'/admin/animos'}></Li> 
        <Li name={'Usuarios'} link={'/admin/users'}></Li> 
        <Li name={'Estados'} link={'/admin/estados'}></Li> 
        <Li name={'Tags Contacto'} link={'/admin/tags_contacto'}></Li> 
        <Li name={'Mandantes'} link={'/admin/mandantes'}></Li> 

    </div> 

                
      </div>
  );
};

export default Main;
