
import {useState,useEffect} from 'react'
import axiosInstance from '../../axios.js'

import Stats from './stats/Stat.jsx'
import Days from './stats/Days.jsx'
import Goal from './stats/Goals.jsx'

const Resumen = ({id}) =>{
  const [data,setData] = useState(null)
  const [revenue,setRevenue] = useState(null)

  const getData = async () =>{
    axiosInstance.get(`mail_campaigns/${id}/resumen`).then(res=>{
      console.log(res)
      setData(res.data)
    })
  }
  const getRevenue = async () =>{
    axiosInstance.get(`mail_campaigns/${id}/revenue`).then(res=>{
      console.log(res)
      setRevenue(res.data)
    })
  }

  useEffect(()=>{
    getData()
    getRevenue()
  },[])
  return ( 
    <div className="w-full flex flex-wrap mt-4  mx-auto gap-y-8">
    {data !== null && <Stats data={data}></Stats>}
    {revenue !== null && <Goal data={revenue}/>}
    {data !== null && <Days data={data}></Days>}
    
    </div>
)

}

export default Resumen;
