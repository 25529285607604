import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axiosInstance from '../../axios';

import MultiPage from '../../structs/layout/MultiPage'
import FilterTable from '../../structs/layout/Filter-Table';
import toast from 'react-hot-toast';
import SmsT from '../../components/sms/SmsT';
import Filters from '../../components/sms/Filters';

import {MultiPageSelector} from '../../structs/layout/MultiPage.js'

import { getQueryValue } from '../../utils/forms';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Sms = () => {
  const [resps, setResps] = useState([]);
  const [change, setChange] = useState(null);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const getResps = async (q = null) => {
    await axiosInstance.get(`sms/?${q !== null ? q : query.toString()}`).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setResps(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const cargar = (q) => {
    //console.log({nro_cuenta:getQueryValue(q,'nro_cuenta__in').split(",")})
    //console.log(getQueryValue(q,'nro_cuenta__in'))
    if (getQueryValue(q, 'nro_cuenta__in') !== '') {
      axiosInstance.post(`carga/cuentas/`, {
        nro_cuenta: getQueryValue(q, 'nro_cuenta__in').split(','),
      });
    }
  };

  useEffect(() => {
    const promise = getResps();
    var prom = toast.promise(promise, {
      loading: 'Cargando',
      error: 'Error',
    });


    return () => {
      toast.remove(prom)
    };
  }, [change]);

  return (
      <div className="w-full flex flex-wrap p-1 gap-y-3">
        
	    <MultiPageSelector  tabs={[{name:'Campañas',href:'camps_sms',current:false},{name:'SMS',href:'sms',current:true},{name:'Templates',href:'sms_templates',current:false}]}/> 

	    <FilterTable
	      nombre="SMS"
	      filters={<Filters getResps={getResps} cargar={cargar} pagination={pagination}></Filters>}
	      table={<SmsT setChange={setChange} move={move} pagination={pagination} resps={resps} />}
	    />
      </div>

  );
};


export default Sms
