import { ActionTypes } from '../constants/action-types';

const initialState = {
  llamada: '',
  agente: '',
  dir: '',
  type: '', //phone || external
  numero: {},
  contacto: '',
  script: '',
  modal: null,
  set_realizada: null,
};

export const llamadaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LLAMADA:
      return { ...state, llamada: payload };
    case ActionTypes.SET_AGENTE:
      return { ...state, agente: payload };
    case ActionTypes.SET_NUMERO:
      return { ...state, numero: payload };
    case ActionTypes.SET_CONTACTO:
      return { ...state, contacto: payload };
    case ActionTypes.SET_DIR:
      return { ...state, dir: payload };
    case ActionTypes.SET_MODAL:
      return { ...state, modal: payload };
    case ActionTypes.SET_TYPE:
      return { ...state, type: payload };
    case ActionTypes.SET_REALIZADA:
      return { ...state, set_realizada: payload };
    default:
      return state;
  }
};
