export const getUserRol= (rol) => {
  if (rol === 1) {
    return 'Agente';
  }
  if (rol === 2) {
    return 'Operador';
  }
  if (rol === 3) {
    return 'Administrador';
  }
};
