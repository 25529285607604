import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import format from '../../utils/formatDate';

import { IndentMore } from '@styled-icons/foundation/IndentMore';
import Back from '../../components/utils/Back';
import Player from './PlayLlamada';

import { FileDownload } from '@styled-icons/material/FileDownload';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { CloseCircleOutline } from '@styled-icons/evaicons-outline/CloseCircleOutline';

const LlamadaDetail = () => {
  const [historial, setHistorial] = useState([]);
  let history = useHistory();
  const { id } = useParams();
  const [iden, setIden] = useState(false);
  const [focus, setFocus] = useState(false);
  const [search, setSearch] = useState('');
  const [contactos, setContactos] = useState([]);

  const [llamada, setLlamada] = useState({
    date: '',
    numero_from: '',
    numero_to: '',
    call_status: '',
    contacto: {
      nombre: '',
      apellido: '',
    },
    respuesta: {
      tipo: {
        nombre: '',
      },
      observacion: '',
    },
  });

  const idenCt = (ct_id) => {
    axiosInstance.patch(`llamadas/${id}/`, { contacto: ct_id });

    setIden(false);
    window.location.reload();
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
    if (e.target.value.length > 3) {
      axiosInstance
        .get(`contactos/?search=${e.target.value}`)
        .then((res) => {
          console.log(res.data);
          setContactos(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getLlamada = async () => {
    await axiosInstance.get(`llamadas/` + id + '/').then((res) => {
      if (res.status !== 404) {
        setLlamada(res.data);
        console.log(res.data);
        res.data.contacto && getHistorial(res.data.contacto);
      } else {
        console.log('not found');
      }
    });
  };

  const getHistorial = async (contacto) => {
    //await axiosInstance.get(`llamadas?contacto=${contacto.id}&status=1&ordering=-date`).then((res) => {
    await axiosInstance.get(`respuestas?llamada__contacto_id=${contacto.id}`).then((res) => {
      //setHistorial(res.data.results);
      //console.log(res.data.results);
      setHistorial(res.data.results);
      console.log(res.data);
    });
  };

  useEffect(() => {
    getLlamada();
  }, [id]);

  return (
    <div className="h-full w-full">
      <div className="flex flex-wrap w-full h-full bg-white">
        <div className="flex h-16 w-full border-b px-3 pt-2">
          <Back></Back>
          <h1 className="text-4xl font-bold border-b ">Detalles de llamada</h1>
        </div>
        <div className="flex flex-wrap content-start h-5/6 w-full mx-4 border rounded-lg border-gray-900 overflow-hidden">
          <div className="w-full flex justify-between bg-gray-900 text-white font-bold text-xl font-bold p-1">
            <p>Contacto</p>
            {llamada.contacto ? (
              <Link to={llamada.contacto && `/contactos/${llamada.contacto.id}`}>
                <button className="text-blue-700 hover:text-blue-500 transform hover:scale-125">
                  <UserDetail size="35" className="" />
                </button>
              </Link>
            ) : (
              <div
                onClick={() => setIden(true)}
                className="border rounded-lg px-1 hover:bg-gray-200 hover:text-black transform hover:scale-110"
              >
                identificar
              </div>
            )}
          </div>
          {iden && (
            <div className="absolute flex overflow-y-scroll no-scrollbar flex-wrap w-96 h-64 bg-gray-600 ml-6 flex p-1 rounded-lg mt-10">
              <input
                type="search"
                name="buscar contactos"
                placeholder="Buscar contactos"
                onChange={handleChange}
                class="w-5/6 h-1/6 px-4 text-sm focus:outline-none rounded-l-lg rounded-r-lg "
              />
              <CloseCircleOutline
                size="40"
                onClick={() => {
                  setIden(false);
                  setContactos([]);
                }}
                className="w-1/6 text-red-500 transform hover:scale-125"
              ></CloseCircleOutline>

              <div className="flex flex-wrap  h-5/6 overflow-y-scroll no-scrollbar content-start  w-full   ">
                {search.length > 3 &&
                  contactos.length > 0 &&
                  contactos.map((ct) => {
                    return (
                      <div
                        onClick={() => idenCt(ct.id)}
                        className="flex overflow-hidden hover:bg-gray-800 text-gray-50 justify-around  w-full h-1/4 border-b border-gray-300  font-bold text-lg"
                      >
                        <p className="my-auto w-1/3">{ct.nombre && ct.nombre}</p>
                        <p className="my-auto w-1/3 text-center ">{ct.apellido && ct.apellido}</p>
                        <p className="my-auto w-1/3 text-center">| {ct.mandante && ct.mandante}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Nombre</h1>
            <h1 className="w-1/2">{llamada.contacto && llamada.contacto.nombre}</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Apellido:</h1>
            <h1 className="w-1/2">{llamada.contacto && llamada.contacto.apellido}</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Numero:</h1>
            <h1 className="w-1/2">{llamada.numero_to && llamada.numero_to}</h1>
          </div>

          <div className="w-full flex justify-between bg-gray-900 text-white font-bold text-xl font-bold p-1">
            <p>Agente</p>
            <Link to={llamada.agente && `/agentes/${llamada.agente.id}`}>
              <button className="text-blue-700 hover:text-blue-500 transform hover:scale-125">
                <UserDetail size="35" className="" />
              </button>
            </Link>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Nombre</h1>
            <h1 className="w-1/2">{llamada.agente && llamada.agente.first_name}</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Apellido:</h1>
            <h1 className="w-1/2">{llamada.agente && llamada.agente.last_name}</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Numero:</h1>
            <h1 className="w-1/2">{llamada.numero_from && llamada.numero_from}</h1>
          </div>
          <div className="w-full bg-gray-900 text-white font-bold text-xl font-bold p-1">
            Llamada
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-black">
            <h1 className="w-1/2 font-bold">Fecha:</h1>
            <h1 className="w-1/2">
              {(llamada.status == 2 ||
                llamada.status == 3 ||
                llamada.status == 4 ||
                llamada.status == 5) &&
                llamada.fecha_asignada &&
                llamada.fecha_asignada.substring(8, 10) +
                  '/' +
                  llamada.fecha_asignada.substring(5, 7) +
                  '/' +
                  llamada.fecha_asignada.substring(0, 4) &&
                llamada.fecha_asignada}
            </h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Hora:</h1>
            <h1 className="w-1/2">{llamada.date && llamada.date.substring(11, 16)}</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Tipo:</h1>
            <h1 className="w-1/2">Web</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Status:</h1>
            <h1 className="w-1/2">
              {llamada.status && llamada.status === 1 && 'Realizada'}
              {llamada.status && llamada.status === 2 && 'Pendiente'}
              {llamada.status && llamada.status === 3 && 'Rellamada'}
              {llamada.status && llamada.status === 5 && 'Recibida'}
            </h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Respuesta:</h1>
            {/* show last respuesta*/}
            <h1 className="w-1/2">
              {llamada &&
                llamada.respuesta.length > 0 &&
                llamada.respuesta[llamada.respuesta.length - 1].tipo.nombre}
            </h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Duracion:</h1>
            <h1 className="w-1/2">{llamada.duration && llamada.duration}</h1>
          </div>
          <div className="w-full bg-gray-900 text-white font-bold text-xl font-bold p-1">
            Grabacion
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Fecha:</h1>
            <h1 className="w-1/2">
              {llamada.recording &&
                llamada.recording.date.substring(8, 10) +
                  '/' +
                  llamada.recording.date.substring(5, 7) +
                  '/' +
                  llamada.recording.date.substring(0, 4)}
            </h1>
          </div>

          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Duracion:</h1>
            <h1 className="w-1/2">{llamada.recording && llamada.recording.duration}</h1>
          </div>
          <div className="flex w-1/3 p-2 border-b border-r border-gray-900">
            <h1 className="w-1/2 font-bold">Descarga:</h1>
            <a
              href={llamada.recording && llamada.recording.url}
              target="_blank"
              className="flex p-1 justify-center"
              download
            >
              <FileDownload size="30" className="text-blue-500" />
            </a>
          </div>
          <div className="flex justify-center w-1/3 bg-gray-900 text-white font-bold text-xl font-bold p-1">
            <p>Observacion</p>
          </div>
          <div className="flex justify-center w-1/3 bg-gray-900 text-white font-bold text-xl font-bold p-1">
            <p>Script</p>
          </div>
          <div className="flex justify-center w-1/3 bg-gray-900 text-white font-bold text-xl font-bold p-1">
            <p>Historial</p>
          </div>
          <div className="flex w-1/3 h-3/5 border-b border-r border-gray-900 p-5">
            <p className="font-bold text-lg w-full">
              {llamada.respuesta && llamada.respuesta.observacion}
            </p>
          </div>
          <div className="flex flex-wrap w-1/3 h-3/6 py-8 border-b border-r border-gray-900 overflow-y-scroll no-scrollbar p-1 ">
            {llamada.script &&
              llamada.script.length > 0 &&
              llamada.script.map((bloque) => {
                return (
                  <div className="flex w-full h-auto border border-gray-600  px-3">
                    <div className="w-1/6 h-full flex border-r border-gray-600 p-2">
                      <p className="uppercase font-semibold my-auto ">{bloque && bloque.nombre}</p>
                    </div>
                    <div className="w-5/6 h-full p-3">
                      <p className="text-gray-900  text-2xl break-words">
                        {bloque && bloque.cuerpo}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="flex content-start flex-wrap w-1/3 h-3/5 border-b border-r border-gray-900 px-2 pt-3 pb-32 overflow-y-scroll">
            {historial &&
              historial.map((llamada) => {
                if (llamada.id == id) {
                  return (
                    <div className="flex w-full h-12 bg-gray-300 rounded-xl px-3 mb-2 border-black border-2">
                      <p className="my-auto w-1/3 border-r border-black text-center">
                        {llamada && llamada.tipo.nombre}
                      </p>
                      <p className="my-auto w-1/3 border-r border-black text-center">
                        {llamada.add_date && format(llamada.add_date)}
                      </p>
                      <p className="my-auto w-1/3 text-center">
                        {/*llamada.agente && llamada.agente.first_name + ' ' + llamada.agente.last_name*/}
                      </p>
                      <Link to={`/llamadas/${llamada.id}`}>
                        <button className=" mt-2 text-black hover:text-gray-900 transform hover:scale-125">
                          <IndentMore size="35" className="" />
                        </button>
                      </Link>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex w-full h-12 bg-gray-300 rounded-xl px-3 mb-2">
                      <p className="my-auto w-1/3 border-r border-black text-center">
                        {llamada && llamada.tipo.nombre}
                      </p>
                      <p className="my-auto w-1/3 border-r border-black text-center">
                        {llamada.add_date && format(llamada.add_date)}
                      </p>
                      <p className="my-auto w-1/3 text-center">
                        {llamada.observacion && llamada.observacion}
                        {/*llamada.agente && llamada.agente.first_name + ' ' + llamada.agente.last_name*/}
                      </p>
                      <Link to={`/llamadas/${llamada.llamada}`}>
                        <button className=" mt-2 text-black hover:text-gray-900 transform hover:scale-125">
                          <IndentMore size="35" className="" />
                        </button>
                      </Link>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LlamadaDetail;

/*
<div  className="flex flex-col justify-start w-full p-2 border ">
                <div className="flex w-full justify-around h-1/5 rounded-xl">
                        
                        <div className="flex bg-white flex-col items-center border w-48 rounded-lg shadow-lg">
                            <div className="">
                                <h1 className="text-3xl mt-1"><Calendar size="30" className="text-green-500 mb-1 mr-2"/>Fecha</h1>
                            </div>
                            <div className="mt-10">
                                <h1 className="font-bold text-gray-800 text-3xl"></h1>
                            </div>
                        </div>
                        <div className="flex bg-white flex-col items-center border w-48 rounded-lg shadow-lg">
                            <div className="">
                                <h1 className="text-3xl mt-1"><ClockOutline size="30" className="text-blue-500 mb-1 mr-2"/>Hora</h1>
                            </div>
                            <div className="mt-10">
                                <h1 className="font-bold  text-gray-800 text-3xl">{llamada.date.substring(11,16)}</h1>
                            </div>
                        </div>
                        <div className="flex bg-white flex-col items-center border w-48 rounded-lg shadow-lg">
                            <div className="">
                                <h1 className="text-3xl mt-1"><BoltCircle size="30" className="text-purple-500 mb-1 mr-2"/>Status</h1>
                            </div>
                            <div className="mt-10">
                                <h1 className="font-bold  text-gray-800 text-3xl">{llamada.call_status}</h1>
                            </div>
                        </div>

                </div>
                <div className="flex mt-5 justify-between w-full h-2/5 rounded-xl">
                    <div className="flex flex-col mx-2 my-2 bg-white w-64 h-64 rounded-lg overflow-hidden">
                        <div className="flex justify-center w-full h-12 mb-3 bg-green-500">
                            <h1 className="text-4xl font-bold mt-1 text-gray-100">HISTORIAL</h1>
                        </div>
                        <div className="flex flex-col flex-grow px-1">
                            <table className="w-full border border-collapse">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className=" border-b border-gray-500">
                                            Tipo
                                        </th>

                                        <th className=" border-b  border-gray-500">
                                            Fecha
                                        </th>
                                        <th className=" border-b border-gray-500">
                                            Status
                                        </th>
                                    </tr>
                               </thead>
                               <tbody>
                                    <tr className="border-b border-gray-400 h-10">
                                        <td>
                                            Llamada
                                        </td>
                                        <td>
                                            20/05/2020
                                        </td>
                                        <td>
                                            Completed
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Llamada
                                        </td>
                                        <td>
                                            20/05/2020
                                        </td>
                                        <td>
                                            Failed   
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="flex flex-col mx-2 my-2 bg-white w-96 h-64 rounded-lg overflow-hidden">
                        <div className="flex justify-center w-full h-16 bg-blue-600">
                            <h1 className="mt-2 text-4xl font-bold text-gray-100 font">FROM</h1>
                        </div>
                        <div className="flex flex-col h-full text-gray-800">
                            <div className="flex justify-start p-1 pb-5 pt-2 border-b rounded-md">
                                <div className="ml-2 my-auto w-16 h-16 bg-gray-900 rounded-full"></div>
                                <h1 className="ml-12 my-auto text-gray-800 text-2xl">Benjamin<br/> Armijo</h1>
                            </div>
                            <h1 className="text-xl my-2 ml-1"><span className="font-bold text-2xl mr-4">Phone:</span>+{llamada.numero_from}</h1>
                            <h1 className="text-xl my-2 ml-1 "><span className="font-bold text-2xl mr-5">Email:</span> benjamin3armijo@gmail.com</h1>
                        </div>
                    </div>
                    <div className="flex flex-col mx-2 my-2 bg-white w-96 h-64 rounded-lg overflow-hidden">
                        <div className="flex justify-center w-full h-16 bg-indigo-600">
                            <h1 className="mt-2 text-4xl font-bold text-gray-100 font">TO</h1>
                        </div>
                        <div className="flex flex-col h-full text-gray-800">
                            <div className="flex justify-start p-1 pb-5 border-b rounded-md">
                                <div className="ml-2 my-auto w-16 h-16 bg-gray-900 rounded-full"></div>
                                <h1 className="ml-12 my-auto text-gray-800 text-2xl">Benjamin<br/> Armijo</h1>
                            </div>
                            <h1 className="text-xl my-2 ml-1"><span className="font-bold text-2xl mr-4">Phone:</span>+{llamada.numero_to}</h1>
                            <h1 className="text-xl my-2 ml-1 "><span className="font-bold text-2xl mr-5">Email:</span> benjamin3armijo@gmail.com</h1>
                        </div>
                    </div>
                </div>
                <div className="flex px-2 w-full h-2/5 justify-start  ">
                    <div className="bg-white h-full w-4/5 rounded-lg overflow-hidden shadow-xl">
                        <h1 className="text-3xl">Comentarios</h1>
                    </div>
                    <div className="flex flex-wrap justify-start p-1 h-full w-1/5 ml-4 rounded-lg">
                        <a  href={llamada.recording ? llamada.recording.url : ''} className="flex p-1 items-center w-full h-1/6 bg-white rounded-full shadow-xl hover:bg-red-300">
                            <RecordCircle size="30" className="text-red-600 ml-2"/>
                            <h1 className="text-2xl ml-2">Grabacion</h1>
                        </a>
                        <div className="flex p-1 items-center w-full h-1/6 bg-white rounded-full shadow-xl">
                            <FileExcel size="30" className="text-green-600 ml-2"/>
                            <h1 className="text-2xl ml-2">Excel</h1>
                        </div>
                        <div className="flex p-1 items-center w-full h-1/6 bg-white rounded-full shadow-xl">
                            <FilePdf size="30" className="text-red-600 ml-2"/>
                            <h1 className="text-2xl ml-2">Pdf</h1>
                        </div>
                        <a  className="flex p-1 items-center w-full h-1/6 bg-white rounded-full shadow-xl">
                            
                            <Share size="30" className="text-blue-600 ml-2"/>
                            
                            <h1 className="text-2xl ml-2">Compartir</h1>
                        </a>
                    </div>

                    
                </div>
                
            </div>



*/
