import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import { useSelector, useDispatch } from 'react-redux';
import {
  setLlamada,
  setDir,
  setNumero,
  setType,
  setContacto,
} from '../../../redux/actions/llamadaActions';
import format from '../../../utils/formatDate';

import formatter from '../../../utils/formatMoney';

import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { User } from '@styled-icons/fa-solid/User';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { SquaredCross } from '@styled-icons/entypo/SquaredCross';
import { Verified } from '@styled-icons/material/Verified';
import { Unverified } from '@styled-icons/octicons/Unverified';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';

const Ct = () => {
  const llamada = useSelector((state) => state.llamada);

  const [addNumModal, setAddNumModal] = useState(false);
  const [toggleEditNum, setToggleEditNum] = useState(1);
  const [editNumero, setEditNumero] = useState(false);
  const [historial, setHistorial] = useState([]);
  const [errors, setErrors] = useState([]);
  const [bienes, setBienes] = useState([]);
  const [data, setData] = useState({
    contacto: llamada.contacto.id,
    numero: '+',
    verified: 2,
    status: 1,
  });
  const [numero, setNumero] = useState({
    contacto: null,
    numero: '+',
    verified: null,
    status: 1,
  });
  //const [data,setData] = useState({
  //    contacto:null,
  //    numero:'+',
  //    verified:2,
  //    status:1
  //})
  const handleChangeN = (e) => {
    // for create num form
    e.preventDefault();
    if ('+' == e.target.value[0]) {
      setData({ ...data, ['numero']: e.target.value });
    } else {
      setData({ ...data, ['numero']: `+${e.target.value}` });
    }
  };

  const [numeroData, setNumeroData] = useState({
    id: null,
    numero: '+',
    verified: null,
    status: 1,
    contacto: null,
  });

  // if  dir == incoing show to identificate contacto
  var user = JSON.parse(localStorage.getItem('user'));
  const h = new Date(Date.now());

  const dispatch = useDispatch();

  const getBienes = async (contacto) => {
    await axiosInstance.get(`bienes?contacto=${contacto.id}`).then((res) => {
      setBienes(res.data);
      console.log(res.data);
    });
  };

  const getHistorial = async (contacto) => {
    //await axiosInstance.get(`llamadas?contacto=${contacto.id}&status=1&ordering=-date`).then((res) => {
    //await axiosInstance.get(`respuestas?contacto_id=${contacto.id}&ordering=-add_datetime&page_size=5`).then((res) => {
    //await axiosInstance.get(`respuestas?contacto=${contacto.id}&ordering=-add_datetime&page_size=10`).then((res) => {
    await axiosInstance
      .get(`respuestas?contacto=${contacto.id}&ordering=-add_datetime&page_size=10`)
      .then((res) => {
        //setHistorial(res.data.results);
        //console.log(res.data.results);
        setHistorial(res.data.results);
      });
  };

  const verificate = (num, status) => {
    axiosInstance.post(`numeros/${num.id}/verificar/`, { verified: status }).then((res) => {
      //setChange(res.data);
      dispatch(
        setContacto({
          ...llamada.contacto,
          ['numeros']: llamada.contacto.numeros.map((nume) => {
            if (nume.id === num.id) {
              return { ...nume, ['verified']: status };
            } else {
              return nume;
            }
          }),
        }),
      );
    });
  };
  const editNumT = (status) => {
    setToggleEditNum(status);
    setNumeroData({ ...numeroData, ['status']: status });
  };
  const editNum = (num) => {
    setToggleEditNum(num.status);
    setNumero(num);
    setNumeroData({
      ['id']: num.id,
      ['numero']: num.numero,
      ['verified']: num.verified,
      ['status']: num.status,
      ['contacto']: num.contacto,
    });
    setEditNumero(true);
  };
  const endEditNum = () => {
    //console.log(JSON.stringify(numeroData))
    //console.log(JSON.stringify(numero))
    //console.log(JSON.stringify(numeroData) == JSON.stringify(numero))
    if (JSON.stringify(numeroData) !== JSON.stringify(numero)) {
      axiosInstance
        .patch(`numeros/${numero.id}/`, {
          ...numeroData,
          id: numero.id,
          contacto: numero.contacto,
          verified: numero.verified,
        })
        .then((res) => {
          console.log(res);

          dispatch(
            setContacto({
              ...llamada.contacto,
              ['numeros']: llamada.contacto.numeros.map((nume) => {
                if (nume.id === numero.id) {
                  return { ...nume, ['status']: numero.verified, numero: numeroData['numero'] };
                } else {
                  return nume;
                }
              }),
            }),
          );
          //setChange(Date().toLocaleString())
        });
    }

    setEditNumero(false);
    setNumero({ contacto: null, numero: '+', verified: null, status: 1 });
    setNumeroData({ contacto: null, numero: '+', verified: null, status: 1 });
  };
  const handleChangeNum = (e) => {
    // for edit num form
    console.log(e.target.value);
    setNumeroData({ ...data, [e.target.name]: e.target.value });
  };

  const addNum = () => {
    //console.log(data)
    var errs = [];
    if (data['numero'] === '+') {
      setErrors(['numero invalido']);
    } else {
      axiosInstance
        .post(`numeros/`, data)
        .then((res) => {
          console.log(res.data);
          setErrors([]);
          console.log({
            ...llamada.contacto,
            ['numeros']: llamada.contacto.numeros.push(res.data),
          });
          //setData({contacto:contacto.id,numero:'+',verified:2,status:1})
          //setNumero({contacto:null,numero:'+',verified:null,status:1})
          //setChange(res.data)

          //dispatch(setContacto({...llamada.contacto,['numeros']:llamada.contacto.numeros.push(res.data)}))
          setAddNumModal(false);
        })
        .catch((err) => {
          if (err.response !== undefined && err.response.status == 500) {
            setErrors(['Error de sistema']);
          } else if (err.response !== undefined && err.response.status == 400) {
            if (err.response !== undefined && 'data' in err.response) {
              for (let property in err.response.data) {
                //console.log(`${property} : ${err.response.data[property]}`)
                errs.push(`${property} : ${err.response.data[property]}`);
              }
            }
          } else {
          }
          setErrors(errs);
        });
    }
  };

  useEffect(() => {
    getHistorial(llamada.contacto);
    getBienes(llamada.contacto);
  }, []);
  return (
    <>
      <div className="flex flex-wrap h-4/5  lg:h-2/4 w-full  rounded-lg content-start overflow-y-scroll no-scrollbar">
        {addNumModal && (
          <div className="fixed pin z-50 overflow-auto flex w-full h-full justify-center">
            <div className="flex flex-wrap mt-6 flex-col h-96 bg-blue-dark shadow-2xl md:mr-64 sm:mr-0  rounded-lg pt-3 px-2 w-96 fixed pin z-50 overflow-auto bg-smoke-light flex">
              <div className="flex flex-wrap content-start h-3/5 w-full p-4">
                <input
                  type="text"
                  className="w-full h-2/6 border"
                  onChange={handleChangeN}
                  placeholder="+56912345678"
                ></input>
                <div class="flex items-center gap-8 w-full mt-8">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      name="vehicle"
                      onClick={() => setData({ ...data, ['verified']: 1 })}
                      class="h-5 w-5 text-red-600"
                    />
                    <span class="ml-2 text-gray-50">verificado</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      name="vehicle"
                      onClick={() => setData({ ...data, ['verified']: 2 })}
                      class="h-5 w-5 text-red-600"
                    />
                    <span class="ml-2 text-gray-50">Sin verificar</span>
                  </label>
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      name="vehicle"
                      onClick={() => setData({ ...data, ['verified']: 3 })}
                      class="h-5 w-5 text-red-600"
                    />
                    <span class="ml-2 text-gray-50">invalido</span>
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap w-full justify-center h-2/5">
                <div className="w-full h-1/2 flex justify-center">
                  <button
                    onClick={() => setAddNumModal(false)}
                    className=" focus:outline-none mx-4  px-4 h-1/2 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={() => addNum()}
                    className="bg-green-500 h-1/2 w-2/6 py-1 px-4 rounded-lg text-white font-bold hover:bg-black"
                  >
                    Crear
                  </button>
                </div>
                {errors.length > 0 && (
                  <div className="flex flex-wrap h-1/2  pt-2 overflow-y-scroll no-scrollbar w-full">
                    {errors.length > 0 &&
                      errors.map((error) => {
                        return (
                          <div
                            className="bg-red-200 border-red-600 text-red-600 border-l-4 px-4 py-1 h-16 w-full mb-2"
                            role="alert"
                          >
                            <p className="font-bold w-full">Error</p>
                            <p clasName="w-full">{error}</p>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-wrap w-full h-full overflow-y-scroll no-scrollbar content-start">
          <div className="flex w-full justify-center bg-blue-semi text-white font-bold text-2xl ">
            <h1>Contacto</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Nombre:</h1>
            <h1 className="w-2/2">{llamada.contacto.nombre}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Apellido:</h1>
            <h1 className="w-2/3">{llamada.contacto.apellido}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Rut:</h1>
            <h1 className="w-2/3">{llamada.contacto.rut}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Numero:</h1>
            <h1 className="w-2/3">{llamada.numero.numero}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Mandante:</h1>
            <h1 className="w-2/3">{llamada.contacto.mandante}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">tarjeta:</h1>
            <h1 className="w-2/3">{llamada.contacto.tarjeta}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Estado:</h1>
            <h1 className="w-2/3">{llamada.contacto.estado}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Fase:</h1>
            <h1 className="w-2/3">{llamada.contacto.fase}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Rol:</h1>
            <h1 className="w-2/3">{llamada.contacto.rol}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Saldo Deuda:</h1>
            <h1 className="w-2/3">{llamada.contacto.saldo_deuda}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Total Deuda:</h1>
            <h1 className="w-2/3">{llamada.contacto.total_deuda}</h1>
          </div>
          <div className="flex w-full md:w-1/2 lg:w-1/2  h-10 items-center p-1 border ">
            <h1 className="w-1/3 font-bold">Link:</h1>
            <h1 className="w-2/3">
              {llamada.contacto && llamada.contacto.id_cuenta && (
                <a
                  target="_blank"
                  className="text-md font-bold text-blue-900"
                  href={`http://astrea.fullpay.me/cuentas/view/${llamada.contacto.id_cuenta}`}
                >
                  Ver en Astrea
                </a>
              )}
            </h1>
          </div>
        </div>
      </div>
      <div className="w-full h-1/3 xl:h-1/2  flex flex-wrap">
        <div className="h-12 w-full border-b font-bold text-black text-2xl font-bold text-center justify-center flex ">
          <p className="my-auto">Historial</p>
        </div>
        <div className="h-5/6 w-full overflow-y-scroll flex flex-wrap content-start scroll gap-y-2 px-1">
          {historial.length > 0 &&
            historial.map((resp) => {
              return (
                <div className="w-full h-28 border border-gray-500 flex flex-wrap ">
                  <div className="w-full flex justify-between  h-8 border-b p-1">
                    <p style={{ color: resp.tipo.color }} className="my-auto font-bold  break-all">
                      {resp.tipo.nombre}
                    </p>
                    <p className="text-sm my-auto font-semibold ">
                      {format(resp.add_date)} {resp.add_datetime}
                    </p>
                  </div>
                  <div className="flex w-full h-20">
                    <div className="h-20 w-9/12 border-r p-1">
                      <p className="text-gray-700 break-all overflow-y-scroll no-scrollbar ">
                        {resp.observacion}
                      </p>
                    </div>
                    <div className="h-20 w-3/12 flex flex-wrap  p-1">
                      <p className="w-full h-1/3 text-end text-sm  ">
                        {resp.numero && resp.numero.numero}
                      </p>
                      <p className="w-full h-1/3 text-end text-sm ">
                        {resp.agente && resp.agente.first_name + ' ' + resp.agente.last_name}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex flex-wrap content-start w-full h-1/3 xl:h-3/5  rounded-lg p-2 overflow-y-scroll no-scrollbar gap-y-3 pt-4 pl-2">
        <div className="h-12 w-full border-b flex font-bold text-black text-2xl font-bold text-center justify-center flex ">
          <p className="my-auto w-11/12">Numeros</p>
          <PlusCircle
            onClick={() => setAddNumModal(true)}
            size="40"
            className="w-1/12 justify-self-end text-green-600 mt-1 justify-self-end self-end transform hover:scale-125"
          />
        </div>

        {llamada.contacto.numeros &&
          llamada.contacto.numeros.map((number) => {
            return (
              <div className="flex w-full h-10 border bg-gray-50 rounded-lg ">
                <div className="w-5/12 flex justify-center px-1 ">
                  {editNumero && number === numero ? (
                    <input
                      name="numero"
                      value={numeroData['numero']}
                      onChange={handleChangeNum}
                      className="w-4/5 ml-1 border px-1 my-1"
                    ></input>
                  ) : (
                    <p className="my-auto font-medium ">{number.numero}</p>
                  )}
                </div>

                <div className="w-2/12 flex justify-center px-1 ">
                  <p className="my-auto font-medium ">
                    {number.verified === 1 && (
                      <span className="font-bold text-green-600">verficado</span>
                    )}
                    {number.verified === 2 && (
                      <span className="font-bold text-gray-600">sin verificar</span>
                    )}
                    {number.verified === 3 && (
                      <span className="font-bold text-red-600">invalido</span>
                    )}
                  </p>
                </div>

                <div className="w-2/12 flex justify-center  px-1 ">
                  {editNumero && number === numero ? (
                    toggleEditNum === 1 ? (
                      <label
                        for="unchecked"
                        class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                      >
                        <span class="relative">
                          <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                          <span class="absolute block w-4 h-4 mt-1 ml-1 bg-green-500 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
                            <input
                              id="unchecked"
                              onClick={() => editNumT(2)}
                              type="checkbox"
                              class="absolute opacity-0 w-0 h-0"
                            />
                          </span>
                        </span>
                        <span class="ml-3 text-sm">Activa</span>
                      </label>
                    ) : (
                      <label
                        for="checked"
                        class="mt-3 inline-flex items-center cursor-pointer mx-auto"
                      >
                        <span class="relative">
                          <span class="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
                          <span class="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-red-600 transform translate-x-full">
                            <input
                              id="checked"
                              onClick={() => editNumT(1)}
                              type="checkbox"
                              class="absolute opacity-0 w-0 h-0"
                            />
                          </span>
                        </span>
                        <span class="ml-3 text-sm">Inactiva</span>
                      </label>
                    )
                  ) : number && number.status === 1 ? (
                    <span className="relative my-auto inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">activo</span>
                    </span>
                  ) : (
                    <span className="relative my-auto inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative">inactivo</span>
                    </span>
                  )}
                </div>
                <div className="w-4/12 flex ">
                  <div className="w-1/5 h-full flex justify-center">
                    <Verified
                      onClick={() => verificate(number, 1)}
                      size="32"
                      className="my-auto text-green-600 transform hover:scale-125"
                    />
                  </div>
                  <div className="w-1/5 h-full flex justify-center">
                    <Unverified
                      onClick={() => verificate(number, 2)}
                      size="32"
                      className="my-auto text-gray-600 transform hover:scale-125"
                    />
                  </div>
                  <div className="w-1/5 h-full flex justify-center">
                    <SquaredCross
                      onClick={() => verificate(number, 3)}
                      size="32"
                      className="my-auto text-red-600 transform hover:scale-125"
                    />
                  </div>
                  <div className="w-1/5 h-full flex justify-center">
                    {editNumero && numero === number ? (
                      <EditOff
                        onClick={() => endEditNum()}
                        size="32"
                        className="my-auto text-blue-800 transform hover:scale-125"
                      />
                    ) : (
                      <Edit
                        onClick={() => editNum(number)}
                        size="32"
                        className="my-auto text-blue-600 transform hover:scale-125"
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="w-full h-1/3 xl:h-1/2 flex flex-wrap  content-start overflow-y-scroll no-scrollbar gap-y-2">
        <div className="h-12 w-full  border-b flex font-bold text-black text-2xl font-bold text-center justify-center flex ">
          <p className="my-auto w-12/12">Bienes</p>
        </div>
        {bienes &&
          bienes.length > 0 &&
          bienes.map((bien) => {
            return (
              <div className="flex w-full h-12 border bg-gray-50 rounded-lg">
                <p className="w-1/6 my-auto text-center font-bold">{bien.tipo}</p>
                <p className="w-3/6 my-auto text-center font-bold">{bien.desc}</p>
                <p className="w-1/6 my-auto text-center font-bold">
                  {formatter.format(bien.avaluo)}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Ct;
{
  /*<div className="w-full h-2/6 border-b  border-gray-500 flex overflow-y-scroll no-scrollbar ">
                                <p className="w-2/12 text-sm my-auto font-bold ">{format(resp.add_date)}</p>
                                <p className="w-3/12 text-center text-sm my-auto font-bold ">{resp.numero && resp.numero.numero}</p>
                                <p style={{color:resp.tipo.color}} className="w-3/12 my-auto font-bold  break-all">{resp.tipo.nombre}</p>
                                <p className="w-3/12 my-auto  text-gray-700 break-all">{resp.observacion}</p>

                            </div>*/
}
