import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

import axiosInstance from '../../../axios'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Actions = ({template,setShowActions,setChange}) => {
  const duplicar = async () =>{
    await axiosInstance.post(`mail_templates_designs/${template.id}/duplicar/`).then(res=>{
      setChange(new Date())
      setShowActions(false)
    })
  }

  const changeStatus = async () =>{
    await axiosInstance.patch(`mail_templates_designs/${template.id}/`,{status:!template.status}).then(res=>{
      setChange(new Date())
      setShowActions(false)
    })
  }
  return (
    <Menu as="div" className="relative inline-block text-left right-1 z-0 ">
      <div>
        <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                    onClick={()=>changeStatus()}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm w-full'
                  )}
                >
                {template.status ? <p>Desactivar</p> : <p>Activar</p>}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                    onClick={()=>duplicar()}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm w-full'
                  )}
                >
                    Duplicar
                </button>
              )}
            </Menu.Item>
              
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Actions;
