import React, { useState, useEffect } from 'react';
import format from '../../utils/formatDate';

import { getLlamadaStatusP } from '../../utils/models';
import { Link } from 'react-router-dom';
import { UserDetail } from '@styled-icons/boxicons-solid/UserDetail';
import { Campaign } from '@styled-icons/material/Campaign';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';

const LlamadaRow = ({ r, addSelected, removeSelected, selected }) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(r);
    } else {
      removeSelected(r);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((rr) => rr.id === r.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
    <tr className="text-gray-700 h-10 ">
      <td className="border p-1 flex justify-center">
        <input className="my-auto h-11" type="checkbox" checked={checked} onChange={handleCheck} />
      </td>
      <td className="border p-1">{r.contacto && r.contacto.nombre + ' ' + r.contacto.apellido}</td>
      <td className="border p-1 ">
        <p>{r.contacto && r.contacto.rut}</p>
      </td>
      <td className="border p-1">{r.contacto && r.contacto.mandante}</td>
      <td className="border p-1">{r.contacto && r.contacto.nro_cuenta}</td>
      <td className="border p-1">{r.agente && r.agente.first_name + ' ' + r.agente.last_name}</td>
      <td className="border p-1">{r.script && r.script?.nombre}</td>
      <td className="border p-1">{r.fecha_asignada && format(r.fecha_asignada)}</td>
      <td className="border p-1">{r.status && getLlamadaStatusP(r.status)}</td>
      <td
        style={{ color: r.respuesta.length > 0 && r.respuesta[r.respuesta.length - 1].tipo.color }}
        className="border p-1"
      >
        {r.respuesta && r.respuesta.length > 0 && r.respuesta[r.respuesta.length - 1].tipo.nombre}
      </td>
      <td className="border">
        <p className="w-15 justify-between flex">{r.campaign && r.campaign.nombre}</p>
      </td>
      <td className="border w-10">
        <Link to={r.contacto && `/contactos/${r.contacto.id}`}>
          <UserDetail size="35" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border w-10">
        <Link to={r.campaign && `/camps/${r.campaign.id}`}>
          <Campaign size="35" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border w-10">
        <Link to={r && `/respuestas?llamada=${r.id}`}>
          <TelephoneAccessible size="35" className="text-blue-ligth" />
        </Link>
      </td>
      <td className="border w-10"></td>
      <td className="border w-10"></td>
    </tr>
  );
};

export default LlamadaRow;
