import ProgressBar from './ProgressBar.jsx'
import {DollarCircle} from '@styled-icons/boxicons-solid/DollarCircle'
import {PersonCircle} from '@styled-icons/bootstrap/PersonCircle'

const Goal = ({data}) =>{

  
  const formatter = Intl.NumberFormat('es',{ maximumSignificantDigits: 3 });
  return (
        <div 
            className="flex flex-wrap overflow-hidden h-auto rounded-lg bg-white px-4 pt-5 gap-y-2  shadow py-4 w-72"
        >

        <div className=" flex  items-center flex-wrap gap-y-1 content-start w-full">
          <div className="w-full flex gap-x-1 items-center justify-between">
            <div className="flex items-center gap-x-1">
              <DollarCircle size="25" className="text-green-500"/>
              <p className="text-gray-500">Ganancia</p>
            </div>
            <p className="justify-end font-semibold">{formatter.format(data.revenue)} {data.currency}</p>
            {/* <p className="justify-end font-semibold">{formatter.format(1000000)} {data.currency}</p> */}

          </div>

          <div className="w-full flex gap-x-1 items-center">
            <ProgressBar progress={data.revenue_pct}></ProgressBar>
            <p className="text-gray-500 text-sm w-6">{data.revenue_pct}%</p>
          </div>
        
        </div>

        <div className=" flex  items-center flex-wrap gap-y-1 content-start w-full">
         
          <div className="w-full flex gap-x-1 items-center justify-between">
            <div className="flex items-center gap-x-1">
              <PersonCircle size="23" className="text-blue-800"/>
              <p className="text-gray-500">Contactos</p>
            </div>
            <p className="justify-end font-semibold">{data.success_contacts}</p>

          </div>

          <div className="w-full flex gap-x-1 items-center">
            <ProgressBar progress={data.success_pct}></ProgressBar>
            <p className="text-gray-500 text-sm w-6">{data.success_pct}%</p>
          </div>
        
        </div>

  
        </div>
  )
  }

export default Goal;
