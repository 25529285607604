import { ActionTypes } from '../constants/action-types';

const initialState = {
  token: null,
};

export const tokenReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TOKEN:
      return { ...state, token: payload };
    default:
      return state;
  }
};
