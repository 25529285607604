import React from 'react';

const Efectividad = ({ data }) => {
  console.log(data);
  return (
    <table className="table-auto border-colapse border border-blue-ligth p-1 bg-white w-full ">
      <thead className="border ">
        <th className="border border-blue-ligth p-1 ">agente</th>
        <th className="border border-blue-ligth p-1">pagos</th>
        <th className="border border-blue-ligth p-1">cuentas</th>
        <th className="border border-blue-ligth p-1">efectividad</th>
      </thead>
      <tbody>
        {data &&
          data.length > 0 &&
          data.map((agente) => {
            return (
              <tr className="border border-blue-ligth p-1">
                <td className="border border-blue-ligth p-1">{agente.name}</td>
                <td className="border border-blue-ligth p-1">{agente.efectivas}</td>
                <td className="border border-blue-ligth p-1">{agente.total}</td>
                <td className="border border-blue-ligth p-1">{agente.efectividad}%</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Efectividad;
