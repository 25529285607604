
import { useDrag } from 'react-dnd'

import {PersonFill} from '@styled-icons/bootstrap/PersonFill'

const DragAgent = ({agent,agentes,from,setFrom,to,setTo,setAgentes})  => {
  // const [{ isDragging }, drag] = useDrag()
  // const [drag] = useDrag()
  // const opacity = isDragging ? 0.4 : 1
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'agent',
      item: agent,
      end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      
      if (dropResult.name === 'From') {
          setFrom([...from,item])
          setAgentes(agentes.filter(x=>x.id != item.id))
          console.log(agentes.filter(x=>x.id != item.id))
      }else if(dropResult.name === 'To') {
          setTo([...to,item])
      }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )
  return (
    <div ref={dragRef}  data-testid={`box`} className={`w-40 p-2 h-20 rounded-lg border bg-white flex items-center gap-x-2 transform cursor-pointer hover:scale-105`}>
                    {agent.picture ? <img
                          className="inline-block h-12 w-12 rounded-full"
                          src={agent.picture}
                          alt=""
                        />
                        :
                      <div key={agent.id} className="shrink-0 inline-block h-12 w-12 rounded-full ring-2 ring-white bg-gray-300 flex justify-center items-center"
                        ><PersonFill size="20" className="text-gray-500"/></div>}

                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{`${agent.first_name} ${agent.last_name}`}</p>
    </div>
  )
}
export default DragAgent;
