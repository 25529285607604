import React, { useState, useEffect } from 'react';

const MandanteRow = ({ r, addSelected, removeSelected, selected }) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = (e) => {
    if (checked === false) {
      addSelected(r);
    } else {
      removeSelected(r);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (selected && selected.length > 0 && selected.some((rr) => rr.id === r.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    return () => {};
  }, [selected]);

  return (
    <tr className="text-gray-700 h-12">
      <td className="border p-1 flex justify-center">
        <input className="my-auto h-11" type="checkbox" checked={checked} onChange={handleCheck} />
      </td>
      <td className="border p-1">{r && r.nombre}</td>
      <td className="border p-1">{r && r.codigo}</td>
    </tr>
  );
};

export default MandanteRow;
