import React, { useState } from 'react';

import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import { Eye } from '@styled-icons/heroicons-solid/Eye';
import { FileText } from '@styled-icons/bootstrap/FileText';

const STable = ({ scripts, setScripts, setChange, setEditModal, setScript }) => {
  const [confR, setConfR] = useState(false);
  const [idU, setIdU] = useState(null);
  const [user, setUser] = useState({});

  const close = () => {
    setIdU(null);
    setConfR(false);
  };
  const rem = (user) => {
    setUser(user);
    setIdU(user.id);
    setConfR(true);
  };

  const changeStatus = async (status,script) =>{
    axiosInstance.patch(`scripts/${script}/`,{is_active:status}).then(res=>{
      setChange(new Date())
    })
  } 
  const del = () => {
    axiosInstance.delete(`scripts/${idU}/`).then((res) => {
      console.log(res);
      setScripts(scripts.filter((x) => x.id !== idU));
      setChange(idU);
    });

    setConfR(false);
  };

  return (
    <table className="w-full table-auto rounded-lg shadow-2xl overflow-hidden">
      {confR && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-gray-300 shadow-2xl rounded-xl pt-3 px-2 w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl h-1/3">Eliminar este script:</p>
            <div className="flex justify-center w-full h-2/3 text-4xl font-bold">
              <p className="my-auto">{user.nombre}</p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => close()}
              className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-black cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => del()}
              className=" focus:outline-none mx-4 py-2 px-5 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <thead>
        <tr>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            Nombre
          </th>
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            Status
          </th>
          {/*<th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal">
                            Editar
                        </th>*/}
          <th
            scope="col"
            className="px-1 py-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-lg uppercase font-normal"
          >
            detalles
          </th>
        </tr>
      </thead>
      <tbody>
        {scripts &&
          scripts.map((script) => (
            <tr key={script.id} className="">
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-lg text-gray-900 whitespace-no-wrap">{script.nombre}</p>
                  </div>
                </div>
              </td>

              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                {scripts && script.is_active ? (
                  <span onClick={()=>changeStatus(false,script.id)} className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight cursor-pointer">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">activo</span>
                  </span>
                ) : (
                  <span onClick={()=>changeStatus(true,script.id)} className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight cursor-pointer">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative">inactivo</span>
                  </span>
                )}
              </td>
              {/*<td className="p-1 border-b border-gray-200 bg-white text-lg">
                            
                            <button onClick={()=>rem(user)}  className="text-blue-500 hover:text-blue-600 transform hover:scale-125"><Edit  size="35" className= ""/></button>
                        </td>*/}
              <td className="p-1 border-b border-gray-200 bg-white text-lg">
                <p className="text-gray-900 whitespace-no-wrap">
                  <FileText
                    onClick={() => {
                      setScript(script);
                      setEditModal(true);
                    }}
                    size="35"
                    className="text-blue-700 transform hover:scale-125 "
                  />
                </p>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default STable;
