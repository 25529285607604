import React,{useEffect,useState} from 'react'
import axiosInstance from '../../axios.js'
import { Device } from '@twilio/voice-sdk';



const CallTest = () => {
  const [device,setDevice] = useState(null)


  var user = JSON.parse(localStorage.getItem('user'));
  
  const call = () =>{
   device.connect({ 
    params: {
      To: '+56991286217'
    } 
    })
  }

  const getToken = async () => {
    await axiosInstance
      .get(
        `twilio/get_token?user=${user.id}&token=${localStorage.getItem(
          'access_token',
        )}`,
      )
      .then((response) => {
        localStorage.setItem('twilio_token', response.data.token);
        newDev(response.data.token)

      });
  };

  const newDev = (token) =>{
    const device = new Device(token);

    device.addListener('registered', device => {
  console.log('The device is ready to receive incoming calls.')
});

    setDevice(device)
  }

  
  useEffect(() => {
    getToken()
  }, [])
  
  return (
    <div className="bg-white w-full p-2 ">

      <button onClick={()=>call()}>Llamar</button>
    </div>
  )
}

export default CallTest
