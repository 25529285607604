import {useState} from 'react'
import CargaSQL from '../../components/carga/cuentas3/CargaSQL.jsx'
import CargaFile from '../../components/carga/cuentas3/CargaFile.jsx'
import TCargas from '../../components/carga/cuentas3/TCargas';

const Carga2 = () =>{
  const [change,setChange] = useState(null)
    
  

  return (
    <div className="w-full min-h-full h-auto flex flex-wrap bg-gray-100 content-start">
      <div className="w-full h-14 border-b bg-white flex justify-between items-center p-4">
        <p className="text-xl font-normal">Carga Datos</p>
      </div>
      
      <div className="w-full flex flex-wrap px-6 content-start mt-8 gap-x-4 justify-center gap-y-4 mb-10"> 
         
        <div className="w-full flex gap-x-2">
        <CargaSQL></CargaSQL>
        <CargaFile></CargaFile>
        </div>

        <div className="w-full h-auto rounded-md border shadow-md bg-white ">
          <TCargas change={change} setChange={setChange}></TCargas> 
        </div> 
        
      </div>
      

    </div>
  )

}

export default Carga2;
