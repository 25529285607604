import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';

import ATable from '../../components/admin/ATable';

import CreateAModal from '../../components/animos/CreateAModal';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

import Select from 'react-select';

function Animos() {
  const [objs, setObjs] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [change, setChange] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [filtro, setFiltro] = useState({
    first_name: '',
    last_name: '',
    roles: [],
  });

  useEffect(() => {
    axiosInstance.get('animos/').then((res) => {
      if (res.data) {
        setObjs(res.data);
        setDisplay(res.data);
      }
    });
  }, [change]);

  const handleToggle = (is_active) => {
    setToggle(is_active);
  };

  const handleChange = (e) => {
    if (e.target.value === '') {
      setDisplay();
    }

    setDisplay(
      objs.filter((x) =>
        x[`${e.target.name}`].toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  return (
    <div className="flex flex flex-wrap w-full h-full bg-white px-2">
      {addModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateAModal setModal={setAddModal} setChange={setChange} />
        </div>
      )}
      <div className="flex justify-between w-full h-16 bg-white border-b px-5 pt-1">
        <h1 className="text-4xl font-bold mt-2">Animos</h1>
        <PlusCircle
          onClick={() => setAddModal(true)}
          size="55"
          className="text-green-600 mt-1 transform hover:scale-125"
        />
      </div>
      <div className="flex w-full mb-3 px-2 bg-gray-dark rounded-lg py-4 gap-4 pt-7">
        <input
          type="text"
          name="nombre"
          onChange={handleChange}
          placeholder="Nombre"
          className="p-1 border border rounded-sm w-1/12"
        ></input>
      </div>
      <div
        style={{ height: '78vh' }}
        className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
      >
        <ATable objs={display} setObjs={setObjs} setChange={setChange}></ATable>
      </div>
    </div>
  );
}
export default Animos;
