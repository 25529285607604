import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const EventsGrafico = ({data,dataKey}) => {
    
  console.log(data)
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#E3ECFB" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#E3ECFB" stopOpacity={0}/>
          </linearGradient>
          
        </defs>
          <CartesianGrid strokeDasharray="4 3 " />
          <XAxis dataKey="name" tickLine={false} tickMargin={5} />
          <YAxis dataKey={dataKey} axisLine={false} tickLine={false} tickMargin={10} domain={[dataMin => (0 - Math.abs(dataMin)), dataMax => (dataMax * 1.5)]}/>
          <Tooltip />
          <Area   type="linear" dataKey={dataKey} dot={{ stroke: 'blue', strokeWidth: 1 ,fill: true }} stroke="#1369E7" fillOpacity={1} fill="url(#colorUv)" />

        </AreaChart>
      </ResponsiveContainer>
    );
}

export default EventsGrafico;
