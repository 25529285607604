import React, { useEffect, useState } from 'react';

import axiosInstance from '../../axios';

import MultiPage from '../../structs/layout/MultiPage'
import ListaTable from '../../components/listas/ListaTable';
import {MultiPageSelector} from '../../structs/layout/MultiPage.js'
import CreateModal from '../../components/listas/CreateModal';
import {Button} from '../../base/buttons/Button'
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

function Listas() {
  const [listas, setListas] = useState([]);
  const [display, setDisplay] = useState([]);
  const [addModal, setAddModal] = useState(false);

  const [change, setChange] = useState(false);
  const [status, setStatus] = useState(1);

  useEffect(() => {
    axiosInstance.get(`listas?status=${status}`).then((res) => {
      if (res.data) {
        setListas(res.data);
        setDisplay(res.data);
      }
    });
  }, [change, status]);

  const handleChange = (e) => {
    setDisplay(listas.filter((x) => x.nombre.includes(e.target.value)));
  };
  return (
    // <div className="flex flex flex-wrap w-full h-full bg-white">
   <div className="w-full flex flex-wrap p-1 gap-y-3">

	    <MultiPageSelector  tabs={[{name:'Contactos',href:'contactos',current:false},{name:'Listas',href:'listas',current:true}]}/> 


    {/* <MultiPage actualPage={{name:'listas',link:'listas'}} pages={[{name:'contactos',link:'contactos'},{name:'listas',link:'listas'}]}>  */}
      {addModal === true && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex w-full h-full bg-gray-900 bg-opacity-70">
          <CreateModal setModal={setAddModal} setListas={setListas} listas={listas} />
        </div>
      )}
      <div className="flex justify-end w-full h-16   px-5 pt-1">
        <Button title="Crear Lista" type="primary" onClick={() => setAddModal(true)}></Button>
        {/*<PlusCircle
          onClick={() => setAddModal(true)}
          size="55"
          className="text-green-600 w-1/12 mt-1 transform hover:scale-125"
      />*/}
      </div>

      <div
        style={{ height: '78vh' }}
        className="px-3 flex flex-wrap content-start h-full w-full overflow-y-scroll no-scrollbar "
      >
        <div className="w-full h-10 flex wrap justify-start p-2 gap-x-4">
          <div
            onClick={() => setStatus(1)}
            className={`w-32 h-full ${
              status !== 1 ? 'bg-green-300' : 'bg-green-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Activas
          </div>
          <div
            onClick={() => setStatus(2)}
            className={`w-32 h-full ${
              status !== 2 ? 'bg-red-300' : 'bg-red-500'
            } transform hover:scale-110 rounded-lg cursor-pointer  text-white font-bold text-lg text-center`}
          >
            Inactivas
          </div>
        </div>
        <ListaTable listas={display} setListas={setListas} setChange={setChange}></ListaTable>
      </div>

    {/* </MultiPage> */}
    </div>
  );
}
export default Listas;

{
  /* {listas.map((lista)=>(
                        <ListaCard lista={lista} key={lista.id}></ListaCard>

                    ))}*/
}
