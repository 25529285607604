import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';

import toast from 'react-hot-toast';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';

import TemplateTestBar from '../../components/mails/TemplateTest.jsx';
import { TitleActions } from '../../structs/layout/Title';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { EditOff } from '@styled-icons/material/EditOff';

const MailTemplateDesignDetail = () => {
  const { id } = useParams();
  const [obj, setObj] = useState(null);
  const [change, setChange] = useState(null);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({ text: '' });

  const getObj = async () => {
    axiosInstance.get(`mail_templates_designs/` + id).then((res) => {
      setObj(res.data);
      console.log(res.data);
      emailEditorRef.current.editor.loadDesign(res.data.json);
      setData(res.data);
    });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    console.log(data['nombre']);

    emailEditorRef.current.editor.exportHtml((datat) => {
      const { design, html } = datat;
      // var json = data.design;
      // console.log(data)

      const save = axiosInstance.patch(`mail_templates_designs/${id}/`, {
        json: design,
        html: html,
        nombre: data['nombre'],
      });
      toast.promise(save, {
        loading: '',
        success: 'Guardado',
        error: 'Error',
      });
      // console.log(html)
      //
      // console.log(json)
      // console.log('exportHtml', html);
    });
  };

  const onLoad = () => {
    axiosInstance.get(`mail_templates_designs/` + id).then((res) => {
      setObj(res.data);
      console.log(res.data);
      emailEditorRef.current.editor.loadDesign(res.data.json);
      setData(res.data);
    });
    // editor instance is created
    // you can load your template here;
    // axiosInstance.get(`mail_templates_designs/${id}/`,{text:data['text']}).then(res=>{
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(obj.json);
    // axiosInstance.get(`mail_templates_designs/${id}/`).then(res=>{
    //
    // })
  };

  const onReady = () => {
    // editor is ready
    // console.log('onReady');
    // emailEditorRef.current.editor.loadDesign(obj.json);
  };

  const editar = () => {
    setEdit(!edit);
    if (!edit === false && data['text'] !== '' && data['text'] !== obj['text']) {
      // console.log('update')
      axiosInstance.patch(`mail_templates_designs/${id}/`).then((res) => {
        setObj(res.data);
        console.log(res.data);
        setData(res.data);
      });
    }
  };

  useEffect(() => {
    // getObj();
  }, [change]);

  return (
    <div className="w-full flex flex-col h-full bg-white">
      <div className="w-full h-16 flex justify-between border-b p-4 ">
        <div>
          <input
            className="text-2xl py-1 px-2 text-gray-700 font-semibold w-auto"
            name="nombre"
            onChange={(e) => setData({ ...data, nombre: e.target.value })}
            value={data['nombre']}
          ></input>
        </div>

        <div>
          <button
            onClick={exportHtml}
            className="w-24 cursor-pointer px-2 py-1 rounded-md bg-green-500 text-center text-gray-50 transform hover:scale-105 font-bold"
          >
            Guardar
          </button>
        </div>
      </div>

      <div className="w-full flex-grow flex ">
        <TemplateTestBar id={id}></TemplateTestBar>
        <EmailEditor
          className="w-full h-full"
          minHeight={700}
          // options={{
          // customJS: [
          // window.location.protocol + '//' + window.location.host + '/custom.js',
          // ]}}
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
        />
      </div>

      {/* {obj && <div dangerouslySetInnerHTML={ { __html: obj.html} }></div>} */}
    </div>
  );
};

export default MailTemplateDesignDetail;
