import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import format from '../../../utils/formatDate';
import { getTipoCarga } from '../../../utils/models';
import { getStatusCarga } from '../../../utils/models';

import { Refresh } from '@styled-icons/foundation/Refresh';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';

import ReactExport from 'react-export-excel';

import CargasAction from './CargasAction';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = ({ cols }) => {
  return (
    <ExcelFile
      element={
        <FileExcel size="32" className="text-green-600 cursor-pointer transform hover:scale-125" />
      }
    >
      <ExcelSheet data={[]} name="">
        {cols &&
          cols.length > 0 &&
          cols.map((col) => {
            return <ExcelColumn label={col} value={col} />;
          })}
      </ExcelSheet>
    </ExcelFile>
  );
};

const TCargas = ({ change, setChange }) => {
  const [page, setPage] = useState(1);
  const [carga, setCarga] = useState(null);
  const [detail, showDetail] = useState(false);
  const [cargas, setCargas] = useState([]);
  const [selected, setSelected] = useState(null);
  const [actionModal, setActionModal] = useState(false);

  const getCargas = () => {
    axiosInstance.get('carga2?page_size=10').then((res) => {
      setCargas(res.data);
    });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setCargas(res.data);
    });
  };

  useEffect(() => {
    getCargas();
  }, [change]);

  return (
    <div className="flex flex-wrap w-full h-auto p-2 rounded-lg">
      <div className="w-full h-12 flex justify-end">
        <Refresh
          onClick={() => setChange(new Date())}
          size="30"
          className="text-blue-400 cursor-pointer transition ease-in-out duration-700 transform hover:scale-125 hover:rotate-90"
        ></Refresh>
      </div>
      {page === 1 && (
        <table className="w-full ">
          <thead className="border-blue-ligth bg-white border">
            <th>Usuario</th>
            <th>Fecha</th>
            <th>Tipo</th>
            <th>Status</th>
            <th>Archivo</th>
            <th>Detalle</th>
          </thead>
          <tbody>
            {cargas.results &&
              cargas.results.length > 0 &&
              cargas.results.map((c) => {
                return (
                  <tr className="h-10  border border-blue-ligth">
                    <td className="p-2 text-center">
                      {c.user && `${c.user.first_name} ${c.user.last_name}`}
                    </td>
                    <td className="p-2 text-center">{format(c.fecha_inicio)}</td>
                    <td className="p-2 text-center">{getTipoCarga(c.tipo)}</td>
                    <td className="p-2 text-center">{getStatusCarga(c.status)}</td>
                    <td className="text-purple-600 font-bold p-2 text-center">{c.file_name}</td>
                    <td className="text-center relative">
                      <Detail
                        size="30"
                        className={`transform hover:scale-125 ${
                          c === carga ? 'text-blue-600' : ' text-blue-400'
                        }`}
                        onClick={() => {
                          setCarga(c);
                          showDetail(!detail);
                        }}
                      ></Detail>
                      {detail && c === carga && (
                        <div className="fixed rounded-lg bg-gray-100 z-40 w-40 h-40 border transform -translate-x-40 -translate-y-12">
                          <div className="flex border-b p-1">
                            <p className="w-2/3 my-auto text-left font-bold">Total</p>
                            <p className="w-1/3">{c.total}</p>
                          </div>
                          <div className="flex border-b p-1 bg-green-200">
                            <p className="w-2/3 my-auto text-left font-bold">Validas</p>
                            <p className="w-1/3">{c.validas}</p>
                          </div>
                          <div className="flex border-b p-1 bg-red-200">
                            <p className="w-2/3 my-auto text-left font-bold">Invalidas</p>
                            <p className="w-1/3">{c.invalidas}</p>
                          </div>
                          <div className="flex border-b p-1 bg-yellow-200">
                            <p className="w-2/3 my-auto text-left font-bold">Creadas</p>
                            <p className="w-1/3">{c.creadas}</p>
                          </div>
                          <div className="flex border-b p-1 bg-blue-200">
                            <p className="w-2/3 my-auto text-left font-bold">Actualizadas</p>
                            <p className="w-1/3">{c.actualizadas}</p>
                          </div>
                        </div>
                      )}
                    </td>

                    <th className="">
                      <MoreVerticalOutline
                        onClick={() => {
                          setActionModal(!actionModal);
                          setSelected(c);
                        }}
                        size="38"
                        className="text-blue-semi transform hover:scale-125"
                      />
                      {actionModal && selected == c && <CargasAction carga={c} />}
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
        {cargas.previous &&<div onClick={()=>move(cargas.previous)} className="px-3 py-1 bg-blue-ligth transform hover:scale-110 font-semibold text-white rounded-md mt-1 cursor-pointer">Prev</div>}
        {cargas.next &&<div onClick={()=>move(cargas.next)} className="px-3 py-1 bg-blue-ligth transform hover:scale-110 font-semibold text-white rounded-md mt-1 cursor-pointer">Next</div>}
    </div>
  );
};

export default TCargas;
