import {useState} from 'react'

import EventsGrafico from './EventosSMS.jsx'
const Events = ({data}) => {



  return (
      <div className="w-full  bg-white rounded-md border  flex flex-wrap">

            <div className="w-full h-14 border-b flex justify-between content-center items-center px-4">
               <p className="text-lg">Eventos</p>
                <div className="flex h-full  items-center gap-x-1 ">
                  <div  className={`text-blue-700 px-1 h-full flex items-center`}><p className="hover:text-blue-700 cursor-pointer">Enviados</p></div>
                </div>

            </div>
            <div className="w-full h-96 py-4 px-0">
    {data && <EventsGrafico data={data.days} dataKey={"delivered"}></EventsGrafico>}
            </div>

          </div>
  )
}

export default Events;
