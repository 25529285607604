import {useState,useEffect} from 'react'

import axiosInstance from '../../../axios.js'

import Select, { createFilter } from 'react-select';

import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import {DeleteForever} from '@styled-icons/material/DeleteForever'
import {AddSquare} from '@styled-icons/fluentui-system-filled/AddSquare'


const CreatePlantillaModal = ({setAddPlantilla,plantilla,setChange,change}) =>{
  const [data,setData] = useState({})
  

  const createPlantilla = async () =>{

    await axiosInstance.post(`plantillas_carga/`,data).then(res=>{
      setChange(new Date())
      setAddPlantilla(false)
    })
  }

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }
  // useEffect(()=>{
  //   // setData(plantilla) 
  //   // getFields()
  // },[])



  return (
    <div className="absolute w-full h-full flex justify-center items-center">

      <div className="w-1/6 h-1/4 bg-white border rounded-md z-40 shadow-lg flex flex-wrap content-start gap-y-2 justify-center">
        <div className="w-full h-8 border-b flex justify-between items-center px-2"><p className="text-gray-600 text-sm">Crear Plantilla</p><XCircle onClick={()=>setAddPlantilla(false)} size="24" className="text-red-500 transform hover:scale-105 cursor-pointer"/></div>
        <div className="w-full h-auto p-4">

          <p className="font-semibold text-gray-700 ml-1">Nombre</p><input type="text" name="nombre" onChange={handleChange} className="w-auto px-2 py-1 border rounded-md"></input>
        </div>

      <button onClick={()=>createPlantilla()} className="px-2   py-1 text-gray-50 font-bold bg-green-500 rounded-md transform hover:scale-105"><span>Crear</span></button>
    </div>
    </div>
  )
}

export default CreatePlantillaModal;
