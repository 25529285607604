import React from 'react';
import { useHistory } from 'react-router-dom';
import {Link} from 'react-router-dom'


const MultiPage = ({ children ,pages,actualPage}) => {

  const history = useHistory();
  return (
    <div className=" w-full flex flex-wrap  bg-gray-50">
      <div className="h-12 w-full  text-xl p-2 text-blue-dark font-bold flex flex-wrap justify-center ">
	      {pages && pages.length > 0 && pages.map((page)=>{
		      return <div
		      onClick={() =>(history.push('/'+page.link))}
			  className={`w-1/4 h-full  ${
			    actualPage.name === page.name? 'bg-blue-ligth text-white' : 'border border-blue-ligth text-blue-ligth hover:bg-blue-ligth hover:text-white'
			  } flex justify-center cursor-pointer`}
			>
			  <p
			    className={`text-xl font-bold  my-auto`}
			  >
			    {page.name}
			   
			  </p>
			</div>	      })} 
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

export const MultiPageSelector2 = ({ children ,pages,actualPage}) => {

  const history = useHistory();
  return (
      <div className="h-8 w-full  text-xl p-2 text-blue-dark font-bold flex flex-wrap justify-center ">
	      {pages && pages.length > 0 && pages.map((page)=>{
		      return <div
		      onClick={() =>(history.push('/'+page.link))}
			  className={`w-1/4 h-full  ${
			    actualPage.name === page.name? 'bg-blue-ligth text-white' : 'border-2 border-blue-ligth text-blue-ligth hover:bg-blue-ligth hover:border-white hover:text-white'
			  } flex justify-center cursor-pointer transition`}
			>
			  <p
			    className={`text-xl font-bold  my-auto`}
			  >
			    {page.name}
			   
			  </p>
			</div>	      })} 
      </div>
  );
};



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const MultiPageSelector  = ({tabs})=> {
  return (
    <div className="w-full">
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-ligth focus:ring-blue-ligth"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                href={tab.href}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-blue-ligth text-blue-semi'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  `w-1/${tabs.length} py-2 px-1 text-center border-b-2 font-medium text-lg`
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

    </div>
  </div>
  )
}
export const MultiPageSelectorChange  = ({tabs,setPage})=> {
  return (
    <div className="w-full">
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-ligth focus:ring-blue-ligth"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                onClick={()=>setPage(tab.page)}
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-blue-ligth text-blue-semi'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  `w-1/${tabs.length} py-2 px-1 text-center border-b-2 font-medium text-lg cursor-pointer`
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>

    </div>
  </div>
  )
}
export const MultiPageSelectorChange2 = ({tabs,setPage})=> {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                onClick={()=>setPage(tab.page)}
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
              {tab.icon && tab.icon}
              {/* <tab.icon */}
              {/*     size="16" */}
              {/*     className={classNames( */}
              {/*       tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', */}
              {/*       '-ml-0.5 mr-2 ' */}
              {/*     )} */}
              {/*     aria-hidden="true" */}
              {/*   /> */}
                <span>{tab.name && tab.name}</span>
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export const MultiPageSelectorChange3  = ({tabs,setPage})=> {
  return (
    <div className="w-full mb-2 bg-white">
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-ligth focus:ring-blue-ligth"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                onClick={()=>setPage(tab.page)}
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-blue-ligth text-blue-semi'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  ` py-2 px-8 text-center border-b-2 font-medium text-lg cursor-pointer`
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>

    </div>
  </div>
  )
}
export default MultiPage;
