import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';

import General from '../components/dashboardAdmin/General';
import Agentes from '../components/dashboardAdmin/Agentes';
import { useLocation } from 'react-router-dom';
import { getQueryValue } from '../utils/forms';
import { getQueryValueSelect } from '../utils/forms';

import Select from 'react-select';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DashboardFiltered = () => {
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(1);
  const [mandantes, setMandantes] = useState([]);
  const [general, setGeneral] = useState(null);
  const [agentes, setAgentes] = useState(null);
  const [query, setQuery] = useState('?');
  const [dates, setDates] = useState({
    desde: null,
    hasta: null,
  });

  let Q = useQuery();

  const handleSelect = (e, name) => {
    if (query.split('&').filter((x) => x.includes(name.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(name.name)) {
              return `${name.name}=${e
                .map((selected) => {
                  return selected.value;
                })
                .join()}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${name.name}=`);
    }
  };

  //const handleChange = (e) =>{
  //    setDates({...dates,[e.target.name]:e.target.value})
  //}
  const handleChange = (e) => {
    if (query.split('&').filter((x) => x.includes(e.target.name)).length > 0) {
      setQuery(
        query
          .split('&')
          .map((x) => {
            if (x.includes(e.target.name)) {
              return `${e.target.name}=${e.target.value}`;
            } else {
              return x;
            }
          })
          .join('&'),
      );
    } else {
      setQuery(`${query}&${e.target.name}=${e.target.value}`);
    }
  };
  const getMandantes = async () => {
    await axiosInstance.get('mandantes/').then((res) => {
      //setMandantes(res.data.map((man)=>{
      //return {value:man.codigo,label:man.nombre}
      //}))
      setMandantes(res.data);
    });
  };
  const optionsMandantes =
    mandantes &&
    mandantes.map((man) => {
      return { value: man.codigo, label: man.nombre };
    });
  const getGeneral = async () => {
    console.log('get GEneral');
    if (dates['desde'] !== null && dates['hasta'] !== null) {
      await axiosInstance.get(`llamadas/dashboard_general/?${query.toString()}`).then((res) => {
        setGeneral(res.data);
        console.log(res.data);
      });
    }
  };

  const getAgentes = async () => {
    if (dates['desde'] !== null && dates['hasta'] !== null) {
      await axiosInstance.get(`llamadas/dashboard_agentes/?${query.toString()}`).then((res) => {
        setAgentes(res.data);
      });
    }
  };

  useEffect(() => {
    setQuery(Q.toString().replace(/%2C/gi, ','));
  }, []);

  useEffect(() => {
    if ((false && dates['desde'] === null) || dates['hasta'] === null) {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
      setDates({ desde: firstDay, hasta: lastDay });
      //handleChange({target:{name:'desde',value:'firstDay'}})
      //handleChange({target:{name:'desde',value:'hasta'}})
    }
    getGeneral();
    getAgentes();
    getMandantes();
  }, [change, dates]);

  return (
    <div className="w-full h-full pt-1 px-1 bg-white overflow-y-scroll no-scrollbar flex flex-wrap">
      <div className="h-10 w-full flex rounded-lg overflow-hidden">
        <div
          onClick={() => setPage(1)}
          className={`w-1/3 h-full rounded-l-lg ${
            page == 1 ? 'bg-blue-ligth' : 'border border-blue-ligth'
          } flex justify-center cursor-pointer`}
        >
          <p
            className={`text-2xl font-bold ${
              page === 1 ? 'text-white' : 'text-blue-ligth'
            } my-auto`}
          >
            General
          </p>
        </div>
        <div
          onClick={() => {
            setPage(2);
            getAgentes();
          }}
          className={`w-1/3 h-full  ${
            page == 2 ? 'bg-blue-ligth' : 'border border-blue-ligth'
          } flex justify-center cursor-pointer`}
        >
          <p
            className={`text-2xl font-bold ${
              page === 2 ? 'text-white' : 'text-blue-ligth'
            } my-auto`}
          >
            Agentes
          </p>
        </div>
        <div
          onClick={() => setPage(3)}
          className={`w-1/3 h-full rounded-r-lg ${
            page == 3 ? 'bg-blue-ligth' : 'border border-blue-ligth'
          } flex justify-center cursor-pointer`}
        >
          <p
            className={`text-2xl font-bold ${
              page === 3 ? 'text-white' : 'text-blue-ligth'
            } my-auto`}
          >
            Campañas
          </p>
        </div>
      </div>

      <div className="h-8 w-full flex rounded-lg mt-1 mb-1 justify-end">
        <div className="w-2/6 h-full">
          <Select
            onChange={handleSelect}
            className="w-full"
            name="mandante"
            isMulti
            hideSelectedOptions
            getOptionLabel={(option) =>
              optionsMandantes.map((item) => {
                //console.log(option);
                //console.log(item)
                if (typeof option === 'number' || typeof option === 'string') {
                  if (item.value == option) {
                    return item.label;
                  }
                } else if (typeof option === 'object') {
                  if (item.value == option.value) {
                    console.log(option);
                    console.log(item);
                    return item.label;
                  }
                }
              })
            }
            value={getQueryValueSelect(query, 'mandante', optionsMandantes)}
            options={optionsMandantes}
          />
        </div>
        <div className="rounded-md flex bg-gray-200 w-auto px-2">
          <p className="text-lg mr-2">Desde</p>
          {/*<input name="desde" type="date" value={dates['desde']} onChange={handleChange} className="border bg-gray-200 rounded-md"></input>*/}
          <input
            name="desde"
            type="date"
            value={getQueryValue(query, 'desde')}
            onChange={handleChange}
            className="border bg-gray-200 rounded-md"
          ></input>
        </div>

        <div className="rounded-md flex bg-gray-200 w-auto px-2 ml-3">
          <p className="text-lg mr-2">Hasta</p>
          {/*<input name="hasta" type="date" value={dates['hasta']} onChange={handleChange} className="border bg-gray-200 rounded-md"></input>*/}
          <input
            name="hasta"
            type="date"
            value={getQueryValue(query, 'hasta')}
            onChange={handleChange}
            className="border bg-gray-200 rounded-md"
          ></input>
        </div>
        <div
          onClick={() => {
            setChange(change + 1);
            window.location.href = `${window.location.pathname}?${query}`;
          }}
          className="px-2 hover:bg-opacity-75 transform hover:scale-110 cursor-pointer rounded-lg bg-blue-semi flex mx-4 justify-center"
        >
          <p className="my-auto font-bold text-white ">Buscar</p>
        </div>
      </div>
      {page === 1 && (
        <General dates={dates} change={change} data={general} getGeneral={getGeneral} />
      )}
      {page === 2 && <Agentes dates={dates} change={change} data={agentes} getData={getAgentes} />}
    </div>
  );
};

export default DashboardFiltered;
