import React, { useState, useEffect } from 'react';

import MailRow from './MailRow';
import MailAction from './MailAction';
import { CSVLink, CSVDownload } from 'react-csv';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';

const MailsT = ({ resps, pagination, move, setChange }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [actionModal, setActionModal] = useState(false);

  const addSelected = (resp) => {
    setSelected([...selected, resp]);
  };
  const removeSelected = (resp) => {
    setSelected(selected.filter((x) => x.id !== resp.id));
  };

  const handleCheckAll = (e) => {
    if (checkedAll === false) {
      setSelected(resps);
      setCheckedAll(true);
    } else {
      setSelected([]);
      setCheckedAll(false);
    }
  };

  const getData = () => {
    return selected.map((row) => {
      return {
        id: `${row.id}`,
        Contacto: `${row.contacto.nombre} ${row.contacto.apellido}`,
        rut: `${row.contacto.rut}`,
        nro_cuenta: `${row.contacto.nro_cuenta}`,
        template: `${row.template_f && row.template_f.nombre}`,
        asunto: `${row.subject}`,
        emisor: `${row.sender}`,
        receptor: `${row.address}`,
        enviado: `${row.delivered}`,
        abierto: `${row.opened}`,
        click: `${row.clicked}`,
      };
    });
  };
  //selected.map((row)=>{return {'contacto':`${row.contacto.nombre} ${row.contacto.apellido}`,'nro_cuenta':`${row.contacto.nro_cuenta}`,'rut':`${row.contacto.rut}`,'mandante':`${row.contacto.mandante}`,'fecha':`${row.add_date}`,'hora':`${row.add_datetime}`,'agente':`${row.agente.first_name} ${row.agente.last_name}`,'tipo':`${row.tipo.nombre}`,'obs':`${row.observacion}`,'numero':`${row.numero && row.numero.numero}`}
  //    })

  return (
    <table className="w-full table-auto">
      <thead className="bg-gray-200">
        <th className="border w-10">
          <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} />
        </th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Contacto</th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Fecha</th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Template</th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Emisor</th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Asunto</th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Email</th>
        <th className="border px-1 py-1 whitespace-nowrap fornt-normal text-gray-900">Enviado</th>
        <th className="border px-2 py-1 whitespace-nowrap fornt-normal text-gray-900">Abierto</th>
        <th className="border px-2 py-1 whitespace-nowrap fornt-normal text-gray-900">Click</th>
        <th className="border p-1 w-10">
          {pagination.previous && (
            <button
              onClick={() => move(pagination.previous)}
              className="border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-lg"
            >
              Prev
            </button>
          )}
        </th>
        <th className="border p-1 w-10">
          <div className="p-1 rounded-sm text-white bg-blue-semi">{pagination.page_number}</div>
        </th>
        <th className="border p-1 w-10">
          {pagination.next && (
            <button
              onClick={() => move(pagination.next)}
              className="border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-lg"
            >
              next
            </button>
          )}
        </th>
        <th className="border p-1 w-10">
          <CSVLink filename="mails.csv" data={getData()}>
            <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
          </CSVLink>
        </th>
        <th className="border p-1 w-10">
          <MoreVerticalOutline
            onClick={() => {
              setActionModal(!actionModal);
            }}
            size="38"
            className="text-blue-semi transform hover:scale-125"
          />
          {actionModal && (
            <MailAction setChange={setChange} contactos={selected}></MailAction>
          )}
        </th>
      </thead>
      <tbody>
        {resps &&
          resps.length > 0 &&
          resps.map((r) => {
            return (
              <MailRow
                selected={selected}
                r={r}
                addSelected={addSelected}
                removeSelected={removeSelected}
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default MailsT
