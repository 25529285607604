import React, { useEffect } from 'react';
import axiosInstance from '../axios';
import { useHistory } from 'react-router-dom';

//import {useSelector, useDispatch } from 'react-redux'
//import {setDevice} from '../redux/actions/deviceActions'
//import {setState} from '../redux/actions/stateActions'

export default function Logout() {
  const history = useHistory();

  var user = JSON.parse(localStorage.getItem('user'));
  //const dispatch = useDispatch();
  useEffect(() => {
    //const response = axiosInstance.post('user/logout/blacklist/', {
    //	refresh_token: localStorage.getItem('refresh_token'),
    //});
    axiosInstance.patch(`users/${user.id}/`, { status: 2 });
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('twilio_token');
    localStorage.removeItem('user');

    axiosInstance.defaults.headers['Authorization'] = null;
    history.push('/login');
    window.location.reload();
  });
  return <div>Logout</div>;
}
