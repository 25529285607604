import React, { useState } from 'react';

import axiosInstance from '../../axios';
import { Link } from 'react-router-dom';

import toast from 'react-hot-toast';
import { Eye } from '@styled-icons/heroicons-solid/Eye';
import { TrashBin } from '@styled-icons/ionicons-outline/TrashBin';
import { IndentMore } from '@styled-icons/foundation/IndentMore';

const ListaTable = ({ listas, setListas, setChange }) => {
  const [confR, setConfR] = useState(false);
  const [idC, setIdC] = useState(null);
  const [lista, setLista] = useState({});

  const close = () => {
    setIdC(null);
    setConfR(false);
  };
  const rem = (lista) => {
    setLista(lista);
    setIdC(lista.id);
    setConfR(true);
    //axiosInstance.delete(`listas/${id}/`).then((res) => {
    //                //console.log(res)
    //                setListas(listas.filter(x=> x.id !== id))
    //                //consoleS.log(contactos)
    //                //setChange(id)
    //                })
  };

  const del = () => {
    console.log(idC);
    console.log('asdsad');
    axiosInstance.delete(`listas/${idC}/`).then((res) => {
      console.log(res);
      setListas(listas.filter((x) => x.id !== idC));
      setChange(idC);
      //consoleS.log(contactos)
    });

    setConfR(false);
  };
  const cambiarStatus = (lista) => {
    if (lista.status === 1) {
      var status = 2;
    } else if (lista.status === 2) {
      var status = 1;
    }
    axiosInstance.patch(`listas/${lista.id}/`, { status: status }).then((res) => {
      setChange(new Date());
      toast.success(`Cambio de status en ${lista.nombre}`, { duration: 3000 });
    });
  };

  return (
    <table className="w-full leading-normal rounded-lg shadow-2xl overflow-hidden">
      {confR && (
        <div className="flex flex-wrap ml-96 flex-col h-1/5 bg-blue-dark shadow-2xl rounded-xl pt-3 px-2 w-3/12 fixed pin z-50 overflow-auto bg-smoke-light flex">
          <div className="flex flex-wrap w-full h-2/3 justify-center">
            <p className="font-bold text-xl text-white h-1/3">Eliminar esta lista:</p>
            <div className="flex justify-center w-full text-white h-2/3 text-4xl font-bold">
              <p className="my-auto">{lista.nombre}</p>
            </div>
          </div>
          <div className="flex justify-center w-full h-1/3 items-end pb-2">
            <button
              onClick={() => close()}
              className=" focus:outline-none mx-4 py-2 px-4 text-black bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-white cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
            >
              Cancelar
            </button>

            <button
              type="submit"
              onClick={() => del()}
              className=" focus:outline-none mx-4 py-2 px-5 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-red-500 cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150"
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <thead>
        <tr>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            Nombre
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            Contactos
          </th>

          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            status
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            Eliminar
          </th>
          <th
            scope="col"
            className="p-1 bg-gray-200 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
          >
            detalles
          </th>
        </tr>
      </thead>
      <tbody>
        {listas &&
          listas.map((lista) => (
            <tr key={lista.id} className="">
              <td className="p-1 border-b border-gray-200 bg-white text-sm">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-lg  font-bold text-gray-900 whitespace-no-wrap">
                      {lista.nombre}
                    </p>
                  </div>
                </div>
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">{lista.contactos}</p>
              </td>

              <td className="p-1  border-b border-gray-200 bg-white text-sm">
                {lista && lista.status === 1 ? (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute cursor-pointer hover:bg-red-200 inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span onClick={() => cambiarStatus(lista)} className="relative cursor-pointer">
                      activa
                    </span>
                  </span>
                ) : (
                  <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 cursor-pointer hover:bg-green-200 bg-red-200 opacity-50 rounded-full"
                    ></span>
                    <span onClick={() => cambiarStatus(lista)} className="relative cursor-pointer">
                      inactiva
                    </span>
                  </span>
                )}
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-sm">
                <button
                  onClick={() => rem(lista)}
                  className="text-red-500 hover:text-red-400 transform hover:scale-125"
                >
                  <TrashBin size="35" className="" />
                </button>
              </td>
              <td className="p-1 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">
                  <Link to={`/listas/${lista.id}`}>
                    <button className="text-black hover:text-gray-900 transform hover:scale-125">
                      <IndentMore size="35" className="" />
                    </button>
                  </Link>
                </p>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ListaTable;
