import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

//const data = [
//  { name: 'Group A', value: 400 },
//  { name: 'Group B', value: 300 },
//  { name: 'Group C', value: 300 },
//  { name: 'Group D', value: 200 },
//];

const Pies = ({ tipos, grupos }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={700} height={700}>
        <Pie
          dataKey="value"
          isAnimationActive={true}
          data={grupos}
          cx="50%"
          cy="50%"
          outerRadius={90}
          fill="#8884d8"
        >
          {grupos.map((entry, index) => {
            //console.log(entry)
            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Pie>
        <Pie
          dataKey="value"
          isAnimationActive={true}
          data={tipos}
          cx="50%"
          cy="50%"
          outerRadius={150}
          innerRadius={120}
          fill="#000000"
          paddingAngle={1.5}
        >
          {tipos.map((entry, index) => {
            //console.log(entry)
            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Pie>

        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default Pies;
