import React, { useState, useEffect } from 'react';

import ContactoRow from './ContactoRow';
import ContactoAction from './ContactoAction';
import { CSVLink, CSVDownload } from 'react-csv';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { FileExcel } from '@styled-icons/fa-solid/FileExcel';

const ContactosT = ({ resps, pagination, move, setChange }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [actionModal, setActionModal] = useState(false);

  const addSelected = (resp) => {
    setSelected([...selected, resp]);
  };
  const removeSelected = (resp) => {
    setSelected(selected.filter((x) => x.id !== resp.id));
  };

  const handleCheckAll = (e) => {
    if (checkedAll === false) {
      setSelected(resps);
      setCheckedAll(true);
    } else {
      setSelected([]);
      setCheckedAll(false);
    }
  };

  const getData = () => {
    return selected.map((row) => {
      return {
        id: `${row.id}`,
        nombre: `${row.nombre}`,
        apellido: `${row.apellido}`,
        nro_cuenta: `${row.nro_cuenta}`,
        nro_pagare: `${row.nro_pagare}`,
        rut: `${row.rut}`,
      };
    });
  };
  //selected.map((row)=>{return {'contacto':`${row.contacto.nombre} ${row.contacto.apellido}`,'nro_cuenta':`${row.contacto.nro_cuenta}`,'rut':`${row.contacto.rut}`,'mandante':`${row.contacto.mandante}`,'fecha':`${row.add_date}`,'hora':`${row.add_datetime}`,'agente':`${row.agente.first_name} ${row.agente.last_name}`,'tipo':`${row.tipo.nombre}`,'obs':`${row.observacion}`,'numero':`${row.numero && row.numero.numero}`}
  //    })

  return (
    <table className="w-full ">
      <thead className="bg-gray-200">
        <th className="border w-10">
          <input type="checkbox" checked={checkedAll} onChange={handleCheckAll} />
        </th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Nombre</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Apellido</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Rut</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Mandante</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Estado</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Cuenta</th>
        <th className="border px-4 py-2 whitespace-nowrap fornt-normal text-gray-900">Pagare</th>
        <th className="border p-1 w-10">
          {pagination.previous && (
            <button
              onClick={() => move(pagination.previous)}
              className="border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-lg"
            >
              Prev
            </button>
          )}
        </th>
        <th className="border p-1 w-10">
          <div className="p-1 rounded-sm text-white bg-blue-semi">{pagination.page_number}</div>
        </th>
        <th className="border p-1 w-10">
          {pagination.next && (
            <button
              onClick={() => move(pagination.next)}
              className="border bg-gray-50 transform hover:scale-110 text-gray-800 px-2 border-gray-300 py-1 rounded-lg"
            >
              next
            </button>
          )}
        </th>
        <th className="border p-1 w-10">
          <CSVLink filename="contactos.csv" data={getData()}>
            <FileExcel size="32" className="text-green-600 transform hover:scale-125" />
          </CSVLink>
        </th>
        <th className="border p-1 w-10">
          <MoreVerticalOutline
            onClick={() => {
              setActionModal(!actionModal);
            }}
            size="38"
            className="text-blue-semi transform hover:scale-125 cursor-pointer"
          />
          {actionModal && (
            <ContactoAction setChange={setChange} contactos={selected}></ContactoAction>
          )}
        </th>
      </thead>
      <tbody>
        {resps &&
          resps.length > 0 &&
          resps.map((r) => {
            return (
              <ContactoRow
                selected={selected}
                r={r}
                addSelected={addSelected}
                removeSelected={removeSelected}
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default ContactosT;
