import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const status_opts = [
  { name: 'realizadas', color: '#6EE7B7' },
  { name: 'rellamadas', color: '#93C5FD' },
  { name: 'terminadas', color: '#630101' },
  { name: 'pendientes', color: '#facc4b' },
];
const data = [{ name: 'camp', realizadas: 10, rellamadas: 5, pendientes: 5 }];

// ({status_data,resps_data,resps_opts,name})
const Camp = ({ nombre, status_data, resps_data, resps_opts }) => {
  //const resp  = [{nombre:'no contesta',color:'#0428ba'},{nombre:'recado',color:'#00ff0d'}]
  console.log({ resps_data, resps_opts });
  return (
    <div className="h-full w-full flex flex-wrap border border-blue-ligth rounded-lg">
      <div className="w-full h-1/5 flex justify-center">
        <p className="font-semibold text-md my-auto">{nombre}</p>
      </div>
      <div className="h-full w-full flex flex-wrap px-4 pb-2">
        <ResponsiveContainer width="100%" height="40%">
          <BarChart layout="vertical" data={status_data}>
            <XAxis type="number" hide={true} />
            <YAxis dataKey="name" type="category" hide={true} />
            {status_opts &&
              status_opts.map((r) => (
                <Bar dataKey={r.name} stackId="a" fill={r.color}>
                  {status_data &&
                    status_data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={r.color} />
                    ))}
                </Bar>
              ))}
            <Tooltip wrapperStyle={{ zIndex: 1000 }} />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height="40%">
          <BarChart layout="vertical" data={resps_data}>
            <XAxis type="number" hide={true} />
            <YAxis dataKey="name" type="category" hide={true} />
            {resps_opts &&
              resps_opts.map((r) => (
                <Bar dataKey={r.name} stackId="a" fill={r.color}>
                  {resps_data &&
                    resps_data.map((entry, index) => <Cell key={`cell-${index}`} fill={r.color} />)}
                </Bar>
              ))}
            <Tooltip
              allowEscapeViewBox={{ x: false, y: true }}
              position={{ x: 0, y: -100 }}
              wrapperStyle={{ zIndex: 1000 }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Camp;
