import React from 'react';

const InfoContacto = ({ contacto }) => {
  return (
    <div className="border-t border-gray-200 h-full overflow-y-scroll no-scrollbar">
      <dl>
        <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Nombre</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.nombre + ' ' + contacto.apellido}
          </dd>
        </div>
        <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Rut</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.rut && contacto.rut}
          </dd>
        </div>
        <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Email</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <select>
              {contacto.email &&
                contacto.email.length > 0 &&
                contacto.email.map((e) => <option value={e}>{e}</option>)}
            </select>
          </dd>
        </div>
        <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Mandante</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.mandante}
          </dd>
        </div>
        <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Estado Cuenta</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.estado}
          </dd>
        </div>
        <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Saldo Deuda</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.saldo_deuda}
          </dd>
        </div>
        <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Total Deuda</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.total_deuda}
          </dd>
        </div>

        <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Status</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.status == 1 ? (
              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                <span
                  aria-hidden="true"
                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                ></span>
                <span className="relative">activo</span>
              </span>
            ) : (
              <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                <span
                  aria-hidden="true"
                  className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                ></span>
                <span className="relative">inactivo</span>
              </span>
            )}
          </dd>
        </div>
        {/*<div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-700 font-bold">
                            About
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            To get social media testimonials like these, keep your customers engaged with your social media accounts by posting regularly yourself
                        </dd>
                    </div>*/}
        <div className="bg-gray-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-700 font-bold">Link</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {contacto && contacto.id_cuenta && (
              <a
                target="_blank"
                className="text-md font-bold text-blue-900"
                href={`http://astrea.fullpay.me/cuentas/view/${contacto.id_cuenta}`}
              >
                Ver en FullPay
              </a>
            )}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default InfoContacto;
