import React from 'react';
import ReactDOM from 'react-dom';
//import './assets/index.css';
import './assets/styles/tailwind.css';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import store from './redux/store';

import App from './App';

import LlamadaDetail from './pages/llamadas/LlamadaDetail';
import Llamadas from './pages/llamadas/Llamadas';

import Contactos from './pages/contactos/Contactos';
import DetalleContacto from './pages/contactos/DetalleContacto';
import DetalleContacto2 from './pages/contactos/DetalleContacto2';

import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Logout from './components/Logout';
import Reset from './pages/Reset';

import Layout from './components/Layout';
import DashboardAgente from './pages/DashboardAgente';
import DashboardStatsAgente from './pages/DashboardStatsAgente';
import DashboardAgente2 from './pages/DashboardAlgo';
import DashboardAdmin from './pages/DashboardAdmin';
import DashboardFiltered from './pages/DashboardFiltered';

import RespuestasD from './pages/respuestas/RespuestasD';

import Operaciones from './pages/operaciones/Operaciones';

import Listas from './pages/Listas/Listas';
import Listas2 from './pages/Listas/Listas2';
import ListaView from './pages/Listas/ListaView';
import ListaView2 from './pages/Listas/ListaView2';

import Campañas from './pages/campaings/Campañas';
import Campaña from './pages/campaings/Campaña';

import Campaigns2 from './pages/campaings/Campaigns2';

import MailDashboard from './pages/dashboards/MailDashboards.jsx';
import SMSDashboard from './pages/dashboards/SMSDashboard.jsx';
import VoiceMsgDashboard from './pages/dashboards/VoiceMsgDashboard.jsx';
import LlamadasDashboard from './pages/dashboards/LlamadasDashboard.jsx';
import LlamadasDashboard2 from './pages/dashboards/LlamadasDashboard2.jsx';
import AgentesDashboard2 from './pages/dashboards/AgentesDashboard.jsx';
import Transfers from './pages/agentes/Transfers.jsx';

import MailCampaigns from './pages/mailCampaigns/Campaigns';
import MailCampaigns2 from './pages/mailCampaigns/Campaigns2';
import MailCampaign from './pages/mailCampaigns/Campaign';
// TESTS pages
import CallTest from './pages/tests/Call.jsx';

//import SMSCampaigns from './pages/smsCampaigns/Campaigns';
import SMSCampaigns2 from './pages/smsCampaigns/Campaigns2';
import SMSCampaign from './pages/smsCampaigns/Campaign';

import VoiceMsgCampaigns from './pages/voiceMsgCampaigns/Campaigns';
import VoiceMSGCampaigns2 from './pages/voiceMsgCampaigns/Campaigns2';
// import VoiceMsgCampaign from './pages/voiceMsgCampaigns/Campaign';
import VoiceMSGCampaign from './pages/voiceMsgCampaigns/Campaign2';

import MailTemplates from './pages/mails/Templates.jsx';
import MailTemplates2 from './pages/mails/Templates2.jsx';
import MailTemplates22 from './pages/mails/Templates22.jsx';
import TemplateCreator from './pages/mails/TemplateCreator.jsx';
import MailTemplateDetail from './pages/mails/TemplateDetail.jsx';
import MailTemplateDesignDetail from './pages/mails/TemplateDesignDetail.jsx';
import TestMails from './pages/mails/TestMails.jsx';

import SmsTemplates from './pages/sms/Templates.jsx';
import SmsTemplates2 from './pages/sms/Templates2.jsx';
import SmsTemplateDetail from './pages/sms/TemplateDetail.jsx';
import SmsTemplateDetail2 from './pages/sms/TemplateDetail2.jsx';
import TestSMS from './pages/sms/TestSMS.jsx';

import VoiceMsgTemplates from './pages/voicemsg/Templates.jsx';
import VoiceMsgTemplateDetail2 from './pages/voicemsg/TemplateDetail2.jsx';
import TestVoiceMsg from './pages/voicemsg/TestVoiceMsg.jsx';

import Carga from './pages/carga/Carga';
import Carga2 from './pages/carga/Carga2';
import Plantillas from './pages/carga/Plantillas.jsx';
import CargaCuentas from './pages/carga/CargaCuentas';
import CargaDatos from './pages/carga/CargaDatos';
import CargaDocs from './pages/carga/CargaDocs';
import CargaMandantes from './pages/carga/CargaMandantes';

import Main from './pages/admin/Main';
import Users from './pages/admin/Users';
import Dominios from './pages/admin/Dominios';
import MailRules from './pages/admin/MailRules';
import SMSRules from './pages/admin/SMSRules';
import Mandantes from './pages/admin/Mandantes';
import Animos from './pages/admin/Animos';
import Reglas from './pages/admin/Reglas';
import Respuestas from './pages/admin/Respuestas';
import RespuestasAdmin from './pages/admin/Respuestas.jsx';
import Numeros from './pages/admin/Numeros.jsx';
import Scripts from './pages/admin/Scripts';
import Estados from './pages/admin/Estados';
import Estados2 from './pages/admin/Estados2';
import Bloqueos from './pages/admin/Bloqueos';
import Users2 from './pages/admin/Users2';
import TagsContacto from './pages/admin/TagsContacto.jsx';

import AdminMandantes from './pages/mandantes/Admin.jsx';
import AdminScripts from './pages/scripts/Admin.jsx';
import UserDetail from './pages/users/UserDetail';
import DetailUser from './pages/users/DetailUser';
import EditUser from './pages/users/EditUser';

import Bars from './pages/TestC';
import TestC2 from './pages/TestC2';

import Mails from './pages/mails/mails';
import Sms from './pages/sms/Sms';
import VoiceMsg from './pages/voicemsg/msg';

import Test from './components/Test';
import UncontrolledDiagram from './pages/Schema';

import AgentesResps from './pages/agentes/AgentesResps';

import HomeAdmin from './pages/HomeAdmin';
import MandantesDashboard from './pages/dashboards/Mandantes';

import TestComps from './pages/TestComps.jsx';
// import Flow from './pages/acalls/Flow.jsx'

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <Provider store={store}>
        <Switch>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/logout" component={Logout}></Route>
          <Route exact path="/forgot" component={Forgot}></Route>
          <Route exact path="/reset_password" component={Reset}></Route>

          <Route exact path="/t2" component={TestC2}></Route>
          <Layout>
            <Route exact path="/dashboardold" component={DashboardAgente}></Route>
            <Route exact path="/" component={DashboardAgente2}></Route>
            <Route exact path="/agentes_resps" component={AgentesResps}></Route>
            <Route exact path="/llamadas/:id" component={withRouter(LlamadaDetail)}></Route>
            <Route exact path="/llamadas" component={Llamadas}></Route>

            <Route exact path="/home" component={HomeAdmin}></Route>

            {/*<Route exact path="/dashboard" component={DashboardAdmin}></Route>*/}
            <Route exact path="/dashboardc" component={DashboardFiltered}></Route>
            <Route exact path="/dashboard" component={DashboardFiltered}></Route>

            <Route exact path="/mail_dashboard" component={MailDashboard}></Route>
            <Route exact path="/sms_dashboard" component={SMSDashboard}></Route>
            <Route exact path="/llamadas_dashboard" component={LlamadasDashboard}></Route>
            <Route exact path="/llamadas_dashboard2" component={LlamadasDashboard2}></Route>
            <Route exact path="/agentes_dashboard2" component={AgentesDashboard2}></Route>
            <Route exact path="/voicemsg_dashboard" component={VoiceMsgDashboard}></Route>
            <Route exact path="/mandantes_dashboard" component={MandantesDashboard}></Route>

            <DndProvider backend={HTML5Backend}>
              <Route exact path="/agentes_transfers" component={Transfers}></Route>
            </DndProvider>
            <Route exact path="/stats" component={DashboardStatsAgente}></Route>

            <Route exact path="/carga" component={Carga}></Route>
            <Route exact path="/carga/plantillas" component={Plantillas}></Route>
            <Route exact path="/carga2" component={Carga2}></Route>
            <Route exact path="/carga/docs" component={CargaDocs}></Route>
            <Route exact path="/carga/cuentas" component={CargaCuentas}></Route>
            <Route exact path="/carga/datos" component={CargaDatos}></Route>
            <Route exact path="/carga/mandantes" component={CargaMandantes}></Route>

            <Route exact path="/ops" component={Operaciones}></Route>
            <Route exact path="/ops/:id" component={RespuestasD}></Route>

            <Route exact path="/sms_templates" component={SmsTemplates2}></Route>
            <Route exact path="/sms_templates/:id" component={SmsTemplateDetail2}></Route>
            <Route exact path="/sms" component={Sms}></Route>
            <Route exact path="/sms_pruebas" component={TestSMS}></Route>

            <Route exact path="/mail_templates" component={MailTemplates}></Route>
            <Route exact path="/mail_template_creator" component={TemplateCreator}></Route>
            <Route exact path="/mail_templates/:id" component={MailTemplateDetail}></Route>
            {/* <Route exact path="/mail_templates_designs/" component={MailTemplates2}></Route> */}
            <Route exact path="/mail_templates_designs/" component={MailTemplates22}></Route>
            <Route
              exact
              path="/mail_templates_designs/:id"
              component={MailTemplateDesignDetail}
            ></Route>
            <Route exact path="/mails" component={Mails}></Route>
            <Route exact path="/mails/:id" component={RespuestasD}></Route>
            <Route exact path="/mail_pruebas" component={TestMails}></Route>

            <Route exact path="/voicemsg_templates" component={VoiceMsgTemplates}></Route>
            <Route exact path="/voicemsg_templates/:id" component={VoiceMsgTemplateDetail2}></Route>
            <Route exact path="/voicemsg" component={VoiceMsg}></Route>
            <Route exact path="/voicemsg_pruebas" component={TestVoiceMsg}></Route>

            <Route exact path="/mails/:id" component={RespuestasD}></Route>

            <Route exact path="/admin" component={Main}></Route>
            {/* <Route exact path="/admin/users" component={Users}></Route> */}
            <Route exact path="/admin/dominios" component={Dominios}></Route>
            <Route exact path="/admin/mail_rules" component={MailRules}></Route>
            <Route exact path="/admin/sms_rules" component={SMSRules}></Route>
            <Route exact path="/admin/respuestasold" component={Respuestas}></Route>
            <Route exact path="/admin/respuestas" component={RespuestasAdmin}></Route>
            <Route exact path="/admin/numeros" component={Numeros}></Route>
            <Route exact path="/admin/scripts" component={AdminScripts}></Route>
            <Route exact path="/admin/scripts_old" component={Scripts}></Route>
            <Route exact path="/admin/reglas" component={Reglas}></Route>
            <Route exact path="/admin/mandantes_old" component={Mandantes}></Route>
            <Route exact path="/admin/mandantes" component={AdminMandantes}></Route>
            <Route exact path="/admin/animos" component={Animos}></Route>
            <Route exact path="/admin/estados_old" component={Estados}></Route>
            <Route exact path="/admin/estados" component={Estados2}></Route>
            <Route exact path="/admin/bloqueos" component={Bloqueos}></Route>
            <Route exact path="/admin/users" component={Users2}></Route>
            <Route exact path="/admin/tags_contacto" component={TagsContacto}></Route>

            {/* <Route exact path="/users/:id" component={UserDetail}></Route> */}
            <Route exact path="/users/:id" component={DetailUser}></Route>
            <Route exact path="/users/:id/edit" component={EditUser}></Route>

            <Route exact path="/contactos" component={Contactos}></Route>
            {/* <Route exact path="/contactos/:id" component={DetalleContacto}></Route> */}
            <Route exact path="/contactos/:id" component={DetalleContacto2}></Route>

            <Route exact path="/respuestas" component={RespuestasD}></Route>

            <Route exact path="/listasold" component={Listas}></Route>
            <Route exact path="/listas" component={Listas2}></Route>
            <Route exact path="/listas2/:id" component={ListaView}></Route>
            <Route exact path="/listas/:id" component={ListaView2}></Route>

            <Route exact path="/l/:id" component={Test}></Route>

            <Route exact path="/schema" component={UncontrolledDiagram}></Route>

            {/* <Route exact path="/flow" component={<Flow></Flow>}></Route> */}

            {/* <Route exact path="/camps_llamadas" component={Campañas}></Route> */}
            <Route exact path="/camps_llamadas" component={Campaigns2}></Route>
            <Route exact path="/camps_llamadas/:id" component={Campaña}></Route>

            {/* <Route exact path="/camps_mails" component={MailCampaigns}></Route> */}
            <Route exact path="/camps_mails" component={MailCampaigns2}></Route>
            <Route exact path="/camps_mails/:id" component={MailCampaign}></Route>

            <Route exact path="/camps_sms" component={SMSCampaigns2}></Route>
            <Route exact path="/camps_sms/:id" component={SMSCampaign}></Route>

            <Route exact path="/camps_voicemsg" component={VoiceMSGCampaigns2}></Route>
            <Route exact path="/camps_voicemsgold" component={VoiceMsgCampaigns}></Route>
            <Route exact path="/camps_voicemsg/:id" component={VoiceMSGCampaign}></Route>

            <Route exact path="/tests/call" component={CallTest}></Route>

            <Route exact path="/test_comps" component={TestComps}></Route>
            <Route exact path="/t" component={Bars}></Route>
          </Layout>
        </Switch>
      </Provider>
    </React.StrictMode>
  </Router>,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
