import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';

import Respuesta from './Respuesta';
import Ct from './pages/Ct';
import Docs from './pages/Docs';
import Actions from './pages/Actions';
import Script from './pages/Script';
import Resultado from './pages/Resultado';
import { useSelector, useDispatch } from 'react-redux';
import {
  setLlamada,
  setDir,
  setNumero,
  setType,
  setContacto,
} from '../../redux/actions/llamadaActions';

import Draggable from 'react-draggable';

import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Cross } from '@styled-icons/icomoon/Cross';
import { Phone } from '@styled-icons/fa-solid/Phone';
import { isNull } from 'lodash';

import { Close } from '@styled-icons/evaicons-solid/Close';
import { TelephoneAccessible } from '@styled-icons/foundation/TelephoneAccessible';
import { WindowMinimize } from '@styled-icons/fa-solid/WindowMinimize';
import { ArrowMinimize } from '@styled-icons/fluentui-system-filled/ArrowMinimize';
import { ArrowMaximize } from '@styled-icons/fluentui-system-filled/ArrowMaximize';

// const FinLlamada = ({ setModal, cl, setCl, tipos_respuestas, animos, mandantes }) => {
const FinLlamada = ({ setModal, cl, setCl, animos, mandantes }) => {
  const [page, setPage] = useState(1);
  const [change,setChange] = useState(null)
  const [minimized, setMinimized] = useState(false);
  const [maximized, setMaximized] = useState(false);
  const [ct,setCt] = useState(null)
  const [tipos_respuestas,setTipoRespuestas] = useState([])

  const llamada = useSelector((state) => state.llamada);

  // if  dir == incoing show to identificate contacto
  var user = JSON.parse(localStorage.getItem('user'));
  const h = new Date(Date.now());

  const dispatch = useDispatch();

  const getCt = () =>{
    axiosInstance.get(`contactos/${llamada.llamada.contacto.id}`).then(res=>{
      setCt(res.data)
      console.log(res.data)
    })
  }
  const get_tipo_respuestas = async () =>{
    await axiosInstance.get(`llamadas/${llamada.llamada.id}/get_resps`).then(res=>{
      setTipoRespuestas(res.data)
      console.log(res.data)
    })
  }

  useEffect(() => {
    getCt()
  }, [change]);

  useEffect(() => {
    get_tipo_respuestas()
    if (cl !== null) {
      dispatch(setLlamada(cl));
    }
    return () => {
      //setCl(null)
      //dispatch(setLlamada(''))
      //dispatch(setDir(''))
      //dispatch(setNumero(''))
      //dispatch(setType(''))
    };
  }, [minimized]);

  return (
    <>
      {minimized ? (
        <div
          onClick={() => setMinimized(false)}
          className={`w-16 h-16 p-3 flex justify-center right-0 bottom-0 mb-5 mr-5 absolute rounded-full bg-blue-dark transform hover:scale-110 cursor-pointer`}
        >
          <TelephoneAccessible className="text-white h-full w-full "></TelephoneAccessible>
        </div>
      ) : (
        <Draggable defaultPosition={{ x: 100, y: 1 }}>
          <div
            className={`absolute flex flex-wrap rounded-lg z-50 mt-6 flex ${
              maximized ? 'h-5/6 w-11/12' : 'h-5/6 w-4/6'
            } shadow-2xl mx-auto rounded-lg  overflow-t-hidden   pt-9  bg-gray-900 bg-opacity-75 bg-clip-padding p-4`}
          >
            <div className="absolute transform -translate-y-10 gap-x-2 h-10 w-full  flex justify-end px-5 pt-1">
              <button
                onClick={() => setMaximized(!maximized)}
                className="w-7 my-auto h-7 flex justify-center rounded-full bg-green-500 transform hover:scale-110 text-white p-1"
              >
                {maximized ? (
                  <ArrowMinimize className=" h-full w-full" />
                ) : (
                  <ArrowMaximize size="30" className="h-full font-bold w-full" />
                )}
              </button>
              <button
                onClick={() => setMinimized(true)}
                className="w-7 my-auto h-7 flex justify-center rounded-full bg-yellow-300 transform hover:scale-110 text-white p-1 pb-2"
              >
                <WindowMinimize className=" h-full w-full" />
              </button>
              <button
                onClick={() => setModal(false)}
                className="w-7 my-auto h-7 flex justify-center rounded-full bg-red-500 transform hover:scale-110 text-white p-1"
              >
                <Close className="h-full w-full font-bold" />
              </button>
            </div>
            <div className="w-2/12 h-full  bg-transparent flex flex-wrap content-start justify-end ">
              {page === 1 ? (
                <div className="h-14 place-self-start   w-full transform scale-x-105 bg-white flex justify-center border border-r-0 border-gray-300">
                  <p className="my-auto text-md lg:text-xl font-bold">Llamada</p>
                </div>
              ) : (
                <div
                  onClick={() => setPage(1)}
                  className="transform hover:scale-x-105   hover:bg-gray-50 h-14 cursor-pointer border bg-gray-200 w-full bg-white flex justify-center   transition duration-500 ease-in-out"
                >
                  <p className="my-auto text-md lg:text-xl font-bold">Llamada</p>
                </div>
              )}
              {page === 2 ? (
                <div className="h-14 place-self-start   w-full transform scale-x-105 bg-white flex justify-center border border-r-0 border-gray-300">
                  <p className="my-auto text-md lg:text-xl font-bold">Contacto</p>
                </div>
              ) : (
                <div
                  onClick={() => setPage(2)}
                  className="transform hover:scale-x-105  hover:bg-gray-50 h-14 cursor-pointer border bg-gray-200 w-full bg-white flex justify-center  transition duration-500 ease-in-out"
                >
                  <p className="my-auto text-md lg:text-xl font-bold">Contacto</p>
                </div>
              )}
              {page === 3 ? (
                <div className="h-14 place-self-start   w-full transform scale-x-105 bg-white flex justify-center border border-r-0 border-gray-300">
                  <p className="my-auto text-md lg:text-xl font-bold">Documentos</p>
                </div>
              ) : (
                <div
                  onClick={() => setPage(3)}
                  className="transform hover:scale-x-105  hover:bg-gray-50 h-14 cursor-pointer border bg-gray-200 w-full bg-white flex justify-center  transition duration-500 ease-in-out"
                >
                  <p className="my-auto text-md lg:text-xl font-bold">Documentos</p>
                </div>
              )}
              {page === 4 ? (
                <div className="h-14 place-self-start   w-full transform scale-x-105 bg-white flex justify-center border border-r-0 border-gray-300">
                  <p className="my-auto text-md lg:text-xl font-bold">Acciones</p>
                </div>
              ) : (
                <div
                  onClick={() => setPage(4)}
                  className="transform hover:scale-x-105  hover:bg-gray-50 h-14 cursor-pointer border bg-gray-200 w-full bg-white flex justify-center  transition duration-500 ease-in-out"
                >
                  <p className="my-auto text-md lg:text-xl font-bold">Acciones</p>
                </div>
              )}
              {/*page === 3 ? <div className="h-14 place-self-start  w-full transform scale-x-105 bg-white flex justify-center border border-r-0 border-gray-300"><p className="my-auto text-xl font-bold">Respuesta</p></div> :<div onClick={()=>setPage(3)} className="transform hover:scale-x-105 hover:bg-gray-50 h-14 cursor-pointer border bg-gray-200 w-full bg-white flex justify-center transition duration-500 ease-in-out"><p className="my-auto text-xl font-bold">Respuesta</p></div>*/}
            </div>
            <div className="flex flex-wrap overflow-y-scroll scroll rounded-b-lg rounded-tr-lg  content-start bg-white h-full w-10/12 p-2 ">
              {/*(page === 1 && <Script llamada={llamada.llamada}/>*/}
              {page === 2 && <Ct ct={ct} />}
              {page === 3 && <Docs />}
              {page === 4 && <Actions ct={ct}/>}
              {page === 1 && (
              
                <Resultado
                  mand={llamada.contacto.mandante}
                  setChange={setChange}
                  ct={ct}
                  mandantes={mandantes}
                  animos={animos}
                  tipos_respuestas={tipos_respuestas}
                  llamada={llamada.llamada}
                  setModal={setModal}
                />
              )}
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default FinLlamada;

