import React, { useEffect, useState, Component } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios';
import { useLocation } from 'react-router-dom';

import Back from '../../components/utils/Back';

import ContactTable from '../../components/listas/ContactTable';
import ContactosT from './ContactosT.jsx';
import Filters from './Filters.js'

import InserExcel from '../../components/listas/InsertExcel';
import CreateCModal from '../../components/contactos/CreateCModal';

import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { PersonAdd } from '@styled-icons/evaicons-solid/PersonAdd';
import { ArrowBoldDown } from '@styled-icons/entypo/ArrowBoldDown';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Contactos = () => {
  const { id } = useParams();
  const [lista, setLista] = useState({
    id: '',
  });
  const [contactos, setContactos] = useState([]);
  const [excelModal, setExcelModal] = useState(false);
  const [contactoModal, setContactoModal] = useState(false);
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: null,
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState(false);
  const [size, setSize] = useState(100);

  //const [query,setQuery] = useState('')
  let query = useQuery();

  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setContactos(res.data.results);
      setPagination(res.data);
      console.log(res.data);
    });
  };

  const getContactos = async (q = null) => {
    await axiosInstance
      .get(`contactos/?lista=${id}&${q !== null ? q : query.toString()}`)
      .then((res) => {
        setContactos(res.data.results);
        setPagination(res.data);
        console.log(res.data);
      });
  };

  const getLista = async (p) => {
    //await axiosInstance.get('/listas/'+id+'?page='+p.toString()).then((res)=>{
    await axiosInstance.get('/listas/' + id).then((res) => {
      console.log(res.data);
      setLista(res.data);
      //setContactos(res.data.contactos)
      //
    });
  };

  

  useEffect(() => {
    // getLista(page);
    getContactos();
  }, [change, page]);

  return (
    <div className="w-full flex flex-wrap py-4 items-start">
            <Filters getResps={getContactos} pagination={pagination}></Filters>
            <ContactosT
              setChange={setChange}
              move={move}
              pagination={pagination}
              objs={contactos}
            />
            <div className="flex flex-1 justify-between sm:justify-end mt-1">
    {pagination.previous && <button
                onClick={()=>move(pagination.previous)}
              
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Prev 
                
              </button>}
    {pagination.next &&<button
                onClick={()=>move(pagination.next)}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>}
            </div>
          </div>
  );
};

export default Contactos;
