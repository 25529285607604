import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';

const InsertExcel = ({ setModal, lista }) => {
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(null);

  const handleChange = (e) => {
    //console.log(e.target.files)
    setFile(e.target.files[0]);
  };

  const close = () => {
    setFile(null);
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('file', file, file.name);
    if (date !== null) {
      formData.append('fecha_limite', date);
    }
    console.log(formData);

    axiosInstance.post(`contactos/load/`, formData);
    setFile(null);
    setModal(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="absolute flex ml-96 mt-6 flex-col h-3/5 bg-white shadow-2xl mx-auto rounded-lg pt-3 px-2 w-5/12 fixed pin z-50 overflow-auto bg-smoke-light flex"
    >
      <div className="flex justify-center h-3/6 w-full">
        <label className="my-auto h-1/2 w-64 flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
          <CloudUpload size="60"></CloudUpload>
          <span className="mt-2 text-base leading-normal">Select a file</span>
          <input
            type="file"
            onChange={handleChange}
            className="hidden"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </label>
      </div>
      <div className="flex flex-wrap items-center h-1/2 w-full">
        <div className="w-full flex justify-center">
          <div className="bg-gray-200 rounded-md shadow-lg tracking-wide h-16 w-64 px-2 py-1">
            <h1 className="font-bold">{file && file.name}</h1>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="bg-gray-200 rounded-md shadow-lg tracking-wide h-16 w-64 px-2 py-1">
            <h1 className="font-bold">Fecha Limite</h1>
            <input
              name="fecha_limite"
              type="date"
              onChange={(e) => setDate(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <button
            onClick={() => close()}
            className=" focus:outline-none mx-4 py-2 px-4 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-black hover:text-white text-black ease-linear transition-all duration-150"
          >
            Cancelar
          </button>

          <button
            type="submit"
            className=" focus:outline-none mx-4 py-2 px-7 bg-white rounded-md font-bold shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-green-600 hover:text-white text-green-600 ease-linear transition-all duration-150"
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default InsertExcel;
