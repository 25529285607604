import { useState, useEffect } from 'react';

import axiosInstance from '../../axios';
import toast from 'react-hot-toast';

import { ArrowFromLeft } from '@styled-icons/boxicons-solid/ArrowFromLeft';
import { SendPlane } from '@styled-icons/remix-fill/SendPlane';

const TemplateTestBar = ({ id }) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [fields, setFields] = useState([]);
  const [domains, setDomains] = useState([]);

  const templateType = 2; //fullcall

  const getFields = async () => {
    await axiosInstance.get(`contactos/fields/`).then((res) => {
      setFields(res.data);
    });
  };

  const getDomains = async () => {
    await axiosInstance.get(`mail_domains`).then((res) => {
      setDomains(res.data);
    });
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const send = async () => {
    console.log(data['email']);
    if (data['email'] === undefined || data['email'] === '') {
      toast.error('Ingrese un Email');
      return 0;
    }
    await axiosInstance
      .post(`mails/test/`, { ...data, template_type: templateType, template_id: id })
      .then((res) => {
        toast.success('En unos segundos le llegara  un mail');
      });
  };

  const add_photo = async () => {
    let form_data = new FormData();
    // console.log(image)
    image !== null && form_data.append('picture', image);
    //

    axiosInstance.patch(`mail_templates_designs/${id}/`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    // axiosInstance.patch(`mail_templates_designs/${id}/`, {picture:image},{headers:{'content-type':'multipart/form-data'}})
  };

  // add button send
  // save
  // export``

  useEffect(() => {
    getFields();
    getDomains();
  }, []);
  return (
    <div
      className={`${
        expand ? 'w-52' : 'w-12'
      } border-r flex flex-wrap  items-start content-start flex-shrink-0 divide-y gap-y-3 h-full`}
    >
      <div className="bg-white rounded-md w-full h-9 mb-1 pl-1 text-gray-500">
        {expand ? (
          <ArrowFromLeft
            size="32"
            onClick={() => setExpand(false)}
            className="transform hover:scale-110 cursor-pointer rotate-180 w-full h-full"
          />
        ) : (
          <ArrowFromLeft
            size="32"
            onClick={() => setExpand(true)}
            className="transform hover:scale-110 cursor-pointer h-full w-full"
          />
        )}
      </div>

      <div className="w-full border-t flex flex-wrap justify-center p-2 gap-y-2 ">
        {expand && (
          <>
            <p className="w-full text-center text-lg text-gray-700">Probar Template</p>

            <p className="w-full text-start">Email</p>
            <input
              onChange={handleChange}
              type="email"
              name="email"
              className="w-full h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            ></input>

            <p className="w-full text-start text-gray-700">Nro</p>
            <input
              onChange={handleChange}
              type="text"
              name="nro_cuenta"
              className="w-full h-10 border  rounded-md p-1 bg-white text-gray-900   sm:text-sm"
            ></input>

            <p className="w-full text-start text-gray-700">Dominio</p>
            <select
              onChange={handleChange}
              type="text"
              name="domain"
              className="w-full border  rounded-md p-3 bg-white text-gray-900   sm:text-sm"
            >
              <option key={0} value="">
                ---------
              </option>
              {domains.map((dom) => {
                return (
                  <option key={dom.id} value={dom.id}>
                    {dom.dominio}
                  </option>
                );
              })}
            </select>
          </>
        )}

        <div
          onClick={() => send()}
          className="rounded-md transform hover:scale-105 cursor-pointer w-full text-center flex justify-center bg-blue-ligth text-gray-50 p-1 "
        >
          <SendPlane size="22" className="text-gray-100 "></SendPlane>
        </div>
      </div>
      <div className="w-full h-60 border-t border-b  p-1 overflow-y-scroll">
        {expand && (
          <>
            <p className="w-full text-center text-lg text-gray-700">Variables</p>

            <div className="w-full mt-2 flex flex-wrap justify-center gap-y-1">
              {fields.map((f) => {
                return (
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(`{{${f}}}`);
                      toast.success(`${f} copiado`);
                    }}
                    className="h-auto w-4/5 text-gray-800 p-1 rounded-md bg-gray-50 border text-center text-lg cursor-pointer break-all"
                  >
                    {f}
                  </p>
                );
              })}
            </div>
          </>
        )}
      </div>
      {expand && (
        <>
          {' '}
          <div className="font-semibold leading-6 rounded-md px-3 py-2 gap-y-2 flex flex-wrap p-1">
            <p className="w-full text-center text-lg text-gray-700">Foto Template</p>

            <input
              type="file"
              name="picture"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              className="block w-full rounded-md  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div
            onClick={() => add_photo()}
            className="rounded-md transform hover:scale-105 cursor-pointer w-full text-center flex justify-center bg-blue-ligth text-gray-50 p-1 "
          >
            {/* <SendPlane size="22" className='text-gray-100 '></SendPlane>                    */}
            Guardar Foto
          </div>
        </>
      )}
    </div>
  );
};

export default TemplateTestBar;
