import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

 const Preview2 = ({open,setOpen,script}) => {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-3/4 h-3/4 transform overflow-y-scroll rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6">

              <div  className="flex flex-wrap content-start w-full  px-2 ">
                <div className="flex w-full ">
                  <p className="my-auto w-full text-gray-800 text-2xl text-center font-bold uppercase">
                    {script && script.nombre}
                  </p>
                </div>
                {script &&
                  script.bloques.length > 0 &&
                  script.bloques.map((bloque) => {
                    return (
                      <div className="flex w-full border mt-2 rounded-md">
                          <>
                            <div className="w-2/12 border-r   flex justify-center">
                              <p className="my-auto text-xl text-gray-700 font-semibold capitalize">
                                {bloque && bloque.nombre}
                              </p>
                            </div>
                            <div className="w-10/12   flex p-2 border-r">
                              <p className="w-full  font-mono text-xl text-gray-600  break-words  px-2">
                                {bloque && bloque.cuerpo}
                              </p>
                            </div>
                          </>
                          
                        </div>
                        )})}

              </div>





                
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Volver
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default Preview2;
