import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Bars = ({ data }) => {
  console.log(data);
  return (
    <ResponsiveContainer width="100%" height="100%" className="border rounded-md">
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        {/*<CartesianGrid  />*/}
       <XAxis dataKey="name" />
        {/* <YAxis /> */}
        <Tooltip payload={[{ name: '05-01', value: 12, unit: 'kg' }]} />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        {/*<ReferenceLine y={0} stroke="#0023" />*/}
        {/* <Brush dataKey="name" height={30} stroke="#00000" /> */}
        <Bar name="con contacto" dataKey="con confirmacion" stackId="a" fill="#3B81F6" />
        <Bar name="sin Contacto" dataKey="sin confirmacion" stackId="a" fill="#a5bec7" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Bars;
