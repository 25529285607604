import {useState,useEffect} from 'react'
import Toggle from '../Toggle.jsx'
import axiosInstance from '../../axios.js'
import toast from 'react-hot-toast'

const Config = ({id}) =>{
  const [camp,setCamp] = useState(null)
  const [data,setData] = useState(null)
  const [fields,setFields] = useState([])

  const getCamp = async () => {
    await axiosInstance.get(`mail_campaigns/` + id).then((res) => {
      setCamp(res.data)
      setData(res.data)
      console.log(res.data)
    });

  }
  const getCtFields = async () => {
    await axiosInstance.get(`contactos/fields`).then((res) => {
      setFields(res.data)
    });
  }

  const handleChange = (e) =>{
    setData({...data,[e.target.name]:e.target.value})
  }

  const handleChangeT = (value,name) =>{
    console.log(value)
    setData({...data,[name]:value})
  }

  const update = async () =>{
    await axiosInstance.patch(`mail_campaigns/${id}/`,data).then((res) => {
      toast.success('configuracion actualizada')
    })
  }


  useEffect(()=>{
    getCamp()
    getCtFields()
  },[])

  return (
    <div className="w-full p-2 flex flex-wrap border shadow-sm bg-white rounded-md">
      <div className="w-full flex flex-wrap gap-y-2">
          <div className="flex flex-wrap">
              <h2 className="w-full text-xl font-semibold leading-7 text-gray-900">Informacion</h2>
              <p className="w-full mt-1 text-sm leading-6 text-gray-500">
                Configuracion de datos.
              </p>
          </div>

          <div className="w-full flex flex-wrap gap-y-3 border-t-2  border-b-2 py-4 pb-4 px-2 ">
            <div className="w-full flex justify-between">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Nombre</dt>
              <input type="text" onChange={handleChange} name="nombre" value={data && data.nombre} className=" border px-2 font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6"></input>
            </div>
          </div>

          <div className="flex flex-wrap">
              <h2 className="w-full  font-semibold text-xl leading-7 text-gray-900">Ganancia</h2>
              <p className="w-full mt-1 text-sm leading-6 text-gray-500">
                Esta configuracion se vera reflejada en el grafico de ganancia.
              </p>
          </div>
          <div className="w-full flex flex-wrap gap-y-3 border-t-2 border-b-2 py-4 pb-4 px-2">
            <div className="w-full flex justify-between">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Campo</dt>
              <select type="text" onChange={handleChange} name="ganancia_field"  value={data && data.ganancia_field} className=" border px-2 font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                {fields.map((f)=>{
                return <option value={f}>{f}</option>
                
              })}

            </select>
            </div>
            <div className="w-full flex justify-between">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Moneda</dt>
              <input type="text" onChange={handleChange} name="ganancia_currency" value={data && data.ganancia_currency} className=" border px-2 font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6"></input>
            </div>
            <div className="w-full flex justify-between">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Sumar abiertos</dt>
              <Toggle name="sum_opened" onChange={(e)=>handleChangeT(!data.sum_opened,"sum_opened")} value={data && data.sum_opened}></Toggle>
            </div>
            <div className="w-full flex justify-between">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Sumar Clicks</dt>
              <Toggle name="sum_click" onChange={(e)=>handleChangeT(!data.sum_click,"sum_click")} value={data && data.sum_click}></Toggle>
            </div>
          </div>
          
        
          <button
          onClick={()=>update()}
          type="button"
          className="rounded-md self-end bg-blue-ligth px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Guardar
        </button>
      </div>

    </div>
  )
}

export default Config;
