import {useState} from 'react'
import Filters from '../components/respuestas/Filters';



const TestComps = () =>{

const pagination = {
    count: 0,
    page: null,
    next: null,
    previous: null,
}

  return (
    <div className="w-full p-4">
      <Filters pagination={pagination}></Filters>

    </div>
  )
}

export default TestComps
