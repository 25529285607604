import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';

import format from '../../../utils/formatDate';

import { Refresh } from '@styled-icons/foundation/Refresh';

import { Download } from '@styled-icons/evaicons-solid/Download';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TCargas = ({ change, setChange }) => {
  const [page, setPage] = useState(1);
  const [carga, setCarga] = useState(null);
  const [detail, showDetail] = useState(false);
  const [cargas, setCargas] = useState([]);
  const [selected, setSelected] = useState(null);
  const [actionModal, setActionModal] = useState(false);

  const getCargas = async () => {
    await axiosInstance.get('carga_docs/').then((res) => {
      setCargas(res.data);
    });
  };
  const move = (url) => {
    axiosInstance.get(url).then((res) => {
      setCargas(res.data);
    });
  };

  useEffect(() => {
    getCargas();
  }, [change]);

  return (
    <div className="flex flex-wrap w-full h-auto p-2 rounded-lg">
      <div className="w-full h-12 flex justify-end">
        <Refresh
          onClick={() => setChange(new Date())}
          size="30"
          className="text-blue-400 cursor-pointer transition ease-in-out duration-700 transform hover:scale-125 hover:rotate-90"
        ></Refresh>
      </div>
      {page === 1 && (
        <table className="table-auto w-full">
          <thead>
            <th className="border p-2">Fecha</th>
            <th className="border p-2">Archivo</th>
            <th className="border p-2">User</th>
            <th className="border p-2">Total</th>
            <th className="border p-2">Validas</th>
            <th className="border p-2">Invalidas</th>
          </thead>

          <tbody>
            {cargas.results &&
              cargas.results.length > 0 &&
              cargas.results.map((c) => {
                return (
                  <tr>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {format(c.add_datetime)}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      <a className="text-blue-500" href={c.file && c.file}>
                        {c.file_name ? c.file_name : 'archivo'}
                      </a>
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.user && `${c.user.first_name} ${c.user.last_name}`}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.validas}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.invalidas}
                    </td>
                    <td className="px-2 py-1 bg-gray-50 text-gray-700 text-center border">
                      {c.invalidas}{' '}
                      {c.invalidas > 0 && (
                        <a href={c.errors_file && c.errors_file}>
                          <Download size="16" className="hover:scale-105 text-blue-500 transform" />
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {cargas.previous && (
        <div
          onClick={() => move(cargas.previous)}
          className="px-3 py-1 bg-blue-ligth transform hover:scale-110 font-semibold text-white rounded-md mt-1 cursor-pointer"
        >
          Prev
        </div>
      )}
      {cargas.next && (
        <div
          onClick={() => move(cargas.next)}
          className="px-3 py-1 bg-blue-ligth transform hover:scale-110 font-semibold text-white rounded-md mt-1 cursor-pointer"
        >
          Next
        </div>
      )}
    </div>
  );
};

export default TCargas;
